import upperFirst from "lodash/upperFirst";
import ProjectBreadcrumbs from "screens/Dashboard/Projects/components/ProjectBreadcrumbs";
import ProjectDrawer from "screens/Dashboard/Projects/components/ProjectDrawer";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import AssetPostInner from "./FormInner";
import useAssetPost from "./hook";

const AssetPost = () => {
    const { type } = useEncryptedParams<{ type: string }>();
    const hook = useAssetPost();

    return (
        <ProjectDrawer
            header={<ProjectBreadcrumbs drawer={true} />}
            open={hook.open}
            onClose={() => hook.setOpen(false)}
            title={hook.asset_id ? `Edit ${upperFirst(type)} Asset` : `Submit ${upperFirst(type)} Asset`}
        >
            <AssetPostInner onSuccess={() => hook.setOpen(false)} />
        </ProjectDrawer>
    );
};

export default AssetPost;
