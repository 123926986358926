import useImages from "assets/images";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import FileUploader from "shared/components/UppyUploader/FileUploader";
import { useAppDispatch } from "shared/hooks/useRedux";
import { setScanTutorialVideo } from "store/slices/tutorial";

const ScanUpload = () => {
    const context = useFormContext();
    const Images = useImages();
    const type = context.watch("dataset_type");
    const dispatch = useAppDispatch();
    const [showTutorial, setShowTutorial] = useState(true);

    return (
        <div className="relative h-full border border-[#D0D5DD] rounded-lg overflow-hidden">
            {showTutorial && (
                <div
                    className="absolute top-4 left-4 z-20 rounded-2xl text-primary text-sm leading-[18px] font-medium h-7 px-2 items-center space-x-[6px] cursor-pointer w-[33px] whitespace-nowrap overflow-hidden animate-tutorial-mark inline-flex"
                    onClick={() => {
                        dispatch(
                            setScanTutorialVideo({
                                open: true,
                            })
                        );
                    }}
                >
                    <span className="min-w-[19px]">
                        <Images.QuestionMarkCircleIcon />
                    </span>
                    <span>Guide to Capturing Optimal focused Scenes</span>
                </div>
            )}
            <FileUploader
                containerClassName="h-[400px]"
                plugins={["Local", "Google-Drive", "Dropbox"]}
                type={type === "video" ? "video" : "zip"}
                onSelectFile={(file) => setShowTutorial(Boolean(!file))}
                onFileUpload={(file, selectedFile) => {
                    if (file) context.setValue("input_file_id", file.file_id);
                    if (selectedFile) context.setValue("name", selectedFile.name.split(".")[0]);
                }}
            />
        </div>
    );
};

export default ScanUpload;
