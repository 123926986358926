import { Skeleton } from "@mui/material";
import PageHeader from "shared/components/ui/PageHeader";
import ProjectContent from "./ProjectContent";

const AssetLoading = () => {
    return (
        <>
            <PageHeader
                title={
                    <>
                        <Skeleton width={300} />
                        <Skeleton width={80} />
                    </>
                }
            />
            <ProjectContent className="w-full">
                <div className="bg-white rounded-xl flex flex-col h-full">
                    <div className="flex items-center justify-between space-x-3 mb-5 border-b pb-4">
                        <div className="flex item-center space-x-3">
                            <h1 className="text-gray-900 text-[30px] font-medium leading-10 whitespace-nowrap">
                                <Skeleton width={150} />
                            </h1>
                            <span className="items-center border border-[#D0D5DD] shadow-sm bg-white self-center flex gap-1 px-1.5 py-0.5 rounded-md border-solid">
                                <div className="text-slate-700 text-center text-xs font-medium leading-5 self-stretch grow whitespace-nowrap">
                                    <Skeleton width={50} />
                                </div>
                            </span>
                        </div>
                    </div>

                    <div className="grid grid-cols-[1fr,1px,350px] gap-8 grow">
                        <div className="flex flex-col">
                            <div className="grid grid-cols-4 gap-4 mb-4">
                                <Skeleton width={"100%"} height={132} className="!translate-x-0" />
                                <Skeleton width={"100%"} height={132} className="!translate-x-0" />
                                <Skeleton width={"100%"} height={132} className="!translate-x-0" />
                                <Skeleton width={"100%"} height={132} className="!translate-x-0" />
                            </div>
                            <Skeleton width={"100%"} height={310} className="!translate-x-0" />
                        </div>
                        <div className="border-l" />
                        <Skeleton width={"100%"} height={461} className="!translate-x-0" />
                    </div>
                </div>
            </ProjectContent>
        </>
    );
};

export default AssetLoading;
