import useImages from "assets/images";
import { NavLink } from "react-router-dom";
import URL from "shared/constants/navigator";
import { GetProfilesQuery } from "shared/graphql";
import { useAppDispatch } from "shared/hooks/useRedux";
import { setSelectedAccount } from "store/slices/accounts";
import { twMerge } from "tailwind-merge";

const AccountLists = ({ data }: { data: GetProfilesQuery["profiles"] }) => {
    const Images = useImages();
    const dispatch = useAppDispatch();

    return (
        <>
            {data
                .filter((p) => p.type !== "Default")
                .map((item, index) => {
                    const image = item.profile_image ? item?.profile_image?.url : "";

                    return (
                        <NavLink
                            key={`${item.profile_id}-${index}`}
                            to={`${URL.Settings}/${URL.Accounts}/${item.profile_id}`}
                            onClick={() => {
                                dispatch(setSelectedAccount(item));
                            }}
                            className={({ isActive }) => {
                                return twMerge(
                                    "relative flex py-4 items-center px-2 space-x-4 border-b border-[#D0D5DD80] !last:border-b-0 hover:border-primary hover:border-opacity-50 transition-300 group",
                                    isActive ? "active !border-primary border-opacity-50" : ""
                                );
                            }}
                        >
                            <div>
                                {image ? (
                                    <div className="w-fit border-[0.5px] border-[#D0D5DD] rounded-md">
                                        <img
                                            src={image}
                                            className="w-[27px] h-[27px] rounded-md object-cover object-center"
                                        />
                                    </div>
                                ) : (
                                    <Images.TeamPaymentIcon />
                                )}
                            </div>
                            <div className="flex flex-col space-y-[2px]">
                                <span className="capitalize text-lg leading-6 font-medium text-[#3F3E3E] group-hover:text-primary transition-300 group-[.active]:text-primary">
                                    {item.name}
                                </span>
                                <span className="text-[10px] leading-[14px] font-medium text-[#5E5D5D]">
                                    {item.username ? `${item.username} - ` : ""}
                                    {item.type}
                                </span>
                            </div>
                            <div className="absolute top-1/2 -translate-y-1/2 right-4 opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-300 group-[.active]:opacity-100 group-[.active]:visible">
                                <Images.ArrowChevronRight color="#7680FF" />
                            </div>
                        </NavLink>
                    );
                })}
        </>
    );
};

export default AccountLists;
