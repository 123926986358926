import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
    AddScanInviteesMutation,
    RemoveScanInviteesMutation,
    useAddScanInviteesMutation,
    useRemoveScanInviteesMutation,
    useUpdateScanMutation,
} from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { encryptIdForUrl } from "shared/utils/encrypt-url";
import { MyScanVisibility } from "shared/utils/enums";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setSelectedScan } from "store/slices/scans";

const schema = yup.object().shape({
    visibility: yup.string().oneOf(Object.values(MyScanVisibility)),
    emails: yup.array().when("visibility", {
        is: (visibility) => visibility !== MyScanVisibility.Private,
        then: (schema) => schema.of(yup.string().email()),
    }),
    link: yup.string().url(),
});

type FormValues = yup.InferType<typeof schema>;

const usePublishScan = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [update, updateRes] = useUpdateScanMutation();
    const [addInvitees, inviteesRes] = useAddScanInviteesMutation();
    const [removeInvitees, removeInviteesRes] = useRemoveScanInviteesMutation();
    const [clickedEmail, setClickedEmail] = useState("");

    const selected = useAppSelector((state) => state.scans.selected);
    const project = useAppSelector((state) => state.projects.selected);
    const folder = project?.folders.find((folder) => folder.folder_id === selected?.folder_id ?? 0);

    const [open, setOpen] = useState(false);

    const link = `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${encryptIdForUrl(
        selected?.scan_id ?? 0
    )}`;

    const form = useForm<FormValues>({
        defaultValues: {
            visibility: (selected?.visibility || MyScanVisibility.Private) as any,
            emails: [selected?.visibility !== MyScanVisibility.Private ? "" : null],
            link,
        },
        resolver: yupResolver(schema),
    });

    const { fields, append, remove } = useFieldArray({
        control: form.control,
        name: "emails",
    });

    const emails = form.watch("emails", []);

    useEffect(() => {
        rtkHandler(updateRes, {
            successMessage: "Share settings updated successfully",
            onSuccess() {
                const emailsData = emails?.filter((i) => i);
                if (visibility !== MyScanVisibility.Private && emailsData?.length && selected) {
                    addInvitees({
                        data: {
                            scan_id: selected.scan_id,
                            emails: emails as string[],
                        },
                    });
                } else {
                    if (selected) dispatch(setSelectedScan({ ...selected, visibility: visibility as any }));
                    setOpen(false);
                }
            },
        });
    }, [updateRes]);

    useEffect(() => {
        rtkHandler(inviteesRes, {
            onSuccess({ add_scan_invitee }: AddScanInviteesMutation) {
                if (selected) dispatch(setSelectedScan({ ...selected, invitees: add_scan_invitee.invitees }));
                setOpen(false);
            },
        });
    }, [inviteesRes]);

    useEffect(() => {
        rtkHandler(removeInviteesRes, {
            successMessage: "Invitee removed successfully",
            onSuccess({ remove_scan_invitee }: RemoveScanInviteesMutation) {
                if (selected) dispatch(setSelectedScan({ ...selected, invitees: remove_scan_invitee.invitees }));
            },
        });
    }, [removeInviteesRes]);

    const visibility = form.watch("visibility");

    useEffect(() => {
        if (visibility === MyScanVisibility.Private) {
            form.setValue("emails", []);
        } else {
            form.setValue("emails", [""]);
        }
    }, [visibility]);

    useEffect(() => {
        if (!folder && selected?.folder_id) {
            navigate(-1);
        } else {
            setOpen(true);
        }
    }, []);

    const onSubmit = (data: FormValues) => {
        if (!selected) return;
        update({
            id: selected.scan_id,
            data: {
                visibility: data.visibility,
            },
        });
    };

    return {
        onSubmit,
        removeInvitees,
        append,
        remove,
        setClickedEmail,
        setOpen,
        form,
        clickedEmail,
        updateRes,
        removeInviteesRes,
        fields,
        selected,
        project,
        folder,
        folder_id: selected?.folder_id,
        id: selected?.project_id,
        open,
        link,
        visibility,
    };
};

export default usePublishScan;
