import { StepType } from "@reactour/tour";
import { Content } from "./components/Elements";

export const OnBoardDashboardSteps: StepType[] = [
    {
        selector: ".dashboard-item",
        position: "right",
        content: (
            <Content
                title="Dashboard Tab"
                body="This is your Dashboard, where you can quickly digest everything going on in your XSpada account."
            />
        ),
    },
    {
        selector: ".media-item",
        position: "right",
        content: (
            <Content
                title="Media Tab"
                body="This is your Media, where you store all of your captured assets, create projects, & share deliverables."
            />
        ),
    },
    {
        selector: ".knowledge-library-item",
        position: "right",
        content: (
            <Content
                title="Knowledge Library Tab"
                body="The Knowledge Library is where you go when you want to learn either how to use XSpada, or how to use some asset from XSpada outside of platform with 3rd party tools."
            />
        ),
    },
    {
        selector: ".notification-item",
        position: "right",
        content: (
            <Content
                title="Notifications"
                body="Your Notifications are where you can see whatever it is you or your team has been up to on your account."
            />
        ),
    },
    {
        selector: ".user-menu-item",
        position: "top",
        content: (
            <Content
                title="Account Menu"
                body="This is your account’s menu. Based on the team you have selected, you can begin managing the account settings & options from here."
            />
        ),
    },
];

export const ScanScreenSteps: StepType[] = [
    {
        selector: ".scan-screen",
        position: "left",
        content: (
            <Content
                title="Assets Page"
                body="This is the page where you will be able to see the details about any asset you’ve added into the XSpada platform."
            />
        ),
    },
    {
        selector: ".scan-detail-card",
        position: "right",
        content: (
            <Content
                title="Asset Details"
                body="Here is where the capture details of an asset are located."
            />
        ),
    },
    {
        selector: ".scan-video-detail",
        position: "left",
        content: (
            <Content
                title="File Details"
                body="These are the details of the file itself, and any metadata information we’ve made available."
            />
        ),
    },
    {
        selector: ".scan-header-action-btn",
        position: "left",
        content: (
            <Content
                title="Asset Sharing"
                body="To preform actions with an asset, like sharing or starting studio, you navigate here, where an asset’s action menu is."
            />
        ),
    },
    {
        selector: ".scan-action-btns",
        position: "left",
        content: (
            <Content
                title="Asset Operations"
                body="To preform actions with an asset, like downloading model or embedding, you will refer to this asset specific menu."
            />
        ),
    },
];
