import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import { useEffect, useMemo, useRef } from "react";
import { updateContentInEditor } from "screens/Dashboard/PublishableProjects/utils/utls";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";
import RepositioningHandler from "../RepositioningHandler";

export interface IButtonProps {
    id: string;
    content: string | null;
    color: string;
    link: string | null;
    width: number;
    justify: "left" | "center" | "right";
    size: 1 | 2 | 3 | 4 | 5;
}

const Button = (props: any) => {
    const { id, content, color, link, width, justify, size } = props.node.attrs as IButtonProps;
    const dispatch = useAppDispatch();

    const contentRef = useRef(null);
    const mutationObserverRef = useRef<any>(null);

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);
    const isPreviewOpen = useAppSelector((state) => state.publishable_project.isPreviewOpen);

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const alignmentStyles = useMemo(() => {
        let style = " ";

        if (justify == "left") {
            style += "justify-start";
        } else if (justify == "right") {
            style += "justify-end";
        } else {
            style += "justify-center";
        }

        return style;
    }, [justify]);

    const sizingStyles = useMemo(() => {
        if (size == 1) {
            return "text-[18px] leading-[28px] px-6 py-4";
        } else if (size == 2) {
            return "text-lg leading-[24px] px-5 py-3";
        } else if (size == 3) {
            return "text-lg leading-[24px] px-[18px] py-[10px]";
        } else if (size == 4) {
            return "text-md leading-[20px] px-4 py-[10px]";
        } else {
            return "text-md leading-[20px] px-[14px] py-[8px]";
        }
    }, [size]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = props.editor;

        props.editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    useEffect(() => {
        if (isSelected) {
            const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
            parentEditor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    useEffect(() => {
        if (contentRef.current) {
            (contentRef.current as any).innerHTML = content ?? "Button";

            (contentRef.current as any).addEventListener("paste", function (event) {
                // Prevent the default paste action
                event.preventDefault();

                // Get the text content from the clipboard
                const text = event.clipboardData.getData("text/plain");

                // Insert the text at the current cursor position
                document.execCommand("insertText", false, text);
            });
        }
    }, []);

    useEffect(() => {
        if (contentRef.current) {
            // Define what to do when mutations are observed
            const observerCallback = (mutationsList) => {
                if (mutationsList.length > 0) {
                    updateContentInEditor(parentEditor!, id, {
                        content: (contentRef.current as any).innerHTML,
                    });
                }
            };

            // Create a new observer instance
            mutationObserverRef.current = new MutationObserver(observerCallback);

            // Start observing the contentEditable element
            mutationObserverRef.current.observe(contentRef.current, {
                characterData: true,
                childList: true,
                subtree: true,
                attributeFilter: ["style"],
            });
        }

        // Disconnect the observer when the component unmounts
        return () => {
            if (mutationObserverRef.current) {
                mutationObserverRef.current.disconnect();
            }
        };
    }, [contentRef, parentEditor]);

    useEffect(() => {}, [content]);

    return (
        <NodeViewWrapper
            id={id}
            className={`editor-block button-component relative ${isSelected && "editor-block-selected"} bg-white`}
            onClick={
                isPreviewOpen
                    ? () => {}
                    : (e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          dispatch(
                              setSelectedBlock({
                                  type: "buttonComponent",
                                  props: { id: props.node.attrs.id, attrs: props.node.attrs },
                              })
                          );
                      }
            }
        >
            <div className={"w-full flex " + alignmentStyles}>
                <a
                    href={link && link.length > 0 ? link : "#"}
                    style={{ width: width < 25 ? "25%" : width > 100 ? "100%" : width + "%" }}
                    target="_blank"
                    rel="noreferrer"
                    className="!no-underline"
                >
                    <div
                        ref={contentRef}
                        contentEditable={!isPreviewOpen}
                        style={{
                            backgroundColor: color,
                            border: "1px solid " + color,
                        }}
                        className={"w-full text-center font-medium rounded-lg text-[#FFFFFF] " + sizingStyles}
                    ></div>
                </a>
            </div>
            {parentEditor && !isPreviewOpen && <RepositioningHandler node_id={id} editor={parentEditor} />}
        </NodeViewWrapper>
    );
};

export default Button;
