import PlaceholderExt from "./Placeholder/Placeholder.Ext";

export const Div = PlaceholderExt.extend({
    name: "paragraphDiv",
});

// Node.create({
//     name: "paragraphDiv",

//     priority: 1000,

//     group: "block",

//     content: "",

//     renderHTML() {
//         return ["div", { class: "editor-placeholder" }, "Drop  here"];
//     },
// });
