import { Button, Tooltip } from "@mui/material";
import ArrowUpRight from "@untitled-ui/icons-react/build/esm/ArrowUpRight";
import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";
import { StudioLink } from "shared/constants/const";
import TooltipTitles from "shared/constants/disabledButtonTooltipTitles";
import useAuth from "shared/hooks/useAuth";
import { useAppSelector } from "shared/hooks/useRedux";
import { encryptIdForUrl } from "shared/utils/encrypt-url";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");


const StartViewer = (props: { setOpenViewer: React.Dispatch<React.SetStateAction<string>> }) => {
    const Can_View_Scan_In_Viewer = useHasPermission(Permissions.Can_View_Scan_In_Viewer);
    const scan = useAppSelector((state) => state.scans.selected);
    const { token, profile, user } = useAuth();
    const model = scan?.model;

    if (scan && model) {
        return (
            <div className="flex items-center space-x-4">
                {scan.method !== "nerfacto" && (
                    <Tooltip
                        title={
                            model.status !== "Completed"
                                ? "Start Studio" + TooltipTitles.ScanNotCompletedTooltipTitle
                                : !model.splat_file
                                ? "Start Studio" + ": This scan needs to get reprocessed for generating ply file."
                                : ""
                        }
                    >
                        <div>
                            <Button
                                variant="contained"
                                disabled={model.status !== "Completed" || !model.splat_file}
                                className="project-btn"
                                onClick={() => {
                                    if (!Can_View_Scan_In_Viewer) {
                                        props.setOpenViewer(
                                            `${StudioLink}/dashboard?scan_id=${encryptIdForUrl(
                                                scan.scan_id
                                            )}&user_id=${encryptIdForUrl(user!.user_id)}&user_email=${
                                                user!.email
                                            }&embed=true&profile_id=${
                                                profile?.profile_id ? encryptIdForUrl(profile?.profile_id) : null
                                            }&token=${token}`
                                        );
                                        return;
                                    }

                                    const url = `${StudioLink}?scan_id=${encryptIdForUrl(scan.scan_id)}&user_id=${
                                        user?.user_id ? encryptIdForUrl(user?.user_id) : null
                                    }&user_email=${user?.email}&profile_id=${
                                        profile?.profile_id ? encryptIdForUrl(profile?.profile_id) : null
                                    }`;
                                    amplitude.track('Viewer Opened', scan);
                                    window.open(url, "_blank");
                                }}
                                endIcon={<ArrowUpRight width={20} height={20} />}
                            >
                                Start Studio
                            </Button>
                        </div>
                    </Tooltip>
                )}
            </div>
        );
    } else {
        return <></>;
    }
};

export default StartViewer;
