import { Tooltip } from "@mui/material";
import ArrowUpRight from "@untitled-ui/icons-react/build/esm/ArrowUpRight";
import Image05 from "@untitled-ui/icons-react/build/esm/Image05";
import { capitalize } from "lodash";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import EmptyListCard from "screens/Dashboard/Projects/components/EmptyListCard";
import ProjectContent from "screens/Dashboard/Projects/components/ProjectContent";
import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";
import ViewerModal from "shared/components/ViewerModal";
import RenderImage from "shared/components/ui/RenderImage";
import TooltipTitles from "shared/constants/disabledButtonTooltipTitles";
import URL from "shared/constants/navigator";
import { AssetDetailFragment, AssetVisibility, ScanDetailFragment } from "shared/graphql";
import { useAppDispatch } from "shared/hooks/useRedux";
import { encryptIdForUrl } from "shared/utils/encrypt-url";
import { extractLocationName, getViewerLink } from "shared/utils/helpers";
import { setSelectedAsset, setSelectedScan } from "store/slices/scans";
import { twMerge } from "tailwind-merge";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");

export interface AssetData {
    name: string;
    created_at: string;
    thumbnail: string;
    id: string;
    method: string;
    location: string | undefined;
    visible: boolean;
    type: string;
    status?: string;
    splat_file?: number;
    folder_id?: number | null | undefined;
}

interface PropsWithData {
    title?: string;
    containerClassName?: string;
    data: AssetData[];
}

interface PropsWithScansAssets {
    title?: string;
    containerClassName?: string;
    scans: ScanDetailFragment[];
    assets: AssetDetailFragment[];
}

type Props = PropsWithData | PropsWithScansAssets;

const AssetsList = (props: Props) => {
    const dispatch = useAppDispatch();
    const Can_View_Scan_In_Viewer = useHasPermission(Permissions.Can_View_Scan_In_Viewer);
    const [openViewer, setOpenViewer] = useState("");
    const [scan_name, setScanName] = useState("");

    const scans = (props as PropsWithScansAssets).scans?.map((scan) => ({
        name: scan.name,
        created_at: scan.created_at || "",
        thumbnail: scan.input_file.thumbnail || "",
        id: encryptIdForUrl(scan.scan_id),
        location: extractLocationName(scan.location.name) || "",
        visible: scan.visibility === "public",
        method: scan.method,
        status: scan.model.status,
        splat_file: scan.model.splat_file?.file_id,
        type: "scan",
    }));

    const assets = (props as PropsWithScansAssets).assets?.map((asset) => ({
        name: asset.name,
        created_at: asset.created_at || "",
        thumbnail: (asset.file.type === "Video" ? asset.file.thumbnail : asset.file.url) || "",
        id: encryptIdForUrl(asset.asset_id),
        visible: asset.visibility === AssetVisibility.Public,
        method: asset.focus,
        location: extractLocationName(asset.location.name) || "",
        type: "asset",
    }));

    const data: AssetData[] = (props as PropsWithData).data ?? [...(scans || []), ...(assets || [])];

    useEffect(() => {
        return () => {
            dispatch(setSelectedScan(null));
            dispatch(setSelectedAsset(null));
        };
    }, []);

    return (
        <>
            {props.title && (
                <div className="px-6 pt-1 text-[#344054] text-[20px] leading-6 font-medium">{props.title}</div>
            )}
            <ProjectContent className={props.containerClassName}>
                {data.length ? (
                    <div className="grid grid-cols-4 gap-4">
                        {data.map((asset) => {
                            const embededLink = getViewerLink(asset.id, true);
                            const windowLink = getViewerLink(asset.id);
                            
                            return (
                                <div key={asset.id} className="group relative">
                                    {asset.visible && (
                                        <span className="absolute top-5 left-5 items-center border border-[#D0D5DD] shadow-sm bg-white self-center flex gap-1 px-1.5 py-0.5 rounded-md border-solid z-10">
                                            <div className="bg-[#15AA2C] w-[6px] h-[6px] rounded-full" />
                                            <div className="text-slate-700 text-center text-xs font-medium leading-5 self-stretch grow whitespace-nowrap">
                                                Public
                                            </div>
                                        </span>
                                    )}
                                    {asset.type === "scan" && (
                                        <Tooltip
                                            title={
                                                asset.status === "Failed"
                                                    ? "Start Studio" + TooltipTitles.ScanFailedTooltipTitle
                                                    : asset.status !== "Completed"
                                                    ? "Start Studio" + TooltipTitles.ScanNotCompletedTooltipTitle
                                                    : !asset.splat_file
                                                    ? "Start Studio" +
                                                      ": This scan needs to get reprocessed for generating ply file."
                                                    : ""
                                            }
                                            placement="top"
                                        >
                                            <div
                                                onClick={() => {
                                                    amplitude.track('Viewer Opened');
                                                    if (asset.status !== "Completed" || !asset.splat_file) {
                                                        return;
                                                    }

                                                    if (!Can_View_Scan_In_Viewer) {
                                                        setOpenViewer(embededLink);
                                                        setScanName(asset.name);
                                                        return;
                                                    }
                                                    window.open(windowLink, "_blank");
                                                }}
                                                className={twMerge(
                                                    "cursor-pointer absolute top-5 right-5 items-center border border-[#D0D5DD] shadow-sm bg-white self-center flex gap-1 px-1.5 py-0.5 rounded-md border-solid invisible opacity-0 group-hover:visible group-hover:opacity-100 transition-300 z-10",
                                                    (asset.status !== "Completed" || !asset.splat_file) &&
                                                        "cursor-not-allowed !opacity-70"
                                                )}
                                            >
                                                <div className="text-slate-700 text-center text-xs font-medium leading-5 self-stretch grow whitespace-nowrap">
                                                    Open in Studio{" "}
                                                    <ArrowUpRight color="#667085" height={12} width={12} />
                                                </div>
                                            </div>
                                        </Tooltip>
                                    )}
                                    <Link
                                        to={`${URL.Media}/${
                                            asset.type === "scan"
                                                ? URL.Media_Scan_Detail.replace(":scan_id", asset.id.toString())
                                                : URL.Media_Folder_Asset_Detail.replace(
                                                      ":asset_id",
                                                      asset.id.toString()
                                                  )
                                        }`}
                                        className="text-[#475467] border border-[#EAECF0] flex flex-col p-4 rounded-lg border-solid"
                                    >
                                        <div className="mb-2 relative">
                                            <RenderImage
                                                image={asset.thumbnail}
                                                containerClassName="h-[190.5px] rounded-md"
                                            />
                                        </div>
                                        <div className="">
                                            <h4 className="text-slate-600 text-md font-medium leading-5 whitespace-nowrap text-ellipsis overflow-hidden">
                                                {asset.name}
                                            </h4>
                                            <h5 className="text-[#98A2B3] text-sm font-medium leading-5 whitespace-nowrap">
                                                {capitalize(asset.type)} •{" "}
                                                {moment(asset.created_at).format("DD MMM YYYY")}
                                            </h5>
                                        </div>
                                    </Link>
                                </div>
                            );
                        })}
                    </div>
                ) : (
                    <EmptyListCard
                        icon={<Image05 color="#344054" />}
                        title="No Media Found"
                        description="Please add a new asset in the folder."
                    />
                )}
            </ProjectContent>
            <ViewerModal viewerLink={openViewer} onClose={() => setOpenViewer("")} scan_name={scan_name} />
        </>
    );
};

export default AssetsList;
