import { formatDate } from "shared/utils/helpers";
import { encryptIdForUrl } from "shared/utils/encrypt-url";

interface IScanOrSnapshotCardProps {
    type: string;
    data: any;
}

const ScanOrSnapshotCard = (props: IScanOrSnapshotCardProps) => {
    const { type, data } = props;

    return (
        <a
            href={
                type == "assets" && !Object.keys(data).includes("snapshot_id")
                    ? data.file.thumbnail ?? data.file.url
                    : type == "scans"
                    ? `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${
                          data.scan_id ? encryptIdForUrl(data.scan_id) : null
                      }`
                    : `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${
                          data.scan_id ? encryptIdForUrl(data.scan_id) : null
                      }&snapshot_id=${data.snapshot_id ? encryptIdForUrl(data.snapshot_id) : null}`
            }
            target="_blank"
            rel="noreferrer"
            className="w-[calc((100%/4)-1.15em)]"
        >
            <div className="w-full">
                <div className="w-full h-0 pb-[76%] relative bg-[#F2F3FD] rounded-[7px]">
                    {(type == "scans" && data.input_file.thumbnail) ||
                    ((type == "snapshots" || Object.keys(data).includes("snapshot_id")) && data.image.url) ||
                    (type == "assets" && (data.file.thumbnail ?? data.file.url)) ? (
                        <img
                            src={
                                type == "scans"
                                    ? data.input_file.thumbnail
                                    : type == "assets" && !Object.keys(data).includes("snapshot_id")
                                    ? data.file.thumbnail ?? data.file.url
                                    : data.image.url
                            }
                            className="w-full h-full object-cover rounded-[7px] absolute top-0 left-0"
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="text-md font-medium text-[#3F3E3E] mt-2 break-words">{data.name}</div>
                <div className="flex items-center gap-x-1 text-sm font-medium text-[#98A2B3] mt-1 break-words">
                    {formatDate(data.created_at)}
                </div>
            </div>
        </a>
    );
};

export default ScanOrSnapshotCard;
