import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    name: yup.string().required(),
    folders: yup.string(),
    thumbnail: yup.mixed().nullable(),
});

type FormValues = yup.InferType<typeof schema>;

const useAssetPost = () => {
    const navigate = useNavigate();
    const { id, folder_id, asset_id } = useEncryptedParams<{ id: string; folder_id: string; asset_id: string }>();
    const [open, setOpen] = useState(false);
    const project = useAppSelector((state) => state.projects.selected);

    const folder = project?.folders.find((folder) => folder.folder_id === parseInt(folder_id as string));

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            thumbnail: null,
        },
    });

    useEffect(() => {
        if (!folder && folder_id) {
            navigate(-1);
        } else {
            setOpen(true);
        }
    }, []);

    const onSubmit = () => {};

    return { form, onSubmit, open, id, folder_id, setOpen, project, folder, asset_id };
};

export default useAssetPost;
