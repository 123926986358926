import { Skeleton } from "@mui/material";
import PageHeader from "shared/components/ui/PageHeader";
import ProjectContent from "./ProjectContent";
import ProjectCardLoader from "./ProjectCardLoader";

const DetailScreenLoader = () => {
    return (
        <>
            <PageHeader
                title={
                    <>
                        <Skeleton width={300} />
                        <Skeleton width={80} />
                    </>
                }
            />
            <ProjectContent>
                <div className="bg-white rounded-xl">
                    <div className="flex item-center space-x-3 mb-5">
                        <h1 className="text-gray-900 text-[30px] font-medium leading-10 whitespace-nowrap">
                            <Skeleton width={150} />
                        </h1>
                        <span className="items-center border border-[#D0D5DD] shadow-sm bg-white self-center flex gap-1 px-1.5 py-0.5 rounded-md border-solid">
                            <div className="text-slate-700 text-center text-xs font-medium leading-5 self-stretch grow whitespace-nowrap">
                                <Skeleton width={50} />
                            </div>
                        </span>
                    </div>
                    <div className="relative">
                        <Skeleton height={160} className="!translate-x-0" />
                    </div>
                </div>
            </ProjectContent>
            <div className="px-8 pt-1 pb-4 border-b border-[#EAECF0] text-[#344054] text-[20px] leading-6 font-medium">
                <Skeleton width={150} height={30} className="!translate-x-0" />
            </div>
            <ProjectContent>
                <div className="grid grid-cols-4 gap-4">
                    {[...Array(4)].map((_, index) => (
                        <ProjectCardLoader key={index} />
                    ))}
                </div>
            </ProjectContent>
        </>
    );
};

export default DetailScreenLoader;
