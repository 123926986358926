import { useParams } from "react-router-dom";

// Function to generate a MongoDB-like ObjectID
const generateObjectId = (id: number): string => {
    const timestamp = Math.floor(new Date().getTime() / 1000).toString(16);
    const machineId = Math.floor(Math.random() * 16777215).toString(16);
    const processId = Math.floor(Math.random() * 65535).toString(16); // Random process ID
    const counter = id.toString(16).padStart(6, "0"); // Using the original ID as part of the counter

    return (
        timestamp.padStart(8, "0") + machineId.padStart(6, "0") + processId.padStart(4, "0") + counter.padStart(6, "0")
    );
};

// Function to parse a MongoDB-like ObjectID
const parseObjectId = (objectId: string) => {
    return {
        timestamp: parseInt(objectId.substring(0, 8), 16),
        machineId: parseInt(objectId.substring(8, 14), 16),
        processId: parseInt(objectId.substring(14, 18), 16),
        counter: objectId.substring(18, 24),
    };
};

// Function to encrypt (encode) the ID for URL
export const encryptIdForUrl = (id: number): string => {
    const objectId = generateObjectId(id);
    return encodeURIComponent(objectId);
};

// check if string is encrypted
const isEncrypted = (id: string) => {
    return id.length === 24 && !isNaN(parseInt(id, 16));
};

// Function to decrypt (decode) the ID from URL
export const decryptIdFromUrl = (encodedId: string): number | null | string => {
    try {
        if (!isEncrypted(encodedId)) {
            return encodedId;
        }

        const objectId = decodeURIComponent(encodedId);
        const parsedObject = parseObjectId(objectId);
        // You can choose which part of the ObjectID to use as the decrypted ID
        return parsedObject.counter ? parseInt(parsedObject.counter, 16) : null; // Using the counter as the decrypted ID
    } catch (error) {
        return null;
    }
};

export const useEncryptedParams = <T>() => {
    const params = useParams();
    const decryptedParams: any = {};

    for (const key in params) {
        decryptedParams[key] = decryptIdFromUrl(params[key] as string);
    }

    return decryptedParams as T;
};
