import { Drawer } from "@mui/material";
import { PropsWithChildren, useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useNavigate } from "react-router-dom";

interface Props extends PropsWithChildren {
    beforeScrollComponent?: React.ReactNode;
    open: boolean;
    onClose: () => void;
    backUrl?: string;
    title?: string;
    description?: string;
    header?: React.ReactNode;
    className?: string;
}

const ProjectDrawer = (props: Props) => {
    const navigate = useNavigate();
    const [modelOpen, setModelOpen] = useState(false);

    useEffect(() => {
        setModelOpen(props.open);
    }, [props.open]);

    return (
        <Drawer
            open={modelOpen}
            anchor="right"
            closeAfterTransition
            transitionDuration={200}
            onTransitionExited={() => (props.backUrl ? navigate(props.backUrl) : navigate(-1))}
            onClose={() => props.onClose()}
            classes={{
                paper:
                    "m-3 !h-[calc(100%-24px)] !shadow-[-4px_4px_24px_0px_#00000026] !rounded-[12px] w-[622px] !overflow-visible",
            }}
        >
            {props.beforeScrollComponent}
            <PerfectScrollbar className={props.className}>
                <div>
                    {props.header && <div className="p-6 border-b border-[#EAECF0]">{props.header}</div>}
                    <div className="p-6 h-full flex flex-col">
                        {(props.title || props.description) && (
                            <div className="mb-6">
                                {props.title && (
                                    <h3 className="text-[#101828] text-[24px] font-medium leading-8">
                                        {props.title}
                                    </h3>
                                )}
                                {props.description && (
                                    <p className="text-[#6B7280] text-[14px] leading-6 mt-3">{props.description}</p>
                                )}
                            </div>
                        )}
                        {props.children}
                    </div>
                </div>
            </PerfectScrollbar>
        </Drawer>
    );
};

export default ProjectDrawer;
