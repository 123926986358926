import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import { IStyledTitleProps } from "./Title";

const StyledTitleProperties = ({ editor, props }: { editor: Editor | null; props: IStyledTitleProps }) => {
    const { id, size, color, alignment, content, custom_class } = props;
    const {
        EditorHeadingSizeIcon,
        EditorTextAlignLeftIcon,
        EditorTextAlignCenterIcon,
        EditorTextAlignRightIcon,
        EditorTextAlignJustifyIcon,
    } = useImages();

    const isInit = useRef(false);

    const { watch, setValue } = useForm<{
        id: string;
        size: 1 | 2 | 3 | 4 | 5;
        color: string;
        alignment: "left" | "center" | "right" | "justify";
    }>({
        defaultValues: {
            id,
            size,
            color,
            alignment,
        },
    });

    const size_val = watch("size");
    const color_val = watch("color");
    const alignment_val = watch("alignment");

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const onUpdate = () => {
        const data = {
            id,
            size: size_val,
            color: color_val,
            alignment: alignment_val,
            content,
            custom_class,
        };

        let pos = 0;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                return false;
            }
        });

        const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, data);

        parentEditor!.view.dispatch(transaction);
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [size_val, color_val, alignment_val]);

    return (
        <div>
            <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                <div className="text-sm text-[#667085] mb-2">Fill</div>
                <div className="flex gap-x-3">
                    {/* <div className="bg-[#F2F3FD] h-[22px] w-[22px] rounded-[4px] flex items-center justify-center cursor-pointer select-none hover:brightness-90">
                        <EditorPlusPrimaryIcon height={10} width={10} />
                    </div> */}
                    {/* <div className="border-l border-[#EAECF0] pl-3 flex-1 flex flex-wrap gap-x-2 gap-y-2"> */}
                    <div className="flex-1 flex flex-wrap gap-x-2 gap-y-2">
                        {[
                            "#F9FAFB",
                            "#EFEFEF",
                            "#475467",
                            "#FBD9D9",
                            "#E0FBD9",
                            "#D9E9FB",
                            "#F9D9FB",
                            "#99EAF5",
                            "#FBE4B9",
                            "#70A0D8",
                            "#EB8383",
                        ].map((colorHex) => (
                            <div
                                key={colorHex}
                                className={`h-[22px] w-[22px] rounded-full inline-block cursor-pointer ${
                                    colorHex == color_val && " border-2 border-[#7680FF] "
                                }`}
                                style={{ backgroundColor: colorHex }}
                                onClick={() => {
                                    setValue("color", colorHex);
                                }}
                            ></div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                <div className="text-sm text-[#667085] mb-2">Size</div>
                <ToggleButtonGroup
                    value={size_val}
                    exclusive
                    fullWidth
                    onChange={(_, newValue) => {
                        if (newValue) {
                            setValue("size", newValue);
                        }
                    }}
                    sx={{
                        // Applying styles using the sx prop
                        ".MuiToggleButtonGroup-grouped": {
                            height: 34,
                            flexGrow: 1, // Makes each toggle button grow to fill the available space
                            borderLeft: "1px solid #D0D5DD !important", // Removes the border
                            color: "#667085",
                            fontSize: "12px",
                            textTransform: "capitalize",

                            "&:first-of-type": {
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px",
                            },

                            "&:last-of-type": {
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px",
                            },

                            "&.Mui-selected": {
                                color: "#7680FF",
                                background: "#F2F3FD",
                            },
                        },
                    }}
                >
                    <ToggleButton value={1} aria-label="1">
                        <EditorHeadingSizeIcon width={14} height={14} />
                    </ToggleButton>
                    <ToggleButton value={2} aria-label="1">
                        <EditorHeadingSizeIcon width={12} height={12} />
                    </ToggleButton>
                    <ToggleButton value={3} aria-label="1">
                        <EditorHeadingSizeIcon width={10.75} height={10.75} />
                    </ToggleButton>
                    <ToggleButton value={4} aria-label="1">
                        <EditorHeadingSizeIcon width={9.5} height={9.5} />
                    </ToggleButton>
                    <ToggleButton value={5} aria-label="1">
                        <EditorHeadingSizeIcon width={8} height={8} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
            <div className="w-full pt-4 pb-6 border-b px-5">
                <div className="text-sm text-[#667085] mb-2">Alignment</div>
                <ToggleButtonGroup
                    value={alignment_val}
                    exclusive
                    fullWidth
                    onChange={(_, newValue) => {
                        if (newValue) {
                            setValue("alignment", newValue);
                        }
                    }}
                    sx={{
                        // Applying styles using the sx prop
                        ".MuiToggleButtonGroup-grouped": {
                            height: 34,
                            flexGrow: 1, // Makes each toggle button grow to fill the available space
                            borderLeft: "1px solid #D0D5DD !important", // Removes the border
                            color: "#667085",
                            fontSize: "12px",
                            textTransform: "capitalize",

                            "&:first-of-type": {
                                borderTopLeftRadius: "8px",
                                borderBottomLeftRadius: "8px",
                            },

                            "&:last-of-type": {
                                borderTopRightRadius: "8px",
                                borderBottomRightRadius: "8px",
                            },

                            "&.Mui-selected": {
                                color: "#7680FF",
                                background: "#F2F3FD",
                            },
                        },
                    }}
                >
                    <ToggleButton value={"left"} aria-label="Yes">
                        <EditorTextAlignLeftIcon width={14} height={14} />
                    </ToggleButton>
                    <ToggleButton value={"center"} aria-label="No">
                        <EditorTextAlignCenterIcon width={14} height={14} />
                    </ToggleButton>
                    <ToggleButton value={"right"} aria-label="No">
                        <EditorTextAlignRightIcon width={14} height={14} />
                    </ToggleButton>
                    <ToggleButton value={"justify"} aria-label="No">
                        <EditorTextAlignJustifyIcon width={14} height={14} />
                    </ToggleButton>
                </ToggleButtonGroup>
            </div>
        </div>
    );
};

export default StyledTitleProperties;
