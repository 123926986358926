import { useEffect, useState } from "react";
import { GetProjectsInput, GetProjectsQuery, useGetProjectsQuery } from "shared/graphql";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { setSelectedProject } from "store/slices/projects";

const useProjectsScreen = () => {
    const dispatch = useAppDispatch();
    const [projects, setProjects] = useState<GetProjectsQuery["projects"]>([]);

    const [params] = useState<GetProjectsInput>({
        sort: "ascending",
        search_key: null,
    });

    const projectRes = useGetProjectsQuery({
        filters: params,
    });

    useEffect(() => {
        dispatch(setSelectedProject(null));
    }, []);

    useEffect(() => {
        rtkHandler(projectRes, {
            onSuccess({ projects }: GetProjectsQuery) {
                setProjects(projects);
            },
        });
    }, [projectRes]);

    return { projects, projectRes };
};

export default useProjectsScreen;
