import { useFormContext } from "react-hook-form";
import ProjectFolderFields from "screens/Dashboard/Projects/components/ProjectFolderFields";
import Map from "shared/components/Map";
import Field from "shared/components/ui/Field";
import FieldLabel from "shared/components/ui/FieldLabel";
import { useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { validCoordinates } from "shared/utils/helpers";

const AssetForm = () => {
    const { options, watch }: any = useFormContext();
    const { asset_id, type } = useEncryptedParams<{ asset_id: string, type: string }>();

    const location = watch("location");
    const selectedScan = useAppSelector((state) => state.scans.asset);

    return (
        <>
            <Field
                name="name"
                label={type === "video" ? "Video Name:" : "Image Name:"}
                placeholder="Introduce your asset with a name"
            />
            <Field
                name="focus"
                label={type === "video" ? "Video Focus:" : "Image Focus:"}
                placeholder="Specify the main focus of the asset"
                type="select"
                options={options.object}
            />
            <ProjectFolderFields />
            <FieldLabel>Location</FieldLabel>
            <div className="w-full h-[400px] border border-[#D0D5DD] rounded-md p-4">
                <Map
                    autoCurrentLocation={!(asset_id && selectedScan)}
                    showLocationMarker={true}
                    isInteract={true}
                    center={
                        asset_id && selectedScan
                            ? validCoordinates(location, selectedScan.location)
                            : validCoordinates(location)
                    }
                    name="location"
                />
            </div>
        </>
    );
};

export default AssetForm;
