import { RouteObject } from "react-router-dom";
import ProjectsScreen from "screens/Dashboard/Projects";
import ProjectPostScreen from "screens/Dashboard/Projects/screens/Post";
import ProjectDetail from "screens/Dashboard/Projects/screens/Single";
import AssetSingleScreen from "screens/Dashboard/Projects/screens/Single/screens/AssetSingle";
import PublishAssetScreen from "screens/Dashboard/Projects/screens/Single/screens/AssetSingle/screens/PublishAsset";
import AssetPost from "screens/Dashboard/Projects/screens/Single/screens/AssetPost";
import ScanPost from "screens/Dashboard/Projects/screens/Single/screens/ScanPost";
import PostFolderScreen from "screens/Dashboard/Projects/screens/Single/screens/PostFolder";
import ScanSingleScreen from "screens/Dashboard/Projects/screens/Single/screens/ScanSingle";
import ScanSingleEmbedScreen from "screens/Dashboard/Projects/screens/Single/screens/ScanSingle/screens/EmbedScan";
import FolderScanPublishScreen from "screens/Dashboard/Projects/screens/Single/screens/ScanSingle/screens/PublishScan";

import URL from "shared/constants/navigator";

const ScansAssetDetailRoutes: RouteObject[] = [
    {
        path: URL.Media_Folder_Asset_Detail,
        element: <AssetSingleScreen />,
        children: [
            {
                path: URL.Media_Folder_Asset_Post,
                element: <AssetPost />,
            },
            {
                path: URL.Media_Asset_Publish_Screen,
                element: <PublishAssetScreen />,
            },
        ],
    },
    {
        path: URL.Media_Scan_Detail,
        element: <ScanSingleScreen />,
        children: [
            {
                path: URL.Media_Folder_Scan_Post,
                element: <ScanPost />,
            },
            {
                path: URL.Media_Scan_Publish_Screen,
                element: <FolderScanPublishScreen />,
            },
            {
                path: URL.Media_Scan_Detail_Embed,
                element: <ScanSingleEmbedScreen />,
            },
        ],
    },
];

const projectsRoutes: RouteObject[] = [
    {
        path: URL.Media,
        children: [
            {
                path: "",
                element: <ProjectsScreen />,
                children: [
                    {
                        path: URL.Media_Project_Post,
                        element: <ProjectPostScreen />,
                    },
                    {
                        path: URL.Media_Folder_Scan_Post,
                        element: <ScanPost />,
                    },
                    {
                        path: URL.Media_Folder_Asset_Post,
                        element: <AssetPost />,
                    },
                ],
            },
            ...ScansAssetDetailRoutes,
            {
                path: URL.Media_Project_Detail,
                children: [
                    {
                        path: "",
                        element: <ProjectDetail />,
                        children: [
                            {
                                path: URL.Media_Project_Post,
                                element: <ProjectPostScreen />,
                            },
                            {
                                path: URL.Media_Folder_Post,
                                element: <PostFolderScreen />,
                            },
                            {
                                path: URL.Media_Folder_Scan_Post,
                                element: <ScanPost />,
                            },
                            {
                                path: URL.Media_Folder_Asset_Post,
                                element: <AssetPost />,
                            },
                            {
                                path: URL.Media_Folder_Edit_Post,
                                element: <PostFolderScreen />,
                            },
                        ],
                    },
                    ...ScansAssetDetailRoutes,
                ],
            },
        ],
    },
];

export default projectsRoutes;
