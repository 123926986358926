const URL = {
    /**
     * Guest Routes
     */
    Home: "/",
    RegisterSuccess: "/register/success",
    VerifyEmail: "/verify-email",
    Waiting_List: "/waiting-list",
    ResetPassword: "/reset-password",
    Accept_Join_Team_Request: "/request/teams/:inviteCode",
    Join_Team_Request_Submitted: "/request-submitted",

    // Auth Routes
    Login: "/login",
    ForgotPassword: "/forgot-password",
    GetStarted: "/get-started",
    CompleteProfile: "/complete-profile/:token",
    ProfileSelection: "/profile-selection",

    Playground: "/playground",

    // TeamChat
    Messages: "/messages",

    // knowledge Library
    Knowledge_Library: "/knowledge-library",

    // Agreements
    Terms_And_Conditions: "/terms-and-conditions",
    Beta_Application_Agreement: "/beta-application-agreement",
    End_User_License_Agreement: "/end-user-license-agreement",

    /**
     * Error Routes
     */
    Under_Maintenance: "/503",

    /**
     * Dashboard Routes
     */
    Dashboard: "/dashboard",
    Feed: "/feed",
    Feed_Posts: "my-posts",
    Feed_Post: "post/:slug",
    Starred_Posts: "starred",

    // Trial Period Routes
    Trail_Period_End: "/trial-period/end",

    // Subscription Routes
    Subscription_End: "/subscription/end",

    // My Scan Routes
    // My_Scans: "/scans",
    // My_Scans_Post: "post/:type",
    // My_Scans_Detail: detailPageURL,
    // My_Scans_Import_Scan: "import",
    // My_Scans_Restart_Training: "restart-training",
    // My_Scans_Download_Scan: "download",
    // My_Scans_Visibility: "visibility",
    // My_Scans_Delete: "delete",
    // My_Scans_Embed: "embed",
    // My_Scans_Scan_Snapshot: "snapshot/:snapshot_id",

    // My_Scans_Asset_Post: "asset/post/:type",
    // My_Scans_Asset_Detail: "asset/detail/:id",
    // My_Scans_Asset_Delete: "delete",

    // Project Requests Routes
    Scan_Requests: "/requests/:type",
    Scan_Requests_Post: "post",
    Scan_Requests_Add_Submission: "add/submission",
    Scan_Requests_Detail: ":id",

    // Projects Routes
    Media: "/media",
    Media_Project_Detail: "project/:id",
    Media_Project_Post: "post",
    Media_Project_Delete: "delete",

    Media_Folder_Detail: "folder/:folder_id",
    Media_Folder_Post: "folder/post",
    Media_Folder_Edit_Post: "folder/:folder_id/post",
    Media_Folder_Delete: "folder/delete",

    Media_Folder_Scan_Post: "scan/post/:type",
    Media_Folder_Scan_Detail: "scan/:scan_id",

    Media_Scan_Detail: "scan/:scan_id",
    Media_Scan_Publish_Screen: "publish",
    Media_Scan_Detail_Embed: "emded",

    Media_Asset_Detail: "asset/:asset_id",
    Media_Asset_Publish_Screen: "publish",
    Media_Folder_Asset_Post: "asset/post/:type",
    Media_Folder_Asset_Detail: "asset/:asset_id",

    Publishable_Project: "/media/:id/editor",
    Media_Publish: "publish",

    // Settings Routes
    Settings: "/settings",
    Profile_Settings: "",
    Security_Settings: "security",
    Subscription_Settings: "subscription",
    Imagine_X_Settings: "imagine-x",
    Shop: "shop",
    Scan_Credits: "scan_credits",
    Shop_Payment_Success: "payment/success",
    Shop_Subscription_Cancel: "subscription/canceled",
    team: "team",
    Accounts: "accounts",
    Developer: "developer",

    // Board Routes
    Board: "/board",

    // Admin Routes
    Users: "/users",
    Admins: "/admins",
    Invite_Codes: "/invite-codes",
    Teams: "/teams",
};

export default URL;
