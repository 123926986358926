import "@fontsource/jetbrains-mono";
import "@fontsource/jetbrains-mono/400.css";
import { Button } from "@mui/material";
import CodeEditor from "@uiw/react-textarea-code-editor";
import "@uiw/react-textarea-code-editor/dist.css";
import ProjectBreadcrumbs from "screens/Dashboard/Projects/components/ProjectBreadcrumbs";
import ProjectDrawer from "screens/Dashboard/Projects/components/ProjectDrawer";
import Field from "shared/components/ui/Field";
import FieldLabel from "shared/components/ui/FieldLabel";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { copyTextToClipboard } from "shared/utils/helpers";
import { successToast } from "shared/utils/toast";
import EmbedData from "./data";
import useScanSingleEmbedScreen from "./hook";

const ScanSingleEmbedScreen = () => {
    const hook = useScanSingleEmbedScreen();

    return (
        <ProjectDrawer
            header={<ProjectBreadcrumbs drawer={true} />}
            open={hook.open}
            onClose={() => hook.setOpen(false)}
            title="Scan Embed"
        >
            <ThemeFormProvider form={hook.form} onSubmit={() => {}}>
                <div className="h-fit flex items-center justify-center">
                    <div className="h-fit w-full flex flex-col">
                        <div className="overflow-auto overflow-x-hidden flex-1">
                            <div className="grid grid-cols-2 w-full gap-x-4">
                                <Field
                                    type="number"
                                    name="embed_width"
                                    label="Embed Width:"
                                    placeholder="Enter embed width"
                                />
                                <Field
                                    type="number"
                                    name="embed_height"
                                    label="Embed Height:"
                                    placeholder="Enter embed height"
                                />
                            </div>
                            <div>
                                <FieldLabel>Embed Code:</FieldLabel>
                                <div className="relative">
                                    <div className="absolute top-0 left-0 w-full h-full z-10 cursor-pointer" />
                                    <CodeEditor
                                        className="bg-white border border-[#D0D5DD] rounded-lg px-3 py-2 text-[#667085] select-none cursor-pointer hover:brightness-95 break-all"
                                        value={EmbedData(hook.scan_name, hook.link, hook.embedWidth, hook.embedHeight)}
                                        language="html"
                                        placeholder="Please enter JS code."
                                        data-color-mode="light"
                                        style={{
                                            fontSize: 14,
                                            backgroundColor: "#f5f5f5",
                                            fontFamily: "JetBrains Mono",
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="flex items-center justify-end space-x-4 mt-6">
                                <Button onClick={() => hook.setOpen(false)} variant="outlined" color="inherit">
                                    Cancel
                                </Button>
                                <LoaderButton
                                    onClick={() => {
                                        successToast("Embed code copied.");
                                        copyTextToClipboard(
                                            EmbedData(hook.scan_name, hook.link, hook.embedWidth, hook.embedHeight)
                                        );
                                    }}
                                    variant="contained"
                                >
                                    Copy Code
                                </LoaderButton>
                            </div>
                        </div>
                    </div>
                </div>
            </ThemeFormProvider>
        </ProjectDrawer>
    );
};

export default ScanSingleEmbedScreen;
