import Image05 from "@untitled-ui/icons-react/build/esm/Image05";
import moment from "moment";
import { Outlet } from "react-router-dom";
import PageHeader from "shared/components/ui/PageHeader";
import URL from "shared/constants/navigator";
import { routeWithParams } from "shared/utils/helpers";
import AddProjectPopover from "./components/AddProjectPopover";
import AssetsList from "./components/AssetsList";
import EmptyListCard from "./components/EmptyListCard";
import ProjectBreadcrumbs from "./components/ProjectBreadcrumbs";
import ProjectCard from "./components/ProjectCard";
import ProjectCardLoader from "./components/ProjectCardLoader";
import ProjectContent from "./components/ProjectContent";
import useProjectsScreen from "./hook";
import useGetAssetsScans from "./hook/useGetAssetsScans";

const ProjectsScreen = () => {
    const hook = useProjectsScreen();
    const { assets, scans } = useGetAssetsScans();

    return (
        <>
            <PageHeader title={<ProjectBreadcrumbs />}>
                <AddProjectPopover />
            </PageHeader>
            <div className="mb-4">
                {hook.projects.length ? (
                    <div className="px-6 pt-4 text-[#344054] text-[20px] leading-6 font-medium">Projects</div>
                ) : (
                    <></>
                )}
                <ProjectContent>
                    {hook.projectRes.isLoading ? (
                        <div className="grid grid-cols-4 gap-4">
                            {[...Array(8)].map((_, index) => (
                                <ProjectCardLoader key={index} />
                            ))}
                        </div>
                    ) : hook.projects.length ? (
                        <div className="grid grid-cols-4 gap-4">
                            {hook.projects.map((project) => (
                                <ProjectCard
                                    inline
                                    url={routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
                                        id: project.project_id,
                                    })}
                                    visible={project.visible}
                                    icon={project.thumbnail.url || ""}
                                    key={project.project_id}
                                    title={project.name}
                                    description={moment(project.created_at).format("DD MMM YYYY")}
                                />
                            ))}
                        </div>
                    ) : (
                        <EmptyListCard
                            icon={<Image05 color="#344054" />}
                            title="No Media Found"
                            description="Please create a new project or an asset to start experiencing our platform."
                            btnText="New Project"
                            url={URL.Media_Project_Post}
                        />
                    )}
                </ProjectContent>
            </div>
            {assets.length || scans.length ? <AssetsList title="Assets" assets={assets} scans={scans} /> : <></>}
            <Outlet />
        </>
    );
};

export default ProjectsScreen;
