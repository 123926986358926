import { TextField } from "@mui/material";
import SettingPageCard from "screens/Dashboard/Settings/partials/SettingPageCard";
import Field from "shared/components/ui/Field/Field";
import FAvatarUploadPhoto from "shared/components/ui/Field/partials/FAvatarUploadPhoto";
import FColorPicker from "shared/components/ui/Field/partials/FColorPicker";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import useAuth from "shared/hooks/useAuth";
import useUpdateTeamDetails from "./hook";

const UpdateTeamDetails = () => {
    const { form, loading, onSubmit } = useUpdateTeamDetails();

    const { isTeamOwner, isTeamManager } = useAuth();

    const bioLength = form.watch("bio")?.length || 0;

    return (
        <ThemeFormProvider form={form} onSubmit={onSubmit} className="relative">
            <SettingPageCard
                title="Team Information"
                subTitle="Update your team information here"
                rightComponent={
                    isTeamOwner ? (
                        <LoaderButton
                            disabled={loading || !form.formState.isDirty}
                            loading={loading}
                            type="submit"
                            variant="contained"
                            className="whitespace-nowrap"
                        >
                            Update Profile
                        </LoaderButton>
                    ) : (
                        <></>
                    )
                }
                hasHeaderDivider={false}
            >
                <Field
                    label="Team Profile Photo:"
                    name="logo"
                    render={({ field }) => {
                        return <FAvatarUploadPhoto value={field.value} onChange={(file) => field.onChange(file)} />;
                    }}
                />

                <div className="grid grid-cols-2 gap-6">
                    <Field
                        label="Team Name:"
                        placeholder="Write name for your team"
                        name="name"
                        inputTextProps={{
                            readOnly: !isTeamOwner && !isTeamManager,
                        }}
                    />
                    <Field
                        label="Branding Color:"
                        name="brand_color"
                        render={({ field }) => {
                            return (
                                <FColorPicker
                                    {...field}
                                    readOnly={!isTeamOwner}
                                    className="shadow-none px-0 !mt-0 pl-[14px]"
                                />
                            );
                        }}
                    />
                </div>

                <Field
                    label="Bio:"
                    placeholder="Write a bio for your team"
                    name="bio"
                    render={({ field }) => {
                        return (
                            <TextField
                                type={"text"}
                                name={"bio"}
                                value={field.value}
                                variant={"outlined"}
                                onChange={(e) => {
                                    field.onChange(e.target.value.slice(0, 160));
                                }}
                                placeholder={"Write a bio for your team"}
                                InputProps={{
                                    readOnly: !isTeamOwner,
                                    endAdornment: (
                                        <span className="text-md leading-6 text-[#667085]">{bioLength}/160</span>
                                    ),
                                }}
                            />
                        );
                    }}
                />
            </SettingPageCard>
        </ThemeFormProvider>
    );
};

export default UpdateTeamDetails;
