import { Button, Collapse, FormControlLabel, Tooltip } from "@mui/material";
import AlertTriangle from "@untitled-ui/icons-react/build/esm/AlertTriangle";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Download01 from "@untitled-ui/icons-react/build/esm/Download01";
import Eye from "@untitled-ui/icons-react/build/esm/Eye";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";
import LinkIcon from "@untitled-ui/icons-react/build/esm/Link01";
import EmailIcon from "@untitled-ui/icons-react/build/esm/Mail01";
import Minus from "@untitled-ui/icons-react/build/esm/Minus";
import useImages from "assets/images";
import ProjectBreadcrumbs from "screens/Dashboard/Projects/components/ProjectBreadcrumbs";
import ProjectDrawer from "screens/Dashboard/Projects/components/ProjectDrawer";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeCheckbox from "shared/components/ui/ThemeCheckbox";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import URL from "shared/constants/navigator";
import { AssetVisibility } from "shared/graphql";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { copyTextToClipboard, routeWithParams } from "shared/utils/helpers";
import { successToast } from "shared/utils/toast";
import usePostPublishScreen from "./hook";
import ScanIcon from "@untitled-ui/icons-react/build/esm/Scan";
import ImageIcon from "@untitled-ui/icons-react/build/esm/Image05";
import VideoIcon from "@untitled-ui/icons-react/build/esm/VideoRecorder";

const PostPublishScreen = () => {
    const Images = useImages();

    const hook = usePostPublishScreen();

    const projectEditorUrl = routeWithParams(URL.Publishable_Project, {
        id: hook.id,
    });

    return (
        <ProjectDrawer
            header={<ProjectBreadcrumbs drawer={true} />}
            open={hook.open}
            onClose={() => hook.toggleSidebar()}
            backUrl={projectEditorUrl}
            title={"Publish Board"}
            className="publish-board-sidebar"
        >
            <div className={"" + (hook.isTutorialOpen && "pointer-events-none")}>
                <ThemeFormProvider
                    form={hook.visibility_form}
                    onSubmit={hook.onVisibilitySubmit}
                    className={
                        "text-left flex flex-col grow " +
                        (hook.project?.visible && "border-b border-[#EAECF0] pb-8 mb-8")
                    }
                >
                    <div className="flex-1">
                        <Field
                            name="visibility"
                            label="Visibility"
                            type="select"
                            options={
                                [
                                    { label: "Private", value: "private" },
                                    { label: "Public", value: "public" },
                                ] as ISelectMenuList[]
                            }
                        />
                        {hook.visibility_val == "public" && hook.hasAssetsOrScans && (
                            <>
                                <div
                                    className={
                                        "flex items-center cursor-pointer -mt-1 " +
                                        (hook.isAssetsListOpen ? "mb-2" : "mb-5")
                                    }
                                    onClick={() => hook.toggleAssetsList()}
                                >
                                    <span className={hook.isAssetsListOpen ? "rotate-180 pb-1 " : ""}>
                                        <ChevronDown height={20} width={20} color="#7680FF" />
                                    </span>
                                    <div className="text-[#7680FF] font-medium text-md ml-1 mr-3">
                                        Advanced Access Settings
                                    </div>
                                    {hook.isPrivateAssetSelected && (
                                        <Tooltip title="Correct all warnings before publishing your project.">
                                            <span>
                                                <AlertTriangle height={16} width={16} color="#FF8A36" />
                                            </span>
                                        </Tooltip>
                                    )}
                                </div>
                                <Collapse in={hook.isAssetsListOpen}>
                                    <div className="text-md leading-5 text-[#5E5D5D] mb-3">
                                        This menu supports toggling both an asset's visibility and allowing project
                                        viewers to download the source file. Please note that these settings{" "}
                                        <span className="underline underline-offset-[3px]">can</span> be changed at any
                                        time.
                                    </div>
                                    <div className="w-full p-4 border border-[#D0D5DD] rounded-lg shadow-[0px_1px_2px_0px_#1018280D] mb-5 pb-8">
                                        <div className="flex items-center justify-between pb-[10px] border-b border-[#EAECF0] gap-x-6">
                                            <div className="flex-1 text-sm text-[#475467] font-medium">Assets</div>
                                            <div className="w-[72px] text-center text-sm text-[#475467] font-medium">
                                                Type
                                            </div>
                                            <div className="w-[130px] flex items-center justify-between">
                                                <div className="flex gap-x-2 items-center">
                                                    <div
                                                        className={
                                                            "w-5 h-5 rounded-md border border-[#D0D5DD] flex items-center justify-center cursor-pointer hover:brightness-95 " +
                                                            (hook.isAllVisible && "border-none")
                                                        }
                                                        onClick={() => hook.toggleAllVisible()}
                                                    >
                                                        {hook.isNoneVisible ? (
                                                            <></>
                                                        ) : hook.isAllVisible ? (
                                                            <Images.CheckboxFilledActive color="#7680FF" />
                                                        ) : (
                                                            <Minus width={16} height={16} color="#7680FF" />
                                                        )}
                                                    </div>
                                                    <Eye width={20} height={20} color="#475467" />
                                                </div>
                                                <div className="flex gap-x-2 items-center">
                                                    <div
                                                        className={
                                                            "w-5 h-5 rounded-md border border-[#D0D5DD] flex items-center justify-center cursor-pointer hover:brightness-95 " +
                                                            (hook.isAllDownloadable && "border-none")
                                                        }
                                                        onClick={() => hook.toggleAllDownload()}
                                                    >
                                                        {hook.isNoneDownloadable ? (
                                                            <></>
                                                        ) : hook.isAllDownloadable ? (
                                                            <Images.CheckboxFilledActive color="#7680FF" />
                                                        ) : (
                                                            <Minus width={16} height={16} color="#7680FF" />
                                                        )}
                                                    </div>
                                                    <Download01 width={20} height={20} color="#475467" />
                                                </div>
                                            </div>
                                        </div>
                                        {hook.project &&
                                        hook.project.folders.length > 0 &&
                                        Object.keys(hook.assetsAccess).length > 0 ? (
                                            <>
                                                {hook.project.folders.map((folder) => (
                                                    <div key={`folder_${folder.folder_id}`} className="mt-3">
                                                        <div className="flex items-center gap-x-3">
                                                            <div className="w-6 h-6 rounded-[3px] bg-[#f9f9f9]">
                                                                {folder.thumbnail && folder.thumbnail.url ? (
                                                                    <img
                                                                        src={folder!.thumbnail.url}
                                                                        className="w-full h-full object-cover rounded-[3px]"
                                                                    />
                                                                ) : (
                                                                    <></>
                                                                )}
                                                            </div>
                                                            <div className="font-medium text-lg text-[#344054]">
                                                                {folder.name}
                                                            </div>
                                                        </div>
                                                        {folder.asset.map((asset) => (
                                                            <div
                                                                key={`asset_${asset.asset_id}`}
                                                                className="w-full flex items-center gap-x-3 py-[3px] mt-2"
                                                            >
                                                                <div className="w-6 h-[1px] bg-[#EAECF0]"></div>
                                                                <div className="flex-1 flex items-center gap-x-3 overflow-hidden">
                                                                    <div className="w-6 h-[28px] min-w-6 rounded-[3px] bg-[#f9f9f9] relative">
                                                                        {asset.file &&
                                                                        ((asset.file.type == "Image" &&
                                                                            asset.file.url) ||
                                                                            (asset.file.type == "Video" &&
                                                                                asset.file.thumbnail)) ? (
                                                                            <img
                                                                                src={
                                                                                    asset.file.type == "Image"
                                                                                        ? asset.file!.url!
                                                                                        : asset.file!.thumbnail!
                                                                                }
                                                                                className="w-full h-6 object-cover rounded-[3px]"
                                                                            />
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        {asset.visibility == AssetVisibility.Public && (
                                                                            <div className="w-[8px] h-[8px] bg-[#15AA2C] border-[1.13px] border-[#FFFFFF] rounded-full absolute bottom-0 right-0 translate-x-1/2"></div>
                                                                        )}
                                                                    </div>
                                                                    <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                                        {asset.name}
                                                                    </div>
                                                                    {(Object.values(hook.allSelectedAssets).includes(
                                                                        "_" + asset.asset_id
                                                                    ) ||
                                                                        Object.values(hook.allSelectedAssets).includes(
                                                                            "" + asset.asset_id
                                                                        )) &&
                                                                        !hook.assetsAccess["asset_" + asset.asset_id]
                                                                            .visible && (
                                                                            <Tooltip title="Assets used in boards, should be public.">
                                                                                <span>
                                                                                    <AlertTriangle
                                                                                        height={16}
                                                                                        width={16}
                                                                                        color="#FF8A36"
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        )}
                                                                </div>
                                                                <div className="w-[72px] mx-3 text-center">
                                                                    <Tooltip title={asset.file.type}>
                                                                        <span>
                                                                            {asset.file.type == "Image" ? (
                                                                                <ImageIcon
                                                                                    height={18}
                                                                                    width={18}
                                                                                    color="#98A2B3"
                                                                                ></ImageIcon>
                                                                            ) : (
                                                                                <VideoIcon
                                                                                    height={18}
                                                                                    width={18}
                                                                                    color="#98A2B3"
                                                                                ></VideoIcon>
                                                                            )}
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="w-[130px] flex justify-between pr-5">
                                                                    <FormControlLabel
                                                                        onChange={() => {
                                                                            const updatedAssetsAccess = {
                                                                                ...hook.assetsAccess,
                                                                            };
                                                                            const updatedAccess = {
                                                                                ...updatedAssetsAccess[
                                                                                    "asset_" + asset.asset_id
                                                                                ],
                                                                            };

                                                                            updatedAccess["visible"] = !updatedAccess[
                                                                                "visible"
                                                                            ];

                                                                            updatedAccess[
                                                                                "is_download_disabled"
                                                                            ] = !updatedAccess["visible"];

                                                                            updatedAssetsAccess[
                                                                                "asset_" + asset.asset_id
                                                                            ] = updatedAccess;

                                                                            hook.setAssetsAccess(updatedAssetsAccess);
                                                                        }}
                                                                        sx={{
                                                                            margin: 0,
                                                                            padding: 0,
                                                                        }}
                                                                        className={`inline-checkbox p-4 ${
                                                                            hook.assetsAccess["asset_" + asset.asset_id]
                                                                                .visible
                                                                                ? "checked"
                                                                                : ""
                                                                        }`}
                                                                        control={
                                                                            <ThemeCheckbox
                                                                                checked={
                                                                                    hook.assetsAccess[
                                                                                        "asset_" + asset.asset_id
                                                                                    ].visible
                                                                                }
                                                                                className="pl-0"
                                                                                variant="filled"
                                                                            />
                                                                        }
                                                                        label=""
                                                                    />
                                                                    <Tooltip
                                                                        title={
                                                                            hook.assetsAccess["asset_" + asset.asset_id]
                                                                                .is_download_disabled
                                                                                ? "View should be checked first."
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <span>
                                                                            <FormControlLabel
                                                                                onChange={() => {
                                                                                    const updatedAssetsAccess = {
                                                                                        ...hook.assetsAccess,
                                                                                    };
                                                                                    const updatedAccess = {
                                                                                        ...updatedAssetsAccess[
                                                                                            "asset_" + asset.asset_id
                                                                                        ],
                                                                                    };

                                                                                    if (updatedAccess["visible"]) {
                                                                                        updatedAccess[
                                                                                            "download"
                                                                                        ] = !updatedAccess["download"];

                                                                                        updatedAssetsAccess[
                                                                                            "asset_" + asset.asset_id
                                                                                        ] = updatedAccess;

                                                                                        hook.setAssetsAccess(
                                                                                            updatedAssetsAccess
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                sx={{
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                }}
                                                                                className={`inline-checkbox p-4 ${
                                                                                    hook.assetsAccess[
                                                                                        "asset_" + asset.asset_id
                                                                                    ].download
                                                                                        ? "checked"
                                                                                        : ""
                                                                                } ${
                                                                                    hook.assetsAccess[
                                                                                        "asset_" + asset.asset_id
                                                                                    ].is_download_disabled &&
                                                                                    "pointer-events-none"
                                                                                }`}
                                                                                control={
                                                                                    <ThemeCheckbox
                                                                                        checked={
                                                                                            hook.assetsAccess[
                                                                                                "asset_" +
                                                                                                    asset.asset_id
                                                                                            ].is_download_disabled
                                                                                                ? false
                                                                                                : hook.assetsAccess[
                                                                                                      "asset_" +
                                                                                                          asset.asset_id
                                                                                                  ].download
                                                                                        }
                                                                                        className="pl-0"
                                                                                        variant="filled"
                                                                                    />
                                                                                }
                                                                                label=""
                                                                            />
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {folder.scans.map((scan) => (
                                                            <div
                                                                key={`scan_${scan.scan_id}`}
                                                                className={
                                                                    "w-full flex items-center gap-x-3 py-[3px] mt-2 " +
                                                                    (hook.assetsAccess["scan_" + scan.scan_id]
                                                                        .is_all_disabled && "opacity-50")
                                                                }
                                                            >
                                                                <div className="w-6 h-[1px] bg-[#EAECF0]"></div>
                                                                <div className="flex-1 flex items-center gap-x-3 overflow-x-hidden">
                                                                    <div className="w-6 h-[28px] min-w-6 rounded-[3px] bg-[#f9f9f9] relative">
                                                                        {scan.input_file.thumbnail ? (
                                                                            <img
                                                                                src={scan.input_file.thumbnail}
                                                                                className="w-full h-6 object-cover rounded-[3px]"
                                                                            />
                                                                        ) : (
                                                                            <></>
                                                                        )}
                                                                        {scan.visibility == "public" && (
                                                                            <div className="w-[8px] h-[8px] bg-[#15AA2C] border-[1.13px] border-[#FFFFFF] rounded-full absolute bottom-0 right-0 translate-x-1/2"></div>
                                                                        )}
                                                                    </div>
                                                                    <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                                        {scan.name}
                                                                    </div>
                                                                    {(Object.values(hook.allSelectedAssets).includes(
                                                                        "_" + scan.scan_id
                                                                    ) ||
                                                                        Object.values(hook.allSelectedAssets).includes(
                                                                            "" + scan.scan_id
                                                                        )) &&
                                                                        !hook.assetsAccess["scan_" + scan.scan_id]
                                                                            .visible && (
                                                                            <Tooltip title="Assets used in boards, should be public.">
                                                                                <span>
                                                                                    <AlertTriangle
                                                                                        height={16}
                                                                                        width={16}
                                                                                        color="#FF8A36"
                                                                                    />
                                                                                </span>
                                                                            </Tooltip>
                                                                        )}
                                                                </div>
                                                                <div className="w-[72px] mx-3 text-center">
                                                                    <Tooltip title="Scan">
                                                                        <span>
                                                                            <ScanIcon
                                                                                height={17}
                                                                                width={17}
                                                                                color="#98A2B3"
                                                                            ></ScanIcon>
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                                <div className="w-[130px] flex justify-between pr-5">
                                                                    <Tooltip
                                                                        title={
                                                                            hook.assetsAccess["scan_" + scan.scan_id]
                                                                                .is_all_disabled
                                                                                ? "Scan is currently processing."
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <span>
                                                                            <FormControlLabel
                                                                                onChange={() => {
                                                                                    const updatedAssetsAccess = {
                                                                                        ...hook.assetsAccess,
                                                                                    };
                                                                                    const updatedAccess = {
                                                                                        ...updatedAssetsAccess[
                                                                                            "scan_" + scan.scan_id
                                                                                        ],
                                                                                    };

                                                                                    if (
                                                                                        !updatedAccess[
                                                                                            "is_all_disabled"
                                                                                        ]
                                                                                    ) {
                                                                                        updatedAccess[
                                                                                            "visible"
                                                                                        ] = !updatedAccess["visible"];

                                                                                        updatedAccess[
                                                                                            "is_download_disabled"
                                                                                        ] = !updatedAccess["visible"];

                                                                                        updatedAssetsAccess[
                                                                                            "scan_" + scan.scan_id
                                                                                        ] = updatedAccess;

                                                                                        hook.setAssetsAccess(
                                                                                            updatedAssetsAccess
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                sx={{
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                }}
                                                                                className={`inline-checkbox p-4 ${
                                                                                    hook.assetsAccess[
                                                                                        "scan_" + scan.scan_id
                                                                                    ].visible
                                                                                        ? "checked"
                                                                                        : ""
                                                                                } ${
                                                                                    hook.assetsAccess[
                                                                                        "scan_" + scan.scan_id
                                                                                    ].is_all_disabled &&
                                                                                    "pointer-events-none"
                                                                                }`}
                                                                                control={
                                                                                    <ThemeCheckbox
                                                                                        checked={
                                                                                            hook.assetsAccess[
                                                                                                "scan_" + scan.scan_id
                                                                                            ].visible
                                                                                        }
                                                                                        className="pl-0"
                                                                                        variant="filled"
                                                                                    />
                                                                                }
                                                                                label=""
                                                                            />
                                                                        </span>
                                                                    </Tooltip>

                                                                    <Tooltip
                                                                        title={
                                                                            hook.assetsAccess["scan_" + scan.scan_id]
                                                                                .is_all_disabled
                                                                                ? "Scan is currently processing."
                                                                                : scan?.default
                                                                                ? "Default scans cannot be downloaded."
                                                                                : hook.assetsAccess[
                                                                                      "scan_" + scan.scan_id
                                                                                  ].is_download_disabled
                                                                                ? "View should be checked first."
                                                                                : ""
                                                                        }
                                                                    >
                                                                        <span>
                                                                            <FormControlLabel
                                                                                onChange={() => {
                                                                                    const updatedAssetsAccess = {
                                                                                        ...hook.assetsAccess,
                                                                                    };
                                                                                    const updatedAccess = {
                                                                                        ...updatedAssetsAccess[
                                                                                            "scan_" + scan.scan_id
                                                                                        ],
                                                                                    };

                                                                                    if (
                                                                                        !updatedAccess[
                                                                                            "is_all_disabled"
                                                                                        ] &&
                                                                                        updatedAccess["visible"]
                                                                                    ) {
                                                                                        updatedAccess[
                                                                                            "download"
                                                                                        ] = !updatedAccess["download"];

                                                                                        updatedAssetsAccess[
                                                                                            "scan_" + scan.scan_id
                                                                                        ] = updatedAccess;

                                                                                        hook.setAssetsAccess(
                                                                                            updatedAssetsAccess
                                                                                        );
                                                                                    }
                                                                                }}
                                                                                sx={{
                                                                                    margin: 0,
                                                                                    padding: 0,
                                                                                }}
                                                                                className={`inline-checkbox p-4 ${
                                                                                    hook.assetsAccess[
                                                                                        "scan_" + scan.scan_id
                                                                                    ].download
                                                                                        ? "checked"
                                                                                        : ""
                                                                                } ${
                                                                                    (scan?.default ||
                                                                                        hook.assetsAccess[
                                                                                            "scan_" + scan.scan_id
                                                                                        ].is_download_disabled ||
                                                                                        hook.assetsAccess[
                                                                                            "scan_" + scan.scan_id
                                                                                        ].is_all_disabled) &&
                                                                                    "pointer-events-none"
                                                                                }`}
                                                                                control={
                                                                                    <ThemeCheckbox
                                                                                        checked={
                                                                                            scan?.default ||
                                                                                            hook.assetsAccess[
                                                                                                "scan_" + scan.scan_id
                                                                                            ].is_download_disabled ||
                                                                                            hook.assetsAccess[
                                                                                                "scan_" + scan.scan_id
                                                                                            ].is_all_disabled
                                                                                                ? false
                                                                                                : hook.assetsAccess[
                                                                                                      "scan_" +
                                                                                                          scan.scan_id
                                                                                                  ].download
                                                                                        }
                                                                                        className="pl-0"
                                                                                        variant="filled"
                                                                                        disabled={scan?.default}
                                                                                    />
                                                                                }
                                                                                label=""
                                                                                disabled={scan?.default}
                                                                            />
                                                                        </span>
                                                                    </Tooltip>
                                                                </div>
                                                            </div>
                                                        ))}
                                                        {folder.asset.length == 0 && folder.scans.length == 0 && (
                                                            <div className="w-full flex items-center gap-x-3 py-[3px] mt-3 opacity-50">
                                                                <div className="w-6 h-[1px] bg-[#EAECF0]"></div>
                                                                <div className="flex-1 flex items-center gap-x-3">
                                                                    <div className="w-6 h-6 rounded-[3px] bg-[#EAECF0] relative"></div>
                                                                    <div>No Assets</div>
                                                                </div>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </>
                                        ) : (
                                            <div></div>
                                        )}
                                        {(hook.nonFolderAssets.length > 0 || hook.nonFolderScans.length > 0) &&
                                        Object.keys(hook.assetsAccess).length > 0 ? (
                                            [...hook.nonFolderAssets, ...hook.nonFolderScans].map(
                                                (nonFolderItem: any) => {
                                                    const isAsset = "asset_id" in nonFolderItem;
                                                    const type = isAsset ? "asset" : "scan";
                                                    const id = isAsset ? nonFolderItem.asset_id : nonFolderItem.scan_id;
                                                    const img_str = isAsset
                                                        ? nonFolderItem.file
                                                            ? nonFolderItem.file.type == "Image"
                                                                ? nonFolderItem.file.url
                                                                : nonFolderItem.file.thumbnail
                                                            : null
                                                        : nonFolderItem.input_file.thumbnail ?? null;

                                                    return (
                                                        <div
                                                            key={`${type}_${id}`}
                                                            className={
                                                                "w-full flex items-center gap-x-3 py-[3px] mt-3 " +
                                                                (hook.assetsAccess[`${type}_${id}`].is_all_disabled &&
                                                                    "opacity-50")
                                                            }
                                                        >
                                                            <div className="flex-1 flex items-center gap-x-3 overflow-hidden">
                                                                <div className="w-6 h-[28px] min-w-6 rounded-[3px] bg-[#f9f9f9] relative">
                                                                    {img_str ? (
                                                                        <img
                                                                            src={img_str}
                                                                            className="w-full h-6 object-cover rounded-[3px]"
                                                                        />
                                                                    ) : (
                                                                        <></>
                                                                    )}
                                                                    {(nonFolderItem.visibility == "public" ||
                                                                        nonFolderItem.visibility ==
                                                                            AssetVisibility.Public) && (
                                                                        <div className="w-[8px] h-[8px] bg-[#15AA2C] border-[1.13px] border-[#FFFFFF] rounded-full absolute bottom-0 right-0 translate-x-1/2"></div>
                                                                    )}
                                                                </div>
                                                                <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                                    {nonFolderItem.name}
                                                                </div>
                                                                {(Object.values(hook.allSelectedAssets).includes(
                                                                    "_" + id
                                                                ) ||
                                                                    Object.values(hook.allSelectedAssets).includes(
                                                                        "" + id
                                                                    )) &&
                                                                    !hook.assetsAccess[`${type}_${id}`].visible && (
                                                                        <Tooltip title="Assets used in boards, should be public.">
                                                                            <span>
                                                                                <AlertTriangle
                                                                                    height={16}
                                                                                    width={16}
                                                                                    color="#FF8A36"
                                                                                />
                                                                            </span>
                                                                        </Tooltip>
                                                                    )}
                                                            </div>
                                                            <div className="w-[72px] mx-3 text-center">
                                                                <Tooltip
                                                                    title={isAsset ? nonFolderItem.file.type : "Scan"}
                                                                >
                                                                    <span>
                                                                        {isAsset ? (
                                                                            nonFolderItem.file.type == "Image" ? (
                                                                                <ImageIcon
                                                                                    height={18}
                                                                                    width={18}
                                                                                    color="#98A2B3"
                                                                                ></ImageIcon>
                                                                            ) : (
                                                                                <VideoIcon
                                                                                    height={18}
                                                                                    width={18}
                                                                                    color="#98A2B3"
                                                                                ></VideoIcon>
                                                                            )
                                                                        ) : (
                                                                            <ScanIcon
                                                                                height={17}
                                                                                width={17}
                                                                                color="#98A2B3"
                                                                            ></ScanIcon>
                                                                        )}
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                            <div className="w-[130px] flex justify-between pr-5">
                                                                <Tooltip
                                                                    title={
                                                                        isAsset
                                                                            ? null
                                                                            : hook.assetsAccess[`${type}_${id}`]
                                                                                  .is_all_disabled
                                                                            ? "Scan is currently processing."
                                                                            : ""
                                                                    }
                                                                >
                                                                    <span>
                                                                        <FormControlLabel
                                                                            onChange={() => {
                                                                                const updatedAssetsAccess = {
                                                                                    ...hook.assetsAccess,
                                                                                };
                                                                                const updatedAccess = {
                                                                                    ...updatedAssetsAccess[
                                                                                        `${type}_${id}`
                                                                                    ],
                                                                                };

                                                                                if (
                                                                                    isAsset ||
                                                                                    !updatedAccess["is_all_disabled"]
                                                                                ) {
                                                                                    updatedAccess[
                                                                                        "visible"
                                                                                    ] = !updatedAccess["visible"];

                                                                                    updatedAccess[
                                                                                        "is_download_disabled"
                                                                                    ] = !updatedAccess["visible"];

                                                                                    updatedAssetsAccess[
                                                                                        `${type}_${id}`
                                                                                    ] = updatedAccess;

                                                                                    hook.setAssetsAccess(
                                                                                        updatedAssetsAccess
                                                                                    );
                                                                                }
                                                                            }}
                                                                            sx={{
                                                                                margin: 0,
                                                                                padding: 0,
                                                                            }}
                                                                            className={`inline-checkbox p-4 ${
                                                                                hook.assetsAccess[`${type}_${id}`]
                                                                                    .visible
                                                                                    ? "checked"
                                                                                    : ""
                                                                            } ${
                                                                                hook.assetsAccess[`${type}_${id}`]
                                                                                    .is_all_disabled &&
                                                                                "pointer-events-none"
                                                                            }`}
                                                                            control={
                                                                                <ThemeCheckbox
                                                                                    checked={
                                                                                        hook.assetsAccess[
                                                                                            `${type}_${id}`
                                                                                        ].visible
                                                                                    }
                                                                                    className="pl-0"
                                                                                    variant="filled"
                                                                                />
                                                                            }
                                                                            label=""
                                                                        />
                                                                    </span>
                                                                </Tooltip>

                                                                <Tooltip
                                                                    title={
                                                                        hook.assetsAccess[`${type}_${id}`]
                                                                            .is_all_disabled
                                                                            ? "Scan is currently processing."
                                                                            : nonFolderItem?.default
                                                                            ? "Default scans cannot be downloaded."
                                                                            : hook.assetsAccess[`${type}_${id}`]
                                                                                  .is_download_disabled
                                                                            ? "View should be checked first."
                                                                            : ""
                                                                    }
                                                                >
                                                                    <span>
                                                                        <FormControlLabel
                                                                            onChange={() => {
                                                                                const updatedAssetsAccess = {
                                                                                    ...hook.assetsAccess,
                                                                                };
                                                                                const updatedAccess = {
                                                                                    ...updatedAssetsAccess[
                                                                                        `${type}_${id}`
                                                                                    ],
                                                                                };

                                                                                if (
                                                                                    isAsset ||
                                                                                    (!updatedAccess[
                                                                                        "is_all_disabled"
                                                                                    ] &&
                                                                                        updatedAccess["visible"])
                                                                                ) {
                                                                                    updatedAccess[
                                                                                        "download"
                                                                                    ] = !updatedAccess["download"];

                                                                                    updatedAssetsAccess[
                                                                                        `${type}_${id}`
                                                                                    ] = updatedAccess;

                                                                                    hook.setAssetsAccess(
                                                                                        updatedAssetsAccess
                                                                                    );
                                                                                }
                                                                            }}
                                                                            sx={{
                                                                                margin: 0,
                                                                                padding: 0,
                                                                            }}
                                                                            className={`inline-checkbox p-4 ${
                                                                                hook.assetsAccess[`${type}_${id}`]
                                                                                    .download
                                                                                    ? "checked"
                                                                                    : ""
                                                                            } ${
                                                                                (nonFolderItem?.default ||
                                                                                    hook.assetsAccess[`${type}_${id}`]
                                                                                        .is_download_disabled ||
                                                                                    hook.assetsAccess[`${type}_${id}`]
                                                                                        .is_all_disabled) &&
                                                                                "pointer-events-none"
                                                                            }`}
                                                                            control={
                                                                                <ThemeCheckbox
                                                                                    checked={
                                                                                        nonFolderItem?.default ||
                                                                                        hook.assetsAccess[
                                                                                            `${type}_${id}`
                                                                                        ].is_download_disabled ||
                                                                                        hook.assetsAccess[
                                                                                            `${type}_${id}`
                                                                                        ].is_all_disabled
                                                                                            ? false
                                                                                            : hook.assetsAccess[
                                                                                                  `${type}_${id}`
                                                                                              ].download
                                                                                    }
                                                                                    className="pl-0"
                                                                                    variant="filled"
                                                                                    disabled={nonFolderItem?.default}
                                                                                />
                                                                            }
                                                                            label=""
                                                                            disabled={nonFolderItem?.default}
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <></>
                                        )}
                                    </div>
                                </Collapse>
                            </>
                        )}
                    </div>
                    <div className="w-full flex items-center justify-end space-x-3">
                        <div className="text-sm font-medium text-[#5E5D5D]">
                            {hook.lastPublishedTime.length > 0 ? `Last Published: ${hook.lastPublishedTime}` : ""}
                        </div>
                        <Button
                            variant="outlined"
                            disabled={hook.isSavingVisibility}
                            onClick={() => hook.toggleSidebar()}
                        >
                            Cancel
                        </Button>
                        <LoaderButton
                            id="change-visibility-btn"
                            variant="contained"
                            loading={hook.isSavingVisibility}
                            disabled={
                                hook.isSavingVisibility ||
                                (!hook.project?.visible && hook.visibility_val == "private") ||
                                (hook.visibility_val == "public" && hook.isPrivateAssetSelected)
                            }
                        >
                            Publish Changes
                        </LoaderButton>
                    </div>
                </ThemeFormProvider>
                {hook.project?.visible && (
                    <>
                        <ThemeFormProvider
                            form={hook.invite_form}
                            onSubmit={hook.onInviteSubmit}
                            className={
                                "text-left flex flex-col grow pb-8 mb-8 " +
                                (hook.isSEOAllowed && "border-b border-[#EAECF0]")
                            }
                        >
                            <div className="flex-1">
                                <div className="text-lg text-[#3F3E3E] font-medium mb-4">Share Board</div>
                                <div
                                    className="flex items-center gap-x-3 rounded-lg h-[44px] w-full border border-[#D0D5DD] px-[14px] py-[10px] bg-white cursor-pointer hover:brightness-95 mb-4"
                                    onClick={() => {
                                        if (hook.project) {
                                            const link = `${process.env.REACT_APP_PUBLISHED_PROJECT_HOST_URL}${hook.project.slug}`;
                                            copyTextToClipboard(link);
                                            successToast("Link copied to clipboard");
                                        }
                                    }}
                                >
                                    <Tooltip title="Public Link to Your Board">
                                        <span>
                                            <LinkIcon width={18} height={18} color="#667085" />
                                        </span>
                                    </Tooltip>
                                    <span className="flex-1 text-lg text-[#667085] text-left whitespace-nowrap overflow-hidden">
                                        {process.env.REACT_APP_PUBLISHED_PROJECT_HOST_URL}
                                        {hook.project?.slug ?? ""}
                                    </span>
                                    <Tooltip title="Copy link">
                                        <Images.CopyGrayIcon width={18} height={18} color="#667085" />
                                    </Tooltip>
                                    <a
                                        href={`${process.env.REACT_APP_PUBLISHED_PROJECT_HOST_URL}${
                                            hook.project!.slug
                                        }`}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <Tooltip title="Open link">
                                            <Images.ArrowUpRight width={22} height={22} color="#7680FF" />
                                        </Tooltip>
                                    </a>
                                </div>
                                {hook.emailFields.length > 0 ? (
                                    hook.emailFields.map((field, index) => (
                                        <Field
                                            key={field.id}
                                            containerClassName="!mb-2"
                                            name={`emails.${index}`}
                                            label={index === 0 ? "Email Address" : ""}
                                            placeholder="Enter email address"
                                            inputTextProps={{
                                                startAdornment: (
                                                    <Tooltip title="Send Your Board Via Email">
                                                        <span>
                                                            <EmailIcon color="#667085" />
                                                        </span>
                                                    </Tooltip>
                                                ),
                                                endAdornment:
                                                    index !== 0 ? (
                                                        <ThemeIconButton
                                                            color="error"
                                                            icon={<Images.DeleteIcon />}
                                                            onClick={() => hook.removeEmail(index)}
                                                        />
                                                    ) : null,
                                                ...field,
                                            }}
                                        />
                                    ))
                                ) : (
                                    <Field
                                        containerClassName="!mb-2"
                                        name={`emails.0`}
                                        label="Email Address"
                                        placeholder="Enter email address"
                                        inputTextProps={{
                                            startAdornment: (
                                                <Tooltip title="Send Your Board Via Email">
                                                    <span>
                                                        <EmailIcon color="#667085" />
                                                    </span>
                                                </Tooltip>
                                            ),
                                            // ...field,
                                        }}
                                    />
                                )}
                                <Button
                                    onClick={() => {
                                        hook.addEmail("");
                                    }}
                                >
                                    <Images.PlusWhite />
                                    Add another
                                </Button>
                            </div>
                            <div className="w-full flex items-center justify-end space-x-3">
                                <Button
                                    variant="outlined"
                                    disabled={hook.isSendingInvite}
                                    onClick={() => hook.toggleSidebar()}
                                >
                                    Cancel
                                </Button>
                                <LoaderButton
                                    variant="contained"
                                    loading={hook.isSendingInvite}
                                    disabled={!hook.isInvitesDirty}
                                >
                                    Send Emails
                                </LoaderButton>
                            </div>
                        </ThemeFormProvider>
                        {hook.isSEOAllowed && (
                            <ThemeFormProvider
                                form={hook.meta_form}
                                onSubmit={hook.onMetaSubmit}
                                className="text-left flex flex-col grow"
                            >
                                <div className="flex-1">
                                    <div className="text-lg text-[#3F3E3E] font-medium mb-4">
                                        Search Engine Optimization
                                    </div>
                                    <Field containerClassName="!mb-2" name="title" label={"Meta Title:"} />
                                    <Field
                                        name="description"
                                        label={"Meta Description:"}
                                        labelTooltip={{
                                            Icon: <HelpCircle width={18} height={18} color="#667085" />,
                                            tooltip: "Optimal meta description length is 150 to 200 characters.",
                                        }}
                                        textFieldProps={{
                                            multiline: true,
                                        }}
                                        inputTextProps={{
                                            sx: {
                                                "&": {
                                                    padding: "6px 10px !important",
                                                },
                                                ".MuiInputBase-inputMultiline": {
                                                    height: "128px !important",
                                                },
                                            },
                                        }}
                                        baseInputTextProps={{
                                            maxLength: 200,
                                        }}
                                    />
                                    <div className="text-right -mt-4 mb-5 text-[#475467] text-md">
                                        {hook.description_limit_string}
                                    </div>
                                </div>
                                <div className="w-full flex items-center justify-end space-x-3">
                                    <Button
                                        variant="outlined"
                                        disabled={hook.isSavingMeta}
                                        onClick={() => hook.toggleSidebar()}
                                    >
                                        Cancel
                                    </Button>
                                    <LoaderButton
                                        variant="contained"
                                        loading={hook.isSavingMeta}
                                        disabled={!hook.isMetaDirty}
                                    >
                                        Save Changes
                                    </LoaderButton>
                                </div>
                            </ThemeFormProvider>
                        )}
                    </>
                )}
            </div>
        </ProjectDrawer>
    );
};

export default PostPublishScreen;
