import useImages from "assets/images";
import { useParams } from "react-router-dom";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import AppIconButton from "shared/components/ui/AppIconButton";
import { IAppIconButtonProps } from "shared/components/ui/AppIconButton/type";
import { StudioLink } from "shared/constants/const";
import useAuth from "shared/hooks/useAuth";
import { encryptIdForUrl } from "shared/utils/encrypt-url";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");

const StartViewer = () => {
    const { scanId } = useParams<{ scanId: string }>();
    const { request } = useSingleSR();
    const scan = request?.applications
        .find((req) => req.submissions.find((sub) => sub.scan_id === parseInt(scanId!)))
        ?.submissions.find((sub) => sub.scan_id === parseInt(scanId!));

    const Images = useImages();
    const { profile, user } = useAuth();
    const model = scan?.model;

    const Viewer1: IAppIconButtonProps = {
        className: "start_viewer_button !bg-[#7680FF] !bg-opacity-25 !w-auto !text-sm !text-primary",
        icon: (
            <>
                Full View
                <Images.ArrowUpRight width={18} height={18} className="ml-1" />
            </>
        ),
    };

    const Viewer2: IAppIconButtonProps = {
        className: "start_viewer_button !bg-[#7680FF] !w-auto !text-sm !text-white",
        icon: (
            <>
                Start Studio
                <Images.ArrowUpRight width={18} height={18} className="ml-1" />
            </>
        ),
    };

    if (scan && model && model.status === "Completed") {
        return (
            <div className="flex items-center space-x-4">
                {scan.method === "nerfacto" && (
                    <AppIconButton
                        {...Viewer1}
                        disabled={true}
                        tooltipTitle="We are in the process of updating our systems for 'Unbounded Scans'. Reprocesses to 'Focused Scans' will be free."
                        onClick={() => {
                            const url = `${StudioLink}?scan_id=${
                                scan.scan_id ? encryptIdForUrl(scan.scan_id) : null
                            }&user_id=${user?.user_id ? encryptIdForUrl(user?.user_id) : null}&user_email=${
                                user?.email
                            }&profile_id=${profile?.profile_id ? encryptIdForUrl(profile?.profile_id) : null}`;
                            window.open(url, "_blank");
                            amplitude.track('Viewer Opened', scan);

                        }}
                    />
                )}

                {scan.method !== "nerfacto" && (
                    <AppIconButton
                        {...Viewer2}
                        disabled={model.status !== "Completed" || !model.splat_file}
                        tooltipTitle={
                            model.status !== "Completed"
                                ? "Start Studio" + ": This scan needs to get reprocessed."
                                : !model.splat_file
                                ? "Start Studio" + ": This scan needs to get reprocessed for generating ply file."
                                : ""
                        }
                        onClick={() => {
                            const url = `${StudioLink}?scan_id=${
                                scan.scan_id ? encryptIdForUrl(scan.scan_id) : null
                            }&user_id=${user?.user_id ? encryptIdForUrl(user?.user_id) : null}&user_email=${
                                user?.email
                            }&profile_id=${profile?.profile_id ? encryptIdForUrl(profile?.profile_id) : null}`;
                            window.open(url, "_blank");
                            amplitude.track('Viewer Opened', scan);

                        }}
                    />
                )}
            </div>
        );
    } else {
        return <></>;
    }
};

export default StartViewer;
