import { useState } from "react";
import MyScanTutorial from "shared/components/MyScanTutorial";
import PageHeader from "shared/components/ui/PageHeader";
import { TeamMember } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import RecentProjects from "./partials/RecentProjects";
import RecentScans from "./partials/RecentScans";
import Stats from "./partials/Stats";
import TeamMemberDetailDrawer from "./partials/TeamMemberDetailDrawer";
import TeamMembers from "./partials/TeamMembers";
import BarChartSquare02 from "@untitled-ui/icons-react/build/esm/BarChartSquare02";
import ScanCountBox from "shared/components/ScanCountBox";

const Dashboard = () => {
    const [selectedMember, setSelectedMember] = useState<TeamMember>();
    const { team } = useAuth();

    const onViewUser = (val: TeamMember) => {
        setSelectedMember(val);
        const dashboardLayout = document.getElementById("dashboard-layout");
        const dashboardLayoutWidth = dashboardLayout?.scrollWidth;
        dashboardLayout?.scrollBy({
            left: dashboardLayoutWidth,
            behavior: "smooth",
        });
    };

    return (
        <>
            <PageHeader
                title={
                    <div className="flex items-center justify-between w-full">
                        <div className="flex items-center space-x-[6px]">
                            <BarChartSquare02 color="#475467" height={20} width={20} />
                            <span>{`${team?.name || ""}'s Dashboard`}</span>
                        </div>
                    </div>
                }
            >
                <ScanCountBox />
            </PageHeader>
            <div className="flex overflow-hidden w-full space-x-4 bg-[#FFFFFF] p-4" id="dashboard-layout">
                <div className="flex min-w-[calc(100%-392px-16px)] w-[calc(100%-392px)] flex-col space-y-4">
                    <Stats />
                    <RecentScans />
                    <RecentProjects />
                </div>
                <div className="min-w-[392px] w-[392px]">
                    <TeamMembers onView={onViewUser} />
                </div>
                <div className="min-w-[392px] w-[392px]">
                    <TeamMemberDetailDrawer data={selectedMember} />
                </div>
            </div>
            <MyScanTutorial />
        </>
    );
};

export default Dashboard;
