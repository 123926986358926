import { Tooltip } from "@mui/material";
import { useState } from "react";
import { twMerge } from "tailwind-merge";

import useImages from "assets/images";
import NoTeamMember from "assets/images/extras/No-Team-Member.png";
import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import ChipBox from "shared/components/ChipBox";
import Map from "shared/components/Map";
import RenderImage from "shared/components/ui/RenderImage";
import UserProfileCard from "shared/components/UserProfileCard";

import SRSingleSubmission from "../screens/SRDetailScreen/screens/Submissions/Single";

const SRDetailBox = () => {
    const { request } = useSingleSR();
    const Images = useImages();
    const [submissionId, setSubmissionId] = useState("");

    if (!request) return <></>;

    const requestHasSubmissions = request?.applications.some((item) => item.submissions.length > 0);

    if (submissionId) return <SRSingleSubmission submissionId={submissionId} onBack={() => setSubmissionId("")} />;

    return (
        <div className="flex flex-col space-y-4">
            <div className="flex-1 bg-white shadow-card p-4 rounded-2xl">
                <p className="text-lg leading-6 text-p mb-6">{request.description}</p>
                <p className="text-lg leading-6 text-p mb-6">
                    <b>Note:</b> {request.extra_notes}
                </p>
                <div className="flex items-center gap-6 flex-wrap">
                    <Tooltip title="Requestor" placement="top">
                        <div className="flex items-center space-x-2 cursor-pointer">
                            <RenderImage
                                containerClassName="h-5 w-5 rounded-full"
                                image={request.requestor.profile_image.url || ""}
                            />
                            <span className="text-md text-p font-normal leading-5">{request.requestor.name}</span>
                        </div>
                    </Tooltip>
                    <Tooltip title="Scanners Count" placement="top">
                        <div className="flex items-center space-x-2 cursor-pointer">
                            <Images.UsersIcon color="#9E9E9E" height={20} width={20} />
                            <span className="text-md text-p font-normal leading-5">
                                {request.scanner_count === 0 ? "Open for all" : request.scanner_count}
                            </span>
                        </div>
                    </Tooltip>
                    <Tooltip title="Urgency" placement="top">
                        <div className="flex items-center space-x-2 cursor-pointer">
                            <Images.CalenderIcon color="#9E9E9E" height={20} width={20} />
                            <span className="text-md text-p font-normal leading-5">{request.urgency}</span>
                        </div>
                    </Tooltip>
                    <Tooltip title="Estimated Time" placement="top">
                        <div className="flex items-center space-x-2 cursor-pointer">
                            <Images.TimeCircleIcon color="#9E9E9E" height={20} width={20} />
                            <span className="text-md text-p font-normal leading-5">{request.estimated_time}</span>
                        </div>
                    </Tooltip>
                    <Tooltip title="Scan Focus" placement="top">
                        <div className="flex items-center space-x-2 cursor-pointer">
                            <Images.CompassIcon color="#9E9E9E" height={20} width={20} />
                            <span className="text-md text-p font-normal leading-5">{request.scan_focus}</span>
                        </div>
                    </Tooltip>
                    <Tooltip title="Camera Type" placement="top">
                        <div className="flex items-center space-x-2 cursor-pointer">
                            <Images.CameraIcon color="#9E9E9E" height={20} width={20} />
                            <span className="text-md text-p font-normal leading-5">{request.camera_type}</span>
                        </div>
                    </Tooltip>
                    <Tooltip title="Dataset Type" placement="top" className="asdas">
                        <div className="flex items-center space-x-2 cursor-pointer">
                            <Images.VideoDarkIcon color="#9E9E9E" height={20} width={20} />
                            <span className="text-md text-p font-normal leading-5">
                                {request.dataset_type === "images"
                                    ? "Zipped Photo Scan"
                                    : request.dataset_type === "video"
                                    ? "Video Scan"
                                    : "360 Video Scan"}
                            </span>
                        </div>
                    </Tooltip>
                </div>
            </div>
            <div className="grid grid-cols-2 gap-4">
                <div className="flex-1 bg-white shadow-card p-4 rounded-2xl">
                    <div className="w-full h-[230px]">
                        <Map
                            isInteract={false}
                            location={{
                                latitude: parseFloat(request.location.latitude),
                                longitude: parseFloat(request.location.longitude),
                            }}
                            showLocationMarker={true}
                        />
                    </div>
                    <div className="mt-3 font-medium text-[#5E5D5D]">
                        <Tooltip placement="top" title={request.location.name}>
                            <div
                                className="flex items-center space-x-2 justify-between cursor-pointer"
                                onClick={() =>
                                    window.open(
                                        `https://maps.google.com?q=${request.location.latitude},${request.location.longitude}`
                                    )
                                }
                            >
                                <div className="text-lg whitespace-nowrap overflow-hidden text-ellipsis">
                                    {request.location.name ?? "N/A"}
                                </div>
                                <Images.ArrowUpRight color="#5E5D5D" />
                            </div>
                        </Tooltip>
                        <h6 className="text-p opacity-50 text-md leading-4 font-normal">Location</h6>
                    </div>
                </div>
                <div className="flex-1 flex flex-col bg-white shadow-card p-4 rounded-2xl">
                    <h6 className="text-p text-lg leading-4 font-bold mb-1">Team Members</h6>
                    <div className="flex-1">
                        {request.applications.length ? (
                            request.applications.map((app, i) => {
                                return (
                                    <div
                                        key={i}
                                        className={twMerge(
                                            "flex-center !justify-between border-b py-3 last:border-b-0",
                                            requestHasSubmissions && "cursor-pointer"
                                        )}
                                        onClick={() => {
                                            if (requestHasSubmissions) {
                                                setSubmissionId(app.application_id.toString());
                                            }
                                        }}
                                    >
                                        <UserProfileCard
                                            key={app.application_id}
                                            image={app.user.profile_image.url}
                                            name={app.user.name}
                                            email={app.user.email}
                                        />
                                        <ChipBox label={app.status} color="default" />
                                    </div>
                                );
                            })
                        ) : (
                            <div className="flex flex-col items-center justify-center h-full">
                                <RenderImage image={NoTeamMember} containerClassName="h-[118px] w-[152px]" />
                                <h4 className="text-lg leading-6 text-p my-1">No Applications</h4>
                                <h5 className="text-md leading-5 text-p text-opacity-50">
                                    Invite team members
                                    <br />
                                    or wait for applications
                                </h5>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SRDetailBox;
