import useImages from "assets/images";
import { twMerge } from "tailwind-merge";

interface PageLoaderProps {
    className?: string;
}

const PageLoader = ({ className = "" }: PageLoaderProps) => {
    const Images = useImages();

    return (
        <div
            className={twMerge(
                `h-screen w-full flex items-center justify-center bg-[rgba(255,255,255,0.5)] fixed top-0 left-0 z-[10000]`,
                className
            )}
        >
            <div className="text-center flex flex-col justify-center items-center">
                <img src={Images.LogoPNG} width={110} />
                <div className="linear-loader mt-5">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>
    );
};

export default PageLoader;
