import { Backdrop, Badge, Divider, Fade, List, ListItem, Paper, Popper, styled } from "@mui/material";
import CheckCircle from "@untitled-ui/icons-react/build/esm/CheckCircle";
import CreditCard02 from "@untitled-ui/icons-react/build/esm/CreditCard02";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";
import HomeLine from "@untitled-ui/icons-react/build/esm/HomeLine";
import Inbox01 from "@untitled-ui/icons-react/build/esm/Inbox01";
import LogOut03 from "@untitled-ui/icons-react/build/esm/LogOut03";
import Phone02 from "@untitled-ui/icons-react/build/esm/Phone02";
import Award02 from "@untitled-ui/icons-react/build/esm/Award02";
import PlusCircle from "@untitled-ui/icons-react/build/esm/PlusCircle";
import Settings01 from "@untitled-ui/icons-react/build/esm/Settings01";
import UserPlus01 from "@untitled-ui/icons-react/build/esm/UserPlus01";
import Users01 from "@untitled-ui/icons-react/build/esm/Users01";
import useImages from "assets/images";
import UserProfileCard from "shared/components/UserProfileCard";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import PageLoader from "shared/components/ui/PageLoader";
import RenderImage from "shared/components/ui/RenderImage";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import URL from "shared/constants/navigator";
import useAuth from "shared/hooks/useAuth";
import useLogout from "shared/hooks/useLogout";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { utilSelector } from "store/selectors";
import { toggleCustomerSupportModal } from "store/slices/utils";
import { twMerge } from "tailwind-merge";
import useLoggedUserDetail from "./hook";

const LoggedUserDetail = () => {
    const hook = useLoggedUserDetail();
    const { user, profile } = useAuth();
    const { confirmLogout } = useLogout();
    const Images = useImages();
    const dispatch = useAppDispatch();

    const { isSidebarCollapsed } = useAppSelector(utilSelector);

    const open = Boolean(hook.anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            {(hook.switchLoading || hook.acceptRes.isLoading || hook.approveRes.isLoading) && (
                <PageLoader className="fixed top-0 left-0 w-full h-full" />
            )}
            <div className="my-5">
                <hr />
                <div
                    ref={hook.userCardEle}
                    className="pt-5 flex items-center relative z-30 !bg-white user-menu-item"
                    onClick={hook.anchorEl ? hook.handleClose : hook.handleClick}
                >
                    <div
                        className={twMerge(
                            "w-[calc(100%-25px)] cursor-pointer",
                            isSidebarCollapsed && "w-full group-hover:w-[calc(100%-25px)]"
                        )}
                    >
                        <UserProfileCard
                            image={hook.user?.profile_image?.url}
                            name={hook.user?.name}
                            email={hook.team?.name}
                            classes={{
                                image: "min-w-[40px]",
                                root:
                                    "justify-normal " +
                                    (isSidebarCollapsed && "justify-center group-hover:justify-normal"),
                                extra_details: "" + (isSidebarCollapsed && "hidden group-hover:block"),
                            }}
                        />
                    </div>
                    <div className={"ml-2 " + (isSidebarCollapsed && "hidden group-hover:block")}>
                        <Images.ArrowChevronRight
                            className={hook.anchorEl ? "rotate-90" : "-rotate-90"}
                            color="#667085"
                            height={16}
                            width={16}
                        />
                    </div>
                </div>
                <Backdrop
                    className="!fixed !top-0 !left-0 !w-full !h-full !z-20"
                    open={open}
                    onClick={hook.handleClose}
                    sx={{ zIndex: 10, backgroundColor: "rgba(255, 255, 255, 0.7)", backdropFilter: "blur(4px)" }}
                />
                <Popper
                    id={id}
                    open={open}
                    anchorEl={hook.anchorEl}
                    placement="top"
                    className="!-bottom-[70px] !left-[0px] z-20"
                >
                    <Paper className="!pt-2 w-[220px] !left-[7px] !shadow-[0px_12px_16px_-4px_#10182814] border border-[#EAECF0] rounded-lg bottom-[19px] !pb-[70px]">
                        <Fade in={hook.showMainMenu} style={{ display: hook.showMainMenu ? "block" : "none" }}>
                            <CustomList>
                                <MenuItem
                                    icon={Award02}
                                    label="Default Profile"
                                    rightArrow
                                    onClick={() => hook.showMenu("profiles")}
                                />
                                <MenuItem
                                    icon={Inbox01}
                                    label="Invitations"
                                    badge={hook.invitations.length}
                                    rightArrow
                                    onClick={() => hook.showMenu("invitations")}
                                />
                                <MenuItem
                                    icon={PlusCircle}
                                    label="New Team"
                                    onClick={() => hook.goTo(`${URL.Settings}/${URL.Accounts}?action=new`)}
                                />
                                <MenuItem
                                    icon={UserPlus01}
                                    label="Join Team"
                                    onClick={() => hook.goTo(`${URL.Settings}/${URL.Accounts}?action=join`)}
                                />
                                <Divider />
                                <MenuItem
                                    icon={Phone02}
                                    label="Scanning Devices"
                                    onClick={() => hook.goTo(`${URL.Settings}?tab=scanning_devices`)}
                                />
                                <MenuItem icon={Settings01} label="Settings" onClick={() => hook.goTo(URL.Settings)} />
                                <Divider />
                                <MenuItem
                                    icon={HomeLine}
                                    label="Team Profile"
                                    onClick={() => hook.goTo(`${URL.Settings}/${URL.team}`)}
                                />
                                <MenuItem
                                    icon={Users01}
                                    label="Team Members"
                                    onClick={() => hook.goTo(`${URL.Settings}/${URL.team}?tab=members`)}
                                />
                                <MenuItem
                                    icon={UserPlus01}
                                    label="Joining Requests"
                                    badge={hook.joinRequests?.length}
                                    onClick={() => hook.showMenu("joinRequests")}
                                    rightArrow
                                />
                                <MenuItem
                                    icon={CreditCard02}
                                    label="Payment & Billing"
                                    onClick={() => hook.goTo(`${URL.Settings}/${URL.Shop}`)}
                                />
                                <Divider />
                                <MenuItem
                                    icon={HelpCircle}
                                    label="Customer Support"
                                    onClick={() => {
                                        hook.handleClose();
                                        dispatch(toggleCustomerSupportModal());
                                    }}
                                />
                                <Divider />
                                <MenuItem icon={LogOut03} label="Logout" onClick={() => confirmLogout()} />
                                <div className="h-1" />
                            </CustomList>
                        </Fade>
                        <Fade in={hook.showProfiles} style={{ display: hook.showProfiles ? "block" : "none" }}>
                            <CustomList>
                                <MenuItem
                                    isHeader
                                    icon={Images.ArrowChevronLeft}
                                    label="Default Profile"
                                    onClick={() => hook.showMenu("main")}
                                />
                                {hook.profiles.length ? (
                                    hook.profiles
                                        .sort((a, b) => {
                                            // sort by current profile
                                            if (a.profile_id === profile?.profile_id) return -1;
                                            if (b.profile_id === profile?.profile_id) return 1;

                                            return 0;
                                        })
                                        .map((pro) => {
                                            const isCurrent = pro.profile_id === profile?.profile_id;
                                            const isDefault = user?.default_profile_id === pro.profile_id;

                                            return (
                                                <MenuItem
                                                    key={pro.profile_id}
                                                    img={pro.profile_image.url ?? ""}
                                                    label={pro.name}
                                                    hoverRightArrow={!isCurrent}
                                                    rightArrow={
                                                        <>
                                                            <ThemeIconButton
                                                                tooltipTitle={
                                                                    isDefault
                                                                        ? "Default Profile"
                                                                        : "Switch and Set as Default"
                                                                }
                                                                tooltipPosition="top"
                                                                className="!shadow-none"
                                                                onClick={
                                                                    !isDefault
                                                                        ? () => {
                                                                              hook.handleClose();
                                                                              pro.profile_id &&
                                                                                  hook.doSwitchProfile(
                                                                                      pro.profile_id,
                                                                                      true
                                                                                  );
                                                                          }
                                                                        : undefined
                                                                }
                                                                icon={
                                                                    <CheckCircle
                                                                        height={16}
                                                                        width={16}
                                                                        color={
                                                                            user?.default_profile_id === pro.profile_id
                                                                                ? "#7680FF"
                                                                                : "#D0D5DD"
                                                                        }
                                                                    />
                                                                }
                                                            />
                                                        </>
                                                    }
                                                />
                                            );
                                        })
                                ) : (
                                    <p className="text-p text-sm px-5 my-3">No profiles found</p>
                                )}
                            </CustomList>
                        </Fade>
                        <Fade in={hook.showInvitations} style={{ display: hook.showInvitations ? "block" : "none" }}>
                            <div>
                                <CustomList>
                                    <MenuItem
                                        isHeader
                                        icon={Images.ArrowChevronLeft}
                                        label="Invitations"
                                        onClick={() => hook.showMenu("main")}
                                    />
                                    {hook.invitations.length ? (
                                        hook.invitations.map((invitation) => {
                                            return (
                                                <MenuItem
                                                    key={invitation.team_id}
                                                    img={invitation.logo.url ?? ""}
                                                    label={invitation.name}
                                                    rightArrow={
                                                        <ThemeIconButton
                                                            tooltipTitle="Accept Invitation"
                                                            tooltipPosition="top"
                                                            className="!shadow-none"
                                                            onClick={() =>
                                                                hook.doActOnInvitation(invitation.invite_code, "accept")
                                                            }
                                                            icon={
                                                                <Images.CircleCheck
                                                                    height={16}
                                                                    width={16}
                                                                    color="#7680FF"
                                                                />
                                                            }
                                                        />
                                                    }
                                                />
                                            );
                                        })
                                    ) : (
                                        <p className="text-p text-sm px-5 my-3">No invitations found</p>
                                    )}
                                </CustomList>
                            </div>
                        </Fade>
                        <Fade in={hook.showJoinRequests} style={{ display: hook.showJoinRequests ? "block" : "none" }}>
                            <div>
                                <CustomList>
                                    <MenuItem
                                        isHeader
                                        icon={Images.ArrowChevronLeft}
                                        label="Joining Requests"
                                        onClick={() => hook.showMenu("main")}
                                    />
                                    {hook.joinRequests && hook.joinRequests.length ? (
                                        hook.joinRequests.map((member) => {
                                            return (
                                                <MenuItem
                                                    key={member.member_id}
                                                    img={member.user?.profile_image.url ?? ""}
                                                    label={member.user?.name ?? ""}
                                                    rightArrow={
                                                        <ConfirmButton
                                                            title="Approve Member"
                                                            content="Are you sure you want to approve this member?"
                                                            icon={<Images.TeamMembersLayerIconLightMode />}
                                                            okBtnText="Approve"
                                                            okBtnProps={{
                                                                onClick: () =>
                                                                    hook.doActOnRequest(member.member_id, "approve"),
                                                            }}
                                                        >
                                                            <ThemeIconButton
                                                                tooltipTitle="Accept Request"
                                                                tooltipPosition="top"
                                                                className="!shadow-none"
                                                                onClick={() => {}}
                                                                icon={
                                                                    <Images.CircleCheck
                                                                        height={16}
                                                                        width={16}
                                                                        color="#7680FF"
                                                                    />
                                                                }
                                                            />
                                                        </ConfirmButton>
                                                    }
                                                />
                                            );
                                        })
                                    ) : (
                                        <p className="text-p text-sm px-5 my-3">No join requests found</p>
                                    )}
                                </CustomList>
                            </div>
                        </Fade>
                    </Paper>
                </Popper>
            </div>
        </>
    );
};

export default LoggedUserDetail;

const CustomList = styled(List)(() => ({
    padding: 0,
    minHeight: "200px",
    borderBottom: "1px solid #EAECF0",
    marginBottom: 4,

    ".MuiListItem-root": {
        padding: "3px 12px 3px 0",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        color: "#344054",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "> div": {
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
        },

        "&:not(.header)": {
            cursor: "pointer",

            "&:hover": {
                backgroundColor: "#F9FAFB",
            },
        },
    },

    ".MuiDivider-root": {
        margin: "5px 0",
        borderColor: "#EAECF0",
    },
}));

type Props = {
    label: string;
    icon?: any;
    img?: string;
    badge?: number;
    onClick?: (event: any) => void;
    rightArrow?: boolean | React.ReactNode;
    hoverRightArrow?: boolean;
    isHeader?: boolean;
};

const MenuItem = (props: Props) => {
    const Icon = props.icon;
    const Images = useImages();

    return (
        <ListItem
            onClick={props.onClick}
            className={twMerge(
                "group min-h-[38px] cursor-pointer",
                props.isHeader ? "!text-primary border-b !pb-[10px] mb-1 header" : "!cursor-default"
            )}
        >
            <div className="whitespace-nowrap flex-1 flex text-ellipsis overflow-hidden">
                <div
                    onClick={props.onClick}
                    className={twMerge(
                        "!h-[30px] !min-w-[30px] !w-[30px] flex items-center justify-center whitespace-nowrap overflow-hidden",
                        props.onClick && "cursor-pointer"
                    )}
                >
                    {props.icon && <Icon height={16} width={16} color="#667085" />}
                    {props.img && (
                        <RenderImage
                            image={props.img}
                            containerClassName="h-[20px] w-[20px] mr-1"
                            imgClassName="rounded-full"
                        />
                    )}
                </div>
                <span className="block whitespace-nowrap w-[calc(100%-40px)] text-ellipsis overflow-hidden">
                    {props.label}{" "}
                </span>
                {props.badge ? (
                    <div className="!min-w-[30px]">
                        <Badge
                            badgeContent={props.badge}
                            color="primary"
                            className="ml-4"
                            classes={{
                                badge: "!w-6 !h-5 !text-sm",
                            }}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className={twMerge(props.hoverRightArrow && "group-hover:!flex !hidden")}>
                {typeof props.rightArrow === "boolean" && props.rightArrow && (
                    <Images.ArrowChevronRight height={16} width={16} color="#4a4a4a" />
                )}
                {typeof props.rightArrow !== "boolean" && props.rightArrow}
            </div>
        </ListItem>
    );
};
