import { useState } from "react";
import UserProfileCard from "shared/components/UserProfileCard";
import CheckboxCard, { CheckboxCardSkeleton } from "shared/components/ui/CheckboxCard";
import LoaderButton from "shared/components/ui/LoaderButton";
import { GetInvitationsQuery, Profile } from "shared/graphql";
import useGetProfileApi from "shared/hooks/useGetProfileApi";
import useSwitchProfileApi from "shared/hooks/useSwitchProfileApi";
import { errorToast } from "shared/utils/toast";
import PerfectScrollbar from "react-perfect-scrollbar";
import User01 from "@untitled-ui/icons-react/build/esm/User01";

const ProfileSelectionScreen = () => {
    const [selectedProfile, setSelectedProfile] = useState<
        Profile["profile_id"] | GetInvitationsQuery["invitations"][0]["invite_code"]
    >();

    const [selectedDefault, setSelectedDefault] = useState(false);

    const { profiles, isLoading } = useGetProfileApi({});

    const { doSwitchProfile, isLoading: switchLoading } = useSwitchProfileApi({
        doNavigate: true,
    });

    return (
        <div className="w-[360px] mx-auto">
            <div className="text-center mb-6">
                <div className="inline-flex justify-center items-center px-3.5 w-14 h-14 bg-white rounded-xl border border-solid shadow-sm border-[#EAECF0] mx-auto mb-4">
                    <User01 width={28} height={28} color="#344054" />
                </div>
                <h4 className="text-[#101828] font-medium text-[30px] leading-[38px] !mb-3">Select Profile</h4>
                <p className="text-[#475467] text-lg leading-6">Select your profile type to log in</p>
            </div>
            <PerfectScrollbar>
                <div className="my-3 flex flex-col space-y-3 max-h-[calc(100vh-500px)]">
                    {isLoading ? (
                        <div className="my-3 flex flex-col space-y-3">
                            {[...Array(2)].map((c, i) => (
                                <CheckboxCardSkeleton key={i} />
                            ))}
                        </div>
                    ) : (
                        profiles
                            .filter((p) => p.type !== "Default")
                            .map((profile) => (
                                <CheckboxCard
                                    key={profile.profile_id}
                                    checked={selectedProfile === profile.profile_id}
                                    value={profile.profile_id.toString()}
                                    onClick={() => setSelectedProfile(profile.profile_id)}
                                >
                                    <UserProfileCard
                                        name={profile.name}
                                        image={profile.profile_image.url}
                                        email={profile.type}
                                    />
                                </CheckboxCard>
                            ))
                    )}
                </div>
            </PerfectScrollbar>
            <LoaderButton
                className="h-[44px] !mt-4 !mb-2"
                fullWidth
                variant="contained"
                type="submit"
                disabled={switchLoading && selectedDefault}
                loading={switchLoading && !selectedDefault}
                onClick={() => {
                    if (selectedProfile) {
                        doSwitchProfile(selectedProfile as number);
                    } else {
                        errorToast("Please select a profile");
                    }
                }}
            >
                Continue
            </LoaderButton>
            <LoaderButton
                className="h-[44px]"
                fullWidth
                type="submit"
                disabled={switchLoading && !selectedDefault}
                loading={switchLoading && selectedDefault}
                onClick={() => {
                    if (selectedProfile) {
                        setSelectedDefault(true);
                        doSwitchProfile(selectedProfile as number, true);
                    } else {
                        errorToast("Please select a profile");
                    }
                }}
            >
                Set as Default Profile
            </LoaderButton>
        </div>
    );
};

export default ProfileSelectionScreen;
