import { Editor } from "@tiptap/core";
import React, { useEffect, useRef } from "react";

import { INestedEditorProps } from "./NestedEditor";
import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import useImages from "assets/images";
import { useForm } from "react-hook-form";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";

const NestedEditorProperties = ({ editor, props }: { editor: Editor | null; props: INestedEditorProps }) => {
    const { id, color, width, justify, editor: nestedEditor, editor_content } = props;
    const { EditorAlignLeftIcon, EditorAlignCenterIcon, EditorAlignRightIcon, PercentageIcon } = useImages();

    const isInit = useRef(false);

    const { control, watch, setValue } = useForm<{
        id: string;
        color: string;
        width: number;
        justify: "left" | "center" | "right";
    }>({
        defaultValues: {
            id,
            color,
            width,
            justify,
        },
    });

    const color_val = watch("color");
    const width_val = watch("width");
    const justify_val = watch("justify");

    const onUpdate = () => {
        const data = {
            id,
            color: color_val,
            width: width_val,
            justify: justify_val,
            nestedEditor,
            editor_content,
        };

        if (editor) {
            let pos = 0;

            editor!.view.state.doc.descendants((node, position) => {
                if (node.attrs.id == id) {
                    pos = position;
                    return false;
                }
            });

            const transaction = editor!.view.state.tr.setNodeMarkup(pos, null, data);

            editor!.view.dispatch(transaction);
        }
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [color_val, width_val, justify_val]);

    return (
        <ThemeFormProvider
            form={
                {
                    control,
                    handleSubmit: () => {},
                } as any
            }
            onSubmit={null}
        >
            <div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Background Color</div>
                    <div className="flex gap-x-3">
                        {/* <div className="bg-[#F2F3FD] h-[22px] w-[22px] rounded-[4px] flex items-center justify-center cursor-pointer select-none hover:brightness-90">
                        <EditorPlusPrimaryIcon height={10} width={10} />
                    </div> */}
                        {/* <div className="border-l border-[#EAECF0] pl-3 flex-1 flex flex-wrap gap-x-2 gap-y-2"> */}
                        <div className="flex-1 flex flex-wrap gap-x-2 gap-y-2">
                            {[
                                "#F9FAFB",
                                "#EFEFEF",
                                "#475467",
                                "#FBD9D9",
                                "#E0FBD9",
                                "#D9E9FB",
                                "#F9D9FB",
                                "#99EAF5",
                                "#FBE4B9",
                                "#70A0D8",
                                "#EB8383",
                            ].map((colorHex) => (
                                <div
                                    key={colorHex}
                                    className={`h-[22px] w-[22px] rounded-full inline-block cursor-pointer ${
                                        colorHex == color_val && " border-2 border-[#7680FF] "
                                    }`}
                                    style={{ backgroundColor: colorHex }}
                                    onClick={() => {
                                        setValue("color", colorHex);
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-full pt-4 pb-6 border-b px-5">
                    <div className="text-sm text-[#667085] mb-2">Width</div>
                    <Field
                        name="width"
                        placeholder=""
                        inputTextProps={{
                            className: "!h-[34px]",
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                                ".MuiOutlinedInput-input": {
                                    color: "#667085",
                                    fontSize: "12px",
                                },
                            },
                            endAdornment: <PercentageIcon width={10} height={10} />,
                        }}
                    />

                    <div className="text-sm text-[#667085] mt-4 mb-2">Justify</div>
                    <ToggleButtonGroup
                        value={justify_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("justify", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderLeft: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },

                                "&:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"left"} aria-label="Yes">
                            <EditorAlignLeftIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"center"} aria-label="No">
                            <EditorAlignCenterIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"right"} aria-label="No">
                            <EditorAlignRightIcon width={14} height={14} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default NestedEditorProperties;
