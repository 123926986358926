import useImages from "assets/images";
import { Navigate } from "react-router-dom";
import ResendOTP from "shared/components/ResendOTP";
import Field from "shared/components/ui/Field";
import LayerIcon from "shared/components/ui/LayerIcon";
import LoaderButton from "shared/components/ui/LoaderButton";
import PageLoader from "shared/components/ui/PageLoader";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeModal from "shared/components/ui/ThemeModal";
import URL from "shared/constants/navigator";
import useRegisterSuccess from "./hook";

const RegisterSuccessScreen = () => {
    const hook = useRegisterSuccess();
    const Images = useImages();

    if (!hook.email || !hook.email_verification_id) {
        return <Navigate to={URL.Home} replace />;
    }

    return (
        <>
            {hook.resendEmailRes.isLoading && <PageLoader className="fixed inset-0 z-50" />}
            <div className="w-[360px] mx-auto">
                <h4 className="text-[#101828] font-medium text-[30px] leading-[38px] !mb-3">Account Verification</h4>
                <p className="text-[#475467] text-lg leading-6 mb-8">
                    We have sent you an email with the auth code at <span className="text-primary">{hook.email}</span>.
                    Please verify your email by adding OTP.
                </p>
                <ThemeFormProvider form={hook.otpForm} onSubmit={hook.onOtpSubmit} className="text-left">
                    <Field name="otp" label="Email OTP" placeholder="Enter your OTP here" />
                    <LoaderButton
                        loading={hook.resendEmailRes.isLoading}
                        loadingText={"Verifying OTP..."}
                        variant="contained"
                        fullWidth
                        type="submit"
                        className="text-lg font-medium !h-11"
                    >
                        Verify Code
                    </LoaderButton>
                </ThemeFormProvider>
                <p className="text-[#475467] text-lg leading-7 my-8">
                    Didn&apos;t receive Code yet?
                    <br />
                    <span className="flex items-center space-x-3">
                        <ResendOTP
                            refresh={hook.doRefresh}
                            onSend={hook.onResendEmailOtp}
                            render={({ resendBtn, sendOtp, timerText }) => {
                                if (resendBtn) {
                                    return (
                                        <p className="text-center font-normal inline">
                                            <span className="text-primary hover:cursor-pointer" onClick={sendOtp}>
                                                Resend OTP
                                            </span>
                                        </p>
                                    );
                                }
                                return (
                                    <p className="text-center font-normal inline">
                                        Resend Code in: <span className="text-secondary">{timerText}</span>
                                    </p>
                                );
                            }}
                        />
                        <div className="h-4 border-l" />
                        <span className="text-primary cursor-pointer" onClick={() => hook.setOpenEmailModal(true)}>
                            Change Email
                        </span>
                    </span>
                </p>
            </div>
            <ThemeModal
                icon={<LayerIcon icon={Images.EmailIcon} className="!h-[60px] !w-[60px]" iconClassName="!h-6 !w-6" />}
                open={hook.openEmailModal}
                onClose={() => hook.setOpenEmailModal(false)}
                title="Change Email"
                cancelBtnProps={{
                    onClick: () => hook.setOpenEmailModal(false),
                }}
                buttons={[
                    {
                        children: "Submit",
                        loading: hook.res.isLoading,
                        onClick: hook.emailForm.handleSubmit(hook.onEmailSubmit),
                    },
                ]}
            >
                Are you sure you want to change your email? <br />
                <ThemeFormProvider form={hook.emailForm} onSubmit={hook.onEmailSubmit} className="text-left">
                    <br />
                    <Field formGroup={false} label="Email" name="email" placeholder="Enter your email" />
                </ThemeFormProvider>
            </ThemeModal>
        </>
    );
};

export default RegisterSuccessScreen;
