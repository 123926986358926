import { CircularProgress } from "@mui/material";
import moment from "moment";
import { useParams } from "react-router-dom";

import useSingleSR from "screens/Dashboard/ScanRequests/common/hooks/useSingleSR";
import ScanRequestStatusCard from "shared/components/ScanRequestStatusCard";
import LoadingSnackbar from "shared/components/ui/LoadingSnackbar";
import ThemeScreenCard from "shared/components/ui/ThemeScreenCard";
import ThemeTabs from "shared/components/ui/ThemeTabs";
import useAuth from "shared/hooks/useAuth";

import SRDetailBox from "../../components/SRDetailBox";
import SRDetailActionButtons from "./components/ActionButtons";
import SRScanTab from "./screens/ScansTab";
import SRSubmissionsTab from "./screens/Submissions";

const SRDetailScreen = () => {
    const { isLoading, isFetching, request, isRequestor } = useSingleSR();
    const { type } = useParams<{ type: string }>();
    const { user } = useAuth();

    const tabs = [
        {
            label: "Details",
            children: <SRDetailBox />,
        },
        {
            label: "Submissions",
            children: <SRSubmissionsTab />,
        },
        {
            label: "Scans",
            children: <SRScanTab />,
            url: "scans",
        },
    ];

    const requestHasSubmissions = request?.applications.some((item) => item.submissions.length > 0);

    const applicationStatus = request?.applications.find((item) => item.user.user_id === user?.user_id)?.status;

    return (
        <ThemeScreenCard
            headerComponent={
                request &&
                !isLoading &&
                (request.status || applicationStatus) && (
                    <>
                        {!isRequestor && type === "in-progress" ? (
                            <ScanRequestStatusCard status={applicationStatus} />
                        ) : (
                            <ScanRequestStatusCard status={request.status} />
                        )}
                    </>
                )
            }
        >
            {isLoading && (
                <div className="card-dashed-border h-full flex items-center justify-center shadow-card">
                    <CircularProgress />
                </div>
            )}
            <LoadingSnackbar loading={!isLoading && isFetching} />
            {!isLoading && request && (
                <>
                    <div className="flex items-center justify-between mb-4">
                        <div>
                            <h4 className="text-p font-medium text-[20px] leading-6 my-1 capitalize">
                                {request.title}
                            </h4>
                            <p className="text-p opacity-50 text-md leading-4 font-normal">
                                {moment(request.created_at).format("MMMM DD, YYYY")}
                            </p>
                        </div>
                        <div className="flex items-center space-x-3">
                            <SRDetailActionButtons />
                        </div>
                    </div>
                    {requestHasSubmissions ? (
                        <div className="card-dashed-border !rounded-[16px] !border-none bg-white !pt-0 pb-4 flex flex-col !px-0">
                            <ThemeTabs className="border-b border-[#D0D5DD40] px-6 !pt-0" tabs={tabs} />
                        </div>
                    ) : (
                        <div className="card-dashed-border flex flex-col">
                            <SRDetailBox />
                        </div>
                    )}
                </>
            )}
        </ThemeScreenCard>
    );
};

export default SRDetailScreen;
