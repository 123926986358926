import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import Field from "shared/components/ui/Field";
import FDropifyUploadPhoto from "shared/components/ui/Field/partials/FDropifyUploadPhoto";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { useCustomerSupportMutation } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";

const schema = yup.object().shape({
    title: yup.string().required(),
    feature: yup.string(),
    type: yup.string(),
    description: yup.string().required(),
    attachmentUrl: yup.string().when("uploadingState", {
        is: "uploading",
        then: (schema) => schema.required("Please wait for the image to upload"),
    }),
    uploadingState: yup.string().oneOf(["uploading", "done", "error"]),
});

type FormValues = yup.InferType<typeof schema>;

const CustomerSupportModalContent = (props: { onClose: () => void }) => {
    const [customerSupport, customerSupportRes] = useCustomerSupportMutation();

    const form = useForm<FormValues>({
        defaultValues: {
            title: "",
            feature: "",
            type: "",
            description: "",
            attachmentUrl: "",
        },
        resolver: yupResolver(schema),
    });

    const onSubmit = (values: FormValues) => {
        customerSupport({
            input: {
                title: values.title,
                type: values.type || "-",
                feature: values.feature || "-",
                description: values.description,
                attachmentUrl: values.attachmentUrl || "",
            },
        });
    };

    useEffect(() => {
        rtkHandler(customerSupportRes, {
            successMessage: "Your bug has been reported successfully",
            setError: form.setError,
            onSuccess() {
                props.onClose();
            },
        });
    }, [customerSupportRes]);

    return (
        <ThemeFormProvider className="text-left" form={form} onSubmit={onSubmit}>
            <Field name="title" label="Ticket Title" placeholder="Enter a title for your issue" />
            <div className="flex space-x-4">
                <Field
                    name="feature"
                    label="Feature"
                    type="select"
                    placeholder="Select feature"
                    options={[
                        "Scans",
                        "Snapshots",
                        "Scan Viewer",
                        "Projects",
                        "Project Requests",
                        "Team Chat",
                        "Notifications",
                        "Settings",
                        "Team Management",
                        "Other",
                    ].map((value) => ({ label: value, value }))}
                />
                <Field
                    name="type"
                    label="Ticket Type"
                    type="select"
                    placeholder="Select ticket type"
                    options={["Feature Request", "Suggestion", "Improvement", "Bug Reporting"].map((value) => ({
                        label: value,
                        value,
                    }))}
                />
            </div>
            <Field
                name="description"
                label="Description"
                placeholder="Describe your bug for our team"
                inputTextProps={{
                    multiline: true,
                    rows: 3,
                }}
            />
            <Field
                name={"attachmentUrl"}
                label="Bug Screenshot"
                render={({ field }) => {
                    return (
                        <FDropifyUploadPhoto
                            value={field.value}
                            onChange={(file) => field.onChange(file ? file.url : "")}
                            uploadingState={(state) => form.setValue("uploadingState", state)}
                        />
                    );
                }}
            />
            <CommonModalActionButton
                buttons={[
                    {
                        children: "Submit",
                        color: "primary",
                        onClick: form.handleSubmit(onSubmit),
                        loading: customerSupportRes.isLoading,
                    },
                ]}
                cancelBtnProps={{
                    onClick: props.onClose,
                }}
            />
        </ThemeFormProvider>
    );
};

export default CustomerSupportModalContent;
