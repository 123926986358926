import { useEffect, useState } from "react";
import {
    GetInvitationsQuery,
    RequestTeamMutation,
    api,
    useAcceptInvitationMutation,
    useApproveRequestMutation,
    useGetInvitationsQuery,
} from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch } from "shared/hooks/useRedux";
import useSwitchProfileApi from "shared/hooks/useSwitchProfileApi";
import { rtkHandler } from "shared/utils/handlers";
import { successToast } from "shared/utils/toast";

const useLoggedUserDetail = () => {
    const { user, team } = useAuth();
    const [showMainMenu, setShowMainMenu] = useState(true);
    const [showInvitations, setShowInvitations] = useState(false);
    const [showJoinRequests, setShowJoinRequests] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openModal, setOpenModal] = useState<"new" | "join" | "">("");
    const dispatch = useAppDispatch();
    const [invitations, setInvitations] = useState<GetInvitationsQuery["invitations"]>([]);
    const { doSwitchProfile, isLoading: switchLoading } = useSwitchProfileApi({
        doNavigate: true,
    });

    const [acceptInvitation, acceptRes] = useAcceptInvitationMutation();

    const [approveMember, approveRes] = useApproveRequestMutation();

    useEffect(() => {
        rtkHandler(approveRes, {
            successMessage: "Member approved successfully",
        });
    }, [approveRes]);

    useEffect(() => {
        rtkHandler(acceptRes, {
            onSuccess() {
                dispatch(api.util.invalidateTags(["Profiles", "UserDetails", "Invitations"]));
            },
        });
    }, [acceptRes]);

    const invRes = useGetInvitationsQuery(undefined);

    useEffect(() => {
        rtkHandler(invRes, {
            onSuccess(res: GetInvitationsQuery) {
                setInvitations(res.invitations);
            },
        });
    }, [invRes]);

    const handleClick = (event) => {
        setShowMainMenu(true);
        setShowInvitations(false);
        setAnchorEl(event.currentTarget);
    };

    const onSuccessJoinTeam = (data: RequestTeamMutation["request_team"]) => {
        successToast(
            `Your request to join team ${data.name} has been submitted successfully. You will be notified once your request is accepted by the team admins.`,
            {
                duration: 3000,
            }
        );
        setOpenModal("");
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const joinRequests = team?.members.filter((item) => item.status === "Requested");

    const showMenu = (type: "main" | "invitations" | "profiles" | "joinRequests" | "close") => {
        setShowInvitations(false);
        setShowMainMenu(false);
        setShowJoinRequests(false);

        if (type === "main") {
            setShowMainMenu(true);
        } else if (type === "invitations") {
            setShowInvitations(true);
        } else if (type === "joinRequests") {
            setShowJoinRequests(true);
        } else {
            return;
        }
    };

    const doActOnInvitation = (code: string, action: "accept") => {
        if (action === "accept") acceptInvitation({ code });
    };

    const doActOnRequest = (member_id: number, action: "approve") => {
        if (action === "approve") approveMember({ member_id });
    };

    const trggerOpenModal = (type: "new" | "join") => {
        setOpenModal(type);
        setAnchorEl(null);
    };

    return {
        user,
        joinRequests,
        showMainMenu,
        showJoinRequests,
        showInvitations,
        switchLoading,
        anchorEl,
        invitations,
        acceptRes,
        approveRes,
        openModal,
        setOpenModal,
        trggerOpenModal,
        onSuccessJoinTeam,
        doActOnRequest,
        doActOnInvitation,
        doSwitchProfile,
        showMenu,
        handleClick,
        handleClose,
    };
};

export default useLoggedUserDetail;
