import { Box } from "@mui/material";
import ArrowUpRight from "@untitled-ui/icons-react/build/esm/ArrowUpRight";
import File05 from "@untitled-ui/icons-react/build/esm/File05";
import Image05 from "@untitled-ui/icons-react/build/esm/Image05";
import Target04 from "@untitled-ui/icons-react/build/esm/Target04";
import VideoRecorder from "@untitled-ui/icons-react/build/esm/VideoRecorder";
import DummyProfileImage from "assets/images/dummy/dummy-profile-pic.png";
import { Link } from "react-router-dom";
import Map from "shared/components/Map";
import ITDCard from "shared/components/ui/ITDCard";
import RenderImage from "shared/components/ui/RenderImage";
import { useAppSelector } from "shared/hooks/useRedux";
import { validCoordinates } from "shared/utils/helpers";

const AssetDetailCards = () => {
    const asset = useAppSelector((state) => state.scans.asset);

    if (!asset) return <></>;

    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-3 gap-4 mb-4">
                <ITDCard
                    className="border border-solid border-gray-200"
                    icon={
                        <RenderImage
                            containerClassName="h-10 w-10 rounded-full"
                            image={asset.user?.profile_image?.url ?? DummyProfileImage}
                        />
                    }
                    iconWrapperClassName="flex-initial"
                    title={asset.user?.name || ""}
                    desc="Scanner"
                />
                <ITDCard
                    className="border border-solid border-gray-200"
                    icon={
                        <Box
                            sx={{
                                'path[stroke-width="2"]': {
                                    strokeWidth: "1.25px",
                                },
                            }}
                        >
                            <Target04 strokeWidth={1} color="#5E5D5D" height={40} width={40} />
                        </Box>
                    }
                    iconWrapperClassName="flex-initial"
                    title={asset.focus}
                    desc={"Focus"}
                />
                <ITDCard
                    className="border border-solid border-gray-200"
                    icon={
                        <Box
                            sx={{
                                'path[stroke-width="2"]': {
                                    strokeWidth: "1.25px",
                                },
                            }}
                        >
                            {asset.file.type === "Video" ? (
                                <VideoRecorder color="#5E5D5D" height={40} width={40} />
                            ) : asset.file.type === "Image" ? (
                                <Image05 color="#5E5D5D" height={40} width={40} />
                            ) : (
                                <File05 color="#5E5D5D" height={40} width={40} />
                            )}
                        </Box>
                    }
                    iconWrapperClassName="flex-initial"
                    title={asset.file.type.charAt(0).toUpperCase() + asset.file.type.slice(1)}
                    desc="Asset"
                />
            </div>

            <ITDCard
                className="border border-solid border-gray-200"
                desc="Location"
                icon={
                    <div className="w-full h-[200px]">
                        <Map center={validCoordinates(asset.location)} isInteract={false} showLocationMarker={true} />
                    </div>
                }
                iconWrapperClassName="flex-initial"
                title={
                    <div className="flex items-center justify-between">
                        <span className="text-ellipsis overflow-hidden whitespace-nowrap">{asset.location.name}</span>
                        <Link
                            to={`https://maps.google.com?q=${asset?.location.latitude},${asset?.location.longitude}`}
                            target="_blank"
                        >
                            <ArrowUpRight color="#475467" className="h-6 w-6 cursor-pointer" />
                        </Link>
                    </div>
                }
            />
        </div>
    );
};

export default AssetDetailCards;
