import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import Folder from "@untitled-ui/icons-react/build/esm/Folder";
import Image05 from "@untitled-ui/icons-react/build/esm/Image05";
import React from "react";
import { Link } from "react-router-dom";
import RenderImage from "shared/components/ui/RenderImage";
import URL from "shared/constants/navigator";
import { useAppSelector } from "shared/hooks/useRedux";
import { routeWithParams } from "shared/utils/helpers";
import { twMerge } from "tailwind-merge";

type Breadcrumb =
    | string
    | {
          name: string;
          link?: string;
          icon?: React.ReactNode;
          image?: string;
          status?: boolean;
          state?: any;
      };

interface Props {
    breadcrumbs?: Breadcrumb[];
    drawer?: boolean;
}

const ProjectBreadcrumbs = (props: Props) => {
    const { asset, scan, project } = useAppSelector((state) => ({
        asset: state.scans.asset,
        scan: state.scans.selected,
        project: state.projects.selected,
    }));

    const id = scan?.scan_id ?? asset?.asset_id;
    const folder_id = scan?.folder_id;

    const folder = project?.folders.find((folder) => folder.folder_id === (folder_id ?? 0));

    return (
        <div className="flex items-center space-x-2">
            <div className="text-lg text-[#475467] flex items-center space-x-2">
                <Link to={URL.Media} className="flex items-center space-x-[6px]">
                    <Image05 color="#667085" width={20} height={20} />
                    <div>Media</div>
                </Link>
                {project && (
                    <>
                        <ChevronRight color="#344054" height={16} width={16} />
                        <Link
                            to={routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
                                id: project.project_id,
                            })}
                            className="flex items-center space-x-[6px]"
                        >
                            <div className="relative">
                                {!props.drawer && (
                                    <>
                                        <RenderImage
                                            image={project.thumbnail.url}
                                            containerClassName="h-8 w-8 rounded-[4px]"
                                            imgClassName="w-full"
                                        />
                                        {project.visible && (
                                            <div className="bg-[#15AA2C] w-[12px] h-[12px] rounded-full absolute -bottom-[5px] -right-[5px] border-2 border-white" />
                                        )}
                                    </>
                                )}
                            </div>
                            <div
                                className={
                                    props.drawer && id && project && !folder && !folder_id
                                        ? "text-primary bg-[#F2F3FD] px-2 py-1 rounded-[6px]"
                                        : ""
                                }
                            >
                                {project.name.length > 20 ? project.name.substring(0, 20).trim() + "..." : project.name}
                            </div>
                        </Link>
                    </>
                )}
                {project && folder && folder_id && (
                    <>
                        <ChevronRight color="#344054" height={16} width={16} />
                        <Link
                            to={routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
                                id: project.project_id,
                                folder_id: folder.folder_id,
                            })}
                            state={{ folder_id: folder.folder_id }}
                            className="flex items-center space-x-[6px]"
                        >
                            {!props.drawer && (
                                <div className="relative">
                                    <Folder width={24} height={24} />
                                </div>
                            )}
                            <div className={props.drawer ? "text-primary bg-[#F2F3FD] px-2 py-1 rounded-[6px]" : ""}>
                                <div>
                                    {folder.name.length > 20
                                        ? folder.name.substring(0, 20).trim() + "..."
                                        : folder.name}
                                </div>
                            </div>
                        </Link>
                    </>
                )}
                {props.breadcrumbs?.map((item: Breadcrumb, index: number) => {
                    if (typeof item === "string") {
                        return (
                            <div key={index} className="flex items-center space-x-[6px]">
                                <ChevronRight color="#344054" height={16} width={16} />
                                <div>{item}</div>
                            </div>
                        );
                    }
                    return (
                        <Link
                            key={index}
                            to={item.link || ""}
                            state={item.state}
                            className={twMerge("flex items-center space-x-[6px]", item.link && "cursor-pointer")}
                        >
                            <ChevronRight color="#344054" height={16} width={16} />
                            <div className="flex items-center space-x-[6px]">
                                {!props.drawer && item.icon}
                                {!props.drawer && item.image && (
                                    <div className="relative">
                                        <RenderImage
                                            image={item.image}
                                            containerClassName="h-8 w-8 rounded-[4px]"
                                            imgClassName="w-full"
                                        />
                                        {item.status && (
                                            <div className="bg-[#15AA2C] w-[12px] h-[12px] rounded-full absolute -bottom-[5px] -right-[5px] border-2 border-white" />
                                        )}
                                    </div>
                                )}
                                <span>
                                    {item.name.length > 20 ? item.name.substring(0, 20).trim() + "..." : item.name}
                                </span>
                            </div>
                        </Link>
                    );
                })}
            </div>
        </div>
    );
};

export default ProjectBreadcrumbs;

ProjectBreadcrumbs.defaultProps = {
    breadcrumbs: [],
    submissions: false,
};
