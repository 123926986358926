import { Link } from "react-router-dom";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import URL from "shared/constants/navigator";
import useRegisterScreen from "./hook";

const RegisterScreen = () => {
    const hook = useRegisterScreen();

    return (
        <div className="w-[360px] mx-auto">
            <h4 className="text-[#101828] font-medium text-[30px] leading-[38px] !mb-3">Create an account</h4>
            <p className="text-[#475467] text-lg leading-6 mb-8">Start your XSpada experience today.</p>
            <ThemeFormProvider form={hook.form} className="block mt-3" onSubmit={hook.onSubmit}>
                <Field label="Email" placeholder="Enter your email" name="email" />
                <Field label="Password" placeholder="Enter your password" name="password" type="password" />
                <Field label="Invite Code" placeholder="Enter your invite code" name="invite_code" formGroup={false} />
                <Field
                    formGroup={true}
                    label={
                        <>
                            I&apos;ve read and agree to XSpada&apos;s{" "}
                            <a
                                href={URL.Terms_And_Conditions}
                                className="text-primary"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Terms and Conditions
                            </a>
                            ,{" "}
                            <a
                                href={URL.Beta_Application_Agreement}
                                className="text-primary"
                                target="_blank"
                                rel="noreferrer"
                            >
                                Beta Application
                            </a>{" "}
                            and{" "}
                            <a
                                href={URL.End_User_License_Agreement}
                                className="text-primary"
                                target="_blank"
                                rel="noreferrer"
                            >
                                End User License Agreement
                            </a>
                        </>
                    }
                    name="terms"
                    type="checkbox-label"
                    sx={{
                        alignItems: "flex-start",
                        paddingBottom: 0,
                        ".MuiCheckbox-root": {
                            marginTop: "2px",
                        },
                        ".MuiFormControlLabel-label": {
                            fontSize: "14px",
                            color: "#7E7D7D",
                        },
                    }}
                />
                <LoaderButton
                    loading={hook.response.isLoading}
                    loadingText="Signing up..."
                    variant="contained"
                    fullWidth
                    type="submit"
                    className="text-lg font-medium !h-11"
                >
                    Sign Up
                </LoaderButton>
                <p className="mt-4 text-md text-center">
                    Have an account?{" "}
                    <Link className="text-primary" to={URL.Login}>
                        Log In
                    </Link>{" "}
                </p>
            </ThemeFormProvider>
        </div>
    );
};

export default RegisterScreen;
