import { Box } from "@mui/material";
import ArrowUpRight from "@untitled-ui/icons-react/build/esm/ArrowUpRight";
import File05 from "@untitled-ui/icons-react/build/esm/File05";
import Image05 from "@untitled-ui/icons-react/build/esm/Image05";
import Target04 from "@untitled-ui/icons-react/build/esm/Target04";
import VideoRecorder from "@untitled-ui/icons-react/build/esm/VideoRecorder";
import DummyProfileImage from "assets/images/dummy/dummy-profile-pic.png";
import { Link } from "react-router-dom";
import Map from "shared/components/Map";
import ScanStatusCard from "shared/components/ScanStatusCard";
import ITDCard from "shared/components/ui/ITDCard";
import RenderImage from "shared/components/ui/RenderImage";
import { useAppSelector } from "shared/hooks/useRedux";
import { validCoordinates } from "shared/utils/helpers";

const ScanDetailCards = () => {
    const scan = useAppSelector((state) => state.scans.selected);

    if (!scan) return <></>;

    return (
        <div className="flex flex-col">
            <div className="grid grid-cols-4 gap-4 mb-4">
                <ScanStatusCard
                    status={scan.model.status as any}
                    classes={{
                        icon: "!h-10 !w-10 ml-4 mt-4 mb-3",
                        title: "text-[#5E5D5D] font-medium text-lg leading-6",
                        desc: "text-[#667085] opacity-50 text-md",
                    }}
                    desc="Model Status"
                    className="scan_status border border-solid border-gray-200 rounded-[16px] flex flex-col !items-start pb-4"
                />
                <ITDCard
                    className="border border-solid border-gray-200"
                    icon={
                        <RenderImage
                            containerClassName="h-10 w-10 rounded-full"
                            image={scan.user?.profile_image?.url ?? DummyProfileImage}
                        />
                    }
                    iconWrapperClassName="flex-initial"
                    title={scan.user?.name || ""}
                    desc="Scanner"
                />
                <ITDCard
                    className="border border-solid border-gray-200"
                    icon={
                        <Box
                            sx={{
                                'path[stroke-width="2"]': {
                                    strokeWidth: "1.25px",
                                },
                            }}
                        >
                            <Target04 strokeWidth={1} color="#5E5D5D" height={40} width={40} />
                        </Box>
                    }
                    iconWrapperClassName="flex-initial"
                    title={scan.capture_intent}
                    desc={"Focus"}
                />
                <ITDCard
                    className="border border-solid border-gray-200"
                    icon={
                        <Box
                            sx={{
                                'path[stroke-width="2"]': {
                                    strokeWidth: "1.25px",
                                },
                            }}
                        >
                            {scan.dataset_type === "video" ? (
                                <VideoRecorder color="#5E5D5D" height={40} width={40} />
                            ) : scan.dataset_type === "Image" ? (
                                <Image05 color="#5E5D5D" height={40} width={40} />
                            ) : (
                                <File05 color="#5E5D5D" height={40} width={40} />
                            )}
                        </Box>
                    }
                    iconWrapperClassName="flex-initial"
                    title={scan.dataset_type.charAt(0).toUpperCase() + scan.dataset_type.slice(1)}
                    desc="Asset Used"
                />
            </div>

            <ITDCard
                className="border border-solid border-gray-200"
                desc="Location"
                icon={
                    <div className="w-full h-[200px]">
                        <Map center={validCoordinates(scan.location)} isInteract={false} showLocationMarker={true} />
                    </div>
                }
                iconWrapperClassName="flex-initial"
                title={
                    <div className="flex items-center justify-between">
                        <span className="text-ellipsis overflow-hidden whitespace-nowrap">{scan.location.name}</span>
                        <Link
                            to={`https://maps.google.com?q=${scan?.location.latitude},${scan?.location.longitude}`}
                            target="_blank"
                        >
                            <ArrowUpRight color="#475467" className="h-6 w-6 cursor-pointer" />
                        </Link>
                    </div>
                }
            />
        </div>
    );
};

export default ScanDetailCards;
