import { setTeam } from "store/slices/teams";
import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        ChangeTeamUsername: {
            invalidatesTags: (_, error) => (error ? [] : ["Profiles"]),
        },
        UpdateTeam: {
            async onQueryStarted(_, { queryFulfilled, dispatch }) {
                const {
                    data: { update_team },
                } = await queryFulfilled;
                dispatch(setTeam(update_team));
            },
            invalidatesTags: (_, error) => (error ? [] : ["UserDetails"]),
        },
    },
});

export const {
    useGetTeamQuery,
    useCreateTeamMutation,
    useUpdateTeamMutation,
    useDeleteTeamMutation,
    useUpdateTeamSettingMutation,
    useLeaveTeamMutation,
    useChangeTeamUsernameMutation,
    useTransferOwnershipMutation,
} = api;
