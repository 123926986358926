import { Button } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import { twMerge } from "tailwind-merge";

import RenderIf from "shared/components/RenderIf";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";

import { useEncryptedParams } from "shared/utils/encrypt-url";
import AssetForm from "./form/AssetForm";
import AssetUpload from "./form/AssetUpload";
import useAssetPostInner from "./hook";
import { PostAssetFormSteps } from "./utils/validations";

const AssetPostInner = (props: { onSuccess: () => void }) => {
    const hook = useAssetPostInner(props);
    const step = hook.form.watch("step");
    const file = hook.form.watch("file_id");
    const { asset_id } = useEncryptedParams<{ asset_id: string }>();
    const isBasicStep = step === PostAssetFormSteps.Basic;
    const isUploadStep = step === PostAssetFormSteps.Upload;

    let steps = Object.values(PostAssetFormSteps);

    if (asset_id) {
        steps = steps.filter((step) => step === PostAssetFormSteps.Basic);
    }

    return (
        <ThemeFormProvider
            contextValue={{
                options: hook.options,
            }}
            form={hook.form}
            onSubmit={hook.onSubmit}
            className="h-full"
        >
            <div className="h-full flex items-center justify-center">
                <div className="h-full w-full flex flex-col relative">
                    <div className="overflow-auto overflow-x-hidden flex-1 flex flex-col pb-2">
                        <div className="flex-1">
                            <RenderIf condition={isBasicStep}>
                                <AssetForm />
                            </RenderIf>
                            <RenderIf condition={isUploadStep}>
                                <AssetUpload />
                            </RenderIf>
                            {/* <RenderIf condition={isLocationStep}>
                                <AssetLocation />
                            </RenderIf> */}
                        </div>
                        <MobileStepper
                            variant="dots"
                            steps={steps.length}
                            position="static"
                            activeStep={steps.indexOf(step)}
                            classes={{
                                root: "!grid !grid-cols-3 items-center gap-x-3 !p-0 mt-6 !h-[36px]",
                                dots: twMerge("flex items-center justify-center", steps.length > 1 ? "" : "!opacity-0"),
                                dotActive: "!h-[10px] !w-[10px]",
                            }}
                            nextButton={
                                <div className="flex items-center justify-end">
                                    {steps.indexOf(step) === steps.length - 1 ? (
                                        <LoaderButton
                                            loading={hook.submitLoading || hook.updateLoading}
                                            type="submit"
                                            variant="contained"
                                        >
                                            {asset_id ? "Save Changes" : "Submit"}
                                        </LoaderButton>
                                    ) : (
                                        <Button
                                            disabled={!file && isUploadStep}
                                            onClick={() => hook.onStepChange("next")}
                                            variant="contained"
                                        >
                                            Next
                                        </Button>
                                    )}
                                </div>
                            }
                            backButton={
                                <div className="">
                                    {steps.indexOf(step) === 0 ? (
                                        <div></div>
                                    ) : (
                                        <Button onClick={() => hook.onStepChange("back")} variant="outlined">
                                            Previous
                                        </Button>
                                    )}
                                </div>
                            }
                        />
                    </div>
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default AssetPostInner;
