import { Link } from "react-router-dom";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import URL from "shared/constants/navigator";
import useLoginScreen from "./hook";

const LoginScreen = () => {
    const hook = useLoginScreen();

    return (
        <div className="w-[360px] mx-auto">
            <h4 className="text-[#101828] font-medium text-[30px] leading-[38px] !mb-3">Sign In Your Account</h4>
            <p className="text-[#475467] text-lg leading-6 mb-8">Continue your XSpada experience</p>
            <ThemeFormProvider form={hook.form} className="block mt-3" onSubmit={hook.onSubmit}>
                <Field label="Email" placeholder="Enter your email" name="email" />
                <Field
                    label="Password"
                    formGroup={false}
                    placeholder="Enter your password"
                    name="password"
                    type="password"
                />
                <div className="flex items-center justify-between mb-5">
                    <Field
                        label={"Remember me"}
                        name="terms"
                        type="checkbox-label"
                        sx={{
                            alignItems: "flex-start",
                            paddingBottom: 0,
                            ".MuiCheckbox-root": {
                                marginTop: "2px",
                            },
                            ".MuiFormControlLabel-label": {
                                fontSize: "14px",
                                color: "#7E7D7D",
                            },
                        }}
                    />
                    <Link className="text-primary text-md whitespace-nowrap" to={URL.ForgotPassword}>
                        Forgot password
                    </Link>
                </div>
                <LoaderButton
                    loading={hook.loginRes.isLoading}
                    loadingText="Signing in..."
                    variant="contained"
                    fullWidth
                    type="submit"
                    className="text-lg font-medium !h-11"
                >
                    Sign In
                </LoaderButton>
                <p className="mt-4 text-md text-center">
                    Don’t have an account?{" "}
                    <Link className="text-primary" to={URL.Home}>
                        Sign Up
                    </Link>
                </p>
            </ThemeFormProvider>
        </div>
    );
};

export default LoginScreen;
