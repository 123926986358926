import React from "react";

interface Props {
    title?: string | React.ReactNode;
    subtitle?: string;
    children?: React.ReactNode;
}

const PageHeader = (props: Props) => {
    return (
        <div className="min-h-[76px] px-6 flex items-center justify-between border-b border-[#D0D5DD40]">
            {props.title || props.subtitle ? (
                <div className="text-[#5E5D5D]">
                    <h1 className="leading-6 text-lg font-medium">{props.title}</h1>
                    {props.subtitle && <h3 className="text-md mt-3 leading-5">{props.subtitle}</h3>}
                </div>
            ) : (
                <></>
            )}
            {props.children}
        </div>
    );
};

export default PageHeader;
