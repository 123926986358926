import { BadgeProps } from "@mui/material";
import { DeviceTypeUtils } from "../utils/utils.type";
import { Notification } from "shared/graphql";

type CustomBadgeProps = BadgeProps & {
    badgeContent: string | number;
};

export interface INotificationAction {
    hasDetails: boolean;
    hasDetailMessageOnly?: boolean;
    buttons?: {
        label?: string;
        variant?: any;
        color?: any;
        onClick?: any;
        badgeProps?: CustomBadgeProps;
        loading?: boolean;
        loadingText?: string;
    }[];
    isNull?: boolean;
}

export interface INotificationItem {
    notif: Notification;
    action: INotificationAction;
}

export interface INotificationMarkPayload {
    notification_ids: string[];
}

export interface INotificationConfiugration {
    _id: string;
    user_id: string;
    settings: {
        notifications: {
            my_scans: {
                submit_scan: DeviceTypeUtils;
                scan_edited: DeviceTypeUtils;
                scan_deleted: DeviceTypeUtils;
                model_downloaded: DeviceTypeUtils;
                scan_storage_in_processing_queue: DeviceTypeUtils;
                scan_storage_in_training_queue: DeviceTypeUtils;
                scan_storage_processing: DeviceTypeUtils;
                scan_storage_training: DeviceTypeUtils;
                scan_storage_completed: DeviceTypeUtils;
                scan_storage_failed: DeviceTypeUtils;
            };
            projects: {
                project_created: DeviceTypeUtils;
                project_edited: DeviceTypeUtils;
                project_deleted: DeviceTypeUtils;
                scan_added_to_project: DeviceTypeUtils;
            };
            folders: {
                folder_created: DeviceTypeUtils;
                folder_edited: DeviceTypeUtils;
                folder_deleted: DeviceTypeUtils;
                scan_added_to_folder: DeviceTypeUtils;
            };
            settings: {
                profile_edited: DeviceTypeUtils;
                profile_image_uploaded: DeviceTypeUtils;
                enable_2_factor_authentication: DeviceTypeUtils;
                disable_2_factor_authentication: DeviceTypeUtils;
                password_changed: DeviceTypeUtils;
                integretion_added: DeviceTypeUtils;
            };
        };
    };
    __v: number;
}

export enum NotificationTypes {
    SCAN_SUBMITTED_IN_PROJECT = "SCAN_SUBMITTED_IN_PROJECT",
    PASSWORD_CHANGE = "PASSWORD_CHANGE",
    PROJECT_CREATE = "PROJECT_CREATE",
    SCAN_PROCESSED = "SCAN_PROCESSED",
    SCAN_TRAINED = "SCAN_TRAINED",
    SCAN_FAILED = "SCAN_FAILED",
    SCAN_COMPLETED = "SCAN_COMPLETED",
    ACCOUNT_DETAILS_CHANGED = "ACCOUNT_DETAILS_CHANGED",
    SCAN_EDITED = "SCAN_EDITED",
    SCAN_DELETED = "SCAN_DELETED",
    SCAN_SUBMITTED = "SCAN_SUBMITTED",
    POST_FLAGGED = "POST_FLAGGED",
    TEAM_INVITATION = "TEAM_INVITATION",
    TEAM_REQUEST = "TEAM_REQUEST",
    TEAM_SCAN_SUBMITTED = "TEAM_SCAN_SUBMITTED",
    TEAM_PROJECT_CREATED = "TEAM_PROJECT_CREATED",
    TEAM_SCAN_PROCESSED = "TEAM_SCAN_PROCESSED",
    TEAM_SCAN_TRAINED = "TEAM_SCAN_TRAINED",
    TEAM_SCAN_FAILED = "TEAM_SCAN_FAILED",
    TEAM_SCAN_COMPLETED = "TEAM_SCAN_COMPLETED",
    TEAM_SCAN_EDITED = "TEAM_SCAN_EDITED",
    TEAM_SCAN_DELETED = "TEAM_SCAN_DELETED",
    TEAM_PROJECT_EDITED = "TEAM_PROJECT_EDITED",
    TEAM_PROJECT_DELETED = "TEAM_PROJECT_DELETED",
    TEAM_ARTICLE_POSTED = "TEAM_ARTICLE_POSTED",
    TEAM_SCAN_SNAPSHOT_SUBMITTED = "TEAM_SCAN_SNAPSHOT_SUBMITTED",
    TEAM_SCAN_SNAPSHOT_DELETED = "TEAM_SCAN_SNAPSHOT_DELETED",
    TEAM_MEMBER_JOINED = "TEAM_MEMBER_JOINED",
}
