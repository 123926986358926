import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useEffect, useMemo, useState } from "react";
import { useFieldArray, useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import {
    AssetVisibility,
    EditProjectMutation,
    useAssetBulkAccessMutation,
    useEditProjectMutation,
    useShareProjectMutation,
} from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { successToast } from "shared/utils/toast";
import yup from "shared/utils/yup";
import { setCommonModalLoadingBtn } from "store/slices/commonModal";
import { setProjectRefetchApi, setSelectedProject } from "store/slices/projects";
import { setAllSelectedAssets, toggleShareSidebarOpen } from "store/slices/publishable_project";
import { PublishableProjectsLayoutContext } from "../../layout";
// import { Content } from "shared/components/Walkthrough/partials/WalkthroughElements";

import useHasPermission from "shared/components/HasPermission/hook";
import Permissions from "shared/components/HasPermission/Permissions";
import useGetAssetsScans from "screens/Dashboard/Projects/hook/useGetAssetsScans";
import { useTour } from "@reactour/tour";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");

const visibility_schema = yup.object().shape({
    visibility: yup.string(),
});
const invite_schema = yup.object().shape({
    emails: yup.array().when("visibility", {
        is: (visibility) => visibility,
        then: (schema) => schema.of(yup.string().email()),
    }),
});
const meta_schema = yup.object().shape({
    title: yup.string(),
    description: yup.string().max(200, "Must be less than 200 characters"),
});

type VisibilityFormValues = yup.InferType<typeof visibility_schema>;
type InviteFormValues = yup.InferType<typeof invite_schema>;
type MetaFormValues = yup.InferType<typeof meta_schema>;

const usePostPublishScreen = () => {
    const dispatch = useAppDispatch();
    const { id } = useParams();
    const [updateProject, updateRes] = useEditProjectMutation();
    const [assetBulkAccess, assetBulkAccessRes] = useAssetBulkAccessMutation();
    const [updateProjectMeta, updateMetaRes] = useEditProjectMutation();
    const [shareProject, shareRes] = useShareProjectMutation();
    const {
        assets: nonFolderAssets,
        scans: nonFolderScans,
        loading: isLoadingNonFolderAssetAndScans,
    } = useGetAssetsScans();

    const project = useAppSelector((state) => state.projects.selected);

    const { onSave } = useContext(PublishableProjectsLayoutContext);

    const { setIsOpen, isOpen: isTutorialOpen, currentStep, setCurrentStep } = useTour();

    const open = useAppSelector((state) => state.publishable_project.isShareSidebarOpen);
    const allSelectedAssets = useAppSelector((state) => state.publishable_project.allSelectedAssets);
    const loadingBtn = useAppSelector((state) => state.commonModal.loadingBtn);

    const [isAssetsListOpen, setIsAssetsListOpen] = useState(false);
    const [lastPublishedTime, setLastPublishedTime] = useState("");

    const Can_Edit_Project_SEO_Admin = useHasPermission({
        permission: Permissions.Can_Edit_Project_SEO,
        type: "admin",
    });
    const Can_Edit_Project_SEO_Manager = useHasPermission({
        permission: Permissions.Can_Edit_Project_SEO,
        type: "manager",
    });
    const Can_Edit_Project_SEO_Member = useHasPermission({
        permission: Permissions.Can_Edit_Project_SEO,
        type: "member",
    });

    const [assetsAccess, setAssetsAccess] = useState<{
        [key: string]: {
            visible: boolean;
            download: boolean;
            type: "scans" | "asset";
            folder_id: string;
            is_default?: boolean;
            is_download_disabled: boolean;
            is_all_disabled: boolean;
        };
    }>({});

    const visibility_form = useForm<VisibilityFormValues>({
        resolver: yupResolver(visibility_schema),
        defaultValues: {
            visibility: project?.visible ? "public" : "private",
        },
    });

    const visibility_val = visibility_form.watch("visibility");

    const invite_form = useForm<InviteFormValues>({
        resolver: yupResolver(invite_schema),
        defaultValues: {
            emails: [""],
        },
    });

    const { fields, append, remove } = useFieldArray({
        control: invite_form.control,
        name: "emails",
    });

    const meta_form = useForm<MetaFormValues>({
        resolver: yupResolver(meta_schema),
        defaultValues: {
            title: project?.meta_data?.title ?? "",
            description: project?.meta_data?.description ?? "",
        },
    });

    const meta_description_val = meta_form.watch("description");

    const is_description_limit_reached = useMemo(() => {
        if (!meta_description_val) return false;

        const maxLength = 200;

        const currentLength = meta_description_val.length;
        const remainingLength = maxLength - currentLength;

        return remainingLength <= 0;
    }, [meta_description_val]);

    const description_limit_string = useMemo(() => {
        const maxLength = 200;

        if (!meta_description_val) return `${maxLength} characters left`;

        const currentLength = meta_description_val.length;
        const remainingLength = maxLength - currentLength;

        return `${remainingLength >= 0 ? remainingLength : 0} character${remainingLength == 1 ? "" : "s"} left`;
    }, [meta_description_val]);

    const getComparisonTimeString = (given_time: Date) => {
        const currentDate = new Date();
        const givenDate = new Date(given_time);
        const differenceInMilliseconds: number = currentDate.getTime() - givenDate.getTime();
        const minutes = Math.floor(differenceInMilliseconds / (1000 * 60));
        const hours = Math.floor(differenceInMilliseconds / (1000 * 60 * 60));
        const days = Math.floor(differenceInMilliseconds / (1000 * 60 * 60 * 24));

        if (minutes < 60) {
            return `${minutes} minute(s) ago`;
        } else if (hours < 24) {
            return `${hours} hour(s) ago`;
        } else {
            return `${days} day(s) ago`;
        }
    };

    const isPublishing = useMemo(() => {
        return loadingBtn == "change-visibility-btn";
    }, [loadingBtn]);

    const isSEOAllowed = useMemo(() => {
        return Can_Edit_Project_SEO_Admin || Can_Edit_Project_SEO_Manager || Can_Edit_Project_SEO_Member;
    }, [Can_Edit_Project_SEO_Admin, Can_Edit_Project_SEO_Manager, Can_Edit_Project_SEO_Member]);

    const toggleSidebar = () => {
        if (open) {
            setAssetsAccess(initializeAssetsAccess(project, nonFolderAssets, nonFolderScans));
        }

        setTimeout(() => {
            setIsAssetsListOpen(false);
            visibility_form.reset();
            invite_form.reset();
            meta_form.reset();
            dispatch(toggleShareSidebarOpen());
        }, 0);
    };

    const toggleAssetsList = () => {
        setIsAssetsListOpen((prev) => !prev);
    };

    const hasAssetsOrScans = useMemo(() => {
        if (project) {
            const folders = project.folders;
            for (let i = 0; i < folders.length; i++) {
                const folder = folders[i];

                if (folder.asset && folder.asset.length > 0) return true;

                if (folder.scans && folder.scans.length > 0) return true;
            }
        }

        return nonFolderAssets.length > 0 || nonFolderScans.length > 0;
    }, [project, nonFolderAssets, nonFolderScans]);

    const isPrivateAssetSelected = useMemo(() => {
        if (Object.keys(allSelectedAssets).length > 0) {
            const assetIDs = Object.values(allSelectedAssets).map((id: string) => id.replace("_", ""));

            for (let i = 0; i < assetIDs.length; i++) {
                if (
                    (`scan_${assetIDs[i]}` in assetsAccess && !assetsAccess["scan_" + assetIDs[i]].visible) ||
                    (`asset_${assetIDs[i]}` in assetsAccess && !assetsAccess["asset_" + assetIDs[i]].visible)
                ) {
                    return true;
                }
            }
        }

        return false;
    }, [project, allSelectedAssets, assetsAccess, nonFolderAssets, nonFolderScans]);

    const isAllVisible = useMemo(() => {
        const isAllTrue = true;
        const assetKeys = Object.keys(assetsAccess);

        for (let i = 0; i < assetKeys.length; i++) {
            const assetKey = assetKeys[i];

            if (!assetsAccess[assetKey]["visible"] && !assetsAccess[assetKey].is_all_disabled) {
                return false;
            }
        }

        return isAllTrue;
    }, [assetsAccess]);

    const isNoneVisible = useMemo(() => {
        const assetKeys = Object.keys(assetsAccess);

        for (let i = 0; i < assetKeys.length; i++) {
            const assetKey = assetKeys[i];

            if (assetsAccess[assetKey]["visible"] && !assetsAccess[assetKey].is_all_disabled) {
                return false;
            }
        }

        return true;
    }, [assetsAccess]);

    const isAllDownloadable = useMemo(() => {
        const assetKeys = Object.keys(assetsAccess);
        let isNoneFound = true;

        for (let i = 0; i < assetKeys.length; i++) {
            const assetKey = assetKeys[i];

            if (
                assetsAccess[assetKey].is_default ||
                assetsAccess[assetKey].is_download_disabled ||
                assetsAccess[assetKey].is_all_disabled
            ) {
                continue;
            }

            isNoneFound = false;

            if (!assetsAccess[assetKey]["download"]) {
                return false;
            }
        }

        return !isNoneFound;
    }, [assetsAccess]);

    const isNoneDownloadable = useMemo(() => {
        const assetKeys = Object.keys(assetsAccess);

        for (let i = 0; i < assetKeys.length; i++) {
            const assetKey = assetKeys[i];

            if (
                assetsAccess[assetKey]["download"] &&
                !assetsAccess[assetKey].is_download_disabled &&
                !assetsAccess[assetKey].is_all_disabled
            ) {
                return false;
            }
        }

        return true;
    }, [assetsAccess]);

    const accessListIsUpdated = useMemo(() => {
        if (project && Object.keys(assetsAccess).length > 0) {
            for (let i = 0; i < project.folders.length; i++) {
                const folder = project.folders[i];

                const assets = folder.asset;
                for (let j = 0; j < assets.length; j++) {
                    const asset = assets[j];

                    if (
                        (asset.visibility == AssetVisibility.Public) !=
                            assetsAccess["asset_" + asset.asset_id].visible ||
                        asset.downloadability != assetsAccess["asset_" + asset.asset_id].download
                    ) {
                        return true;
                    }
                }

                const scans = folder.scans;
                for (let j = 0; j < scans.length; j++) {
                    const scan = scans[j];

                    if (
                        (scan.visibility == "public") != assetsAccess["scan_" + scan.scan_id].visible ||
                        scan.downloadability != assetsAccess["scan_" + scan.scan_id].download
                    ) {
                        return true;
                    }
                }
            }
        }

        if (nonFolderAssets.length > 0 && Object.keys(assetsAccess).length > 0) {
            for (let j = 0; j < nonFolderAssets.length; j++) {
                const asset = nonFolderAssets[j];

                if (
                    (asset.visibility == AssetVisibility.Public) != assetsAccess["asset_" + asset.asset_id].visible ||
                    asset.downloadability != assetsAccess["asset_" + asset.asset_id].download
                ) {
                    return true;
                }
            }
        }

        if (nonFolderScans.length > 0 && Object.keys(assetsAccess).length > 0) {
            for (let j = 0; j < nonFolderScans.length; j++) {
                const scan = nonFolderScans[j];

                if (
                    (scan.visibility == "public") != assetsAccess["scan_" + scan.scan_id].visible ||
                    scan.downloadability != assetsAccess["scan_" + scan.scan_id].download
                ) {
                    return true;
                }
            }
        }

        return false;
    }, [project, assetsAccess, nonFolderAssets, nonFolderScans]);

    const finalAssetsAccessData = () => {
        if (project && Object.keys(assetsAccess).length > 0) {
            const assetsAccessObj: any = {};

            project.folders.forEach((folder) => {
                assetsAccessObj["" + folder.folder_id] = {
                    folder_id: folder.folder_id,
                    visibility: {
                        assets: [],
                        scans: [],
                    },
                    downloadability: {
                        assets: [],
                        scans: [],
                    },
                };
            });

            assetsAccessObj["-1"] = {
                folder_id: -1,
                visibility: {
                    assets: [],
                    scans: [],
                },
                downloadability: {
                    assets: [],
                    scans: [],
                },
            };

            Object.keys(assetsAccess).forEach((assetKey) => {
                const asset = assetsAccess[assetKey];

                const folder_id = asset.folder_id;
                const asset_id = parseInt(assetKey.split("_")[1]);

                if (asset.visible) {
                    assetsAccessObj["" + folder_id]["visibility"][
                        asset.type == "asset" ? asset.type + "s" : asset.type
                    ].push(asset_id);
                }

                if (asset.download) {
                    assetsAccessObj["" + folder_id]["downloadability"][
                        asset.type == "asset" ? asset.type + "s" : asset.type
                    ].push(asset_id);
                }
            });

            return Object.values(assetsAccessObj);
        }

        return [];
    };

    const toggleAllVisible = () => {
        const updatedAssetsAccess = { ...assetsAccess };

        Object.keys(updatedAssetsAccess).forEach((assetKey) => {
            if (!updatedAssetsAccess[assetKey].is_all_disabled) {
                updatedAssetsAccess[assetKey]["visible"] = !isAllVisible;
                updatedAssetsAccess[assetKey]["is_download_disabled"] = isAllVisible;

                if (!updatedAssetsAccess[assetKey]["visible"]) {
                    updatedAssetsAccess[assetKey]["download"] = false;
                }
            }
        });

        setAssetsAccess(updatedAssetsAccess);
    };

    const toggleAllDownload = () => {
        const updatedAssetsAccess = { ...assetsAccess };

        Object.keys(updatedAssetsAccess).forEach((assetKey) => {
            if (
                !updatedAssetsAccess[assetKey].is_default &&
                !updatedAssetsAccess[assetKey].is_download_disabled &&
                !updatedAssetsAccess[assetKey].is_all_disabled
            ) {
                updatedAssetsAccess[assetKey]["download"] = !isAllDownloadable;
            }
        });

        setAssetsAccess(updatedAssetsAccess);
    };

    const updateSelectedAssets = (project: any) => {
        const updatedSelectedAssets = { ...allSelectedAssets };
        const assetKeys = Object.keys(updatedSelectedAssets);
        for (let i = 0; i < assetKeys.length; i++) {
            const type = assetKeys[i].includes("scan") ? "scans" : "asset";
            let assetId = parseInt(updatedSelectedAssets[assetKeys[i]]);
            if (updatedSelectedAssets[assetKeys[i]].includes("_")) {
                assetId = parseInt(updatedSelectedAssets[assetKeys[i]].replace("_", ""));
            }
            const project_folder_index = project!.folders!.findIndex(
                (folder) =>
                    folder[type].findIndex((asset) => (type == "scans" ? asset.scan_id : asset.asset_id) == assetId) !=
                    -1
            );

            const folder = project!.folders![project_folder_index];
            const assetObj = (folder[type] as any[]).filter(
                (asset: any) => (type == "scans" ? asset.scan_id : asset.asset_id) == assetId
            )[0];
            const isAssetVisible =
                type == "scans" ? assetObj.visibility == "public" : assetObj.visibility == AssetVisibility.Public;
            updatedSelectedAssets[assetKeys[i]] = (isAssetVisible ? "" : "_") + assetId;
        }
        dispatch(setAllSelectedAssets(updatedSelectedAssets));

        return updatedSelectedAssets;
    };

    const getUpdatedProject = () => {
        const updatedProject = { ...project };
        Object.keys(assetsAccess).forEach((assetKey) => {
            const asset = assetsAccess["" + assetKey];
            const folder_id = asset.folder_id;

            const project_folder_index = updatedProject!.folders!.findIndex(
                (folder) => folder.folder_id.toString() == folder_id
            );

            if (project_folder_index >= 0) {
                const folders = [...updatedProject!.folders!];
                const folder = { ...folders[project_folder_index] };

                const folderAssets = [...folder[asset.type]];

                const assetIndex = folderAssets.findIndex(
                    (folderAsset) =>
                        folderAsset[asset.type == "scans" ? "scan_id" : "asset_id"].toString() == assetKey.split("_")[1]
                );

                const folderAsset = {
                    ...folderAssets[assetIndex],
                    visibility:
                        asset.type == "scans"
                            ? asset.visible
                                ? "public"
                                : "private"
                            : asset.visible
                            ? AssetVisibility.Public
                            : AssetVisibility.Private,
                    downloadability: asset.download,
                };

                folderAssets[assetIndex] = folderAsset as any;

                folder[asset.type] = folderAssets as any;

                folders[project_folder_index] = folder;

                updatedProject["folders"] = folders;
            }
        });

        return updatedProject;
    };

    const initializeAssetsAccess = (project: any, assets: any[], scans: any[]) => {
        const updatedAssetsAccess: any = {};
        project.folders.forEach((folder) => {
            folder.asset.forEach((asset) => {
                updatedAssetsAccess["asset_" + asset.asset_id] = {
                    visible: asset.visibility == AssetVisibility.Public,
                    download: asset.visibility == AssetVisibility.Public && asset.downloadability,
                    type: "asset",
                    folder_id: folder.folder_id,
                    is_download_disabled: asset.visibility == AssetVisibility.Private,
                    is_all_disabled: false,
                };
            });

            folder.scans.forEach((scan) => {
                updatedAssetsAccess["scan_" + scan.scan_id] = {
                    visible: scan.visibility == "public",
                    download: scan.visibility == "public" && scan.downloadability,
                    type: "scans",
                    folder_id: folder.folder_id,
                    is_default: scan.default,
                    is_download_disabled: scan.visibility == "private",
                    is_all_disabled: scan.model.status != "Completed",
                };
            });
        });

        assets.forEach((asset) => {
            updatedAssetsAccess["asset_" + asset.asset_id] = {
                visible: asset.visibility == AssetVisibility.Public,
                download: asset.visibility == AssetVisibility.Public && asset.downloadability,
                type: "asset",
                folder_id: -1,
                is_download_disabled: asset.visibility == AssetVisibility.Private,
                is_all_disabled: false,
            };
        });

        scans.forEach((scan) => {
            updatedAssetsAccess["scan_" + scan.scan_id] = {
                visible: scan.visibility == "public",
                download: scan.visibility == "public" && scan.downloadability,
                type: "scans",
                folder_id: -1,
                is_default: scan.default,
                is_download_disabled: scan.visibility == "private",
                is_all_disabled: scan.model.status != "Completed",
            };
        });

        return updatedAssetsAccess;
    };

    useEffect(() => {
        let timer: any = null;

        const updatePublishedTime = () => {
            if (project && project.published_file) {
                const updatedString = getComparisonTimeString(project.published_file.created_at);

                if (updatedString != lastPublishedTime) {
                    setLastPublishedTime(updatedString);
                }
            }
        };

        timer = setInterval(updatePublishedTime, 30000);

        updatePublishedTime();

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [project]);

    useEffect(() => {
        if (project && !isLoadingNonFolderAssetAndScans) {
            setAssetsAccess(initializeAssetsAccess(project, nonFolderAssets, nonFolderScans));
        }
    }, [project, isLoadingNonFolderAssetAndScans, nonFolderAssets, nonFolderScans]);

    useEffect(() => {
        rtkHandler(updateRes, {
            setError: visibility_form.setError,
            onSuccess(res: EditProjectMutation) {
                successToast("Project published successfully.");

                dispatch(setSelectedProject(res.edit_project));
                visibility_form.reset({ visibility: res.edit_project.visible ? "public" : "private" });
                dispatch(setProjectRefetchApi(true));

                setTimeout(() => {
                    updateSelectedAssets(res.edit_project);

                    setTimeout(() => {
                        onSave("auto", res.edit_project.visible && !isPrivateAssetSelected);
                    }, 0);
                }, 0);
            },
        });
    }, [updateRes]);

    useEffect(() => {
        rtkHandler(shareRes, {
            setError: invite_form.setError,
            successMessage: "Emails sent successfully.",
            onSuccess() {
                invite_form.reset();
            },
        });
    }, [shareRes]);

    useEffect(() => {
        rtkHandler(updateMetaRes, {
            setError: meta_form.setError,
            successMessage: "Meta data updated successfully",
            onSuccess(res: EditProjectMutation) {
                dispatch(setSelectedProject(res.edit_project));
                meta_form.reset({
                    title: res.edit_project.meta_data?.title ?? res.edit_project.name,
                    description: res.edit_project.meta_data?.description ?? "",
                });
                dispatch(setProjectRefetchApi(true));
            },
        });
    }, [updateMetaRes]);

    useEffect(() => {
        if (id && project) {
            const editorTitle = document.getElementById("mainHeading");
            const editorSubTitle = document.getElementById("mainSubtitle");

            if (!project.meta_data?.title) {
                meta_form.setValue("title", editorTitle?.innerText ?? "", {
                    shouldDirty: true,
                });
            }

            if (!project.meta_data?.description) {
                meta_form.setValue("description", editorSubTitle?.innerText ?? "", {
                    shouldDirty: true,
                });
            }
        }
    }, [id, project]);

    const onVisibilitySubmit = async (data: VisibilityFormValues) => {
        const isProjectVisibilityUpdated = data.visibility != (project?.visible ? "public" : "private");
        const isBothUpdated = accessListIsUpdated && isProjectVisibilityUpdated;

        dispatch(setCommonModalLoadingBtn("change-visibility-btn"));

        if (isBothUpdated) {
            assetBulkAccess({ data: finalAssetsAccessData() as any }).then(() => {
                updateProject({
                    id: project!.project_id,
                    data: {
                        visible: data.visibility == "public" ?? false,
                    },
                });
            });
        } else if (isProjectVisibilityUpdated) {
            updateProject({
                id: project!.project_id,
                data: {
                    visible: data.visibility == "public" ?? false,
                },
            });
        } else if (accessListIsUpdated) {
            assetBulkAccess({ data: finalAssetsAccessData() as any }).then(() => {
                const updatedProject = getUpdatedProject();

                dispatch(setSelectedProject(updatedProject));

                setTimeout(() => {
                    updateSelectedAssets(updatedProject);

                    onSave("auto", updatedProject.visible && !isPrivateAssetSelected, true);
                }, 0);
            });
        } else {
            onSave("auto", project?.visible && !isPrivateAssetSelected, true);
        }
        if(data.visibility === 'public'){
            amplitude.track('Project Published');
        }
        setIsAssetsListOpen(false);
    };

    const onInviteSubmit = (data: InviteFormValues) => {
        if (project && data.emails && data.emails.filter((email) => email).length > 0) {
            shareProject({
                slug: project.slug,
                data: {
                    emails: data.emails as string[],
                },
            });
        }
    };

    const onMetaSubmit = async (data: MetaFormValues) => {
        updateProjectMeta({
            id: project!.project_id,
            data: {
                meta_data: {
                    title: data.title ?? "",
                    description: data.description ?? "",
                },
            },
        });
    };

    useEffect(() => {
        if (open && currentStep == 4) {
            setTimeout(() => {
                if (!window.sessionStorage.getItem("editor-publish-tutorial-show")) {
                    setIsOpen(true);
                }

                window.sessionStorage.setItem("editor-publish-tutorial-show", "true");
            }, 300);
        } else if (open && currentStep != 4) {
            setCurrentStep(4);
        }
    }, [open, setIsOpen, currentStep]);

    // useEffect(() => {
    //     let timer: any = null;

    //     if (setSteps && open) {
    //         setSteps([
    //             {
    //                 selector: ".publish-board-sidebar",
    //                 content: () => (
    //                     <Content
    //                         title="Publishing Board"
    //                         body="In this drawer, you can change the visibility of your board to private or public. When changed to public, you can manage accesses of assets, as well as sharing the public link with people by copying it, or sending via email."
    //                     />
    //                 ),
    //                 position: "left",
    //             },
    //         ]);

    //         const checkIfInitialized = () => {
    //             if (document.querySelector(".publish-board-sidebar") && steps.length == 1) {
    //                 setIsOpen(true);
    //                 clearInterval(timer);
    //             }
    //         };

    //         timer = setInterval(checkIfInitialized, 500);
    //     }

    //     return () => {
    //         if (timer) {
    //             clearInterval(timer);
    //         }
    //     };
    // }, [setSteps, setIsOpen, open]);

    return {
        visibility_form,
        invite_form,
        meta_form,
        onVisibilitySubmit,
        onInviteSubmit,
        onMetaSubmit,
        emailFields: fields,
        addEmail: append,
        removeEmail: remove,
        open,
        id,
        isSavingVisibility: updateRes.isLoading || assetBulkAccessRes.isLoading || isPublishing,
        isVisibilityDirty: visibility_form.formState.isDirty,
        isSendingInvite: shareRes.isLoading,
        isInvitesDirty: invite_form.formState.isDirty,
        isSavingMeta: updateMetaRes.isLoading,
        isMetaDirty: meta_form.formState.isDirty,
        toggleSidebar,
        project,
        description_limit_string,
        is_description_limit_reached,
        allSelectedAssets,
        isAssetsListOpen,
        toggleAssetsList,
        assetsAccess,
        setAssetsAccess,
        toggleAllVisible,
        toggleAllDownload,
        isAllVisible,
        isAllDownloadable,
        isNoneVisible,
        isNoneDownloadable,
        accessListIsUpdated,
        isPrivateAssetSelected,
        visibility_val,
        hasAssetsOrScans,
        lastPublishedTime,
        isSEOAllowed,
        isTutorialOpen,
        nonFolderAssets,
        nonFolderScans,
    };
};

export default usePostPublishScreen;
