import { Link } from "react-router-dom";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import URL from "shared/constants/navigator";
import useForgotPassword from "./hook";

const ForgotPasswordScreen = () => {
    const hook = useForgotPassword();

    return (
        <div className="w-[360px] mx-auto">
            <h4 className="text-[#101828] font-medium text-[30px] leading-[38px] !mb-3">Forgot Password</h4>
            <p className="text-[#475467] text-lg leading-6 mb-8">
                Add your primary email linked with your account and receive a password reset link.
            </p>
            <ThemeFormProvider form={hook.form} className="block mt-3" onSubmit={hook.onSubmit}>
                <Field label="Account Email" placeholder="Enter your account email" name="email" />
                <LoaderButton
                    loading={hook.response.isLoading}
                    loadingText="Signing up..."
                    variant="contained"
                    fullWidth
                    type="submit"
                    className="text-lg font-medium !h-11"
                >
                    Send Password Link
                </LoaderButton>
                <p className="mt-4 text-md text-center">
                    Don’t have an account?{" "}
                    <Link className="text-primary" to={URL.Home}>
                        Sign Up
                    </Link>
                </p>
            </ThemeFormProvider>
        </div>
    );
};

export default ForgotPasswordScreen;
