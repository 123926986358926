import useImages from "assets/images";
import { twMerge } from "tailwind-merge";

interface I {
    icon?:
        | "square-tick"
        | "play-cirlce"
        | "clock-timer"
        | "triangle-failed"
        | "refresh"
        | "cube-training"
        | "video"
        | "info";
    title?: string;
    desc?: string;
    status?:
        | "Success"
        | "Failed"
        | "Processing"
        | "Pending"
        | "Added to Queue"
        | "Completed"
        | "Training"
        | "In Processing Queue"
        | "In Training Queue"
        | "Preparing"
        | "Unavailable";
    className?: string;
    classes?: {
        icon?: string;
        title?: string;
        desc?: string;
    };
    hasSperator?: boolean;
}

const ScanStatusCard = (props: I) => {
    const {
        TickSquareGreen,
        PlayBlueCircleIcon,
        VideoBlueIcon,
        ReturnIcon,
        CubeBlueIcon,
        DangerTriangleIcon,
        TimeCircleIcon,
        InfoCircleStatusIcon,
    } = useImages();
    const { title, desc, icon, status, className = "", hasSperator = false } = props;

    let IconElement;
    let iconType = icon;
    let titleText;
    let descText;

    if (status) {
        switch (status) {
            case "Success":
            case "Completed":
                iconType = "square-tick";
                titleText = "Completed";
                descText = "Scan Ready to view";
                break;
            case "Failed":
                iconType = "triangle-failed";
                titleText = "Failed";
                descText = "Scan Failed to process";
                break;
            case "Processing":
                iconType = "refresh";
                titleText = "Processing";
                descText = "You will be notified when it is done.";
                break;
            case "Training":
                iconType = "cube-training";
                titleText = "Processing";
                descText = "You will be notified when it is done.";
                break;
            case "In Processing Queue":
                iconType = "clock-timer";
                titleText = "Processing";
                descText = "You will be notified when it is processing.";
                break;
            case "In Training Queue":
                iconType = "clock-timer";
                titleText = "Processing";
                descText = "You will be notified when it is training.";
                break;
            case "Added to Queue":
                iconType = "clock-timer";
                titleText = "Processing";
                descText = "You will be notified when it is started.";
                break;
            case "Preparing":
                iconType = "clock-timer";
                titleText = "Processing";
                descText = "You will be notified when it is done.";
                break;
            default:
                iconType = "info";
                titleText = "Unavailable";
                descText = "You will be notified when it is available.";
                break;
        }
    }

    if (typeof iconType === "string") {
        switch (iconType) {
            case "clock-timer":
                IconElement = <TimeCircleIcon className={props.classes?.icon} height={24} width={24} color="#FF8A36" />;
                break;

            case "triangle-failed":
                IconElement = (
                    <DangerTriangleIcon className={props.classes?.icon} height={24} width={22} color="#EB617A" />
                );
                break;

            case "refresh":
                IconElement = <ReturnIcon className={props.classes?.icon} height={22} width={29} color="#7680FF" />;
                break;

            case "cube-training":
                IconElement = <CubeBlueIcon className={props.classes?.icon} height={24} width={24} />;
                break;

            case "video":
                IconElement = <VideoBlueIcon className={props.classes?.icon} height={26} width={26} />;
                break;

            case "square-tick":
                IconElement = (
                    <TickSquareGreen className={props.classes?.icon} color="#15AA2C" height={24} width={24} />
                );
                break;

            case "play-cirlce":
                IconElement = <PlayBlueCircleIcon className={props.classes?.icon} height={26} width={26} />;
                break;

            case "info":
                IconElement = <InfoCircleStatusIcon className={props.classes?.icon} height={26} width={26} />;
                break;
            default:
                break;
        }
    } else {
        IconElement = icon;
    }

    return (
        <div
            className={twMerge(
                `flex items-center space-x-3`,
                className,
                hasSperator && "border-l border-[#949496] border-opacity-25 pl-3"
            )}
        >
            {IconElement}
            <div>
                <h4 className={twMerge("text-h1 text-[12px] font-medium leading-4", props.classes?.title)}>
                    {title || titleText}
                </h4>
                <p className={twMerge("text-p font-light text-xs leading-3", props.classes?.desc)}>
                    {desc || descText}
                </p>
            </div>
        </div>
    );
};

export default ScanStatusCard;
