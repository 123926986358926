import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { IEmbedProps } from "./Embed";

const EmbedProperties = ({ editor, props }: { editor: Editor | null; props: IEmbedProps }) => {
    const { id, link, width, height, justify, caption, caption_str, redirecting } = props as IEmbedProps;
    const { EditorAlignLeftIcon, EditorAlignCenterIcon, EditorAlignRightIcon, PercentageIcon, PixelIcon } = useImages();

    const isInit = useRef(false);

    const { control, watch, setValue } = useForm<{
        id: string;
        link: string | null;
        width: number;
        height: number;
        justify: "left" | "center" | "right";
        caption: "yes" | "no";
        caption_str: string | null;
        redirecting: "yes" | "no";
    }>({
        defaultValues: {
            id,
            link: link ? link?.replace("https://", "") : null,
            width,
            height,
            justify,
            caption: caption ? "yes" : "no",
            caption_str,
            redirecting: redirecting ? "yes" : "no",
        },
    });

    const link_val = watch("link");
    const width_val = watch("width");
    const height_val = watch("height");
    const justify_val = watch("justify");
    const caption_val = watch("caption");
    const redirecting_val = watch("redirecting");

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const onUpdate = () => {
        const data = {
            id,
            link: "https://" + link_val,
            width: width_val,
            height: height_val,
            justify: justify_val,
            caption: caption_val == "yes",
            redirecting: redirecting_val == "yes",
        };

        let pos = 0;
        let nodeAttrs: any = null;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                nodeAttrs = node.attrs;
                return false;
            }
        });

        const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, { ...nodeAttrs, ...data });

        parentEditor!.view.dispatch(transaction);
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [link_val, width_val, justify_val, caption_val, redirecting_val]);

    useEffect(() => {
        if (link_val && link_val.includes("https://")) {
            setValue("link", link_val.replace("https://", ""));
        }
    }, [link_val]);

    return (
        <ThemeFormProvider form={{ control, handleSubmit: () => {} } as any} onSubmit={null}>
            <div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Link</div>
                    <Field
                        name="link"
                        placeholder=""
                        inputTextProps={{
                            className: "!h-[34px]",
                            startAdornment: <div className="text-sm text-[#667085] -mr-[10px]">https://</div>,
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                                ".MuiOutlinedInput-input": {
                                    color: "#667085",
                                    fontSize: "12px",
                                },
                            },
                        }}
                    />
                </div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="flex gap-x-2 items-center">
                        <div>
                            <div className="text-sm text-[#667085] mb-2">Width</div>
                            <Field
                                name="width"
                                placeholder=""
                                inputTextProps={{
                                    className: "!h-[34px]",
                                    sx: {
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderRadius: "8px !important",
                                        },
                                        ".MuiOutlinedInput-input": {
                                            color: "#667085",
                                            fontSize: "12px",
                                        },
                                    },
                                    endAdornment: <PercentageIcon width={10} height={10} />,
                                }}
                            />
                        </div>
                        <div>
                            <div className="text-sm text-[#667085] mb-2">Height</div>
                            <Field
                                name="height"
                                placeholder=""
                                inputTextProps={{
                                    className: "!h-[34px]",
                                    sx: {
                                        ".MuiOutlinedInput-notchedOutline": {
                                            borderRadius: "8px !important",
                                        },
                                        ".MuiOutlinedInput-input": {
                                            color: "#667085",
                                            fontSize: "12px",
                                        },
                                    },
                                    endAdornment: <PixelIcon width={10} height={10} />,
                                }}
                            />
                        </div>
                    </div>

                    <div className="text-sm text-[#667085] mt-4 mb-2">Justify</div>
                    <ToggleButtonGroup
                        value={justify_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("justify", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderLeft: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },

                                "&:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"left"} aria-label="Yes">
                            <EditorAlignLeftIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"center"} aria-label="No">
                            <EditorAlignCenterIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"right"} aria-label="No">
                            <EditorAlignRightIcon width={14} height={14} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className="w-full pt-4 pb-6 border-b px-5">
                    <div className="text-sm text-[#667085] mb-2">Caption</div>
                    <ToggleButtonGroup
                        value={caption_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("caption", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderLeft: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },

                                "&:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"yes"} aria-label="Yes">
                            Yes
                        </ToggleButton>
                        <ToggleButton value={"no"} aria-label="No">
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <div className="text-sm text-[#667085] mt-4 mb-2">Redirecting</div>
                    <ToggleButtonGroup
                        value={redirecting_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("redirecting", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderLeft: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },

                                "&:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"yes"} aria-label="Yes">
                            Yes
                        </ToggleButton>
                        <ToggleButton value={"no"} aria-label="No">
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default EmbedProperties;
