import ArrowOutwardIcon from "@mui/icons-material/ArrowOutward";
import useImages from "assets/images";
import { useController, useFormContext } from "react-hook-form";
import SettingPageCard from "screens/Dashboard/Settings/partials/SettingPageCard";
import Field from "shared/components/ui/Field/Field";
import { IFieldProps } from "shared/components/ui/Field/Field.type";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import useAuth from "shared/hooks/useAuth";
import useSocialMediaHandler from "./hook";

const SocialMediaHandler = () => {
    const { form, onSubmit, updateTeamResponse } = useSocialMediaHandler();
    const Images = useImages();
    const { isTeamOwner } = useAuth();

    return (
        <>
            <ThemeFormProvider form={form} onSubmit={onSubmit} className="relative">
                <SettingPageCard
                    title="Social Media Handles"
                    subTitle="Update your social handles here"
                    rightComponent={
                        isTeamOwner && (
                            <LoaderButton
                                loading={updateTeamResponse.isLoading}
                                disabled={!form.formState.isDirty || updateTeamResponse.isLoading}
                                type="submit"
                                variant="contained"
                                className="whitespace-nowrap"
                            >
                                Update Socials
                            </LoaderButton>
                        )
                    }
                    hasHeaderDivider={false}
                >
                    <div className="grid grid-cols-2 gap-6">
                        <SocialField
                            label="Facebook:"
                            name="facebook"
                            placeholder="https://facebook.com/"
                            inputTextProps={{
                                startAdornment: <Images.FacebookOutlinedIcon />,
                            }}
                        />
                        <SocialField
                            label="LinkedIn:"
                            name="linkedin"
                            placeholder="https://linkedin.com/"
                            inputTextProps={{
                                startAdornment: <Images.LinkedinOutlinedIcon />,
                            }}
                        />
                        <SocialField
                            label="Twitter:"
                            name="twitter"
                            placeholder="https://twitter.com/"
                            inputTextProps={{
                                startAdornment: <Images.TwitterOutlinedIcon />,
                            }}
                        />
                        <SocialField
                            label="Instagram:"
                            name="instagram"
                            placeholder="https://instagram.com/"
                            inputTextProps={{
                                startAdornment: <Images.InstagramOutlinedIcon />,
                            }}
                        />
                    </div>
                </SettingPageCard>
            </ThemeFormProvider>
        </>
    );
};

export default SocialMediaHandler;

const SocialField: React.FC<IFieldProps> = ({ inputTextProps, ...props }) => {
    const { isTeamOwner } = useAuth();

    const { control } = useFormContext();

    const { field } = useController({
        name: props.name,
        control: control,
    });

    const value = field.value as string;

    return (
        <Field
            formGroup={false}
            inputTextProps={{
                startAdornment: props.icon,
                classes: {
                    root: "!pr-1",
                },
                endAdornment: (
                    <ThemeIconButton
                        onClick={() => (value ? window.open(value, "_blank") : null)}
                        icon={<ArrowOutwardIcon style={{ color: "#98A2B3", fontSize: "16px" }} />}
                    ></ThemeIconButton>
                ),
                onClick: () => (value && !isTeamOwner ? window.open(value, "_blank") : null),
                readOnly: !isTeamOwner,
                ...inputTextProps,
            }}
            {...props}
        />
    );
};
