import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import FolderActionDropdown from "screens/Dashboard/Projects/components/FolderActionDropdown";
import { useAppSelector } from "shared/hooks/useRedux";

const FolderActionButton = (props: { folder_id: number }) => {
    const project = useAppSelector((state) => state.projects.selected);
    const folder = project?.folders.find((folder) => folder.folder_id === props.folder_id);

    if (!folder) return <></>;

    return (
        <div className="mt-6 flex items-center space-x-2 text-[#344054] text-[20px] leading-6 font-medium">
            <span>{folder.name}</span>
            <FolderActionDropdown folder_id={folder.folder_id}>
                <ChevronDown color="#344054" width={20} height={20} />
            </FolderActionDropdown>
        </div>
    );
};

export default FolderActionButton;
