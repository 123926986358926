import { TourProvider, useTour } from "@reactour/tour";
import { PropsWithChildren, useEffect } from "react";
import { Badge, Close, Navigation } from "shared/components/Walkthrough/components/Elements";
import { StepLocalStorageKeys } from "./const";
import { OnBoardDashboardSteps } from "./steps";

const WalkthroughLayout = ({ children }: any) => {
    return (
        <TourProvider
            steps={[]}
            components={{ Close, Badge, Navigation }}
            onClickClose={() => {}}
            onClickMask={() => {}}
        >
            <InnerWrapper>{children}</InnerWrapper>
        </TourProvider>
    );
};

export default WalkthroughLayout;

const InnerWrapper = (props: PropsWithChildren) => {
    const localKey = StepLocalStorageKeys.Dashboard;
    const steps = OnBoardDashboardSteps;

    useOpenTour({ localKey, steps });

    return <>{props.children}</>;
};

export const useOpenTour = (props: { localKey: string; steps: any; skip?: boolean }) => {
    const { setIsOpen, setSteps } = useTour();

    useEffect(() => {
        if (props.skip) return;
        const hasUserAlreadySeenWalkthrough = localStorage.getItem(props.localKey);
        if (hasUserAlreadySeenWalkthrough) return;
        localStorage.setItem(props.localKey, "true");
        setSteps && setSteps(props.steps);
        setIsOpen(true);
    }, [props.skip]);
};
