import { twMerge } from "tailwind-merge";
import ThemeCheckbox from "../ThemeCheckbox";
import RenderImage from "../RenderImage";

interface I {
    selectable?: boolean;
    selected?: boolean;
    value?: any;
    onClick?: (value: any) => void;
    className?: string;
    iconWrapperClassName?: string;
    iconClassName?: string;
    icon?: any;
    img?: any;
    title: string | React.ReactNode;
    desc: string;
    disbaled?: boolean;
}

const ITDCard = (props: I) => {
    const {
        className = "",
        iconWrapperClassName = "",
        iconClassName = "",
        icon: Icon,
        img,
        title,
        desc,
        selectable,
        value,
        selected = false,
        disbaled = false,
    } = props;

    const handleClick = () => {
        if (selectable) {
            props.onClick?.(value);
        }
    };

    return (
        <div
            className={twMerge(
                `bg-white p-4 rounded-[16px] flex flex-col relative transition-all`,
                className,
                selectable ? "cursor-pointer" : "",
                selected ? "!bg-primary !bg-opacity-20" : "",
                disbaled ? "opacity-50 cursor-not-allowed" : ""
            )}
            onClick={disbaled ? undefined : handleClick}
        >
            {selectable && (
                <div className="absolute top-3 right-3">
                    <ThemeCheckbox value={value} checked={selected} color="primary" onChange={() => handleClick()} />
                </div>
            )}
            {(Icon || img) && (
                <div className={`flex-1 ${iconWrapperClassName}`}>
                    {Icon}
                    {img && (
                        <RenderImage
                            containerClassName="!w-[40px] !min-w-[40px] !h-[40px]"
                            image={img}
                            imgClassName={iconClassName}
                        />
                    )}
                </div>
            )}
            <h4 className="text-[#5E5D5D] font-medium text-lg leading-6 mb-[2px] mt-3 overflow-hidden text-ellipsis whitespace-nowrap">
                {title}
            </h4>
            <p className="text-[#667085] opacity-50 text-md leading-5 font-normal">{desc}</p>
        </div>
    );
};

export default ITDCard;
