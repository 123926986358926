import { useContext, useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";

import { Button } from "@mui/material";
import useImages from "assets/images";
import CompanyOrientedTemplateImg from "assets/images/dummy/Company-Oriented-Template.png";
import ContentOrientedTemplateImg from "assets/images/dummy/Content-Oriented-Template.png";
import LandingPageTemplateImg from "assets/images/dummy/Landing-Page-Template.png";
import TemplateSidebarCard from "shared/components/TemplateSidebarCard/TemplateSidebarCard";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { closeTemplateSelection, setSelectedTemplate } from "store/slices/publishable_project";

import Template0 from "assets/files/template_0.json";
import Template1 from "assets/files/template_1.json";
import Template2 from "assets/files/template_2.json";

import { v4 as uuidv4 } from "uuid";
import { PublishableProjectsLayoutContext } from "screens/Dashboard/PublishableProjects/layout";

const templates: { id: number; label: string; image: string }[] = [
    {
        id: 0,
        label: "Content Oriented",
        image: ContentOrientedTemplateImg,
    },
    {
        id: 1,
        label: "Company Oriented",
        image: CompanyOrientedTemplateImg,
    },
    {
        id: 2,
        label: "Landing Page",
        image: LandingPageTemplateImg,
    },
];

const templateContentsById = [Template0, Template1, Template2];

const TemplatesSidebar = () => {
    const dispatch = useAppDispatch();
    const { ArrowRightWhite } = useImages();

    const { onSave } = useContext(PublishableProjectsLayoutContext);

    const selectedTemplate = useAppSelector((state) => state.publishable_project.selectedTemplate);
    const editor = useAppSelector((state) => state.publishable_project.main_editor);

    useEffect(() => {}, [selectedTemplate]);

    return (
        <div className="w-[240px] h-full rounded-xl bg-[#FFFFFF] border border-[#EAECF0] p-5 flex flex-col">
            <div className="text-[#667085] font-medium text-md mb-4">Page Styles</div>
            <div className="h-full flex-1">
                <PerfectScrollbar>
                    {templates.map((template) => (
                        <TemplateSidebarCard
                            key={template.id}
                            isSelected={selectedTemplate == template.id}
                            onClick={() => dispatch(setSelectedTemplate(template.id))}
                            {...template}
                        />
                    ))}
                </PerfectScrollbar>
            </div>
            <div>
                <Button
                    variant="contained"
                    onClick={() => {
                        dispatch(closeTemplateSelection());

                        editor?.commands.clearContent();

                        const content = { ...templateContentsById[selectedTemplate] };

                        if (selectedTemplate == 0) {
                            content["content"] = [
                                ...content["content"].slice(0, content["content"].length - 1),
                                {
                                    type: "placeholder",
                                    attrs: {
                                        id: uuidv4(),
                                    },
                                },
                                content["content"][content["content"].length - 1],
                            ] as any;
                        } else {
                            content["content"] = (content["content"] as any).filter(
                                (node) => node.type != "placeholder"
                            ) as any[];
                            content["content"].push({
                                type: "placeholder",
                                attrs: {
                                    id: uuidv4(),
                                },
                            });
                        }

                        editor!.commands.setContent(content);

                        setTimeout(() => {
                            onSave("auto");
                        }, 0);
                    }}
                    color="primary"
                    className={"!h-9 !w-full !m-0 !flex !items-center gap-2 !text-md !font-medium"}
                    disabled={selectedTemplate == -1}
                >
                    Use Style
                    <ArrowRightWhite width={20} />
                </Button>
            </div>
        </div>
    );
};

export default TemplatesSidebar;
