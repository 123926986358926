import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import Button from "./Button";

export default Node.create({
    name: "buttonComponent",

    group: "block",
    content: "",

    atom: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            content: {
                default: null,
            },
            color: {
                default: "#7680FF", // Default color (none)
            },
            link: {
                default: null,
            },
            width: {
                default: 30,
            },
            justify: {
                default: "left",
            },
            size: {
                default: 1, // Set a default size
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.button-component",
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    content: dom.getAttribute("content") || null,
                    color: dom.getAttribute("color") ?? "#7680FF",
                    link: dom.getAttribute("link"),
                    width: parseInt(dom.getAttribute("width")) || 100,
                    justify: dom.getAttribute("justify"),
                    size: parseInt(dom.getAttribute("size")) || 1,
                }),
            },
        ];
    },

    renderHTML({ node }) {
        // Construct the HTML representation of the node using its attributes
        const customAttributes = {
            class: "button-component",
            id: node.attrs.id,
            content: node.attrs.content,
            color: node.attrs.color ?? "#7680FF",
            link: node.attrs.link,
            width: node.attrs.width,
            justify: node.attrs.width,
            size: node.attrs.size,
        };

        return ["div", customAttributes, 0]; // This node doesn't have any direct content
    },

    addNodeView() {
        return ReactNodeViewRenderer(Button);
    },
});
