import { useEffect, useState } from "react";
import { tutorialSelector } from "store/selectors";
import { ScanTutorialWizard, setScanTutorialWizards } from "store/slices/tutorial";
import useAuth from "./useAuth";
import { useAppDispatch, useAppSelector } from "./useRedux";

const useScanTutorialWizard = () => {
    const dispatch = useAppDispatch();
    const { user, team } = useAuth();
    const { scan_tutorial_wizards } = useAppSelector(tutorialSelector);
    const [detail, setDetail] = useState<ScanTutorialWizard>();

    useEffect(() => {
        if (user && team) {
            const wizard = scan_tutorial_wizards?.find(
                (wizard) => wizard.user_id === user?.user_id && wizard.team_id === team?.team_id
            );
            setDetail(wizard);
        }
    }, [user, team, scan_tutorial_wizards]);

    const updateStep = (step: number) => {
        const steps = scan_tutorial_wizards || [];

        const wizard = steps.find((wizard) => wizard.user_id === user?.user_id && wizard.team_id === team?.team_id);

        if (wizard) {
            dispatch(
                setScanTutorialWizards([
                    ...steps.filter((wizard) => wizard.user_id !== user?.user_id && wizard.team_id !== team?.team_id),
                    {
                        ...wizard,
                        watched_step: step,
                    },
                ])
            );
        } else {
            dispatch(
                setScanTutorialWizards([
                    ...steps,
                    {
                        user_id: user?.user_id || 0,
                        team_id: team?.team_id || 0,
                        watched_step: step,
                        total_steps: 7,
                    },
                ] as ScanTutorialWizard[])
            );
        }
    };

    return { detail, updateStep };
};

export default useScanTutorialWizard;
