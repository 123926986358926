import { NodeViewWrapper } from "@tiptap/react";
import useImages from "assets/images";
import _ from "lodash";
import { useEffect } from "react";
import useAuth from "shared/hooks/useAuth";

export interface IFooterBlockProps {
    id: string;
    user_name: string | null;
    user_img: string | null;
    team_name: string | null;
    team_bio: string | null;
    team_img: string | null;
    facebook: string | null;
    instagram: string | null;
    linkedin: string | null;
    twitter: string | null;
}

const FooterBlock = (props: any) => {
    const { id, user_name, user_img, team_name, team_bio, team_img, facebook, instagram, linkedin, twitter } = props
        .node.attrs as IFooterBlockProps;

    const { SocialInstagramIcon, SocialFacebookIcon, SocialLinkedInIcon, SocialNewTwitterIcon } = useImages();

    const { user, team } = useAuth();

    useEffect(() => {
        let timer: any = null;
        if (user && team && props.editor) {
            timer = setInterval(() => {
                const data = {
                    id,
                    user_name: user.name,
                    user_img: user.profile_image.url,
                    team_name: team.name,
                    team_bio: team.bio,
                    team_img: team.logo.url,
                    facebook: team.social_accounts.facebook,
                    linkedin: team.social_accounts.linkedin,
                    instagram: team.social_accounts.instagram,
                    twitter: team.social_accounts.twitter,
                };

                let pos = 0;
                let nodeAttrs: any = null;

                props.editor!.view.state.doc.descendants((node, position) => {
                    if (node.attrs.id == id) {
                        pos = position;
                        nodeAttrs = node.attrs;
                        return false;
                    }
                });

                if (!_.isEqual(data, nodeAttrs)) {
                    const transaction = props.editor!.view.state.tr.setNodeMarkup(pos, null, data);

                    props.editor!.view.dispatch(transaction);
                }
            }, 1000);

            return () => {
                if (timer) {
                    clearInterval(timer);
                }
            };
        }
    }, [user, team, props.editor]);

    return (
        <NodeViewWrapper id={id} className={`footer-component bg-white w-full mt-12`}>
            <div className="w-full relative pt-[40px]">
                <div className="absolute top-0 left-1/2 -translate-x-1/2 w-[90px] h-[90px]">
                    <div className=" h-[80px] w-[80px] rounded-md border border-[#E9E9E9] bg-[#f9f9f9]">
                        <img src={team_img ?? undefined} className="w-full h-full rounded-md object-cover" />
                    </div>
                    <div className="h-[40px] w-[40px] rounded-full absolute border border-[#E9E9E9] bottom-0 right-0 bg-[#f9f9f9]">
                        <img src={user_img ?? undefined} className="h-full w-full rounded-full object-cover" />
                    </div>
                </div>
                <div
                    className="w-full px-8 pb-8 rounded-2xl pt-[72px]"
                    style={{
                        background: "linear-gradient(74.12deg, #F2F3FD 17.62%, rgba(242, 243, 253, 0) 99.96%)",
                    }}
                >
                    <div
                        className={
                            "w-full text-center text-[#101828] text-xl mb-2 " +
                            (!(user_name && team_name) && "opacity-0")
                        }
                    >
                        {user_name} from <span className="text-[#7680FF]">{team_name}</span>
                    </div>
                    <div className={"w-full text-center text-xl text-[#475467] mb-8 " + (!team_bio && "opacity-0")}>
                        {team_bio}
                    </div>
                    <div className="w-full flex items-center justify-center gap-x-6">
                        {instagram && (
                            <a href={instagram}>
                                <SocialInstagramIcon width={32} height={32} />
                            </a>
                        )}
                        {facebook && (
                            <a href={facebook}>
                                <SocialFacebookIcon width={32} height={32} />
                            </a>
                        )}
                        {linkedin && (
                            <a href={linkedin}>
                                <SocialLinkedInIcon width={32} height={32} />
                            </a>
                        )}
                        {twitter && (
                            <a href={twitter}>
                                <SocialNewTwitterIcon width={32} height={32} />
                            </a>
                        )}
                    </div>
                </div>
            </div>
        </NodeViewWrapper>
    );
};

export default FooterBlock;
