// import useImages from "assets/images";
// import React, { useEffect, useRef } from "react";
// // import IsStagingComponent from "shared/components/IsStagingComponent";

// const BubbleMenu = () => {
//     const Images = useImages();

//     const menuRef = useRef(null);

//     const linkInpRef = useRef(null);

//     const showBubbleMenu = () => {
//         const selection = window.getSelection();
//         if (!selection || selection.rangeCount === 0) return;

//         const range = selection.getRangeAt(0);
//         const rect = range.getBoundingClientRect();

//         if (document.getElementById("link-menu")) {
//             document.getElementById("link-menu")!.style.display = "inline-block";
//             if (isHyperlinkPresent()) {
//                 (linkInpRef.current as any).value = isHyperlinkPresent();
//             }
//         }
//         document.getElementById("bubble-menu")!.style.display = "flex";
//         (menuRef.current as any).style.top = `${rect.top + window.scrollY - 40}px`;
//         (menuRef.current as any).style.left = `${rect.left + window.scrollX + rect.width / 2}px`;
//     };

//     // Event listener for selection change
//     const handleSelectionChange = () => {
//         const selection = window.getSelection();
//         if (selection && selection.rangeCount > 0) {
//             const range = selection.getRangeAt(0);
//             const editorContentEditables = document.querySelectorAll(".editor-block-with-text");
//             let isWithinEditor = false;

//             editorContentEditables.forEach((editable) => {
//                 if (editable.contains(range.commonAncestorContainer)) {
//                     isWithinEditor = true;
//                 }
//             });

//             if (isWithinEditor && range.startOffset != range.endOffset) {
//                 showBubbleMenu();
//             } else {
//                 // Hide the bubble menu if the selection is outside of editor content editables
//                 document.getElementById("bubble-menu")!.style.display = "none";
//                 if (document.getElementById("link-menu")) document.getElementById("link-menu")!.style.display = "none";
//             }
//         }
//     };

//     const applyFormatting = (command, extras?: string) => {
//         document.execCommand(command, false, extras);
//     };

//     const isHighlighted = () => {
//         const selection = window.getSelection();
//         if (!selection || selection.rangeCount === 0) return false;

//         let node = selection.getRangeAt(0).commonAncestorContainer;

//         // Traverse up the DOM tree
//         while (node && node !== document.body) {
//             if (node.nodeType === Node.ELEMENT_NODE && (node as any).style && (node as any).style.backgroundColor) {
//                 return true; // Highlight found
//             }
//             node = node.parentNode as any;
//         }

//         return false; // No highlight found
//     };

//     const applyHyperlink = () => {
//         if (isHyperlinkPresent()) {
//             const selection = window.getSelection();
//             if (!selection?.rangeCount) return;

//             // Check if selection already contains a hyperlink
//             const node = selection!.getRangeAt(0).commonAncestorContainer;

//             // Remove the link, keep the text
//             const range = document.createRange();
//             range.selectNodeContents(node);
//             selection!.removeAllRanges();
//             selection!.addRange(range);
//             document.execCommand("unlink");
//         } else {
//             const url = prompt("Enter the URL");
//             if (url) {
//                 document.execCommand("createLink", false, url);
//             }
//         }
//     };

//     const isHyperlinkPresent = () => {
//         const selection = window.getSelection();
//         if (!selection || selection.rangeCount === 0) return false;

//         let node: any = selection.getRangeAt(0).commonAncestorContainer;

//         // Traverse up the DOM tree
//         while (node && node !== document.body) {
//             if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === "A") {
//                 return node.getAttribute("href");
//             }
//             node = node.parentNode;
//         }

//         return false; // No hyperlink found
//     };

//     useEffect(() => {
//         document.addEventListener("selectionchange", handleSelectionChange);
//         return () => {
//             document.removeEventListener("selectionchange", handleSelectionChange);
//         };
//     }, []);

//     return (
//         <div ref={menuRef} className="fixed -translate-x-1/2 -translate-y-[10px] z-20">
//             <div className="inline-block">
//                 <div
//                     id="bubble-menu"
//                     className="hidden h-[34px] items-center bg-[#0C111D] rounded-lg gap-x-3 px-3 pt-1 pb-2"
//                 >
//                     <button
//                         onClick={(e: any) => {
//                             e.stopPropagation();
//                             applyFormatting("bold");
//                         }}
//                         className={document.queryCommandState("bold") ? "bubble-menu-item-active" : ""}
//                     >
//                         <Images.EditorBubbleBoldIcon width={12} height={12} />
//                     </button>
//                     <button
//                         onClick={(e: any) => {
//                             e.stopPropagation();
//                             applyFormatting("italic");
//                         }}
//                         className={document.queryCommandState("italic") ? "bubble-menu-item-active" : ""}
//                     >
//                         <Images.EditorBubbleItalicIcon width={12} height={12} />
//                     </button>
//                     <button
//                         onClick={(e: any) => {
//                             e.stopPropagation();
//                             applyFormatting("strikeThrough");
//                         }}
//                         className={document.queryCommandState("strikeThrough") ? "bubble-menu-item-active" : ""}
//                     >
//                         <Images.EditorBubbleStrikeIcon width={16} height={16} />
//                     </button>
//                     <button
//                         onClick={(e: any) => {
//                             e.stopPropagation();
//                             applyFormatting("hiliteColor", !isHighlighted() ? "#C8CCFF" : "transparent");
//                         }}
//                         className={isHighlighted() ? "bubble-menu-item-active" : ""}
//                     >
//                         <Images.EditorBubbleHighlightIcon width={16} height={16} />
//                     </button>
//                     <button
//                         onClick={(e: any) => {
//                             e.stopPropagation();
//                             applyFormatting("underline");
//                         }}
//                         className={document.queryCommandState("underline") ? "bubble-menu-item-active" : ""}
//                     >
//                         <Images.EditorBubbleUnderlineIcon width={12} height={12} />
//                     </button>
//                     {/* <IsStagingComponent> */}
//                     <button
//                         onClick={(e: any) => {
//                             e.stopPropagation();
//                             applyHyperlink();
//                         }}
//                         className={isHyperlinkPresent() ? "bubble-menu-item-active" : ""}
//                     >
//                         <Images.EditorBubbleLinkIcon width={12} height={12} />
//                     </button>
//                     {/* </IsStagingComponent> */}
//                 </div>
//             </div>
//             {isHyperlinkPresent() && (
//                 <div
//                     id="link-menu"
//                     className="inline-block ml-[18px]"
//                     onClick={(e) => {
//                         e.stopPropagation();
//                     }}
//                 >
//                     <div className="w-[146px] h-[34px] bg-[#0C111D] rounded-lg flex items-center gap-x-2 py-2 px-3">
//                         <Images.EditorBubbleLinkIcon width={12} height={12} />
//                         <input
//                             ref={linkInpRef}
//                             className="w-full text-sm text-[#98A2B3] outline-none !bg-transparent !border-none"
//                             placeholder="Paste link here"
//                             onClick={(e) => {
//                                 e.stopPropagation();
//                             }}
//                         />
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default BubbleMenu;

import useImages from "assets/images";
import React, { useEffect, useRef, useState } from "react";
// import IsStagingComponent from "shared/components/IsStagingComponent";

const BubbleMenu = () => {
    const Images = useImages();

    const menuRef = useRef(null);
    const linkInpRef = useRef(null);
    const storedSelectionRef = useRef<any>(null);

    const hyperlinkRef = useRef("");

    const [isHyperlinkMenuVisible, setIsHyperlinkMenuVisible] = useState(false);

    const storeSelection = () => {
        const selection = window.getSelection();
        if (selection && selection.rangeCount > 0) {
            storedSelectionRef.current = selection.getRangeAt(0);
        }
    };

    const restoreSelection = () => {
        const selection = window.getSelection();
        if (storedSelectionRef.current) {
            selection?.removeAllRanges();
            selection?.addRange(storedSelectionRef.current);
        }
    };

    const showBubbleMenu = () => {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) return;

        const range = selection.getRangeAt(0);
        const rect = range.getBoundingClientRect();

        if (isHyperlinkPresent()) {
            (linkInpRef.current as any).value = isHyperlinkPresent();
        }
        (menuRef.current as any).style.display = "flex";
        (menuRef.current as any).style.top = `${rect.top + window.scrollY - 40}px`;
        (menuRef.current as any).style.left = `${rect.left + window.scrollX + rect.width / 2}px`;
    };

    // Event listener for selection change
    const handleSelectionChange = () => {
        const selection = window.getSelection();
        const isLinkMenu = selection?.focusNode && (selection.focusNode as any).id == "link-menu";
        if (selection && selection.rangeCount > 0 && !isLinkMenu) {
            const range = selection.getRangeAt(0);
            const editorContentEditables = document.querySelectorAll(".editor-block-with-text");
            let isWithinEditor = false;

            editorContentEditables.forEach((editable) => {
                if (editable.contains(range.commonAncestorContainer)) {
                    isWithinEditor = true;
                }
            });

            if (isWithinEditor && range.startOffset != range.endOffset) {
                showBubbleMenu();
            } else {
                // Hide the bubble menu if the selection is outside of editor content editables
                (menuRef.current as any).style.display = "none";
            }
        }

        if ((menuRef.current as any).style.display != "flex") {
            setIsHyperlinkMenuVisible(false);
        }
    };

    const applyFormatting = (command, extras?: string) => {
        document.execCommand(command, false, extras);
    };

    const isHighlighted = () => {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) return false;

        let node = selection.getRangeAt(0).commonAncestorContainer;

        // Traverse up the DOM tree
        while (node && node !== document.body) {
            if (node.nodeType === Node.ELEMENT_NODE && (node as any).style && (node as any).style.backgroundColor) {
                return true; // Highlight found
            }
            node = node.parentNode as any;
        }

        return false; // No highlight found
    };

    // const applyHyperlink = () => {
    //     restoreSelection();
    //     if (isHyperlinkPresent()) {
    //         const selection = window.getSelection();
    //         if (!selection?.rangeCount) return;

    //         // Check if selection already contains a hyperlink
    //         const node = selection!.getRangeAt(0).commonAncestorContainer;

    //         // Remove the link, keep the text
    //         const range = document.createRange();
    //         range.selectNodeContents(node);
    //         selection!.removeAllRanges();
    //         selection!.addRange(range);
    //         document.execCommand("unlink");
    //     } else {
    //         document.execCommand("createLink", false, "test");
    //     }
    // };

    const applyHyperlink = () => {
        restoreSelection();
        if (hyperlinkRef.current.trim() !== "") {
            document.execCommand("createLink", false, hyperlinkRef.current);
        } else {
            document.execCommand("unlink");
        }
        // Clear stored selection after applying hyperlink
        storedSelectionRef.current = null;

        setIsHyperlinkMenuVisible(false);
    };

    const createAnchorTagOnSelection = () => {
        document.execCommand("createLink", false, "https://");
    };

    const isHyperlinkPresent = () => {
        const selection = window.getSelection();
        if (!selection || selection.rangeCount === 0) return false;

        let node: any = selection.getRangeAt(0).commonAncestorContainer;

        // Traverse up the DOM tree
        while (node && node !== document.body) {
            if (node.nodeType === Node.ELEMENT_NODE && node.nodeName === "A") {
                // storeSelection();
                return node.getAttribute("href");
            }
            node = node.parentNode;
        }

        return false; // No hyperlink found
    };

    // const handleInput = (event) => {
    //     const href = event.target.value;
    //     restoreSelection();
    //     if (href.trim() !== "") {
    //         // Apply the hyperlink
    //         document.execCommand("createLink", false, href);
    //     } else {
    //         // Remove the hyperlink if the input is cleared
    //         document.execCommand("unlink");
    //     }
    //     // Store the selection again as it will be lost after execCommand
    //     storeSelection();
    // };

    const handleInput = (event) => {
        // const url = event.target.value;
        // applyHyperlink(url);
        hyperlinkRef.current = event.target.value;
    };

    useEffect(() => {
        document.addEventListener("selectionchange", handleSelectionChange);

        // Store the initial selection when the input is focused
        if (linkInpRef.current) {
            (linkInpRef.current as any).addEventListener("focus", storeSelection);
        }

        return () => {
            document.removeEventListener("selectionchange", handleSelectionChange);

            if (linkInpRef.current) {
                (linkInpRef.current as any).removeEventListener("focus", storeSelection);
            }
        };
    }, []);

    return (
        <div
            ref={menuRef}
            id="bubble-menu"
            className="hidden h-[34px] bg-[#0C111D] rounded-lg px-3 pt-1 pb-2 fixed -translate-x-1/2 -translate-y-[10px] z-20"
            onClick={(e) => {
                e.stopPropagation();
            }}
        >
            <div className={"flex items-center gap-x-3 " + (isHyperlinkMenuVisible && "opacity-0 select-none")}>
                <button
                    onClick={(e: any) => {
                        e.stopPropagation();
                        applyFormatting("bold");
                    }}
                    className={document.queryCommandState("bold") ? "bubble-menu-item-active" : ""}
                >
                    <Images.EditorBubbleBoldIcon width={12} height={12} />
                </button>
                <button
                    onClick={(e: any) => {
                        e.stopPropagation();
                        applyFormatting("italic");
                    }}
                    className={document.queryCommandState("italic") ? "bubble-menu-item-active" : ""}
                >
                    <Images.EditorBubbleItalicIcon width={12} height={12} />
                </button>
                <button
                    onClick={(e: any) => {
                        e.stopPropagation();
                        applyFormatting("strikeThrough");
                    }}
                    className={document.queryCommandState("strikeThrough") ? "bubble-menu-item-active" : ""}
                >
                    <Images.EditorBubbleStrikeIcon width={16} height={16} />
                </button>
                <button
                    onClick={(e: any) => {
                        e.stopPropagation();
                        applyFormatting("hiliteColor", !isHighlighted() ? "#C8CCFF" : "transparent");
                    }}
                    className={isHighlighted() ? "bubble-menu-item-active" : ""}
                >
                    <Images.EditorBubbleHighlightIcon width={16} height={16} />
                </button>
                <button
                    onClick={(e: any) => {
                        e.stopPropagation();
                        applyFormatting("underline");
                    }}
                    className={document.queryCommandState("underline") ? "bubble-menu-item-active" : ""}
                >
                    <Images.EditorBubbleUnderlineIcon width={12} height={12} />
                </button>
                <button
                    onClick={(e: any) => {
                        e.stopPropagation();
                        if (isHyperlinkPresent()) {
                            storeSelection();
                        } else {
                            createAnchorTagOnSelection();
                        }
                        setIsHyperlinkMenuVisible(true);
                    }}
                    className={isHyperlinkPresent() ? "bubble-menu-item-active" : ""}
                >
                    <Images.EditorBubbleLinkIcon width={12} height={12} />
                </button>
            </div>
            <div
                id="link-menu"
                className={
                    "absolute w-full h-full top-0 left-0 flex items-center gap-x-2 z-[1] px-3 " +
                    (!isHyperlinkMenuVisible && "hidden")
                }
            >
                {/* <div className="w-[146px] h-[34px] bg-[#0C111D] rounded-lg py-2 px-3"> */}
                <span
                    onClick={() => {
                        setIsHyperlinkMenuVisible(false);
                    }}
                >
                    <Images.EditorBubbleLinkIcon width={12} height={12} />
                </span>
                <input
                    ref={linkInpRef}
                    className="h-full w-full text-sm text-[#98A2B3] outline-none !bg-transparent !border-none"
                    placeholder="Paste link here"
                    onChange={handleInput}
                    onClick={(e) => e.stopPropagation()}
                    onBlur={applyHyperlink}
                    onKeyDown={(event: any) => {
                        if (event.key == "Escape" || event.key == "Enter") {
                            event.preventDefault();
                            event.stopPropagation();
                            (linkInpRef.current as any).blur();

                            setIsHyperlinkMenuVisible(false);
                        }
                    }}
                />
                {/* </div> */}
            </div>
        </div>
    );
};

export default BubbleMenu;
