import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { AssetVisibility } from "shared/graphql";
import { useAppSelector } from "shared/hooks/useRedux";
import ScanOrSnapshotCard from "./partials/ScanOrSnapshotCard";
import FolderItemCard from "./partials/FolderItemCard";
import useHasPermission from "shared/components/HasPermission/hook";
import Permissions from "shared/components/HasPermission/Permissions";
import useGetAssetsScans from "screens/Dashboard/Projects/hook/useGetAssetsScans";
import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";

export interface IScansOrSnapshotsBlockProps {
    id: string;
}

const ScansOrSnapshotsBlock = forwardRef((props: any, ref) => {
    const { id } = props.node.attrs as IScansOrSnapshotsBlockProps;

    const project = useAppSelector((state) => state.projects.selected);

    const [selectedFolder, setSelectedFolder] = useState(-1);
    const [selectedMediaTab, setSelectedMediaTab] = useState(-1);

    const { assets: nonFolderAssets, scans: nonFolderScans } = useGetAssetsScans();

    const Can_View_Snapshot_Admin = useHasPermission({ permission: Permissions.Can_View_Snapshots, type: "admin" });
    const Can_View_Snapshot_Manager = useHasPermission({ permission: Permissions.Can_View_Snapshots, type: "manager" });
    const Can_View_Snapshot_Member = useHasPermission({ permission: Permissions.Can_View_Snapshots, type: "member" });

    const canViewSnapshotTab = useMemo(() => {
        return Can_View_Snapshot_Admin || Can_View_Snapshot_Manager || Can_View_Snapshot_Member;
    }, [Can_View_Snapshot_Admin, Can_View_Snapshot_Manager, Can_View_Snapshot_Member]);

    const folders = useMemo(() => {
        if (!project) return [];

        const foldersList: {
            id: number;
            name: string;
            items: { assets: any[]; scans: any[] };
        }[] = [];

        foldersList.push({
            id: -2,
            name: "All",
            items: {
                assets: nonFolderAssets.filter((asset) => asset.visibility == AssetVisibility.Public) as any[],
                scans: nonFolderScans.filter(
                    (scan) => scan.visibility == "public" && scan.method == "gaussian-splatting"
                ) as any[],
            },
        });

        project.folders.forEach((folder) => {
            const thisFolder = {
                id: folder.folder_id,
                name: folder.name,
                items: {
                    assets: [] as any[],
                    scans: [] as any[],
                },
            };

            thisFolder["items"]["assets"] = folder.asset.filter(
                (asset) => asset.visibility == AssetVisibility.Public
            ) as any[];

            thisFolder["items"]["scans"] = folder.scans.filter(
                (scan) => scan.visibility == "public" && scan.method == "gaussian-splatting"
            ) as any[];

            foldersList[0]["items"]["assets"] = [
                ...foldersList[0]["items"]["assets"],
                ...thisFolder["items"]["assets"],
            ];
            foldersList[0]["items"]["scans"] = [...foldersList[0]["items"]["scans"], ...thisFolder["items"]["scans"]];

            if (thisFolder["items"]["assets"].length > 0 || thisFolder["items"]["scans"].length > 0) {
                foldersList.push(thisFolder);
            }
        });

        return foldersList;
    }, [project, nonFolderAssets, nonFolderScans]);

    const scans = useMemo(() => {
        if (!project) return [];

        let scansList: any[] = [];

        project.folders.forEach((folder) => {
            scansList = [
                ...scansList,
                ...folder.scans.filter((scan) => scan.visibility == "public" && scan.method == "gaussian-splatting"),
            ];
        });

        nonFolderScans.forEach((scan) => {
            if (scan.visibility == "public" && scan.method == "gaussian-splatting") {
                scansList = [...scansList, scan];
            }
        });

        return scansList;
    }, [project, nonFolderScans]);

    const images = useMemo(() => {
        if (!project) return [];

        const canViewSnapshot = Can_View_Snapshot_Admin || Can_View_Snapshot_Manager || Can_View_Snapshot_Member;

        let imagesList: any[] = [];

        project.folders.forEach((folder) => {
            imagesList = [
                ...imagesList,
                ...folder.asset.filter(
                    (asset) => asset.visibility == AssetVisibility.Public && asset.file.type == "Image"
                ),
            ];
        });

        nonFolderAssets.forEach((asset) => {
            if (asset.visibility == AssetVisibility.Public && asset.file.type == "Image") {
                imagesList = [...imagesList, asset];
            }
        });

        if (canViewSnapshot) {
            project.folders.forEach((folder) => {
                folder.scans.forEach((scan) => {
                    if (scan.visibility == "public" && scan.method == "gaussian-splatting")
                        imagesList = [
                            ...imagesList,
                            ...scan.snapshots.map((snapshot) => ({ scan_id: scan.scan_id, ...snapshot })),
                        ];
                });
            });

            nonFolderScans.forEach((scan) => {
                if (scan.visibility == "public" && scan.method == "gaussian-splatting") {
                    imagesList = [
                        ...imagesList,
                        ...scan.snapshots.map((snapshot) => ({ scan_id: scan.scan_id, ...snapshot })),
                    ];
                }
            });
        }

        return imagesList;
    }, [
        project,
        Can_View_Snapshot_Admin,
        Can_View_Snapshot_Manager,
        Can_View_Snapshot_Member,
        nonFolderAssets,
        nonFolderScans,
    ]);

    const videos = useMemo(() => {
        if (!project) return [];

        let videos: any[] = [];

        project.folders.forEach((folder) => {
            videos = [
                ...videos,
                ...folder.asset.filter(
                    (asset) => asset.visibility == AssetVisibility.Public && asset.file.type == "Video"
                ),
            ];
        });

        nonFolderAssets.forEach((asset) => {
            if (asset.visibility == AssetVisibility.Public && asset.file.type == "Video") {
                videos = [...videos, asset];
            }
        });

        return videos;
    }, [project, nonFolderAssets]);

    useEffect(() => {
        const resetTabs = () => {
            const isFoldersTabVisible =
                (window.getComputedStyle(document.querySelector(".editor-folders-grid") as any) as any).display ==
                "flex";

            const isMediaTabVisible =
                (window.getComputedStyle(document.querySelector(".editor-media-grid") as any) as any).display == "flex";

            if (!isFoldersTabVisible && folders.length > 0) {
                setSelectedFolder(0);
            }

            if (!isMediaTabVisible && (scans.length > 0 || images.length > 0 || videos.length > 0)) {
                setSelectedMediaTab(0);
            }
        };

        if (folders.length > 0) {
            setSelectedFolder(0);
        }

        if (scans.length > 0 || images.length > 0 || videos.length > 0) {
            setSelectedMediaTab(0);
        }

        const timer = setInterval(resetTabs, 1000);

        return () => {
            if (timer) {
                clearInterval(timer);
            }
        };
    }, [folders, images, scans, videos, canViewSnapshotTab]);

    return (
        <NodeViewWrapper ref={ref} id={id} contentEditable={false}>
            <div className="scans-or-snapshots w-full mt-12">
                <div className="editor-media-grid w-full grid grid-cols-1 gap-8 pb-16 -mt-6">
                    <div className="w-full border-b-2 border-[#D0D5DD40] flex items-center justify-center gap-x-6 col-span-3">
                        {[
                            { id: 0, name: "All" },
                            ...(scans.length > 0 ? [{ id: 1, name: "Scans" }] : []),
                            ...(images.length > 0 ? [{ id: 2, name: "Images" }] : []),
                            ...(videos.length > 0 ? [{ id: 3, name: "Videos" }] : []),
                        ].map((tab) => (
                            <div
                                key={`media_tab_${tab.id}`}
                                className={
                                    "h-[38px] px-2 border-b-2 border-transparent pb-[18px] font-normal text-md text-[#5E5D5D] transition-all cursor-pointer hover:text-[#7680FFAA] -mb-[2px] " +
                                    (selectedMediaTab == tab.id ? "!border-[#7680FF] !text-[#3F3E3E] !font-medium" : "")
                                }
                                onClick={() => {
                                    setSelectedMediaTab(tab.id);
                                }}
                            >
                                {tab.name}
                            </div>
                        ))}
                    </div>
                    <div className="w-full flex flex-wrap justify-center gap-6 pb-16 px-9">
                        {(selectedMediaTab == 0 || selectedMediaTab == 1) &&
                            scans.map((card: any, index) => (
                                <ScanOrSnapshotCard key={`scan_${index}`} type={"scans"} data={card} />
                            ))}
                        {(selectedMediaTab == 0 || selectedMediaTab == 2) &&
                            images.map((card: any, index) => (
                                <ScanOrSnapshotCard key={`image_${index}`} type={"assets"} data={card} />
                            ))}
                        {(selectedMediaTab == 0 || selectedMediaTab == 3) &&
                            videos.map((card: any, index) => (
                                <ScanOrSnapshotCard key={`asset_${index}`} type={"assets"} data={card} />
                            ))}
                        {((selectedMediaTab == 1 && scans.length == 0) ||
                            (selectedMediaTab == 2 && images.length == 0) ||
                            (selectedMediaTab == 3 && videos.length == 0)) && (
                            <div className="w-full">
                                <div className="w-full h-0 pb-[76%] relative bg-transparent"></div>
                            </div>
                        )}
                    </div>
                </div>
                <div className="editor-folders-grid w-full grid grid-cols-1 gap-8 pb-16 -mt-6">
                    <div className="w-full border-b-2 border-[#D0D5DD40] flex items-center justify-center gap-x-6 col-span-3">
                        {folders.map((folder, index) => (
                            <div
                                key={`folder_${folder.id}`}
                                className={
                                    "h-[38px] flex items-start -mb-[2px] " + (folder.name == "All" ? "gap-x-4" : "")
                                }
                            >
                                <div
                                    className={
                                        "h-[38px] px-2 border-b-2 border-transparent pb-[18px] font-normal text-md text-[#5E5D5D] transition-all cursor-pointer hover:text-[#7680FFAA] -mb-[2px] " +
                                        (selectedFolder == index
                                            ? "!border-[#7680FF] !text-[#3F3E3E] !font-medium"
                                            : "")
                                    }
                                    onClick={() => {
                                        setSelectedFolder(index);
                                    }}
                                >
                                    {folder.name}
                                </div>
                                {folder.name == "All" && (
                                    <ChevronRight height={18} width={18} color="#5E5D5D" className="mt-1" />
                                )}
                            </div>
                        ))}
                    </div>
                    <div className="w-full flex flex-wrap justify-center gap-6 pb-16 px-9">
                        {selectedFolder != -1 &&
                            folders[selectedFolder] &&
                            folders[selectedFolder].items.assets.map((card: any, index) => (
                                <FolderItemCard key={`asset_${index}`} type={"assets"} data={card} />
                            ))}
                        {selectedFolder != -1 &&
                            folders[selectedFolder] &&
                            folders[selectedFolder].items.scans.map((card: any, index) => (
                                <FolderItemCard key={`scan_${index}`} type={"scans"} data={card} />
                            ))}

                        {selectedFolder == -1 ||
                            (folders.length == 0 && (
                                <div className="w-full">
                                    <div className="w-full h-0 pb-[76%] relative bg-transparent"></div>
                                </div>
                            ))}
                    </div>
                </div>
            </div>
        </NodeViewWrapper>
    );
});

export default ScansOrSnapshotsBlock;
