import React from "react";

interface ITemplateSidebarCardProps {
    label: string;
    image: string;
    isSelected: boolean;
    onClick: any;
}

const TemplateSidebarCard = (props: ITemplateSidebarCardProps) => {
    const { label, image, isSelected, onClick } = props;

    return (
        <div
            className={"w-full mb-4 select-none cursor-pointer group " + (isSelected && "pointer-events-none")}
            onClick={onClick}
        >
            <div className="w-full h-0 pb-[68%] rounded-md relative overflow-clip">
                <img src={image} className="absolute top-0 left-0 h-full w-full object-cover select-none" />
            </div>
            <div
                className={
                    "text-sm mt-1 group-hover:text-[#7680FFAA] " +
                    (isSelected ? " text-[#7680FF] " : " text-[#667085] ") +
                    (isSelected ? "font-semibold" : "font-medium")
                }
            >
                {label}
            </div>
        </div>
    );
};

export default TemplateSidebarCard;
