import { Link } from "react-router-dom";
import RenderImage from "shared/components/ui/RenderImage";
import { twMerge } from "tailwind-merge";

interface Props {
    url: string;
    title?: string;
    description?: string;
    visible?: boolean;
    inline?: boolean;
    icon?: string | React.ReactNode;
}

const ProjectCard = (props: Props) => {
    return (
        <Link
            to={props.url}
            className={twMerge(
                "items-stretch border border-[color:var(#EAECF0)] p-4 rounded-lg border-solid",
                props.inline ? "grid grid-cols-2 gap-3 place-content-stretch" : "flex flex-col"
            )}
        >
            <div className="flex-col items-stretch overflow-hidden relative flex w-full">
                {typeof props.icon === "string" && (
                    <RenderImage
                        image={props.icon}
                        containerClassName={twMerge("w-full rounded-md", props.inline ? "h-[60px]" : "h-[190px]")}
                    />
                )}
                {typeof props.icon !== "string" && props.icon}
                {props.visible && (
                    <div className="absolute  items-center border border-[#D0D5DD] shadow-sm bg-white bg-opacity-80 flex justify-between gap-1 px-[6px] h-[22px] rounded-md border-solid top-2 left-2">
                        <div className="bg-[#15AA2C] w-[6px] h-[6px] rounded-full" />
                        <div className="text-slate-700 text-center text-xs font-medium leading-5">Public</div>
                    </div>
                )}
            </div>
            <div className={props.inline ? "flex flex-col justify-center" : ""}>
                <div
                    className={twMerge(
                        "text-slate-600 text-sm font-medium leading-5",
                        props.inline ? "line-clamp-2" : "whitespace-nowrap text-ellipsis overflow-hidden mt-2"
                    )}
                >
                    {props.title}
                </div>
                <div className="text-[#98A2B3] text-sm font-medium leading-5 whitespace-nowrap">
                    {props.description}
                </div>
            </div>
        </Link>
    );
};

export default ProjectCard;
