import { Button, Tooltip } from "@mui/material";
import useImages from "assets/images";
import { capitalize } from "lodash";
import { Context, createContext } from "react";
import { Outlet } from "react-router-dom";
import PageLoader from "shared/components/ui/PageLoader";
import { twMerge } from "tailwind-merge";
import usePublishableProjectLayout from "./hook";
import Eye from "@untitled-ui/icons-react/build/esm/Eye";
import ArrowUpRight from "@untitled-ui/icons-react/build/esm/ArrowUpRight";
import CheckCircle from "@untitled-ui/icons-react/build/esm/CheckCircle";
import RefreshCW05 from "@untitled-ui/icons-react/build/esm/RefreshCcw05";

export const PublishableProjectsLayoutContext: Context<{
    onSave: any;
}> = createContext({
    onSave: () => {},
});

const PublishableProjectsLayout = () => {
    const {
        isSelectingTemplate,
        project,
        onOpenProject,
        onSaveAndPreviewClickedHandler,
        onClosePreviewClickedHandler,
        isPreviewOpen,
        isOnboardingFlow,
        isShowPageLoader,
        onOpenShareSidebar,
        isEditorDirty,
        saveStatus,
        isTutorialOpen,
    } = usePublishableProjectLayout();

    const { ArrowChevronLeft } = useImages();

    return (
        <PublishableProjectsLayoutContext.Provider value={{ onSave: onSaveAndPreviewClickedHandler }}>
            <div
                className={twMerge(
                    "h-full",
                    !isOnboardingFlow && "bg-[#F9F9F9]",
                    isTutorialOpen && "pointer-events-none"
                )}
            >
                {isShowPageLoader && <PageLoader />}
                <div className="h-[80px] px-6 flex items-center justify-between bg-[#FFFFFF] border-b border-b-[#D0D5DD40]">
                    <span
                        className="text-[20px] font-medium text-[#344054] flex items-center gap-x-[6px] select-none cursor-pointer"
                        onClick={() =>
                            !isOnboardingFlow
                                ? isSelectingTemplate
                                    ? onOpenProject()
                                    : onSaveAndPreviewClickedHandler("back")
                                : {}
                        }
                    >
                        {!isOnboardingFlow && <ArrowChevronLeft height={20} />}
                        {isSelectingTemplate
                            ? "Select Page Style For Your Board"
                            : `${capitalize(project?.name || "")} board`}
                    </span>
                    <span className="editor-topbar-controls flex items-center">
                        {!isSelectingTemplate && (
                            <>
                                {<div className="text-sm text-[#5E5D5D] font-medium leading-[18px]">{saveStatus}</div>}
                                <Tooltip
                                    title={
                                        isEditorDirty
                                            ? "Your changes are being saved. One moment please."
                                            : "Your changes are saved."
                                    }
                                >
                                    <span className="mx-3" onClick={() => onSaveAndPreviewClickedHandler("auto")}>
                                        {isEditorDirty ? (
                                            <RefreshCW05 width={20} height={20} color="#98A2B3" />
                                        ) : (
                                            <CheckCircle width={20} height={20} color="#15AA2C" />
                                        )}
                                    </span>
                                </Tooltip>
                                {isPreviewOpen ? (
                                    <Button
                                        variant="outlined"
                                        onClick={onClosePreviewClickedHandler}
                                        color="inherit"
                                        className={"h-8 !ml-0"}
                                    >
                                        Close Preview
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            variant="outlined"
                                            onClick={() => onSaveAndPreviewClickedHandler()}
                                            color="inherit"
                                            className={"h-8 !ml-0 !mr-3 !flex !items-center !justify-center !gap-x-2"}
                                        >
                                            <Eye width={18} height={18} color="#344054" /> Preview
                                        </Button>
                                        <Button
                                            variant="contained"
                                            onClick={() => {
                                                onSaveAndPreviewClickedHandler("auto");
                                                onOpenShareSidebar();
                                            }}
                                            color="primary"
                                            className={"h-8 !ml-0 !gap-x-3 !flex !items-center"}
                                        >
                                            Publish Board <ArrowUpRight width={18} height={18} color="#FFFFFF" />
                                        </Button>
                                    </>
                                )}
                            </>
                        )}
                        {/* {!isSelectingTemplate && <div className="w-[1px] h-4 bg-[#BCBCBC] mx-3"></div>}
                        <Button
                            variant={isOnboardingFlow ? "contained" : "outlined"}
                            onClick={() =>
                                isOnboardingFlow
                                    ? onSkipOrNextStep()
                                    : isSelectingTemplate
                                    ? onOpenProject()
                                    : onSaveAndPreviewClickedHandler("back")
                            }
                            color={isOnboardingFlow ? "primary" : "inherit"}
                            className={
                                "h-8 !ml-0 !text-[#344054] " + (isOnboardingFlow && "!font-medium !text-white")
                            }
                        >
                            {isOnboardingFlow ? (isSavedOnce ? "Next Step" : "Skip Step") : "Main Page"}
                        </Button> */}
                    </span>
                </div>
                <div className="h-[calc(100%-80px)] p-4 ">
                    <Outlet />
                </div>
            </div>
        </PublishableProjectsLayoutContext.Provider>
    );
};

export default PublishableProjectsLayout;
