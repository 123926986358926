const TooltipTitles = {
    // Scan
    DefaultScanTooltipTitle: ": You cannot access this feature on a trial scan.",
    TeamMemberNoAccessTooltipTitle: ": Your role does not have access to this feature.",
    ScanNotCompletedTooltipTitle: ": The scan has not done processing yet.",
    ScanFailedTooltipTitle: ": The scan has failed.",

    // Project
    DefaultProjectTooltipTitle: ": You cannot access this feature on a trial project.",
};

export default TooltipTitles;
