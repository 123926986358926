import { Skeleton } from "@mui/material";
import React from "react";

const ProjectCardLoader = () => {
    return (
        <div className="items-stretch border border-[color:var(#EAECF0)] flex flex-col p-4 rounded-lg border-solid">
            <div className="flex-col items-stretch overflow-hidden relative flex w-full">
                <Skeleton className="!h-[190px] !translate-x-0 w-full rounded-md" />
            </div>
            <div className="text-slate-600 text-sm font-medium leading-5 whitespace-nowrap mt-2">
                <Skeleton />
            </div>
            <div className="text-indigo-400 text-xs font-medium leading-5 whitespace-nowrap">
                <Skeleton />
            </div>
        </div>
    );
};

export default ProjectCardLoader;
