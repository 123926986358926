import { Button, MenuItem, Popover } from "@mui/material";
import Image05 from "@untitled-ui/icons-react/build/esm/Image05";
import Package from "@untitled-ui/icons-react/build/esm/Package";
import Plus from "@untitled-ui/icons-react/build/esm/Plus";
import Scan from "@untitled-ui/icons-react/build/esm/Scan";
import VideoRecorder from "@untitled-ui/icons-react/build/esm/VideoRecorder";
import { MouseEvent, useState } from "react";
import { Link } from "react-router-dom";
import URL from "shared/constants/navigator";
import { useAppSelector } from "shared/hooks/useRedux";
import { routeWithParams } from "shared/utils/helpers";

const AddFolderPopover = () => {
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { selected: project } = useAppSelector((state) => state.projects);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    if (!project) return null;

    return (
        <>
            <Button
                className="project-btn"
                variant="contained"
                startIcon={<Plus height={20} width={20} />}
                onClick={handleClick}
            >
                New
            </Button>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{
                    paper: "!min-w-[150px] !py-2 !shadow-[0px_4px_6px_-2px_#10182808] !border border-[#EAECF0] !rounded-md",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
            >
                <div className="px-4 text-primary text-sm font-medium mb-1">Folder</div>
                <div className="flex flex-col text-md text-[#344054] space-y-1 mt-2 font-medium mb-3">
                    <Link
                        to={routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}/${URL.Media_Folder_Post}`, {
                            id: project.project_id,
                        })}
                    >
                        <MenuItem onClick={() => setAnchorEl(null)} className="flex items-center space-x-2 !py-1">
                            <Scan color="#667085" height={16} width={16} />
                            <span>Create Folder</span>
                        </MenuItem>
                    </Link>
                </div>
                <div className="px-4 text-primary text-sm font-medium mb-1">New Asset</div>
                <div className="flex flex-col text-md text-[#344054] space-y-1 mt-2 font-medium">
                    <Link
                        to={routeWithParams(URL.Media_Folder_Scan_Post, {
                            type: "video",
                        })}
                    >
                        <MenuItem onClick={() => setAnchorEl(null)} className="flex items-center space-x-2 !py-1">
                            <Scan color="#667085" height={16} width={16} />
                            <span>Video Scan</span>
                        </MenuItem>
                    </Link>
                    <Link
                        to={routeWithParams(URL.Media_Folder_Scan_Post, {
                            type: "images",
                        })}
                    >
                        <MenuItem onClick={() => setAnchorEl(null)} className="flex items-center space-x-2 !py-1">
                            <Package color="#667085" height={16} width={16} />
                            <span>Zipped Photo Scan</span>
                        </MenuItem>
                    </Link>
                    <Link
                        to={routeWithParams(URL.Media_Folder_Asset_Post, {
                            type: "image",
                        })}
                    >
                        <MenuItem onClick={() => setAnchorEl(null)} className="flex items-center space-x-2 !py-1">
                            <Image05 color="#667085" height={16} width={16} />
                            <span>Image Asset</span>
                        </MenuItem>
                    </Link>
                    <Link
                        to={routeWithParams(URL.Media_Folder_Asset_Post, {
                            type: "video",
                        })}
                    >
                        <MenuItem onClick={() => setAnchorEl(null)} className="flex items-center space-x-2 !py-1">
                            <VideoRecorder color="#667085" height={16} width={16} />
                            <span>Video Asset</span>
                        </MenuItem>
                    </Link>
                </div>
            </Popover>
        </>
    );
};

export default AddFolderPopover;
