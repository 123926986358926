import { Node } from "@tiptap/core";
import { ReactNodeViewRenderer } from "@tiptap/react";

import TabsBlock from "./TabsBlock";

export default Node.create({
    name: "tabsBlock",
    content: "inline*",
    group: "block",
    defining: true,

    addAttributes() {
        return {
            id: {
                default: null,
            },
            template: {
                default: 0, // Set a default size
            },
            can_view_snapshots: {
                default: false,
            },
        };
    },

    parseHTML() {
        return [
            {
                tag: "div.editor-tabs", // Adjust this selector based on your actual HTML structure
                getAttrs: (dom: any) => ({
                    id: dom.getAttribute("id"),
                    template: parseInt(dom.getAttribute("template")) || 0,
                    can_view_snapshots: dom.getAttribute("can_view_snapshots"),
                }),
            },
        ];
    },

    renderHTML({ node }) {
        return [
            "div",
            {
                class: "editor-tabs",
                id: node.attrs.id,
                template: node.attrs.template,
                can_view_snapshots: node.attrs.can_view_snapshots,
            },
            0,
        ];
    },

    addNodeView() {
        return ReactNodeViewRenderer(TabsBlock);
    },
});
