import { MenuItem, Popover } from "@mui/material";
import Edit05 from "@untitled-ui/icons-react/build/esm/Edit05";
import Trash04 from "@untitled-ui/icons-react/build/esm/Trash04";
import { MouseEvent, PropsWithChildren, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import PageLoader from "shared/components/ui/PageLoader";
import RenderImage from "shared/components/ui/RenderImage";
import URL from "shared/constants/navigator";
import { useDeleteFolderMutation } from "shared/graphql";
import { useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";
import { routeWithParams } from "shared/utils/helpers";

interface Props extends PropsWithChildren {
    folder_id: number;
}

const FolderActionDropdown = (props: Props) => {
    const navigate = useNavigate();
    const [deleteFolder, deleteRes] = useDeleteFolderMutation();
    const { id } = useEncryptedParams<{ id: string }>();
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
    const { selected: project } = useAppSelector((state) => state.projects);
    const folder = project?.folders.find((folder) => folder.folder_id === props.folder_id);

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goTo = (path: string) => {
        handleClose();
        navigate(
            routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}/${path}`, {
                id,
                folder_id: props.folder_id,
            })
        );
    };

    const open = Boolean(anchorEl);
    const popoverId = open ? "simple-popover" : undefined;

    useEffect(() => {
        rtkHandler(deleteRes, {
            successMessage: "Folder deleted successfully",
            onSuccess: () => {
                navigate(
                    routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
                        id,
                    })
                );
            },
        });
    }, [deleteRes]);

    return (
        <>
            {deleteRes.isLoading && <PageLoader />}
            <button onClick={handleClick}>{props.children}</button>
            <Popover
                id={popoverId}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                classes={{
                    paper: "!min-w-[150px] !py-2 !shadow-[0px_4px_6px_-2px_#10182808] !border border-[#EAECF0] !rounded-md",
                }}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                }}
            >
                <div className="px-4 text-primary text-sm font-medium mb-1">Folder</div>
                <div className="flex flex-col text-md text-[#344054] space-y-1 mt-2 font-medium">
                    <MenuItem onClick={() => goTo(URL.Media_Folder_Edit_Post)} className="flex items-center space-x-2 !py-1">
                        <Edit05 color="#667085" height={16} width={16} />
                        <span>Edit Folder</span>
                    </MenuItem>
                    <ConfirmButton
                        title="Delete Folder"
                        content="Are you sure you want to delete this folder? You will lose all the scans and assets associated with this project."
                        icon={
                            <RenderImage
                                image={folder?.thumbnail?.url || project?.thumbnail.url || ""}
                                containerClassName="h-[60px] w-[60px] rounded-full"
                            />
                        }
                        okBtnProps={{
                            children: "Delete",
                            color: "error",
                            onClick: () => {
                                setAnchorEl(null);
                                id &&
                                    props.folder_id &&
                                    deleteFolder({
                                        project_id: parseInt(id ?? "0"),
                                        folder_id: props.folder_id,
                                    });
                            },
                        }}
                    >
                        <MenuItem className="flex items-center space-x-2 !py-1 !text-error">
                            <Trash04 height={16} width={16} />
                            <span>Delete Folder</span>
                        </MenuItem>
                    </ConfirmButton>
                </div>
            </Popover>
        </>
    );
};

export default FolderActionDropdown;
