import { Skeleton } from "@mui/material";

const LibraryLoading = () => {
    return (
        <>
            {[...Array(4)].map((_, index) => (
                <div key={index} className="border rounded-md p-5 border-[#EAECF0] mb-6">
                    <Skeleton width={300} className="!translate-x-0 !mb-4" />
                    <div className="flex items-center space-x-4">
                        {[...Array(4)].map((_, index) => (
                            <div className="w-full" key={`skeleton-${index}`}>
                                <Skeleton className="!translate-x-0 !mb-4" height={190} width={"100%"} />
                                <Skeleton width={"100%"} className="!translate-x-0 !mb-2" />
                                <Skeleton width={"40%"} className="!translate-x-0" />
                            </div>
                        ))}
                    </div>
                </div>
            ))}
        </>
    );
};

export default LibraryLoading;
