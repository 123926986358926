import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";
import RepositioningHandler from "../RepositioningHandler";
import { updateContentInEditor } from "screens/Dashboard/PublishableProjects/utils/utls";

export interface IToCBlockProps {
    id: string;
    type: "numbered" | "alphabetical" | "bullet";
    size: 1 | 2 | 3 | 4 | 5;
    content: string | null;
}

const ToCBlock = forwardRef((props: any, ref) => {
    const { id, type, size, content } = props.node.attrs as IToCBlockProps;
    const dispatch = useAppDispatch();

    const listRef = useRef(null);
    const mutationObserverRef = useRef<any>(null);

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);
    const isPreviewOpen = useAppSelector((state) => state.publishable_project.isPreviewOpen);

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const listStyling = useMemo(() => {
        if (type == "alphabetical") {
            return {
                listStyleType: "lower-alpha",
            };
        } else if (type == "bullet") {
            return {
                listStyleType: "disc",
            };
        } else {
            return {
                listStyleType: "decimal",
            };
        }
    }, [type]);

    const sizingStyles = useMemo(() => {
        return `text-size-${size} `;
    }, [size]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = props.editor;

        props.editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (listRef.current) {
                const selection = window.getSelection();
                if (selection) {
                    const currentNode = selection.anchorNode;

                    // Find the <li> element
                    let li = currentNode;
                    while (li && li.nodeName !== "LI") {
                        li = li.parentNode;
                    }

                    if (li && li.nodeName === "LI") {
                        if (event.key === "Enter") {
                            event.preventDefault();

                            // Create a new <li> element
                            const newLi = document.createElement("li");
                            newLi.contentEditable = "true";
                            newLi.innerText = ""; // Start with an empty item

                            // Insert the new <li> after the current <li>
                            li.parentNode!.insertBefore(newLi, li.nextSibling);

                            // Move the cursor to the new <li>
                            const range = document.createRange();
                            const sel = window.getSelection();
                            range.setStart(newLi, 0);
                            range.collapse(true);
                            sel?.removeAllRanges();
                            sel?.addRange(range);
                            newLi.focus();
                        } else if (event.key === "Backspace" && selection.isCollapsed && li.textContent === "") {
                            event.preventDefault();

                            // If the <li> is empty, remove it and move the cursor to the previous <li>
                            const prevLi = (li as any).previousElementSibling;
                            li.parentNode!.removeChild(li);

                            if (prevLi) {
                                // Move the cursor to the end of the previous <li>
                                const range = document.createRange();
                                const sel = window.getSelection();
                                range.setStart(prevLi, prevLi.childNodes.length);
                                range.collapse(true);
                                sel?.removeAllRanges();
                                sel?.addRange(range);
                                prevLi.focus();
                            }
                        }
                    }
                }
            }
        };

        if (parentEditor) {
            (listRef.current as any)?.addEventListener("keydown", handleKeyDown);
        }

        return () => {
            if (parentEditor) (listRef.current as any)?.removeEventListener("keydown", handleKeyDown);
        };
    }, [parentEditor]);

    useEffect(() => {
        if (isSelected) {
            const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
            parentEditor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    useEffect(() => {
        if (listRef.current) {
            (listRef.current as any).innerHTML = content ?? "<li>Add text here</li>";

            (listRef.current as any).addEventListener("paste", function (event) {
                // Prevent the default paste action
                event.preventDefault();

                // Get the text content from the clipboard
                const text = event.clipboardData.getData("text/plain");

                // Insert the text at the current cursor position
                document.execCommand("insertText", false, text);
            });
        }
    }, []);

    useEffect(() => {
        if (listRef.current) {
            // Define what to do when mutations are observed
            const observerCallback = (mutationsList) => {
                if (mutationsList.length > 0) {
                    updateContentInEditor(parentEditor!, id, {
                        content: (listRef.current as any).innerHTML,
                    });
                }
            };

            // Create a new observer instance
            mutationObserverRef.current = new MutationObserver(observerCallback);

            // Start observing the contentEditable element
            mutationObserverRef.current.observe(listRef.current, {
                characterData: true,
                childList: true,
                subtree: true,
                attributeFilter: ["style"],
            });
        }

        // Disconnect the observer when the component unmounts
        return () => {
            if (mutationObserverRef.current) {
                mutationObserverRef.current.disconnect();
            }
        };
    }, [listRef, parentEditor]);

    useEffect(() => {
        const currentListItems = (listRef.current as any).children;

        if (currentListItems) {
            for (let i = 0; i < currentListItems.length; i++) {
                currentListItems[i].setAttribute("contentEditable", !isPreviewOpen);
            }
        }
    }, [isPreviewOpen]);

    return (
        <NodeViewWrapper
            ref={ref}
            id={id}
            className={`editor-block toc-component relative ${isSelected && "editor-block-selected"}`}
            onClick={
                isPreviewOpen
                    ? () => {}
                    : (e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          dispatch(
                              setSelectedBlock({
                                  type: "tocComponent",
                                  props: { id: props.node.attrs.id, attrs: props.node.attrs },
                              })
                          );
                      }
            }
            contentEditable={false}
        >
            <div className="text-xl font-medium leading-[28px] text-[#475467] mb-2">Table of Contents</div>
            <ol
                ref={listRef}
                contentEditable={!isPreviewOpen}
                style={listStyling}
                className={"font-normal text-[#475467] px-4 editor-block-with-text " + sizingStyles}
            >
                <li>Add text here</li>
            </ol>
            {parentEditor && !isPreviewOpen && <RepositioningHandler node_id={id} editor={parentEditor} />}
        </NodeViewWrapper>
    );
});

export default ToCBlock;
