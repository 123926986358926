import { Button } from "@mui/material";
import useImages from "assets/images";
import GetStartedBg from "assets/images/banners/Get-Started.png";
import Obstacle from "assets/images/banners/Get-Started-Obstacles.png";
import { Link } from "react-router-dom";
import RenderImage from "shared/components/ui/RenderImage";
import URL from "shared/constants/navigator";
import AuthHeader from "../../components/AuthHeader";
import { routeWithParams } from "shared/utils/helpers";
import useLogout from "shared/hooks/useLogout";
import * as amplitude from "@amplitude/analytics-browser";
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");

const GetStartedScreen = () => {
    const Images = useImages();

    const { confirmLogout } = useLogout();

    return (
        <div className="h-screen">
            <img src={GetStartedBg} className="" />
            <div
                className="absolute -left-[20%] top-0 w-[140%] h-full"
                style={{
                    background:
                        "radial-gradient(49.04% 49.04% at 50% 2.52%, rgba(255, 255, 255, 0.97) 57.47%, rgba(255, 255, 255, 0.857641) 72.75%, rgba(255, 255, 255, 0) 100%)",
                }}
            ></div>
            <div className="absolute h-screen top-0 left-0 w-full z-20">
                <AuthHeader>
                    <Button
                        className="!h-6 !rounded-[30px] text-[#5E5D5D] !text-[10px] !leading-5 !px-[14px] !min-w-0"
                        color="inherit"
                        variant="outlined"
                        onClick={() =>
                            confirmLogout(
                                undefined,
                                "None of your profile will be created right now. You'll have to create a profile on your next login."
                            )
                        }
                    >
                        Logout
                    </Button>
                </AuthHeader>
                <div className="h-[768px] w-[768px] absolute left-1/2 transform -translate-x-1/2 top-0 z-20">
                    <RenderImage image={Obstacle} containerClassName="" />
                    <div className="absolute left-1/2 transform -translate-x-1/2 top-[123px] w-[440px] text-center">
                        <div className="shadow-[0px_1px_2px_0px_#1018280D] border border-[#EAECF0] rounded-[12px] flex items-center justify-center h-[52px] w-[52px] mx-auto">
                            <Images.LogoMono />
                        </div>
                        <h4 className="mt-5 mb-2 text-[#101828] leading-[38px] text-[30px] font-medium">XSpada</h4>
                        <p className="text-[#475467] text-[18px] leading-7 mb-6">
                            Save hundreds of hours visiting locations in-person by enabling your team with XSpada: The
                            platform built for the exchange of spatial data.
                        </p>
                        <Link
                            to={routeWithParams(URL.CompleteProfile, {
                                token: "personal",
                            })}
                        >
                            <Button
                                className="max-w-[360px] mx1-auto h-11"
                                variant="contained"
                                fullWidth
                                onClick={() => {
                                    amplitude.track("Get Started");
                                }}
                            >
                                Get Started
                            </Button>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default GetStartedScreen;
