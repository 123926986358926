import { StepType } from "@reactour/tour";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

export interface ScanTutorialWizard {
    user_id: number;
    team_id: number;
    watched_step: number;
    total_steps: number;
}

interface TutorialState {
    is_visible: boolean;
    steps: StepType[];
    scan_tutorial_video: {
        open?: boolean;
        showButtons?: boolean;
    };
    scan_tutorial_wizards?: ScanTutorialWizard[];
    web: {
        scan_tutorial_video: boolean;
        my_scans: boolean;
        projects: boolean;
        home?: boolean;
    };
}

const initialState: TutorialState = {
    is_visible: false,
    steps: [],
    scan_tutorial_video: {
        open: false,
        showButtons: true,
    },
    web: {
        scan_tutorial_video: false,
        my_scans: true,
        projects: true,
        home: true,
    },
};

const tutorial = createSlice({
    name: "tutorial",
    initialState,
    reducers: {
        setInitialTutorial: (state, action) => {
            state.web = action.payload;
        },
        setScanTutorialVideo: (state, action: PayloadAction<TutorialState["scan_tutorial_video"]>) => {
            state.scan_tutorial_video = {
                ...state.scan_tutorial_video,
                ...action.payload,
            };
        },
        updateTutorialState: (state, action: PayloadAction<{ key: keyof TutorialState["web"]; value?: boolean }>) => {
            state.web[action.payload.key] = action.payload.value || false;
        },
        showTutorial: (state, action) => {
            state.steps = action.payload;
            state.is_visible = true;
        },
        dismissTutorial: (state) => {
            state.is_visible = false;
        },
        setScanTutorialWizards: (state, action: PayloadAction<ScanTutorialWizard[]>) => {
            state.scan_tutorial_wizards = action.payload;
        },
    },
});

export const {
    setInitialTutorial,
    updateTutorialState,
    setScanTutorialVideo,
    showTutorial,
    dismissTutorial,
    setScanTutorialWizards,
} = tutorial.actions;

export default tutorial.reducer;
