import ArrowRight from "@untitled-ui/icons-react/build/esm/ArrowRight";
import React from "react";
import { useNavigate } from "react-router";
import URL from "shared/constants/navigator";
import ThemeModal from "../ui/ThemeModal";

const ViewerModal: React.FC<{
    viewerLink: string;
    onClose: () => void;
    scan_name?: string;
}> = (props) => {
    const navigate = useNavigate();

    return (
        <ThemeModal
            open={Boolean(props.viewerLink)}
            onClose={props.onClose}
            maxWidth="md"
            classes={{
                paper: "!bg-transparent !shadow-none",
            }}
            sx={{
                "& .MuiDialogContent-root": {
                    padding: 0,
                    "& div": {
                        margin: 0,
                    },
                },
            }}
            contentComponent={
                props.viewerLink && props.scan_name ? (
                    <>
                        <div
                            className="bg-white bg-opacity-70 text-[#344054] text-md font-medium leading-5 rounded-[40px] !h-8 inline-flex items-center !w-auto space-x-3 !mx-auto pr-2 pl-1 border border-primary cursor-pointer"
                            onClick={() => navigate(`${URL.Settings}/${URL.Shop}`)}
                        >
                            <span className="h-6 bg-white bg-opacity-80 font-semibold text-primary px-[10px] py-[2px] rounded-2xl">
                                Upgrade
                            </span>
                            <div className="flex-center space-x-1">
                                <span>Get access to snapshots, animations and full screen with XSpada Studio</span>
                                <ArrowRight color="#7680FF" height={16} width={16} />
                            </div>
                        </div>
                        <iframe
                            src={props.viewerLink}
                            title={props.scan_name}
                            width="900px"
                            style={{
                                height: "calc(100vh - 100px)",
                            }}
                        ></iframe>
                    </>
                ) : (
                    <></>
                )
            }
        />
    );
};

export default ViewerModal;
