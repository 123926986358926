import { Button } from "@mui/material";
import useImages from "assets/images";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import UserProfileCard from "shared/components/UserProfileCard";
import Field from "shared/components/ui/Field";
import RenderImage from "shared/components/ui/RenderImage";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeModal from "shared/components/ui/ThemeModal";
import { TeamMember, useInviteMembersToScanRequestMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";

interface Props {
    id: number | null;
    onCloseModal: () => void;
}

const SRInviteMemberModal = (props: Props) => {
    const Images = useImages();
    const { id } = useParams<{ id: string }>();
    const { user, team } = useAuth();
    const [inviteMember, inviteRes] = useInviteMembersToScanRequestMutation();
    const request = useAppSelector((state) => state.scan_request.selected);

    const form = useForm({
        defaultValues: {
            selected: [],
            invitee: null,
        },
    });

    const invitee = form.watch("invitee");
    const selected = form.watch("selected");

    const onSubmit = (data) => {
        if (props.id && data.selected.length) {
            inviteMember({
                scan_request_id: props.id,
                users: data.selected.map((v: TeamMember) => v.user?.user_id),
            });
        }
    };

    useEffect(() => {
        rtkHandler(inviteRes, {
            successMessage: "Invitation sent successfully",
            onSuccess() {
                props.onCloseModal();
            },
        });
    }, [inviteRes]);

    useEffect(() => {
        if (invitee) {
            form.setValue("invitee", null);
            form.setValue("selected", [...selected, invitee]);
        }
    }, [invitee]);

    if (!team) return <></>;

    const selectedIds = selected.map((v: TeamMember) => v.member_id);

    return (
        <ThemeModal
            icon={<Images.SuccessLayerIcon />}
            open={Boolean(props.id)}
            onCloseModal={props.onCloseModal}
            title="Project Request Published"
            content={
                "This project request has been published throughout the team. You can also choose to invite specific team members to get their attention to this request."
            }
            buttons={[{ children: "Invite", onClick: form.handleSubmit(onSubmit), loading: inviteRes.isLoading }]}
        >
            <ThemeFormProvider form={form} onSubmit={onSubmit} className="text-left">
                <Field
                    formGroup={false}
                    type="auto-complete"
                    name="invitee"
                    label="Invite Team Members"
                    placeholder="Select Team Members"
                    options={
                        team.members
                            .filter(
                                (m) =>
                                    m.user?.user_id !== user.user_id &&
                                    !selectedIds.includes(m.member_id) &&
                                    m.status === "Active"
                            )
                            .filter((m) =>
                                id ? !request?.applications.find((a) => a.user.user_id === m.user?.user_id) : true
                            ) as any
                    }
                    autoCompleteProps={{
                        getOptionLabel: (option: TeamMember) => option.user?.name || "",
                        isOptionEqualToValue: (option: TeamMember, value: TeamMember) => {
                            return option.member_id == value.member_id;
                        },
                        renderOption(props, option: TeamMember) {
                            return (
                                <div className="flex items-center px-[14px] py-[10px] cursor-pointer" {...props}>
                                    <div className="flex items-center space-x-2 text-md font-medium text-[#344054]">
                                        <RenderImage
                                            image={option.user?.profile_image.url || ""}
                                            containerClassName="h-6 w-6 rounded-full"
                                        />
                                        <h3>{option.user?.name}</h3>
                                        <h5 className="text-[#98A2B3] font-normal">
                                            {option.profile?.username && `@${option.profile?.username}`}
                                        </h5>
                                    </div>
                                </div>
                            );
                        },
                    }}
                />
                <Field
                    name="selected"
                    render={({ field: { value } }) => {
                        return value ? (
                            value.map((v: TeamMember) => {
                                return (
                                    <div className="flex items-center justify-between mt-5" key={v.member_id}>
                                        <UserProfileCard
                                            image={v.user?.profile_image.url}
                                            name={v.user?.name}
                                            email={v.user?.email}
                                        />
                                        <Button
                                            color="error"
                                            variant="text"
                                            size="small"
                                            onClick={() =>
                                                form.setValue(
                                                    "selected",
                                                    value.filter((val: TeamMember) => val.member_id != v.member_id)
                                                )
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </div>
                                );
                            })
                        ) : (
                            <></>
                        );
                    }}
                    formGroup={false}
                />
            </ThemeFormProvider>
        </ThemeModal>
    );
};

export default SRInviteMemberModal;
