import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { GetAssetByIdQuery, GetProjectByIdQuery, useGetAssetByIDQuery, useGetProjectByIdQuery } from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";
import { setSelectedProject } from "store/slices/projects";
import { setSelectedAsset } from "store/slices/scans";

const useAssetSingleScreen = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { asset_id } = useEncryptedParams<{ asset_id: string }>();
    const asset = useAppSelector((state) => state.scans.asset);
    const projectRes = useGetProjectByIdQuery(
        { id: asset?.project_id ?? 0 },
        {
            skip: !asset?.project_id,
        }
    );
    const { selected: project } = useAppSelector((state) => state.projects);
    const assetRes = useGetAssetByIDQuery({ asset_id: parseInt(asset_id!) });

    const folder = project?.folders.find((folder) => folder.folder_id === asset?.folder_id ?? 0);

    const loading = assetRes.isLoading || projectRes.isLoading;

    useEffect(() => {
        return () => {
            dispatch(setSelectedProject(null));
        };
    }, []);

    useEffect(() => {
        rtkHandler(assetRes, {
            onSuccess: (res: GetAssetByIdQuery) => {
                dispatch(setSelectedAsset(res.asset));
            },
        });
    }, [assetRes]);

    useEffect(() => {
        rtkHandler(projectRes, {
            onSuccess: (res: GetProjectByIdQuery) => {
                dispatch(setSelectedProject(res.project));
            },
            onError: () => {
                navigate(URL.Media);
            },
        });
    }, [projectRes]);

    return { project, folder, asset, loading };
};

export default useAssetSingleScreen;
