import { Button, Tooltip } from "@mui/material";
import Mail01 from "@untitled-ui/icons-react/build/esm/Mail01";
import Plus from "@untitled-ui/icons-react/build/esm/Plus";
import Trash04 from "@untitled-ui/icons-react/build/esm/Trash04";
import ProjectBreadcrumbs from "screens/Dashboard/Projects/components/ProjectBreadcrumbs";
import ProjectDrawer from "screens/Dashboard/Projects/components/ProjectDrawer";
import UserProfileCard from "shared/components/UserProfileCard";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import Field from "shared/components/ui/Field";
import LayerIcon from "shared/components/ui/LayerIcon";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { MyScanVisibility } from "shared/utils/enums";
import usePublishScan from "./hook";
import Link03 from "@untitled-ui/icons-react/build/esm/Link03";
import Copy01 from "@untitled-ui/icons-react/build/esm/Copy01";
import ArrowUpRight from "@untitled-ui/icons-react/build/esm/ArrowUpRight";
import { copyTextToClipboard } from "shared/utils/helpers";

const PublishScanScreen = () => {
    const hook = usePublishScan();

    return (
        <ProjectDrawer
            header={<ProjectBreadcrumbs drawer={true} />}
            open={hook.open}
            onClose={() => hook.setOpen(false)}
            title="Share Scan"
            description="Select one of the options below to change the visibility of your scan. "
        >
            <ThemeFormProvider form={hook.form} onSubmit={hook.onSubmit} className="text-left">
                <Field
                    name="visibility"
                    label="Visibility"
                    type="select"
                    options={
                        [
                            { label: "Private", value: "private" },
                            { label: "Public", value: "public" },
                            { label: "Email Invite Only", value: "shared" },
                        ].filter((i) => i) as ISelectMenuList[]
                    }
                />
                {hook.visibility === MyScanVisibility.Public && (
                    <Field
                        name="link"
                        label="Share link"
                        inputTextProps={{
                            startAdornment: <Link03 width={20} height={20} color="#667085" />,
                            endAdornment: (
                                <div className="flex items-center space-x-3">
                                    <ThemeIconButton
                                        tooltipTitle="Copy link"
                                        color="default"
                                        onClick={() => {
                                            copyTextToClipboard(hook.link, true);
                                        }}
                                        icon={<Copy01 width={20} height={20} color="#667085" />}
                                    />
                                    <a
                                        href={hook.link}
                                        onClick={(e) => {
                                            e.stopPropagation();
                                        }}
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        <Tooltip title="Open link">
                                            <ArrowUpRight width={20} height={20} color="#7680FF" />
                                        </Tooltip>
                                    </a>
                                </div>
                            ),
                            readOnly: true,
                        }}
                    />
                )}
                {hook.visibility === MyScanVisibility.Shared && (
                    <>
                        {hook.fields.map((field, index) => (
                            <Field
                                key={field.id}
                                containerClassName="!mb-2"
                                name={`emails.${index}`}
                                label={index === 0 ? "Email Address" : ""}
                                placeholder="Enter email address"
                                inputTextProps={{
                                    startAdornment: <Mail01 />,
                                    endAdornment:
                                        index !== 0 ? (
                                            <ThemeIconButton
                                                color="error"
                                                icon={<Trash04 />}
                                                onClick={() => hook.remove(index)}
                                            />
                                        ) : null,
                                    ...field,
                                }}
                            />
                        ))}
                        <Button
                            onClick={() => {
                                hook.append("");
                            }}
                        >
                            <Plus />
                            Add another
                        </Button>
                        {hook.selected?.invitees?.length ? (
                            <div className="flex flex-col space-y-4 my-4">
                                {hook.selected?.invitees?.map((user) => (
                                    <div
                                        className="flex items-center justify-between space-x-3"
                                        key={user.user?.user_id}
                                    >
                                        <div className="w-full">
                                            <UserProfileCard name={""} email={user.email} />
                                        </div>
                                        <div className="flex space-x-3 items-center">
                                            {user.status === "Pending" && (
                                                <span className="text-[#BFBEBE] text-sm font-medium leading-4">
                                                    Invited
                                                </span>
                                            )}
                                            <ConfirmButton
                                                title="Remove invitee"
                                                content={
                                                    <p>
                                                        Are you sure you want to remove{" "}
                                                        <span className="text-primary">{user.email}</span> from the
                                                        invitees list?
                                                    </p>
                                                }
                                                icon={
                                                    <LayerIcon
                                                        color="danger"
                                                        icon={Trash04}
                                                        className="h-[60px] w-[60px]"
                                                        iconClassName="!text-error h-6 w-6"
                                                    />
                                                }
                                                okBtnText="Remove"
                                                okBtnProps={{
                                                    color: "error",
                                                    onClick: () => {
                                                        if (!hook.selected) return;
                                                        hook.removeInvitees({
                                                            data: {
                                                                scan_id: hook.selected.scan_id,
                                                                emails: [user.email],
                                                            },
                                                        });
                                                    },
                                                }}
                                            >
                                                <LoaderButton
                                                    className="!text-sm !font-normal"
                                                    color="error"
                                                    type="button"
                                                    variant="text"
                                                    size="small"
                                                    loadingText="Removing..."
                                                    loading={
                                                        hook.removeInviteesRes.isLoading &&
                                                        hook.clickedEmail === user.email
                                                    }
                                                    onClick={() => {
                                                        hook.setClickedEmail(user.email);
                                                    }}
                                                >
                                                    Remove
                                                </LoaderButton>
                                            </ConfirmButton>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        ) : (
                            <></>
                        )}
                    </>
                )}
                <div className="flex items-center justify-end space-x-4 mt-6">
                    <Button onClick={() => hook.setOpen(false)} variant="outlined" color="inherit">
                        Cancel
                    </Button>
                    <LoaderButton loading={hook.updateRes.isLoading} variant="contained">
                        Save Changes
                    </LoaderButton>
                </div>
            </ThemeFormProvider>
        </ProjectDrawer>
    );
};

export default PublishScanScreen;
