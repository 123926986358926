import { yupResolver } from "@hookform/resolvers/yup";
import Camera01 from "@untitled-ui/icons-react/build/esm/Camera01";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Field from "shared/components/ui/Field";
import LayerIcon from "shared/components/ui/LayerIcon";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import ThemeModal from "shared/components/ui/ThemeModal";
import { useEditFolderMutation, useEditProjectMutation } from "shared/graphql";
import useFileUploader from "shared/hooks/useFileUploader";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setProjectRefetchApi } from "store/slices/projects";

const schema = yup.object().shape({
    thumbnail: yup.mixed().required(),
});

type FormValues = yup.InferType<typeof schema>;

interface Props {
    type: "project" | "folder";
}

const UpdateThumbnail = (props: Props) => {
    const dispatch = useAppDispatch();
    const project = useAppSelector((state) => state.projects.selected);
    const { id, folder_id } = useEncryptedParams<{ id: string; folder_id: string }>();
    const [open, setOpen] = useState(false);
    const [updateProject, updateRes] = useEditProjectMutation();
    const [editFolder, updateFolderRes] = useEditFolderMutation();
    const { uploadFile, loading: uploadLoading } = useFileUploader();

    const isLoading = updateRes.isLoading || updateFolderRes.isLoading || uploadLoading;

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
    });

    useEffect(() => {
        rtkHandler(updateFolderRes, {
            setError: form.setError,
            successMessage: "Folder updated successfully",
            onSuccess() {
                form.reset();
                dispatch(setProjectRefetchApi(true));
                setOpen(false);
            },
        });
    }, [updateFolderRes]);

    useEffect(() => {
        rtkHandler(updateRes, {
            setError: form.setError,
            successMessage: "Project updated successfully",
            onSuccess() {
                form.reset();
                dispatch(setProjectRefetchApi(true));
                setOpen(false);
            },
        });
    }, [updateRes]);

    const onSubmit = async (data: FormValues) => {
        if (!id || !project) return;

        const thumbnailId = new Promise<number | null>((resolve) => {
            if (data.thumbnail && typeof data.thumbnail !== "string") {
                uploadFile(data.thumbnail as File).then((res) => {
                    if (!res) resolve(null);
                    else resolve(res.file_id);
                });
            } else resolve(null);
        });

        if (props.type === "folder" && folder_id) {
            return editFolder({
                project_id: parseInt(id as string),
                folder_id: parseInt(folder_id as string),
                data: {
                    thumbnail_id: await thumbnailId,
                },
            });
        } else {
            return updateProject({
                id: parseInt(id),
                data: {
                    thumbnail_id: await thumbnailId,
                },
            });
        }
    };

    return (
        <>
            <button
                className="bottom absolute bottom-[10px] right-[10px] h-[30px] px-3 flex items-center bg-white bg-opacity-80 rounded-[4px] space-x-[5px]"
                onClick={() => setOpen(true)}
            >
                <Camera01 color="#344054" height={15} width={15} />
                <div className="text-[#344054] text-[10px] font-medium leading-3 grow whitespace-nowrap">
                    Change Thumbnail
                </div>
            </button>
            <ThemeModal
                open={open}
                onClose={() => setOpen(false)}
                icon={<LayerIcon icon={Camera01} className="h-[60px] w-[60px]" iconClassName="text-primary h-6 w-6" />}
                title="Update Thumbnail"
                cancelBtnProps={{
                    onClick: () => setOpen(false),
                }}
                buttons={[
                    {
                        children: "Update",
                        loading: isLoading,
                        onClick: () => form.handleSubmit(onSubmit)(),
                    },
                ]}
            >
                <br />
                <ThemeFormProvider form={form} onSubmit={onSubmit} className="text-left flex flex-col grow">
                    <Field formGroup={false} name="thumbnail" type="image-picker" />
                </ThemeFormProvider>
            </ThemeModal>
        </>
    );
};

export default UpdateThumbnail;
