import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { api } from "shared/graphql/baseApi";
import accounts from "./slices/accounts";
import commonModal from "./slices/commonModal";
import fileUpload from "./slices/fileUpload";
import notifications from "./slices/notifications";
import posts from "./slices/posts";
import projects from "./slices/projects";
import publishable_project from "./slices/publishable_project";
import scan_request from "./slices/scan_requests";
import scans from "./slices/scans";
import team from "./slices/teams";
import token, { setToken } from "./slices/token";
import tutorial from "./slices/tutorial";
import user, { logout } from "./slices/user";
import utils from "./slices/utils";
import verifyUser from "./slices/verifyUser";
// @ts-ignore
import { encryptTransform } from "redux-persist-transform-encrypt";

const key = process.env.REACT_APP_REDUX_PERSIST_SECRET_KEY || "xspada";

const encryptor = encryptTransform({
    secretKey: key,
    onError: function () {
        const { dispatch } = store;
        dispatch(setToken(null));
        dispatch(logout());
    },
});

const rootPersistConfig = {
    key: "xspada.web",
    storage,
    whitelist: ["token"],
    transforms: [encryptor],
};

const userPersistConfig = {
    key: "user",
    storage,
    whitelist: ["profile"],
    transforms: [encryptor],
};

const tutorialPersistConfig = {
    key: "tutorial",
    storage,
    whitelist: ["scan_tutorial_wizards"],
    transforms: [encryptor],
};

const rootReducer = combineReducers({
    [api.reducerPath]: api.reducer,
    token,
    verifyUser,
    user: persistReducer(userPersistConfig, user),
    posts,
    utils,
    notifications,
    projects,
    scans,
    commonModal,
    fileUpload,
    tutorial: persistReducer(tutorialPersistConfig, tutorial),
    accounts,
    team,
    scan_request,
    publishable_project,
});

const persistedReducer = persistReducer(rootPersistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(api.middleware),
});

export const persistor = persistStore(store);

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof rootReducer>;

export * from "./selectors";
