import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useEffect, useState, useMemo } from "react";
import useHasPermission from "shared/components/HasPermission/hook";
import Permissions from "shared/components/HasPermission/Permissions";
import { AssetVisibility } from "shared/graphql";
import { useAppSelector } from "shared/hooks/useRedux";

export interface ITabsBlockProps {
    id: string;
    template: number;
    can_view_snapshots: boolean;
}

const TabsBlock = forwardRef((props: any, ref) => {
    const { id } = props.node.attrs as ITabsBlockProps;

    const selectedTemplate = useAppSelector((state) => state.publishable_project.selectedTemplate);

    const [selectedTab, setSelectedTab] = useState("project");

    const Can_View_Snapshot_Admin = useHasPermission({ permission: Permissions.Can_View_Snapshots, type: "admin" });
    const Can_View_Snapshot_Manager = useHasPermission({ permission: Permissions.Can_View_Snapshots, type: "manager" });
    const Can_View_Snapshot_Member = useHasPermission({ permission: Permissions.Can_View_Snapshots, type: "member" });

    const project = useAppSelector((state) => state.projects.selected);

    const hasFolders = useMemo(() => {
        if (!project) return false;

        for (let i = 0; i < project.folders.length; i++) {
            const folder = project.folders[i];

            for (let j = 0; j < folder.asset.length; j++) {
                const asset = folder.asset[j];

                if (asset.visibility == AssetVisibility.Public) return true;
            }

            for (let j = 0; j < folder.scans.length; j++) {
                const scan = folder.scans[j];

                if (scan.visibility == "public") return true;
            }
        }

        return false;
    }, [project]);

    const hasScans = useMemo(() => {
        if (!project) return false;

        for (let i = 0; i < project.folders.length; i++) {
            const folder = project.folders[i];

            for (let j = 0; j < folder.scans.length; j++) {
                const scan = folder.scans[j];

                if (scan.visibility == "public") return true;
            }
        }

        return false;
    }, [project]);

    const hasImages = useMemo(() => {
        if (!project) return false;

        const canViewSnapshot = Can_View_Snapshot_Admin || Can_View_Snapshot_Manager || Can_View_Snapshot_Member;

        for (let i = 0; i < project.folders.length; i++) {
            const folder = project.folders[i];

            for (let j = 0; j < folder.asset.length; j++) {
                const asset = folder.asset[j];

                if (asset.visibility == AssetVisibility.Public && asset.file.type == "Image") return true;
            }

            if (canViewSnapshot) {
                for (let j = 0; j < folder.scans.length; j++) {
                    const scan = folder.scans[j];

                    if (scan.visibility == "public" && scan.snapshots.length > 0) return true;
                }
            }
        }

        return false;
    }, [project, Can_View_Snapshot_Admin, Can_View_Snapshot_Manager, Can_View_Snapshot_Member]);

    const hasVideos = useMemo(() => {
        if (!project) return false;

        for (let i = 0; i < project.folders.length; i++) {
            const folder = project.folders[i];

            for (let j = 0; j < folder.asset.length; j++) {
                const asset = folder.asset[j];

                if (asset.visibility == AssetVisibility.Public && asset.file.type == "Video") return true;
            }
        }

        return false;
    }, [project]);

    useEffect(() => {
        const canViewSnapshot = Can_View_Snapshot_Admin || Can_View_Snapshot_Manager || Can_View_Snapshot_Member;

        if (canViewSnapshot) {
            let pos = 0;
            let nodeAttrs: any = null;

            props.editor!.view.state.doc.descendants((node, position) => {
                if (node.attrs.id == id) {
                    pos = position;
                    nodeAttrs = node.attrs;
                    return false;
                }
            });

            const transaction = props.editor!.view.state.tr.setNodeMarkup(pos, null, {
                ...nodeAttrs,
                can_view_snapshots: canViewSnapshot,
            });

            props.editor!.view.dispatch(transaction);
        }
    }, [Can_View_Snapshot_Admin, Can_View_Snapshot_Manager, Can_View_Snapshot_Member]);

    useEffect(() => {
        const nodes: any[] = [];
        document
            .querySelectorAll<any>(
                ".react-renderer:not(.node-publishedDateComponent,.node-projectBannerComponent,.node-tabsBlock,.node-projectLinksColumnComponent,.node-scansOrSnapshotsBlockComponent)"
            )
            .forEach((el) => {
                if (!el.querySelector("#mainHeading,#mainSubtitle")) {
                    nodes.push(el);
                }
            });

        document.querySelector(".editor-preview")?.classList.remove("selected-tab-project");
        document.querySelector(".editor-preview")?.classList.remove("selected-tab-media");
        document.querySelector(".editor-preview")?.classList.remove("selected-tab-folders");

        document.querySelector(".editor-preview")?.classList.add("selected-tab-" + selectedTab);

        if (selectedTab == "project") {
            nodes.forEach((el) => el.classList.remove("tab-hidden"));
        } else {
            nodes.forEach((el) => el.classList.add("tab-hidden"));
        }
    }, [selectedTab]);

    useEffect(() => {
        setSelectedTab("project");
    }, [selectedTemplate]);

    return (
        <>
            <NodeViewWrapper ref={ref} id={id} contentEditable={false}>
                <div className={`editor-tabs w-fit mx-auto flex items-center gap-x-6 z-10`}>
                    <div
                        className={
                            "h-[38px] px-2 border-b-2 border-transparent pb-[18px] text-md font-normal text-[#5E5D5D] transition-all cursor-pointer hover:text-[#7680FFAA] -mb-[2px] " +
                            (selectedTab == "project" ? "!border-[#7680FF] !text-[#3F3E3E] !font-medium" : "")
                        }
                        onClick={() => {
                            setSelectedTab("project");
                        }}
                    >
                        Project
                    </div>
                    {(hasScans || hasImages || hasVideos) && (
                        <div
                            className={
                                "h-[38px] px-2 border-b-2 border-transparent pb-[18px] text-md font-normal text-[#5E5D5D] transition-all cursor-pointer hover:text-[#7680FFAA] -mb-[2px] " +
                                (selectedTab == "media" ? "!border-[#7680FF] !text-[#3F3E3E] !font-medium" : "")
                            }
                            onClick={() => {
                                setSelectedTab("media");
                            }}
                        >
                            Media
                        </div>
                    )}
                    {hasFolders && (
                        <div
                            className={
                                "h-[38px] px-2 border-b-2 border-transparent pb-[18px] text-md font-normal text-[#5E5D5D] transition-all cursor-pointer hover:text-[#7680FFAA] -mb-[2px] " +
                                (selectedTab == "folders" ? "!border-[#7680FF] !text-[#3F3E3E] !font-medium" : "")
                            }
                            onClick={() => {
                                setSelectedTab("folders");
                            }}
                        >
                            Folders
                        </div>
                    )}
                </div>
            </NodeViewWrapper>
        </>
    );
});

export default TabsBlock;
