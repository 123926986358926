import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { IButtonProps } from "./Button";
import SpacingHeight02 from "@untitled-ui/icons-react/build/esm/SpacingHeight02";

const ButtonProperties = ({ editor, props }: { editor: Editor | null; props: IButtonProps }) => {
    const { id, color, link, width, justify, size } = props as IButtonProps;
    const { EditorAlignLeftIcon, EditorAlignCenterIcon, EditorAlignRightIcon, PercentageIcon } = useImages();

    const isInit = useRef(false);

    const { control, watch, setValue } = useForm<{
        id: string;
        color: string;
        link: string | null;
        width: number;
        justify: "left" | "center" | "right";
        size: 1 | 2 | 3 | 4 | 5;
    }>({
        defaultValues: {
            id,
            color,
            link: link ? link?.replace("https://", "") : null,
            width,
            justify,
            size,
        },
    });

    const color_val = watch("color");
    const link_val = watch("link");
    const width_val = watch("width");
    const justify_val = watch("justify");
    const size_val = watch("size");

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const onUpdate = () => {
        const data = {
            id,
            color: color_val,
            link: "https://" + link_val,
            width: width_val,
            justify: justify_val,
            size: size_val,
        };

        let pos = 0;
        let nodeAttrs: any = null;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                nodeAttrs = node.attrs;
                return false;
            }
        });

        const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, { ...nodeAttrs, ...data });

        parentEditor!.view.dispatch(transaction);
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [color_val, link_val, width_val, justify_val, size_val]);

    useEffect(() => {
        if (link_val && link_val.includes("https://")) {
            setValue("link", link_val.replace("https://", ""));
        }
    }, [link_val]);

    return (
        <ThemeFormProvider form={{ control, handleSubmit: () => {} } as any} onSubmit={null}>
            <div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Fill</div>
                    <div className="flex gap-x-3">
                        {/* <div className="bg-[#F2F3FD] h-[22px] w-[22px] rounded-[4px] flex items-center justify-center cursor-pointer select-none hover:brightness-90">
                        <EditorPlusPrimaryIcon height={10} width={10} />
                    </div> */}
                        {/* <div className="border-l border-[#EAECF0] pl-3 flex-1 flex flex-wrap gap-x-2 gap-y-2"> */}
                        <div className="flex-1 flex flex-wrap gap-x-2 gap-y-2">
                            {[
                                "#7680FF",
                                "#F9FAFB",
                                "#EFEFEF",
                                "#475467",
                                "#FBD9D9",
                                "#E0FBD9",
                                "#D9E9FB",
                                "#F9D9FB",
                                "#99EAF5",
                                "#FBE4B9",
                                "#70A0D8",
                                "#EB8383",
                            ].map((colorHex) => (
                                <div
                                    key={colorHex}
                                    className={`h-[22px] w-[22px] rounded-full inline-block cursor-pointer ${
                                        colorHex == color_val && " border-2 border-[#7680FF] "
                                    }`}
                                    style={{ backgroundColor: colorHex }}
                                    onClick={() => {
                                        setValue("color", colorHex);
                                    }}
                                ></div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mt-4 mb-2">Link</div>
                    <Field
                        name="link"
                        placeholder=""
                        inputTextProps={{
                            className: "!h-[34px]",
                            startAdornment: <div className="text-sm text-[#667085] -mr-[10px]">https://</div>,
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                                ".MuiOutlinedInput-input": {
                                    color: "#667085",
                                    fontSize: "12px",
                                },
                            },
                        }}
                    />
                </div>
                <div className="w-full pt-4 pb-6 border-b border-[#D0D5DD] px-5">
                    <div className="text-sm text-[#667085] mb-2">Width</div>
                    <Field
                        name="width"
                        placeholder=""
                        inputTextProps={{
                            className: "!h-[34px]",
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                                ".MuiOutlinedInput-input": {
                                    color: "#667085",
                                    fontSize: "12px",
                                },
                            },
                            endAdornment: <PercentageIcon width={10} height={10} />,
                        }}
                    />

                    <div className="text-sm text-[#667085] mt-4 mb-2">Justify</div>
                    <ToggleButtonGroup
                        value={justify_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("justify", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderLeft: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },

                                "&:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"left"} aria-label="Yes">
                            <EditorAlignLeftIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"center"} aria-label="No">
                            <EditorAlignCenterIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"right"} aria-label="No">
                            <EditorAlignRightIcon width={14} height={14} />
                        </ToggleButton>
                    </ToggleButtonGroup>

                    <div className="text-sm text-[#667085] mt-4 mb-2">Size</div>
                    <ToggleButtonGroup
                        value={size_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("size", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderLeft: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },

                                "&:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={1} aria-label="1">
                            <SpacingHeight02 width={14} height={14} color="#667085" />
                        </ToggleButton>
                        <ToggleButton value={2} aria-label="1">
                            <SpacingHeight02 width={12} height={12} color="#667085" />
                        </ToggleButton>
                        <ToggleButton value={3} aria-label="1">
                            <SpacingHeight02 width={10.75} height={10.75} color="#667085" />
                        </ToggleButton>
                        <ToggleButton value={4} aria-label="1">
                            <SpacingHeight02 width={9.5} height={9.5} color="#667085" />
                        </ToggleButton>
                        <ToggleButton value={5} aria-label="1">
                            <SpacingHeight02 width={8} height={8} color="#667085" />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default ButtonProperties;
