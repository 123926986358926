import { BenefitType } from "../partials/PlanCards/utils";

export const BenefitItemName: Record<BenefitType, string> = {
    __typename: "Benefit",
    devices_per_seat: "Devices per Account",
    no_credit_limits: "Unlimited 3D Scan Uploads",
    projects: "Published Projects",
    all_supported_asset_types: "All Supported Asset Types",
    unlimited_3d_scan_uploads: "Unlimited 3D Scan Uploads",
    unlimited_photo_video_uploads: "Unlimited Photo & Video Uploads",
    snapshots: "Snapshots",
    storage: "Included Storage",
    team_seat: "Team Seats",
    video_renders: "Video Renders",
    xspada_studio: "3D Viewer",


    // Customer Support
    discord: "Discord",
    email: "Email",
    phone: "Phone",
    dedicated_representative: "Dedicated Account Specialist",
    private_onboarding: "1-on-1 Team Training",
    white_glove_account_setup: "White Glove Account Setup",

    // Roles
    admins: "Owner",
    guests: "Guests",
    managers: "Admins/Managers",
    members: "Members",

    // Sharing Permissions
    private: "Private",
    public_link: "Public Link",
    email_invite: "Email Invite",
    email_whitelist: "Email Whitelist",
    password_protected: "Password Protected",


    // Add ons
    additional_storage: "Additional Storage",
    api_access: "API Access",
    custom_branded_projects: "Custom Branded Projects",
    custom_capture_pipelines: "Custom Capture Pipelines",
    on_demand_integrations: "On-Demand Integrations",
    professional_training: "Professional Training",
};
