import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { GetProjectByIdQuery, useGetProjectByIdQuery } from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";
import { routeWithParams } from "shared/utils/helpers";
import { setSelectedProject } from "store/slices/projects";
import { resetEditor } from "store/slices/publishable_project";

const useProjectDetail = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useEncryptedParams<{ id: string }>();
    const projectRes = useGetProjectByIdQuery({ id: parseInt(id || "0") });
    const { selected: project } = useAppSelector((state) => state.projects);

    useEffect(() => {
        rtkHandler(projectRes, {
            onSuccess: (res: GetProjectByIdQuery) => {
                dispatch(setSelectedProject(res.project));
            },
            onError: () => {
                navigate(URL.Media);
            },
        });
    }, [projectRes]);

    const onOpenEditor = () => {
        dispatch(resetEditor());
        setTimeout(() => {
            navigate(
                routeWithParams(`${URL.Publishable_Project}`, {
                    id,
                })
            );
        }, 0);
    };

    return { project, projectRes, onOpenEditor };
};

export default useProjectDetail;
