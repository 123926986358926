import moment from "moment";
import { useNavigate } from "react-router-dom";
import RenderImage from "shared/components/ui/RenderImage";
import URL from "shared/constants/navigator";
import useAuth from "shared/hooks/useAuth";
import useProjects from "shared/hooks/useProjects";
import { routeWithParams } from "shared/utils/helpers";
import DashboardCard from "../components/DashboardCard";
import SwiperSlider from "../components/Slider";

const RecentProjects = () => {
    const navigate = useNavigate();
    const { projects } = useProjects({});
    const { isTeamExpired } = useAuth();

    if (projects.length === 0) return <></>;

    return (
        <DashboardCard className="">
            <h4 className="text-[#101828] text-[18px] font-medium leading-7 mb-5">Recent Projects</h4>
            <div className="relative">
                {projects.length === 0 && <div className="text-p text-[14px] leading-6">No projects found</div>}
                <SwiperSlider
                    data={projects}
                    renderItem={(item) => {
                        return (
                            <div
                                key={item.project_id}
                                onClick={() => {
                                    if (!isTeamExpired)
                                        navigate(
                                            routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
                                                id: item.project_id,
                                            })
                                        );
                                }}
                                className={!isTeamExpired ? "cursor-pointer" : ""}
                            >
                                <RenderImage
                                    image={item.thumbnail.url}
                                    containerClassName="h-[200px] w-full rounded-lg mb-[6px]"
                                />
                                <h5 className="text-[#101828] text-md font-medium leading-5 mb-1">{item.name}</h5>
                                <p className="text-primary text-sm leading-5 text-ellipsis overflow-hidden whitespace-nowrap">
                                    {moment(item.created_at).format("DD MMM YYYY")}
                                </p>
                            </div>
                        );
                    }}
                />
            </div>
        </DashboardCard>
    );
};

export default RecentProjects;
