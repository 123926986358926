import { Badge } from "@mui/material";
import DummyNotification from "assets/images/dummy/dummy-notification-thumbnail.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import LoaderButton from "shared/components/ui/LoaderButton";
import useNotificationSidebar from "./hook";
import NotificationSidebarLoader from "./loader";
import "./notification.scss";
import NotificationCard from "./utils/card";

const NotificationSidebar = () => {
    const {
        isLoading,
        acceptLoading,
        handleSidebarClose,
        isOpen,
        Tabs,
        activeTab,
        handleTabClick,
        notifications,
        clickedButton,
        setClickedButton,
    } = useNotificationSidebar();

    return (
        <div className={`notification-sidebar-wrapper ${isOpen ? "active" : ""}`} onClick={handleSidebarClose}>
            <div className="notification-sidebar">
                <div className="header">
                    <h4>Notifications</h4>
                </div>
                <div className="body">
                    {isLoading ? (
                        <NotificationSidebarLoader tabsLength={Tabs.length} />
                    ) : (
                        <>
                            <div className="nav">
                                {Tabs.map((tab, index) => (
                                    <div
                                        key={index}
                                        className={`nav-item ${activeTab === tab.id ? "active" : ""}`}
                                        onClick={() => handleTabClick(tab.id)}
                                    >
                                        <span className="nav-item-title">{tab.title}</span>
                                        <span className="nav-item-count">{tab.badge}</span>
                                    </div>
                                ))}
                            </div>

                            <PerfectScrollbar>
                                {notifications.map((notif, notiIndex) => (
                                    <NotificationCard
                                        key={`notif_${notiIndex}`}
                                        title={notif.notif?.body ?? ""}
                                        description={[""]}
                                        is_read={notif.notif.read}
                                        has_content={!notif.action.isNull}
                                    >
                                        <div className="card-dashed-border bg-white !p-6">
                                            <div className="flex items-center gap-4">
                                                {notif.action.hasDetails && (
                                                    <div className="flex-1 grid grid-cols-[56px_1fr] items-center space-x-4">
                                                        <img
                                                            className="h-[56px] w-[56px] rounded-[8px] object-cover object-center"
                                                            src={notif.notif?.metadata?.thumbnail ?? DummyNotification}
                                                            alt="Notification Image"
                                                        />
                                                        <div className="overflow-hidden text-ellipsis whitespace-nowrap">
                                                            <h5 className="text-p text-[10px] font-medium leading-[14px] text-ellipsis whitespace-nowrap">
                                                                {notif.notif?.metadata?.project_name &&
                                                                    notif.notif.type === "TEAM_ARTICLE_POSTED" &&
                                                                    notif.notif!.metadata!.project_name}

                                                                {["TEAM_INVITATION", "TEAM_REQUEST"].includes(
                                                                    notif.notif.type
                                                                ) && notif.notif!.title}

                                                                {notif.notif?.metadata?.name &&
                                                                    notif.notif.type !== "TEAM_ARTICLE_POSTED" &&
                                                                    notif.notif!.metadata!.name}

                                                                {notif.notif?.metadata?.location &&
                                                                    notif.notif!.metadata!.location.name}
                                                            </h5>
                                                            <h6 className="text-h1 text-lg font-medium leading-6 text-ellipsis whitespace-nowrap">
                                                                {notif.notif?.metadata?.name &&
                                                                    ![
                                                                        "SCAN_SUBMITTED_IN_PROJECT",
                                                                        "TEAM_SCAN_SNAPSHOT_SUBMITTED",
                                                                        "TEAM_SCAN_SNAPSHOT_DELETED",
                                                                        "TEAM_ARTICLE_POSTED",
                                                                    ].includes(notif.notif.type) &&
                                                                    notif.notif!.metadata!.name}

                                                                {["TEAM_INVITATION", "TEAM_REQUEST"].includes(
                                                                    notif.notif.type
                                                                ) && notif.notif!.metadata!.team_name}

                                                                {notif.notif?.metadata?.post_name &&
                                                                    notif.notif!.metadata!.post_name}

                                                                {notif.notif?.metadata?.snapshot_name &&
                                                                    notif.notif!.metadata!.snapshot_name}

                                                                {notif.notif?.metadata?.project_name &&
                                                                    notif.notif!.metadata!.project_name}
                                                            </h6>
                                                            {notif.notif?.metadata?.scan_count !== undefined && (
                                                                <h5 className="text-p text-[10px] font-medium leading-[14px] text-ellipsis whitespace-nowrap">
                                                                    {notif.notif!.metadata!.scan_count} Scan
                                                                    {notif.notif!.metadata!.scan_count > 1
                                                                        ? "s"
                                                                        : ""}{" "}
                                                                    found.
                                                                </h5>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {notif.action.hasDetailMessageOnly && (
                                                    <h6 className="space-x-4 w-full text-h1 text-lg font-medium leading-6">
                                                        {notif.notif?.metadata?.post_title ?? ""}
                                                    </h6>
                                                )}
                                                {notif.action.buttons && (
                                                    <div className="flex items-center space-x-4">
                                                        {notif.action.buttons.map(
                                                            ({ badgeProps, onClick, ...button }, index) => (
                                                                <Badge
                                                                    {...badgeProps}
                                                                    onClick={() => {
                                                                        setClickedButton(`notif_${notiIndex}_${index}`);
                                                                        onClick(notif.notif);
                                                                    }}
                                                                    key={index}
                                                                >
                                                                    <LoaderButton
                                                                        {...button}
                                                                        loading={
                                                                            notif.notif.type === "TEAM_INVITATION" &&
                                                                            clickedButton ===
                                                                                `notif_${notiIndex}_${index}` &&
                                                                            acceptLoading
                                                                        }
                                                                    >
                                                                        {button.label}
                                                                    </LoaderButton>
                                                                </Badge>
                                                            )
                                                        )}
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </NotificationCard>
                                ))}
                                <div className="h-14 w-full"></div>
                            </PerfectScrollbar>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
};

export default NotificationSidebar;
