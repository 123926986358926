import { File as FileType } from "shared/graphql";
import useMultiUploader, { PickerFile } from "./hook";
import DropboxPicker from "./partials/DropboxPicker";
import FileItemContainer from "./partials/FileItemContainer";
import FilePreview from "./partials/FilePreview";
import GooglePicker from "./partials/GooglePicker";
import LocalPicker from "./partials/LocalPicker";
import { twMerge } from "tailwind-merge";

const FileUploader = (props: UppyFileUploaderProps) => {
    const hook = useMultiUploader(props);

    return (
        <div className={twMerge("h-full min-h-[300px] w-full", props.containerClassName)}>
            {hook.serverFile && props.type === "video" ? (
                <FilePreview file={hook.serverFile} onRemove={hook.onRemoveFile} />
            ) : (
                <>
                    {hook.selectedFile ? (
                        <FileItemContainer
                            name={hook.selectedFile.name}
                            size={hook.selectedFile.size}
                            progress={hook.progress}
                            showProgress
                            onRemove={hook.onRemoveFile}
                        />
                    ) : (
                        <div className="flex items-center justify-center flex-col space-y-4 h-full">
                            <div className="flex items-center justify-center space-x-5">
                                {props.plugins.includes("Local") && (
                                    <LocalPicker onSelectFile={hook.setSelectedFile} type={props.type} />
                                )}
                                {props.plugins.includes("Google-Drive") && (
                                    <GooglePicker onFileSelect={hook.setSelectedFile} type={props.type} />
                                )}
                                {props.plugins.includes("Dropbox") && (
                                    <DropboxPicker onFileSelect={hook.setSelectedFile} type={props.type} />
                                )}
                            </div>
                            <div className="text-center text-p">
                                <p className="text-md">
                                    <span className="text-primary">Click any option</span> to upload your file
                                </p>
                            </div>
                        </div>
                    )}
                </>
            )}
        </div>
    );
};

export default FileUploader;

export interface UppyFileUploaderProps {
    plugins: ("Google-Drive" | "Dropbox" | "Local")[];
    type?: "images" | "video" | "zip" | "image";
    onSelectFile?: (file: PickerFile | null) => void;
    onFileUpload?: (serverFile: FileType | null, selectedFile?: PickerFile) => void;
    multiple?: boolean;
    containerClassName?: string;
}

export interface UppyFileUploaderPickerProps extends Partial<UppyFileUploaderProps> {
    onFileSelect?: (file: PickerFile) => void;
}
