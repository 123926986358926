import { MenuItem, MenuItemProps, Tooltip } from "@mui/material";
import { useCallback, useMemo } from "react";

interface IEditorCustomDropDownProps extends MenuItemProps {
    empty_placeholder?: string;
    dropdown_data?: {
        [key: string]: {
            Icon?: any;
            image: string;
            label: string;
            is_public?: boolean;
            value: string;
            is_disabled?: boolean;
            disabled_msg?: string;
        };
    };
}

const EditorCustomDropDown = ({ dropdown_data, empty_placeholder, ...props }: IEditorCustomDropDownProps) => {
    const data = useMemo(() => {
        if (!dropdown_data) return null;

        return dropdown_data![props["data-value"] as string];
    }, [dropdown_data, props]);

    const disabledProps =
        data && data["is_disabled"]
            ? {
                  onClick: (e: any) => {
                      e.preventDefault();
                  },
              }
            : {};

    const MenuItemWrapper = useCallback(
        ({ children }: { children: any }) => {
            if (!dropdown_data) return <>{children}</>;

            if (
                dropdown_data![props["data-value"] as string] &&
                dropdown_data![props["data-value"] as string]["is_disabled"]
            ) {
                return (
                    <Tooltip title={dropdown_data![props["data-value"] as string]["disabled_msg"]}>{children}</Tooltip>
                );
            }

            return <>{children}</>;
        },
        [dropdown_data, props]
    );

    return (
        <MenuItemWrapper>
            <MenuItem
                {...props}
                value={(data && data?.value) ?? ""}
                disabled={props.disabled ?? false}
                {...disabledProps}
                className="!h-fit"
            >
                {dropdown_data && Object.keys(dropdown_data).length == 0 ? (
                    <div className="text-md font-medium text-[#5e5d5d]">{empty_placeholder}</div>
                ) : (
                    <div
                        className={
                            "w-[160px] flex items-center gap-x-3 justify-between "
                            // + (data && data.is_public != undefined && !data["is_public"] && "opacity-50")
                        }
                    >
                        <div className="flex-1 flex items-center gap-x-3">
                            {data?.Icon}
                            {data?.image && (
                                <div className="w-6 relative">
                                    <img src={data?.image ?? ""} className="!w-6 !h-6 rounded-[4px] object-cover" />

                                    {data?.is_public && (
                                        <div className="w-[8px] h-[8px] bg-[#15AA2C] border-[1.13px] border-[#FFFFFF] rounded-full absolute bottom-0 right-0 translate-x-1/2 translate-y-1/2"></div>
                                    )}
                                </div>
                            )}
                            <span className="w-[124px] overflow-hidden whitespace-nowrap text-ellipsis text-md font-medium text-[#101828]">
                                {data?.label ?? ""}
                            </span>
                        </div>
                    </div>
                )}
            </MenuItem>
        </MenuItemWrapper>
    );
};

export default EditorCustomDropDown;
