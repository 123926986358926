import { formatDate } from "shared/utils/helpers";

interface IFolderItemCardProps {
    type: string;
    data: any;
}

const FolderItemCard = (props: IFolderItemCardProps) => {
    const { type, data } = props;

    return (
        <div className="w-[calc((100%/4)-1.15em)]">
            <div className="w-full">
                <div className="w-full h-0 pb-[66.9%] relative bg-[#F2F3FD] rounded-lg">
                    {(type == "scans" && data.input_file.thumbnail) ||
                    (type == "assets" && (data.file.thumbnail ?? data.file.url)) ? (
                        <img
                            src={
                                type == "scans"
                                    ? data.input_file.thumbnail
                                    : type == "assets"
                                    ? data.file.thumbnail ?? data.file.url
                                    : data.image.url
                            }
                            className="w-full h-full object-cover rounded-[7px] absolute top-0 left-0"
                        />
                    ) : (
                        <></>
                    )}
                </div>
                <div className="text-md font-medium text-[#475467] mt-2 break-words">{data.name}</div>
                <div className="flex items-center gap-x-1 text-sm font-medium text-[#98A2B3] mt-1 break-words">
                    {type == "scans" ? "Scan" : type == "assets" && data.file.thumbnail ? "Video" : "Image"} •{" "}
                    {formatDate(data.created_at)}
                </div>
            </div>
        </div>
    );
};

export default FolderItemCard;
