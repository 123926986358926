import { Button } from "@mui/material";
import ArrowUpRight from "@untitled-ui/icons-react/build/esm/ArrowUpRight";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import { Outlet } from "react-router-dom";
import LoadingSnackbar from "shared/components/ui/LoadingSnackbar";
import PageHeader from "shared/components/ui/PageHeader";
import RenderImage from "shared/components/ui/RenderImage";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import AddFolderPopover from "../../components/AddFolderPopover";
import DetailScreenLoader from "../../components/DetailScreenLoader";
import ProjectActionDropdown from "../../components/ProjectActionDropdown";
import ProjectBreadcrumbs from "../../components/ProjectBreadcrumbs";
import ProjectContent from "../../components/ProjectContent";
import ProjectHeaderActions from "../../components/ProjectHeaderActions";
import UpdateThumbnail from "../../components/UpdateThumbnail";
import useProjectDetail from "./hook";
import FolderTabs from "./partials/FolderTabs";

const ProjectDetail = () => {
    const hook = useProjectDetail();

    if (hook.projectRes.isLoading) return <DetailScreenLoader />;

    if (!hook.project) return <></>;

    return (
        <>
            <LoadingSnackbar loading={!hook.projectRes.isLoading && hook.projectRes.isFetching} />
            <PageHeader title={<ProjectBreadcrumbs />}>
                <AddFolderPopover />
            </PageHeader>
            <ProjectContent>
                <div className="bg-white rounded-xl">
                    <div className="flex justify-between items-center mb-5">
                        <div className="flex item-center space-x-3">
                            <h1 className="text-gray-900 text-[30px] font-medium leading-10 whitespace-nowrap">
                                {hook.project.name.length > 40
                                    ? hook.project.name.substring(0, 40).trim() + "..."
                                    : hook.project.name}
                            </h1>
                            {hook.project.visible && (
                                <span className="items-center border border-[#D0D5DD] shadow-sm bg-white self-center flex gap-1 px-1.5 py-0.5 rounded-md border-solid">
                                    <div className="bg-[#15AA2C] w-[6px] h-[6px] rounded-full" />
                                    <div className="text-slate-700 text-center text-xs font-medium leading-5 self-stretch grow whitespace-nowrap">
                                        Public
                                    </div>
                                </span>
                            )}
                            <ProjectActionDropdown>
                                <ThemeIconButton
                                    icon={<ChevronDown color="#344054" height={18} width={18} />}
                                    className="!shadow-none !mt-1"
                                />
                            </ProjectActionDropdown>
                        </div>
                        <div className="flex items-center space-x-4">
                            <ProjectHeaderActions />
                            <Button
                                onClick={hook.onOpenEditor}
                                variant="contained"
                                className="project-btn"
                                endIcon={<ArrowUpRight height={20} width={20} />}
                            >
                                {hook.project?.unpublished_file
                                    ? hook.project.visible
                                        ? "Edit Published Project"
                                        : "Continue Editing"
                                    : "Create Public Project"}
                            </Button>
                        </div>
                    </div>
                    <div className="relative">
                        <RenderImage
                            image={hook.project.thumbnail.url}
                            containerClassName="w-full h-[160px] rounded-md"
                            imgClassName="w-full"
                        />
                        <UpdateThumbnail type="project" />
                    </div>
                </div>
            </ProjectContent>
            <FolderTabs />
            <Outlet />
        </>
    );
};

export default ProjectDetail;
