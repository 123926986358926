import { api as generatedApi } from "../../generated";

const api = generatedApi.enhanceEndpoints({
    endpoints: {
        GetKnowledgeLibrary: {
            providesTags: ["KnowledgeLibrary"],
        },
    },
});

export const { useGetKnowledgeLibraryQuery } = api;
