import { Button } from "@mui/material";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Share07 from "@untitled-ui/icons-react/build/esm/Share07";
import { Link, Outlet } from "react-router-dom";
import AssetActionDropdown from "screens/Dashboard/Projects/components/AssetActionDropdown";
import AssetLoading from "screens/Dashboard/Projects/components/AssetLoading";
import AssetScreenBreadcrumbs from "screens/Dashboard/Projects/components/AssetScreenBreadcrumbs";
import ProjectContent from "screens/Dashboard/Projects/components/ProjectContent";
import PageHeader from "shared/components/ui/PageHeader";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import URL from "shared/constants/navigator";
import useAssetSingleScreen from "./hook";
import AssetDetailCards from "./partials/AssetDetailCards";
import AssetVideoDetail from "./partials/AssetVideoDetail";
import { AssetVisibility } from "shared/graphql";

const AssetSingleScreen = () => {
    const hook = useAssetSingleScreen();

    if (hook.loading) return <AssetLoading />;

    if (!hook.asset) return <></>;

    const asset = hook.asset;

    return (
        <>
            <div className="h-full flex flex-col">
                <PageHeader
                    title={
                        <AssetScreenBreadcrumbs
                            asset_name={asset.name}
                            img={(asset.file.type === "Video" ? asset.file.thumbnail : asset.file.url) || ""}
                            public={asset.visibility === AssetVisibility.Public}
                        />
                    }
                >
                    <Link to={URL.Media_Asset_Publish_Screen}>
                        <Button
                            startIcon={<Share07 height={20} width={20} color="#344054" />}
                            variant="outlined"
                            color="inherit"
                            className={"project-btn"}
                        >
                            Share
                        </Button>
                    </Link>
                </PageHeader>
                <div className="flex-1 flex">
                    <ProjectContent className="w-full">
                        <div className="bg-white rounded-xl flex flex-col h-full">
                            <div className="flex item-center space-x-3 mb-5 border-b pb-4">
                                <h1 className="text-gray-900 text-[30px] font-medium leading-10 whitespace-nowrap">
                                    {hook.asset.name}
                                </h1>
                                {hook.asset.visibility === AssetVisibility.Public && (
                                    <span className="items-center border border-[#D0D5DD] shadow-sm bg-white self-center flex gap-1 px-1.5 py-0.5 rounded-md border-solid">
                                        <div className="bg-[#15AA2C] w-[6px] h-[6px] rounded-full" />
                                        <div className="text-slate-700 text-center text-xs font-medium leading-5 self-stretch grow whitespace-nowrap">
                                            Public
                                        </div>
                                    </span>
                                )}
                                <AssetActionDropdown>
                                    <ThemeIconButton
                                        icon={<ChevronDown color="#344054" height={18} width={18} />}
                                        className="!shadow-none !mt-1"
                                    />
                                </AssetActionDropdown>
                            </div>
                            <div className="grid grid-cols-[minmax(0,1fr),1px,350px] gap-8 grow">
                                <AssetDetailCards />
                                <div className="border-l" />
                                <AssetVideoDetail />
                            </div>
                        </div>
                    </ProjectContent>
                </div>
            </div>
            <Outlet />
        </>
    );
};

export default AssetSingleScreen;
