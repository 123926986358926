import { createApi } from "@reduxjs/toolkit/query/react";
import { graphqlRequestBaseQuery } from "@rtk-query/graphql-request-base-query";
import { RootState } from "store";

// create api slice
export const api = createApi({
    reducerPath: "api",
    baseQuery: graphqlRequestBaseQuery({
        url: process.env.REACT_APP_GRAPHQL_URL,
        prepareHeaders: async (headers, { getState }) => {
            const states = getState() as RootState;
            const token = states.token.current;
            if (token) {
                headers.set("Authorization", `Bearer ${token}`);
            }
            return headers;
        },
        customErrors: ({ response }) => {
            return response?.errors?.length ? response?.errors[0] : {};
        },
    }),
    tagTypes: [
        "UserDetails",
        "Projects",
        "MyScans",
        "ProjectsById",
        "PublicPosts",
        "PersonalPosts",
        "StarredPosts",
        "Notification",
        "Credits",
        "Teams",
        "ProjectArticles",
        "Profiles",
        "VerifyToken",
        "DeveloperApiKey",
        "Snapshots",
        "Subscriptions",
        "ScanningDevices",
        "Users",
        "InviteCode",
        "Invitations",
        "ScanRequests",
        "Assets",
        "Storage",
        "UserPromoCode",
        "KnowledgeLibrary",
    ],
    endpoints: () => ({}),
});
