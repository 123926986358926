import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";

const useHasChatAccess = () => {
    const UserHasChatAccess = useHasPermission(Permissions.Can_Do_Chat);

    let hasChatAccess = false;
    if (UserHasChatAccess) {
        hasChatAccess = true;
    } else {
        hasChatAccess = false;
    }

    return hasChatAccess;
};

export default useHasChatAccess;