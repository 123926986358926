import moment from "moment";
import { Outlet } from "react-router-dom";
import HeaderActionBtns from "shared/components/HeaderActionBtns";
import ScanStatusCard from "shared/components/ScanStatusCard";
import ThemeScreenCard from "shared/components/ui/ThemeScreenCard";
import useSRSingleScanLayout from "./hook";
import StartViewer from "./components/StartViewer";

const SRSingleScanLayout = () => {
    const hook = useSRSingleScanLayout();
    const scan = hook.scan;
    const request = hook.request;

    if (!scan || !request) return <></>;

    return (
        <ThemeScreenCard
            headerComponent={
                <div className="flex items-center justify-between w-full">
                    {scan.model.status ? (
                        <ScanStatusCard status={scan.model.status as any} className="scan_status" />
                    ) : (
                        <></>
                    )}
                    {scan && <StartViewer />}
                </div>
            }
        >
            <div className="flex items-center justify-between mb-4">
                <div>
                    <h4 className="text-p font-medium text-[20px] leading-6 my-1 capitalize">{scan.name}</h4>
                    <p className="text-p opacity-50 text-md leading-4 font-normal">
                        {moment(scan.created_at).format("MMMM DD, YYYY")}
                    </p>
                </div>
                <div className={`flex items-center space-x-8`}>
                    <HeaderActionBtns
                        buttons={hook.menus}
                        mergeLastButton={scan?.model?.status === "Completed"}
                        closeUrl={`/requests/in-progress/detail/${request.scan_request_id}/scans/${scan?.scan_id}`}
                    />
                </div>
            </div>
            <Outlet />
        </ThemeScreenCard>
    );
};

export default SRSingleScanLayout;
