import { createSlice } from "@reduxjs/toolkit";
import { Editor } from "@tiptap/core";

interface Props {
    selectedTemplate: number;
    isSelectingTemplate: boolean;
    isReselectingTemplate: boolean;
    isReselected: boolean;
    isSidebarOpen: boolean;
    isPropertiesSidebarOpen: boolean;
    isShareSidebarOpen: boolean;
    isPreviewOpen: boolean;
    isDownloading: boolean;
    selectedBlock?: {
        type: string;
        props: {
            id: string;
            attrs: any;
        };
    };
    main_editor: Editor | null;
    defaultContent: string | null;
    allSelectedAssets: {
        [key: string]: string;
    };
}

const initialState: Props = {
    selectedTemplate: -1,
    isSelectingTemplate: true,
    isReselectingTemplate: false,
    isReselected: false,
    isSidebarOpen: true,
    isPropertiesSidebarOpen: false,
    isShareSidebarOpen: false,
    isPreviewOpen: false,
    isDownloading: false,
    selectedBlock: undefined,
    main_editor: null,
    defaultContent: null,
    allSelectedAssets: {},
};

const publishableProject = createSlice({
    name: "publishable_project",
    initialState,
    reducers: {
        setSelectedTemplate(state, action) {
            state.selectedTemplate = action.payload;
        },
        showTemplateSelection(state) {
            state.isSelectingTemplate = true;
            state.isReselectingTemplate = true;
            state.isReselected = true;
            state.isPropertiesSidebarOpen = false;
            state.selectedTemplate = -1;
            state.defaultContent = null;
        },
        closeTemplateSelection(state) {
            state.isSelectingTemplate = false;
            state.isReselectingTemplate = false;
            state.isPropertiesSidebarOpen = true;
        },
        toggleSidebar(state) {
            state.isSidebarOpen = !state.isSidebarOpen;
        },
        togglePropertiesSidebar(state) {
            state.isPropertiesSidebarOpen = !state.isPropertiesSidebarOpen;
        },
        togglePreview(state) {
            state.isPropertiesSidebarOpen = state.isPreviewOpen;
            state.isSidebarOpen = state.isPreviewOpen;
            state.isPreviewOpen = !state.isPreviewOpen;
        },
        enablePreview(state) {
            state.isPropertiesSidebarOpen = false;
            state.isSelectingTemplate = false;
            state.isSidebarOpen = false;
            state.isPreviewOpen = true;
        },
        setSelectedBlock(state, action) {
            state.selectedBlock = action.payload;
        },
        setEditor(state, action) {
            state.main_editor = action.payload;
        },
        resetEditor(state) {
            state.selectedTemplate = -1;
            state.isSelectingTemplate = true;
            state.isReselectingTemplate = false;
            state.isReselected = false;
            state.isSidebarOpen = true;
            state.isPropertiesSidebarOpen = false;
            state.isShareSidebarOpen = false;
            state.isPreviewOpen = false;
            state.isDownloading = false;
            state.selectedBlock = undefined;
            state.main_editor = null;
            state.defaultContent = null;
            state.allSelectedAssets = {};
        },
        setDefaultContent(state, action) {
            state.defaultContent = action.payload;
        },
        toggleShareSidebarOpen(state) {
            state.isShareSidebarOpen = !state.isShareSidebarOpen;
        },
        closeShareSidebar(state) {
            state.isShareSidebarOpen = false;
        },
        startDownloading(state) {
            state.isDownloading = true;
        },
        finishDownloading(state) {
            state.isDownloading = false;
        },
        removeAsset(state, action) {
            if (action.payload in state.allSelectedAssets) {
                delete state.allSelectedAssets[action.payload];
            }
        },
        removeAssetsByComponent(state, action) {
            const componentId = action.payload;

            const idsToRemove = Object.keys(state.allSelectedAssets).filter((key) => key.includes(componentId));

            idsToRemove.forEach((id) => {
                if (id in state.allSelectedAssets) {
                    delete state.allSelectedAssets[id];
                }
            });
        },
        addOrRemoveAsset(state, action) {
            const { asset_id, component_id, removal_id } = action.payload;
            if (removal_id && removal_id in state.allSelectedAssets) {
                delete state.allSelectedAssets[removal_id];
            }

            state.allSelectedAssets[`${asset_id}_${component_id}`] = asset_id;
        },
        setAllSelectedAssets(state, action) {
            state.allSelectedAssets = action.payload;
        },
    },
});

export default publishableProject.reducer;

export const {
    setSelectedTemplate,
    showTemplateSelection,
    closeTemplateSelection,
    toggleSidebar,
    togglePropertiesSidebar,
    togglePreview,
    enablePreview,
    setSelectedBlock,
    setEditor,
    resetEditor,
    setDefaultContent,
    toggleShareSidebarOpen,
    closeShareSidebar,
    startDownloading,
    finishDownloading,
    removeAsset,
    removeAssetsByComponent,
    addOrRemoveAsset,
    setAllSelectedAssets,
} = publishableProject.actions;
