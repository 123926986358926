import { Link } from "react-router-dom";
import Field from "shared/components/ui/Field/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import PageLoader from "shared/components/ui/PageLoader";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import URL from "shared/constants/navigator";
import useResetPasswordScreen from "./hook";

const ResetPasswordScreen = () => {
    const { email, isLoading, isVerifyingLink, form, onSubmit } = useResetPasswordScreen();

    return (
        <>
            {isVerifyingLink ? (
                <PageLoader className="!fixed" />
            ) : (
                <div className="max-w-[360px] w-full">
                    <h4 className="text-[#101828] font-medium text-[36px] mb-3">Reset Password</h4>
                    <p className="text-[#667085] text-md font-normal">
                        Please create your new password for your email of <span className="text-primary">{email}</span>.
                    </p>
                    <ThemeFormProvider form={form} className="block mt-8" onSubmit={onSubmit}>
                        <Field
                            label="Password*"
                            placeholder="Enter your Password"
                            name="password"
                            type="password"
                            helperText="Must be at least 8 characters."
                        />
                        <Field
                            label="Confirm Password*"
                            placeholder="Write your password again"
                            name="cPassword"
                            type="password"
                        />
                        <div className="mt-7">
                            <LoaderButton
                                loading={isLoading}
                                loadingText="Reseting Password..."
                                variant="contained"
                                fullWidth
                                type="submit"
                                className="text-lg font-medium !h-11"
                            >
                                Reset Password
                            </LoaderButton>
                        </div>
                        <p className="mt-4 text-md text-center text-[#667085]">
                            Already have an account?{" "}
                            <Link className="text-primary font-medium" to={URL.Login}>
                                Log In
                            </Link>{" "}
                        </p>
                    </ThemeFormProvider>
                </div>
            )}
        </>
    );
};

export default ResetPasswordScreen;
