import { Editor } from "@tiptap/core";

export function updateContentInEditor(editor: Editor, node_id: string, content: any) {
    let pos = 0;
    let nodeAttrs: any = null;

    editor!.view.state.doc.descendants((node, position) => {
        if (node.attrs.id == node_id) {
            pos = position;
            nodeAttrs = node.attrs;
            return false;
        }
    });

    const transaction = editor!.view.state.tr.setNodeMarkup(pos, null, {
        ...nodeAttrs,
        ...content,
    });

    editor!.view.dispatch(transaction);
}
