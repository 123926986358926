import { useFormContext } from "react-hook-form";
import FileUploader from "shared/components/UppyUploader/FileUploader";
import { useEncryptedParams } from "shared/utils/encrypt-url";

const AssetUpload = () => {
    const context = useFormContext();
    const { type } = useEncryptedParams<{ type: string }>();

    return (
        <div className="relative h-full border border-[#D0D5DD] rounded-lg overflow-hidden max-h-[500px]">
            <FileUploader
                plugins={["Local", "Google-Drive", "Dropbox"]}
                type={type === "video" ? "video" : "image"}
                onFileUpload={(serverFile, localFile) => {
                    if (serverFile && localFile) {
                        context.setValue("name", localFile.name.split(".")[0]);
                        context.setValue("file_id", serverFile.file_id);
                    }
                }}
                containerClassName="h-[400px]"
            />
        </div>
    );
};

export default AssetUpload;
