import { Editor } from "@tiptap/core";
import useImages from "assets/images";

interface IRepositioningHandlerProps {
    node_id: string;
    editor: Editor;
}

const RepositioningHandler = ({ node_id, editor }: IRepositioningHandlerProps) => {
    const Images = useImages();

    const moveBlockUpOrDown = (direction: "up" | "down") => {
        let thisNodePos = -1;
        let thisNode: any = null;
        let insertAt = -1;
        let prevNode: any = null;
        // let lastNodePos = -1;
        let nextNode: any = null;
        let isNodeFound = false;

        const excluding = [
            "tabsBlock",
            "scansOrSnapshotsBlockComponent",
            "projectLinksColumnComponent",
            "footerComponent",
        ];

        editor.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == node_id) {
                thisNodePos = position;
                thisNode = node;
            }

            if (!thisNode) {
                prevNode = node;
            }

            if (thisNodePos == -1 && direction == "up") {
                insertAt = position;
            } else if (direction == "down" && insertAt == -1 && isNodeFound) {
                insertAt = position;
                nextNode = node;

                if (thisNode.type.name == "styledText" || thisNode.type.name == "styledTitle") {
                    insertAt -= 1;
                }
            }

            if (thisNodePos != -1) {
                isNodeFound = true;
            }
        });

        if (
            (direction == "up" && (!prevNode || (prevNode && excluding.includes(prevNode.type.name)))) ||
            (direction == "down" && (!nextNode || (nextNode && excluding.includes(nextNode.type.name))))
        ) {
            return false;
        }

        const removeTransaction = editor.view.state.tr.delete(thisNodePos, thisNodePos + thisNode.nodeSize);

        editor.view.dispatch(removeTransaction);

        setTimeout(() => {
            const nodeType = editor.view.state.schema.nodes.placeholder;
            const placeholderId = node_id;
            const transaction = editor.view.state.tr.insert(
                insertAt,
                (nodeType as any).createAndFill({
                    id: placeholderId,
                })
            );

            editor.view.dispatch(transaction);

            let placeholderNode: any = null;
            let placeholderPos = -1;

            editor.view.state.doc.descendants((node, position) => {
                if (node.attrs.id == placeholderId) {
                    placeholderNode = node;
                    placeholderPos = position;
                    return false;
                }
            });
            const placeholderReplaceTransaction = editor.view.state.tr.replaceWith(
                placeholderPos,
                placeholderPos + placeholderNode.nodeSize,
                thisNode
            );

            editor.view.dispatch(placeholderReplaceTransaction);

            const decoration_transaction = editor.view.state.tr.setMeta("updateDecorations", true);
            editor.view.dispatch(decoration_transaction);
        }, 0);
    };

    return (
        <div
            className="editor-block-drag-wrapper absolute w-full h-full z-10 top-0 right-0 pointer-events-none"
            draggable="true"
            contentEditable="false"
            data-drag-handle
            onDragStart={(event: any) => {
                event.stopPropagation();
                event.dataTransfer.effectAllowed = "move";
                const dragData = {
                    type: "move",
                    from: node_id,
                };
                event.dataTransfer.setData("application/x-tiptap-drag", JSON.stringify(dragData));
            }}
        >
            <div className="editor-block-drag-handle h-full flex flex-row items-center justify-center gap-x-2">
                <div
                    className="w-7 h-7 rounded-full bg-white flex items-center justify-center cursor-move pointer-events-auto ml-[2px]"
                    style={{
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.2)",
                    }}
                >
                    <Images.DotsGridIcon height={16} width={16} />
                </div>
                <div className="h-full flex-1 border-[0.5px] border-[#c8ccff66] rounded-lg"></div>
                <div
                    className="w-[30px] flex flex-col items-center justify-center gap-y-2 rounded-full bg-white relative overflow-clip pointer-events-auto"
                    style={{
                        boxShadow: "0px 1px 3px 0px rgba(16, 24, 40, 0.2)",
                    }}
                >
                    <div className="absolute top-0 left-0 h-full w-full flex flex-col">
                        <div
                            className="h-1/2 w-full bg-white hover:cursor-pointer hover:brightness-90"
                            onClick={() => moveBlockUpOrDown("up")}
                        ></div>
                        <div
                            className="h-1/2 w-full bg-white hover:cursor-pointer hover:brightness-90"
                            onClick={() => moveBlockUpOrDown("down")}
                        ></div>
                    </div>
                    <span className="rotate-0 relative z-[1] pointer-events-none select-none">
                        <Images.ArrowChevronUpGrayIcon width={12} height={12} />
                    </span>
                    <span className="rotate-180 relative z-[1] pointer-events-none select-none">
                        <Images.ArrowChevronUpGrayIcon width={12} height={12} />
                    </span>
                </div>
            </div>
        </div>
    );
};

export default RepositioningHandler;
