import { Outlet, useMatch, useNavigate } from "react-router-dom";

import NoDeliveredMessage from "assets/images/extras/Delivered-Empty-Table.png";
import DataTable from "shared/components/ui/DataTable";
import RenderImage from "shared/components/ui/RenderImage";
import ThemeScreenCard from "shared/components/ui/ThemeScreenCard";
import useAuth from "shared/hooks/useAuth";

import SRLists from "../../common/components/SRLists";
import useSRs from "../../common/hooks/useSRs";
import useSRDeliveredScreen from "./hook";

const SRDeliveredScreen = () => {
    const hook = useSRDeliveredScreen();
    const { requests, scanReq } = useSRs();
    const navigate = useNavigate();
    const { user } = useAuth();

    const data = requests
        .filter((req) => req.status === "Delivered")
        .filter(
            (req) =>
                req.requestor.user_id === user?.user_id ||
                req.applications.find((item) => item.user.user_id === user?.user_id && item.status === "Completed")
        );

    const indexPage = useMatch(`/requests/delivered`);

    return (
        <>
            {indexPage ? (
                <ThemeScreenCard
                    headerComponent={
                        <div>
                            <div className="text-[#5E5D5D]">
                                <h1 className="leading-6 text-[20px] mb-2 font-medium">Project Requests</h1>
                                <h3 className="text-md leading-5">Following are all your delivered scan requests.</h3>
                            </div>
                        </div>
                    }
                >
                    <div className="bg-white p-6 rounded-[16px]">
                        <DataTable
                            loading={scanReq.isLoading}
                            headers={hook.Headers}
                            data={data}
                            emptyMessage={
                                <div className="flex flex-col items-center justify-center h-full mt-5">
                                    <RenderImage image={NoDeliveredMessage} containerClassName="h-[118px] w-[152px]" />
                                    <h4 className="text-lg leading-6 text-p my-1">No Delivered Project Requests</h4>
                                    <h5 className="text-md leading-5 text-p text-opacity-50">
                                        Complete requests to show data here
                                    </h5>
                                </div>
                            }
                            onRowClick={(item) => navigate(`/requests/delivered/detail/${item.scan_request_id}`)}
                            hasActionColumn={true}
                            classes={{
                                root: "submission-table",
                                bodyRow: "cursor-pointer",
                            }}
                        />
                    </div>
                </ThemeScreenCard>
            ) : (
                <div className="grid lg:grid-cols-[440px,1fr] grid-cols-[300px,1fr]">
                    <SRLists type="delivered" />
                    <Outlet />
                </div>
            )}
        </>
    );
};

export default SRDeliveredScreen;
