import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import { useCreateFolderMutation, useEditFolderMutation } from "shared/graphql";
import useFileUploader from "shared/hooks/useFileUploader";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";
import { routeWithParams } from "shared/utils/helpers";
import yup from "shared/utils/yup";
import { setProjectRefetchApi } from "store/slices/projects";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");

const schema = yup.object().shape({
    name: yup.string().required(),
    folders: yup.string(),
    thumbnail: yup.mixed().nullable(),
});

type FormValues = yup.InferType<typeof schema>;

const usePostFolderScreen = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id, folder_id } = useEncryptedParams<{ id: string; folder_id: string }>();

    const [createFolder, createRes] = useCreateFolderMutation();
    const [editFolder, updateRes] = useEditFolderMutation();
    const { uploadFile, loading: uploadLoading } = useFileUploader();
    const [open, setOpen] = useState(false);
    const project = useAppSelector((state) => state.projects.selected);

    const folder = project?.folders.find((folder) => folder.folder_id === parseInt(folder_id as string));

    const isLoading = createRes.isLoading || updateRes.isLoading || uploadLoading;

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            thumbnail: null,
        },
    });

    useEffect(() => {
        rtkHandler(createRes, {
            setError: form.setError,
            successMessage: "Folder created successfully",
            onSuccess() {
                amplitude.track('Folder Created', createRes.data);
                form.reset();
                setOpen(false);
            },
        });
    }, [createRes, navigate]);

    useEffect(() => {
        rtkHandler(updateRes, {
            setError: form.setError,
            successMessage: "Folder updated successfully",
            onSuccess() {
                form.reset();
                dispatch(setProjectRefetchApi(true));
                setOpen(false);
            },
        });
    }, [updateRes, navigate]);

    useEffect(() => {
        if (id && folder_id && folder && project) {
            form.setValue("name", folder.name || "");
            form.setValue("thumbnail", folder.thumbnail?.url || "");
        }
    }, [id, folder_id, folder, project]);

    useEffect(() => {
        if (!folder && folder_id) {
            navigate(routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, { id }));
        } else {
            setOpen(true);
        }
    }, []);

    const onSubmit = async (data: FormValues) => {
        const thumbnailId = new Promise<number | null>((resolve) => {
            if (data.thumbnail && typeof data.thumbnail !== "string") {
                uploadFile(data.thumbnail as File).then((res) => {
                    if (!res) resolve(null);
                    else resolve(res.file_id);
                });
            } else resolve(null);
        });

        if (id && folder_id && project) {
            return editFolder({
                project_id: parseInt(id as string),
                folder_id: parseInt(folder_id as string),
                data: {
                    name: data.name,
                    thumbnail_id: await thumbnailId,
                },
            });
        } else {
            createFolder({
                project_id: parseInt(id as string),
                data: {
                    name: data.name,
                    thumbnail_id: await thumbnailId,
                },
            });
        }
    };

    return { form, onSubmit, open, id, folder_id, isLoading, setOpen, project, folder };
};

export default usePostFolderScreen;
