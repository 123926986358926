import { Button } from "@mui/material";
import Calendar from "@untitled-ui/icons-react/build/esm/Calendar";
import Clock from "@untitled-ui/icons-react/build/esm/Clock";
import Coins01 from "@untitled-ui/icons-react/build/esm/Coins01";
import Database01 from "@untitled-ui/icons-react/build/esm/Database01";
import Folder from "@untitled-ui/icons-react/build/esm/Folder";
import LayersTwo01 from "@untitled-ui/icons-react/build/esm/LayersTwo01";
import Users01 from "@untitled-ui/icons-react/build/esm/Users01";
import useImages from "assets/images";
import moment from "moment";
import numeral from "numeral";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import SettingPageCard from "screens/Dashboard/Settings/partials/SettingPageCard";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import LayerIcon from "shared/components/ui/LayerIcon";
import URL from "shared/constants/navigator";
import {
    GetSubscriptionsQuery,
    PlanPeriod,
    api,
    useCancelSubscriptionMutation,
    useGetSubscriptionsQuery,
} from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import { SubscriptionCard } from "../components";

const SubscriptionDetails = () => {
    const Images = useImages();
    const [currentPlan, setCurrentPlan] = useState<GetSubscriptionsQuery["subscriptions"][0]>();

    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { team } = useAuth();

    const subscriptionRes = useGetSubscriptionsQuery();
    const [cancelSubscription, cancelResponse] = useCancelSubscriptionMutation();

    useEffect(() => {
        rtkHandler(subscriptionRes, {
            onSuccess(response: GetSubscriptionsQuery) {
                setCurrentPlan(response.subscriptions.find((plan) => plan.status === "Active"));
            },
        });
    }, [subscriptionRes]);

    useEffect(() => {
        rtkHandler(cancelResponse, {
            successMessage: "Subscription Cancelled Successfully",
            onSuccess() {
                navigate(URL.Dashboard);
                dispatch(api.util.resetApiState());
            },
        });
    }, [cancelResponse]);

    return (
        <SettingPageCard
            title="Active Subscription"
            subTitle="Get Access to more features and upgrade your account."
            rightComponent={
                <div className="space-x-4">
                    <ConfirmButton
                        icon={
                            <LayerIcon
                                icon={Images.CloseIcon}
                                color="danger"
                                className="h-[48px] w-[48px]"
                                iconClassName="!h-5 !w-5 !text-error"
                            />
                        }
                        title="Cancel Subscription"
                        content="Are you sure you want to cancel your subscription? You will lose access to all the premium features."
                        okBtnProps={{
                            color: "error",
                            loading: cancelResponse.isLoading,
                            onClick: () =>
                                currentPlan &&
                                cancelSubscription({
                                    id: currentPlan.subscription_id,
                                }),
                        }}
                    >
                        {currentPlan && (
                            <Button color="error" variant="outlined">
                                Cancel Subscription
                            </Button>
                        )}
                    </ConfirmButton>
                    {/* <Button variant="contained">Manage Subscription</Button> */}
                </div>
            }
        >
            {currentPlan ? (
                <div className="grid grid-cols-6 gap-6">
                    <SubscriptionCard
                        active
                        icon={<LayersTwo01 color="#344054" height={20} width={20} />}
                        title={`${currentPlan?.plan?.name} Plan`}
                        description="Plan Active"
                    />
                    <SubscriptionCard
                        icon={<Users01 color="#344054" height={20} width={20} />}
                        title={numeral(team?.setting.limits.maximum_seats_limit).format("0,0")}
                        description="Seats Allowed"
                    />
                    <SubscriptionCard
                        icon={<Folder color="#344054" height={20} width={20} />}
                        title={
                            (team?.setting.limits.projects_limit.value ?? 0) === -1
                                ? "Unlimited"
                                : `${(team?.setting.limits.projects_limit.value ?? 0).toString()}`
                        }
                        description="Projects Allowed"
                    />
                    <SubscriptionCard
                        icon={<Database01 color="#344054" height={20} width={20} />}
                        title={`${numeral(team?.setting.limits.storage_limit_in_gbs.value).format("0,0")} GB`}
                        description="Storage Allowed"
                    />
                    <SubscriptionCard
                        icon={<Coins01 color="#344054" height={20} width={20} />}
                        title={`${currentPlan.price} USD`}
                        description="Next Billing Amount"
                    />
                    {currentPlan.plan.period !== PlanPeriod.Custom && (
                        <SubscriptionCard
                            icon={<Calendar color="#344054" height={20} width={20} />}
                            title={moment(currentPlan?.created_at)
                                .add(currentPlan.plan.period === PlanPeriod.Monthly ? 1 : 12, "months")
                                .format("MMM DD, YY")}
                            description="Next Billing Date"
                        />
                    )}
                </div>
            ) : (
                <div className="grid grid-cols-5 gap-6">
                    <SubscriptionCard
                        active
                        icon={<LayersTwo01 color="#344054" height={20} width={20} />}
                        title={!team?.trial_period && !currentPlan ? "Free Plan" : `Trial Plan`}
                        description="Plan Active"
                    />
                    <SubscriptionCard
                        icon={<Users01 color="#344054" height={20} width={20} />}
                        title={numeral(team?.setting.limits.maximum_seats_limit).format("0,0")}
                        description="Seats Allowed"
                    />
                    <SubscriptionCard
                        icon={<Folder color="#344054" height={20} width={20} />}
                        title={
                            (team?.setting.limits.projects_limit.value ?? 0) === -1
                                ? "Unlimited"
                                : `${(team?.setting.limits.projects_limit.value ?? 0).toString()}`
                        }
                        description="Projects Allowed"
                    />
                    <SubscriptionCard
                        icon={<Database01 color="#344054" height={20} width={20} />}
                        title={`${numeral(team?.setting.limits.storage_limit_in_gbs.value).format("0,0")} GB`}
                        description="Storage Allowed"
                    />
                    {team?.trial_period ? (
                        <SubscriptionCard
                            icon={<Clock color="#344054" height={20} width={20} />}
                            title={team?.trial_period?.days_remaining + " Days"}
                            description="Trial Period"
                        />
                    ) : (
                        <SubscriptionCard
                            icon={<Images.BoltIcon color="#ffffff" stroke="#344054" height={20} width={20} />}
                            title={
                                (team?.credits.addition_credit_limit ?? 0) === -1
                                    ? "Unlimited"
                                    : `${(team?.credits.addition_credit_limit ?? 0).toString()} ${
                                          team?.credits.addition_period
                                      }`
                            }
                            description="Scan Credit Limit"
                        />
                    )}
                </div>
            )}
        </SettingPageCard>
    );
};

export default SubscriptionDetails;
