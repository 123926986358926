import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useCreateProjectMutation, useEditProjectMutation } from "shared/graphql";
import useFileUploader from "shared/hooks/useFileUploader";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setProjectRefetchApi } from "store/slices/projects";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");

const schema = yup.object().shape({
    name: yup.string().required(),
    folders: yup.string(),
    thumbnail: yup.mixed().nullable(),
});

type FormValues = yup.InferType<typeof schema>;

const useProjectPostScreen = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { id } = useEncryptedParams<{ id: string }>();
    const [createProject, createRes] = useCreateProjectMutation();
    const [updateProject, updateRes] = useEditProjectMutation();
    const { uploadFile, loading: uploadLoading } = useFileUploader();
    const [open, setOpen] = useState(false);
    const project = useAppSelector((state) => state.projects.selected);

    const isLoading = createRes.isLoading || updateRes.isLoading || uploadLoading;

    const form = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            name: "",
            thumbnail: null,
        },
    });

    useEffect(() => {
        rtkHandler(createRes, {
            setError: form.setError,
            successMessage: "Project created successfully",
            onSuccess() {
                amplitude.track('Project Created', createRes.data);
                form.reset();
                setOpen(false);
            },
        });
    }, [createRes, navigate]);

    useEffect(() => {
        rtkHandler(updateRes, {
            setError: form.setError,
            successMessage: "Project updated successfully",
            onSuccess() {
                form.reset();
                dispatch(setProjectRefetchApi(true));
                setOpen(false);
            },
        });
    }, [updateRes, navigate]);

    useEffect(() => {
        if (id && project) {
            form.setValue("name", project.name || "");
            form.setValue("thumbnail", project.thumbnail.url || "");
        }
    }, [id, project]);

    useEffect(() => {
        setOpen(true);
    }, []);

    const onSubmit = async (data: FormValues) => {
        const thumbnailId = new Promise<number | null>((resolve) => {
            if (data.thumbnail && typeof data.thumbnail !== "string") {
                uploadFile(data.thumbnail as File).then((res) => {
                    if (!res) resolve(null);
                    else resolve(res.file_id);
                });
            } else resolve(null);
        });

        if (id && project) {
            return updateProject({
                id: parseInt(id),
                data: {
                    name: data.name,
                    description: "Dummy",
                    thumbnail_id: await thumbnailId,
                },
            });
        } else {
            createProject({
                data: {
                    name: data.name,
                    description: "Dummy",
                    thumbnail_id: await thumbnailId,
                    folders: data.folders && data.folders.length ? [data.folders as unknown as string] : [],
                },
            });
        }
    };

    return { form, onSubmit, open, id, isLoading, setOpen, project };
};

export default useProjectPostScreen;
