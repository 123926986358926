import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";
import { useGetMyScansQuery, useSubmitScanMutation, useUpdateScanMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import useScanCredits from "shared/hooks/useScanCredits";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { ScanDataSetTypeNames } from "shared/utils/enums";
import { rtkHandler } from "shared/utils/handlers";
import { errorToast } from "shared/utils/toast";
import { setTeam } from "store/slices/teams";
import { PostScanFormSteps, SubmitScanFormValues, submitScanSchema } from "./utils/validations";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");

const useScanPostInner = (props: { onSuccess: () => void }) => {
    const { scan_id, type, folder_id } = useEncryptedParams<{
        folder_id: string;
        scan_id: string;
        type: string;
    }>();

    const HasVideoPermission = useHasPermission(Permissions.Can_Submit_Scan_Video);
    const HasZippedPhotosPermission = useHasPermission(Permissions.Can_Submit_Scan_Zipped_Photos);
    const [create, submitRes] = useSubmitScanMutation();
    const [update, updateRes] = useUpdateScanMutation();
    const selectedProject = useAppSelector((state) => state.projects.selected);
    const folder = selectedProject?.folders.find((folder) => folder.folder_id === parseInt(folder_id as string));
    const selectedScan = useAppSelector((state) => state.scans.selected);
    const { data } = useGetMyScansQuery({
        filters: {
            sort: "ascending",
        },
    });
    const { creditsCount } = useScanCredits();
    const { user, team } = useAuth();
    const dispatch = useAppDispatch();

    const [options, setOptions] = useState<MyScanOptions>({
        category: [{ value: "3d Scan", label: "3D Scan" }],
        object: [
            { value: "Object", label: "Object" },
            { value: "Building", label: "Building" },
            { value: "Scene", label: "Scene" },
            { value: "Pose", label: "Pose" },
            { value: "Room", label: "Room" },
        ],
        dataset_type: [
            HasZippedPhotosPermission && { value: "images", label: ScanDataSetTypeNames.images },
            HasVideoPermission && { value: "video", label: ScanDataSetTypeNames.video },
        ].filter(Boolean) as ISelectMenuList[],
        scanning_device: [],
    });

    const form = useForm<SubmitScanFormValues>({
        resolver: yupResolver(submitScanSchema),
        defaultValues: {
            step: scan_id ? PostScanFormSteps.Basic : PostScanFormSteps.Upload,
            name: "",
            existing_scans: data?.scans.map((i) => i.name),
            capture_intent: "",
            dataset_type: type,
            category: "3d Scan",
            location: {
                latitude: "40.7018596",
                longitude: "-74.0411671",
            },
            project: selectedProject?.project_id ?? null,
            folder: folder?.folder_id ?? null,
            method: "gaussian-splatting",
            scan_id,
        },
    });

    useEffect(() => {
        if (scan_id && selectedScan) {
            const currentFolder = selectedProject?.folders.find((f) => f.folder_id === selectedScan.folder_id);
            if (scan_id && selectedScan) {
                form.setValue("name", selectedScan.name);
                selectedProject && form.setValue("project", selectedProject.project_id);
                currentFolder && form.setValue("folder", currentFolder.folder_id);
                form.setValue("category", selectedScan.category);
                form.setValue("capture_intent", selectedScan.capture_intent);
                form.setValue("dataset_type", selectedScan.dataset_type);
                form.setValue("location", {
                    latitude: selectedScan.location.latitude,
                    longitude: selectedScan.location.longitude,
                });
                if (selectedScan.scanning_device) {
                    form.setValue("scanning_device_id", selectedScan.scanning_device.scanning_device_id.toString());
                }
            }
        }
    }, [scan_id, selectedScan, selectedProject]);

    const onReqSuccess = () => {
        if (!scan_id && team && creditsCount !== -1) {
            dispatch(
                setTeam({
                    ...team,
                    credits: {
                        ...team.credits,
                        credits: creditsCount - 1,
                    },
                })
            );
        }
        props.onSuccess();
    };

    useEffect(() => {
        rtkHandler(submitRes, {
            successMessage: "Scan submitted successfully.",
            setError: form.setError,
            onSuccess() {
                if (submitRes && submitRes.data && submitRes.data.submit_scan.dataset_type === 'video') {
                    amplitude.track('Video Scan', submitRes.data);
                } else if (submitRes && submitRes.data && submitRes.data.submit_scan.dataset_type === 'images') {
                    amplitude.track('Zipped Photo Scan', submitRes.data);
                }
             onReqSuccess();
            },
        });
    }, [submitRes]);

    useEffect(() => {
        rtkHandler(updateRes, {
            successMessage: "Scan updated successfully.",
            setError: form.setError,
            onSuccess: onReqSuccess
        });
    }, [updateRes]);

    useEffect(() => {
        if (user?.scanning_devices && user.scanning_devices.length > 0) {
            const scanning_device: ISelectMenuList[] = [];

            user.scanning_devices.forEach((device) => {
                if (!scanning_device.find((i) => i.value === device.category)) {
                    scanning_device.push({
                        value: device.category,
                        label: device.category,
                        isGroup: true,
                    });
                }
                scanning_device.push({
                    value: device.scanning_device_id.toString(),
                    label: device.name,
                });
            });

            setOptions((prev) => ({
                ...prev,
                scanning_device,
            }));
        }
    }, [user]);

    const onSubmit = (data: SubmitScanFormValues) => {
        const folder_id = data.folder ?? undefined;
        const project_id = data.project ?? undefined;
        const scanning_device_id = data.scanning_device_id ? parseInt(data.scanning_device_id) : undefined;

        if (scan_id && selectedScan) {
            update({
                id: parseInt(scan_id || "0"),
                data: {
                    capture_intent: data.capture_intent,
                    category: data.category,
                    folder_id,
                    name: data.name,
                    project_id,
                    scanning_device_id,
                    location: {
                        ...data.location,
                        name: selectedScan.location.name,
                    },
                },
            });
        } else {
            create({
                data: {
                    capture_intent: data.capture_intent!,
                    category: data.category!,
                    folder_id: folder_id,
                    name: data.name!,
                    project_id: project_id!,
                    scanning_device_id,
                    dataset_type: data.dataset_type,
                    input_file_id: data.input_file_id || 0,
                    location: data.location,
                    method: data.method,
                },
            });
        }
    };

    useEffect(() => {
        if (scan_id) return;
        form.reset();
        form.setValue("dataset_type", type!);
    }, [scan_id, type]);

    useEffect(() => {
        if (!scan_id && creditsCount !== -1 && creditsCount <= 0) {
            errorToast("You don't have enough scan credit to post a scan.");
            props.onSuccess();
        }
    }, [scan_id]);

    const onStepChange = async (move: "next" | "back") => {
        if (move === "next" && !(await form.trigger())) return;

        let steps = Object.values(PostScanFormSteps);
        if (scan_id) {
            steps = steps.filter((step) => step === PostScanFormSteps.Basic);
        }
        const currentStep = steps.indexOf(form.getValues("step"));
        const nextStep = steps[currentStep + (move === "next" ? 1 : -1)];
        form.setValue("step", nextStep);
    };

    return {
        form,
        onSubmit,
        submitLoading: submitRes.isLoading,
        updateLoading: updateRes.isLoading,
        options,
        onStepChange,
    };
};

export default useScanPostInner;

export interface MyScanOptions {
    category: ISelectMenuList[];
    object: ISelectMenuList[];
    dataset_type: ISelectMenuList[];
    scanning_device: ISelectMenuList[];
}
