import useImages from "assets/images";
import SettingPageCard from "screens/Dashboard/Settings/partials/SettingPageCard";
import Field from "shared/components/ui/Field/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";

import AddressField from "../../utils/AddressField";
import ProfilePhotoUploadField from "../../utils/ProfilePhotoUploadField";
import useUpdateAccountDetail from "./hook";
import { useSearchParams } from "react-router-dom";

const UpdateAccountDetail = () => {
    const { methods: form, uploadLoading, isLoading, onSubmit, imageRes, isModified } = useUpdateAccountDetail();
    const { EmailIcon, PhoneIcon } = useImages();

    const [searchParams] = useSearchParams();
    const tab = searchParams.get("tab");

    return (
        <ThemeFormProvider form={form} onSubmit={onSubmit}>
            <SettingPageCard
                title="Personal Info"
                subTitle="Update your photo and personal details here."
                collapseAble
                open={tab ? (tab === "profile" ? true : undefined) : undefined}
            >
                <ProfilePhotoUploadField name="profile_image" />
                <Field
                    name="name"
                    label="Name"
                    placeholder="Enter your full name"
                    inlineFlex={true}
                    containerClassName="grid grid-cols-[280px,1fr] gap-9 !space-x-0 mt-4"
                />
                <Field
                    name="email"
                    label="Email"
                    placeholder="Enter your email"
                    inlineFlex={true}
                    containerClassName="grid grid-cols-[280px,1fr] gap-9 !space-x-0"
                    icon={<EmailIcon width={20} height={20} color="#667085" />}
                    inputTextProps={{
                        readOnly: true,
                        // endAdornment: <ChangePrimaryEmail />,
                    }}
                />
                <Field
                    name="phone"
                    label="Phone Number"
                    placeholder="Enter your phone number"
                    inlineFlex={true}
                    containerClassName="grid grid-cols-[280px,1fr] gap-9 !space-x-0"
                    icon={<PhoneIcon width={20} height={20} color="#667085" />}
                    inputTextProps={{
                        readOnly: true,
                        // endAdornment: <ChangePhoneNumber />,
                    }}
                />
                <AddressField />
                <div className="flex items-center justify-end mt-5">
                    <LoaderButton
                        loading={isLoading || uploadLoading || imageRes.isLoading}
                        loadingText={"Updating..."}
                        type="submit"
                        variant="contained"
                        disabled={!isModified || isLoading || uploadLoading}
                    >
                        Update Profile
                    </LoaderButton>
                </div>
            </SettingPageCard>
        </ThemeFormProvider>
    );
};

export default UpdateAccountDetail;
