import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { StudioLink } from "shared/constants/const";
import URL from "shared/constants/navigator";
import {
    useAcceptInvitationMutation,
    useGetNotificationsQuery,
    useMarkNotificationsAsReadMutation,
} from "shared/graphql";
import { GetNotificationsQuery, Notification } from "shared/graphql";
import { api } from "shared/graphql/baseApi";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { INotificationAction, INotificationItem } from "shared/types/notifications";
import { rtkHandler } from "shared/utils/handlers";
import { routeWithParams } from "shared/utils/helpers";
import { setNotificationCount, setNotificationPanel } from "store/slices/notifications";
import { toggleNotificationSidebar } from "store/slices/utils";
import { encryptIdForUrl } from "shared/utils/encrypt-url";

const useNotificationSidebar = () => {
    const [activeTab, setActiveTab] = useState("personal");
    const dispatch = useAppDispatch();
    const isOpen = useAppSelector((state) => state.utils.notificationSidebar);
    const [clickedButton, setClickedButton] = useState<string>("");
    const notRes = useGetNotificationsQuery(undefined, {
        skip: !isOpen,
    });
    const [markAsRead, markRes] = useMarkNotificationsAsReadMutation();

    const navigate = useNavigate();

    const { current: user, profile } = useAppSelector((state) => state.user);

    const [acceptInvitation, acceptRes] = useAcceptInvitationMutation();

    const [notifications, setNotifications] = useState<INotificationItem[]>([]);

    useEffect(() => {
        rtkHandler(acceptRes, {
            successMessage: "Invitation accepted successfully",
            onSuccess() {
                dispatch(api.util.invalidateTags(["Profiles", "UserDetails", "Invitations"]));
            },
        });
    }, [acceptRes]);

    useEffect(() => {
        rtkHandler(notRes, {
            onSuccess: (res: GetNotificationsQuery) => {
                const data = res.notifications;
                const unreadNotifs = data.filter((notif) => !notif.read);
                dispatch(setNotificationCount(unreadNotifs.length));
                setNotifications(getNotificationItems(data as Notification[]));
            },
        });
    }, [notRes]);

    useEffect(() => {
        if (!notRes.isLoading && isOpen) {
            notRes.refetch();
            dispatch(setNotificationPanel(true));
        }
    }, [isOpen, notRes.isLoading]);

    useEffect(() => {
        if (!isOpen && notifications.length) {
            markNotificationAsRead();
            dispatch(setNotificationCount(0));
        }
    }, [isOpen, notifications]);

    const Tabs = useMemo(() => {
        if (profile) {
            return [profile].map((profile) => ({
                id: profile.type,
                title: profile.name,
                badge: notifications.filter((notif) => !notif.notif.read).length,
            }));
        }

        return [
            {
                id: "Starter",
                title: "Starter",
                badge: notifications.filter((notif) => !notif.notif.read).length,
            },
        ];
    }, [notifications]);

    const getNotificationItems = (data: Notification[]): INotificationItem[] => {
        return data.map((notif: Notification) => {
            return {
                notif,
                action: getScanActionsByType(notif),
            };
        });
    };

    const getScanActionsByType = (notification: Notification): INotificationAction => {
        const ViewButton = {
            hasDetails: true,
            // buttons: [
            //     {
            //         label: "View",
            //         variant: "outlined",
            //         color: "inherit",
            //         onClick: () => {
            //             if (notification?.metadata?.scan_id) {
            //                 dispatch(toggleNotificationSidebar());
            //                 navigate(
            //                     routeWithParams(`${URL.My_Scans}/${URL.My_Scans_Detail}`, {
            //                         id: notification!.metadata.scan_id,
            //                     })
            //                 );
            //             }
            //         },
            //     },
            // ],
        };

        const hasDetail = {
            hasDetails: true,
        };

        const ViewOpenViewerBetaButton: INotificationAction = {
            hasDetails: true,
            buttons: [
                // {
                //     label: "View",
                //     variant: "outlined",
                //     color: "inherit",
                //     onClick: () => {
                //         if (notification?.metadata?.scan_id) {
                //             dispatch(toggleNotificationSidebar());
                //             navigate(
                //                 routeWithParams(`${URL.My_Scans}/${URL.My_Scans_Detail}`, {
                //                     id: notification!.metadata.scan_id,
                //                 })
                //             );
                //         }
                //     },
                // },
                {
                    label: "Open Viewer",
                    variant: "contained",
                    onClick: () => {
                        if (notification?.metadata?.scan_id && user) {
                            dispatch(toggleNotificationSidebar());
                            const url = `${StudioLink}?scan_id=${
                                notification?.metadata?.scan_id
                                    ? encryptIdForUrl(notification?.metadata?.scan_id)
                                    : null
                            }&user_id=${user.user_id ? encryptIdForUrl(user.user_id) : null}&user_email=${
                                user.email
                            }&profile_id=${profile?.profile_id ? encryptIdForUrl(profile?.profile_id) : null}`;
                            window.open(url, "_blank");
                        }
                    },
                    badgeProps: {
                        badgeContent: "Beta",
                        color: "success",
                        sx: {
                            "& .MuiBadge-badge": {
                                left: -25,
                                top: 0,
                                right: "unset",
                                position: "absolute",
                                width: 28,
                                height: 13,
                                fontSize: 8,
                            },
                        },
                    },
                },
            ],
        };

        switch (notification.type) {
            // case "SCAN_SUBMITTED_IN_PROJECT":
            //     return {
            //         hasDetails: true,
            //         buttons: [
            //             {
            //                 label: "View Project",
            //                 variant: "outlined",
            //                 color: "inherit",
            //                 onClick: () => {
            //                     if (notification?.metadata?.project_id) {
            //                         dispatch(toggleNotificationSidebar());
            //                         navigate(
            //                             routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
            //                                 id: notification!.metadata.project_id,
            //                             })
            //                         );
            //                     }
            //                 },
            //             },
            //             // {
            //             //     label: "View",
            //             //     variant: "contained",
            //             //     onClick: () => {
            //             //         if (notification?.metadata?.scan_id) {
            //             //             dispatch(toggleNotificationSidebar());
            //             //             navigate(
            //             //                 routeWithParams(`${URL.My_Scans}/${URL.My_Scans_Detail}`, {
            //             //                     id: notification!.metadata.scan_id,
            //             //                 })
            //             //             );
            //             //         }
            //             //     },
            //             // },
            //         ],
            //     };

            case "SCAN_DELETED":
            case "TEAM_SCAN_EDITED":
            case "TEAM_SCAN_DELETED":
            case "TEAM_SCAN_SNAPSHOT_DELETED":
            case "TEAM_PROJECT_DELETED":
                return hasDetail;

            case "SCAN_EDITED":
            case "SCAN_SUBMITTED":
            case "SCAN_PROCESSED":
            case "SCAN_TRAINED":
            case "SCAN_FAILED":
            case "TEAM_SCAN_SUBMITTED":
            case "TEAM_SCAN_PROCESSED":
            case "TEAM_SCAN_TRAINED":
            case "TEAM_SCAN_FAILED":
                return ViewButton;

            case "PASSWORD_CHANGE":
            case "ACCOUNT_DETAILS_CHANGED":
                return {
                    hasDetails: false,
                    buttons: [
                        {
                            label: "Go to Profile Settings",
                            variant: "outlined",
                            color: "inherit",
                            onClick: () => {
                                dispatch(toggleNotificationSidebar());
                                navigate(URL.Settings);
                            },
                        },
                    ],
                };
            case "POST_FLAGGED":
                return {
                    hasDetails: false,
                    hasDetailMessageOnly: true,
                    buttons: [
                        {
                            label: "View Post",
                            variant: "outlined",
                            color: "inherit",
                            onClick: () => {
                                if (notification?.metadata?.post_slug) {
                                    dispatch(toggleNotificationSidebar());
                                    navigate(
                                        routeWithParams(`${URL.Feed}/${URL.Feed_Post}`, {
                                            slug: notification!.metadata.post_slug,
                                        })
                                    );
                                }
                            },
                        },
                    ],
                };

            case "TEAM_PROJECT_EDITED":
            case "PROJECT_CREATE":
            case "TEAM_PROJECT_CREATED":
            case "SCAN_SUBMITTED_IN_PROJECT":
                return {
                    hasDetails: true,
                    buttons: [
                        {
                            label: "View Project",
                            variant: "outlined",
                            color: "inherit",
                            onClick: () => {
                                if (notification?.metadata?.project_id) {
                                    dispatch(toggleNotificationSidebar());
                                    navigate(
                                        routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
                                            id: notification!.metadata.project_id,
                                        })
                                    );
                                }
                            },
                        },
                    ],
                };

            case "SCAN_COMPLETED":
            case "TEAM_SCAN_COMPLETED":
                return ViewOpenViewerBetaButton;
            case "TEAM_ARTICLE_POSTED":
                return {
                    hasDetails: true,
                    buttons: [
                        {
                            label: "View Article",
                            variant: "outlined",
                            color: "inherit",
                            onClick: () => {
                                if (notification?.metadata?.post_slug) {
                                    dispatch(toggleNotificationSidebar());
                                    navigate(
                                        routeWithParams(`${URL.Feed}/${URL.Feed_Post}`, {
                                            slug: notification!.metadata.post_slug,
                                        })
                                    );
                                }
                            },
                        },
                    ],
                };
            case "TEAM_SCAN_SNAPSHOT_SUBMITTED":
                return {
                    hasDetails: true,
                    // buttons: [
                    //     {
                    //         label: "View Snapshot",
                    //         variant: "outlined",
                    //         color: "inherit",
                    //         onClick: () => {
                    //             if (notification?.metadata?.post_slug) {
                    //                 dispatch(toggleNotificationSidebar());
                    //                 navigate(
                    //                     routeWithParams(
                    //                         `${URL.My_Scans}/${URL.My_Scans_Detail}/${URL.My_Scans_Scan_Snapshot}`,
                    //                         {
                    //                             id: notification!.metadata.scan_id,
                    //                             snapshot_id: notification!.metadata.snapshot_id,
                    //                         }
                    //                     )
                    //                 );
                    //             }
                    //         },
                    //     },
                    // ],
                };
            case "TEAM_INVITATION":
                return {
                    hasDetails: true,
                    buttons: [
                        {
                            label: "Accept Invitation",
                            variant: "outlined",
                            color: "inherit",
                            loading: acceptRes.isLoading,
                            loadingText: "Processing...",
                            onClick: (notif: any) => {
                                const inviteCode = notif.metadata.link.split("/").pop();
                                acceptInvitation({ code: inviteCode });
                            },
                        },
                    ],
                };
            case "TEAM_REQUEST":
                return {
                    hasDetails: true,
                    buttons: [
                        {
                            label: "View Request",
                            variant: "outlined",
                            color: "inherit",
                            onClick: () => {
                                dispatch(toggleNotificationSidebar());
                                navigate(`${URL.Settings}/${URL.team}?teamTab=Requested`);
                            },
                        },
                    ],
                };

            case "TEAM_MEMBER_JOINED":
            default:
                return {
                    hasDetails: false,
                    isNull: true,
                };
        }
    };

    const handleTabClick = (id: string) => {
        setActiveTab(id);
    };

    const handleSidebarClose = (e: any) => {
        if (!e.target.closest(".notification-sidebar")) dispatch(toggleNotificationSidebar());
    };

    const markNotificationAsRead = () => {
        const unreadNotifs = notifications
            .filter((notif) => !notif.notif.read)
            .map((notif) => notif.notif.notification_id);

        if (unreadNotifs.length > 0) {
            markAsRead().then(() => {
                dispatch(setNotificationPanel(false));
            });
            dispatch(setNotificationCount(0));
        }
    };

    return {
        isLoading: notRes.isLoading,
        isOpen,
        handleSidebarClose,
        Tabs,
        clickedButton,
        setClickedButton,
        activeTab,
        acceptLoading: acceptRes.isLoading,
        handleTabClick,
        notifications,
        user,
        isMarking: markRes.isLoading,
        markNotificationAsRead,
    };
};

export default useNotificationSidebar;
