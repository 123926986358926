import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import CardLoader from "shared/components/ui/CardLoader";
import LoaderButton from "shared/components/ui/LoaderButton";
import { PlanPeriod, StudioViewerType } from "shared/graphql";
import { twMerge } from "tailwind-merge";
import { CardHeader } from "../../components";
import EnterprisePlanModal from "../EnterprisePlanModal";
import PurchasePlanModal from "../PurchasePlanModal";
import usePlanCards from "./hook";
import { PlanTable, TitleRowItem } from "./utils";

const PlanCards = (props: { interval: PlanPeriod }) => {
    const hook = usePlanCards(props);

    return (
        <>
            {hook.plansRes.isLoading ? (
                <div className="relative h-52">
                    <CardLoader loading={true} />
                </div>
            ) : hook.intervalPlans.length ? (
                <>
                    <PlanTable ref={hook.tableRef}>
                        <TableHead>
                            <TableRow>
                                <TableCell width="25%"></TableCell>
                                {hook.intervalPlans.map((plan) => {
                                    const isCustom = plan.period === "Custom";

                                    return (
                                        <TableCell
                                            key={plan.name + plan.period}
                                            className={twMerge("relative", plan.disabled && "!opacity-50")}
                                            width="25%"
                                        >
                                            {plan.active && (
                                                <div
                                                    className="border-[2px] border-primary w-full absolute top-0 left-0 rounded-[16px] z-10"
                                                    style={{
                                                        height: hook.selectedDivHeight,
                                                        pointerEvents: "none",
                                                    }}
                                                />
                                            )}
                                            <CardHeader
                                                icon={plan.image}
                                                title={plan.name + " plan"}
                                                currency={plan.name === "Enterprise" ? "" : plan.currency}
                                                price={
                                                    isCustom
                                                        ? "Custom"
                                                        : plan.name === "Enterprise"
                                                        ? "Contact Us"
                                                        : plan.price.toString()
                                                }
                                                description={
                                                    plan.name === "Enterprise"
                                                        ? "For larger teams"
                                                        : `per seat billed ${props.interval.toLocaleLowerCase()}`
                                                }
                                                buttonProps={{
                                                    children: plan.btnText,
                                                    disabled: plan.disabled || plan.active,
                                                    color: plan.active ? "inherit" : "primary",
                                                    variant: plan.active ? "outlined" : "contained",
                                                    onClick: () => {
                                                        if (plan.is_enterprise && !hook.enableEnterprisePlan) {
                                                            hook.setEnterpriseModalOpen(true);
                                                            return;
                                                        }
                                                        if (plan.active) return;
                                                        hook.setSelectedPlan(plan);
                                                        hook.setPurchaseModalOpen(true);
                                                    },
                                                }}
                                            />
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {/* Tier Details */}
                            <TitleRowItem title="Tier Details" />
                            <hook.LimitItem type="team_seat" />
                            <hook.LimitItem
                                type="storage"
                                renderCell={(value: any) => {
                                    return (
                                        <>
                                            {value} GB{" "}
                                            {hook.storageIncrease ? (
                                                <span className="text-primary">
                                                    x {hook.storageIncrease} ={" "}
                                                    {parseFloat(value.replace(/\D/g, "")) * hook.storageIncrease} GB
                                                </span>
                                            ) : (
                                                <></>
                                            )}
                                        </>
                                    );
                                }}
                            />
                            <hook.LimitItem type="projects" />
                            <hook.LimitItem type="devices_per_seat" />
                            <hook.LimitItem type="all_supported_asset_types" />
                            <hook.LimitItem type="unlimited_photo_video_uploads" />
                            <hook.LimitItem type="unlimited_3d_scan_uploads" />
                            <hook.LimitItem type="snapshots" />
                            <hook.LimitItem
                                type="xspada_studio"
                                renderCell={(value: StudioViewerType) =>
                                    value === StudioViewerType.Fullscreen ? "Full Screen" : "Pop-Up"
                                }
                            />

                            {/* Sharing Permissions */}
                            <TitleRowItem title="Sharing Permissions" />
                            <hook.SharingPermissionsItem type="private" />
                            <hook.SharingPermissionsItem type="public_link" />
                            <hook.SharingPermissionsItem type="email_invite" />
                            <hook.SharingPermissionsItem type="email_whitelist" />
                            <hook.SharingPermissionsItem type="password_protected" />

                            {/* Customer Support */}
                            <TitleRowItem title="Customer Support" />
                            <hook.CustomerSupportItem type="discord" />
                            <hook.CustomerSupportItem type="email" />
                            <hook.CustomerSupportItem type="phone" />
                            <hook.CustomerSupportItem type="dedicated_representative" />
                            <hook.CustomerSupportItem type="private_onboarding" />
                            <hook.CustomerSupportItem type="white_glove_account_setup" />

                            {/* Roles */}
                            <TitleRowItem title="Roles" />
                            <hook.RolesItem type="admins" />
                            <hook.RolesItem type="members" />
                            <hook.RolesItem type="managers" />
                            <hook.RolesItem type="guests" />

                            {/* Add Ons */}
                            <TitleRowItem title="Add Ons" />
                            <hook.AddOnsItem type="additional_storage" />
                            <hook.AddOnsItem type="professional_training" />
                            <hook.AddOnsItem type="api_access" />
                            <hook.AddOnsItem type="custom_branded_projects" />
                            <hook.AddOnsItem type="on_demand_integrations" />
                            <hook.AddOnsItem type="custom_capture_pipelines" />

                            <TableRow>
                                <TableCell width="25%"></TableCell>
                                {hook.intervalPlans.map((plan) => {
                                    return (
                                        <TableCell
                                            key={plan.name + plan.period}
                                            className={twMerge("relative", plan.disabled && "!opacity-50")}
                                            width="25%"
                                        >
                                            <LoaderButton
                                                disabled={plan.disabled || plan.active}
                                                color={plan.active ? "inherit" : "primary"}
                                                variant={plan.active ? "outlined" : "contained"}
                                                onClick={() => {
                                                    if (plan.active) return;
                                                    hook.setSelectedPlan(plan);
                                                    hook.setPurchaseModalOpen(true);
                                                }}
                                                fullWidth
                                            >
                                                {plan.btnText}
                                            </LoaderButton>
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    </PlanTable>
                    <PurchasePlanModal
                        open={Boolean(hook.purchaseModalOpen)}
                        selectedPlan={hook.selectedPlan}
                        onClose={() => hook.setPurchaseModalOpen(false)}
                    />
                    <EnterprisePlanModal
                        open={hook.enterpriseModalOpen}
                        onClose={() => hook.setEnterpriseModalOpen(false)}
                    />
                </>
            ) : (
                <div className="text-p text-[14px] leading-6 text-center">{"No Plan found"}</div>
            )}
        </>
    );
};

export default PlanCards;
