import { ToggleButton, ToggleButtonGroup } from "@mui/material";
import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import { useEffect, useMemo, useRef } from "react";
import { useForm } from "react-hook-form";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { IQuoteBlockProps } from "./QuoteBlock";

const QuoteBlockProperties = ({ editor, props }: { editor: Editor | null; props: IQuoteBlockProps }) => {
    const { id, quote_str, size, alignment, caption } = props as IQuoteBlockProps;
    const {
        EditorTextAlignLeftIcon,
        EditorTextAlignCenterIcon,
        EditorTextAlignRightIcon,
        EditorTextAlignJustifyIcon,
        EditorTextSizeIcon,
    } = useImages();

    const isInit = useRef(false);

    const { control, watch, setValue } = useForm<{
        id: string;
        size: 1 | 2 | 3 | 4 | 5;
        alignment: "left" | "center" | "right" | "justify";
        caption: "yes" | "no";
    }>({
        defaultValues: {
            id,
            size,
            alignment,
            caption: caption ? "yes" : "no",
        },
    });

    const alignment_val = watch("alignment");
    const size_val = watch("size");
    const caption_val = watch("caption");

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = editor;

        editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    const onUpdate = () => {
        const data = {
            id,
            quote_str,
            size: size_val,
            alignment: alignment_val,
            caption: caption_val == "yes",
        };

        let pos = 0;
        let nodeAttrs: any = null;

        parentEditor!.view.state.doc.descendants((node, position) => {
            if (node.attrs.id == id) {
                pos = position;
                nodeAttrs = node.attrs;
                return false;
            }
        });

        const transaction = parentEditor!.view.state.tr.setNodeMarkup(pos, null, { ...nodeAttrs, ...data });

        parentEditor!.view.dispatch(transaction);
    };

    useEffect(() => {
        if (isInit.current) {
            onUpdate();
        } else {
            isInit.current = true;
        }
    }, [alignment_val, size_val, caption_val]);

    return (
        <ThemeFormProvider form={{ control, handleSubmit: () => {} } as any} onSubmit={null}>
            <div>
                <div className="w-full pt-4 pb-6 border-b px-5">
                    <div className="text-sm text-[#667085] mb-2">Caption</div>
                    <ToggleButtonGroup
                        value={caption_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("caption", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderLeft: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },

                                "&:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"yes"} aria-label="Yes">
                            Yes
                        </ToggleButton>
                        <ToggleButton value={"no"} aria-label="No">
                            No
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className="w-full pt-4 px-5">
                    <div className="text-sm text-[#667085] mb-2">Size</div>
                    <ToggleButtonGroup
                        value={size_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("size", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderLeft: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },

                                "&:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={1} aria-label="1">
                            <EditorTextSizeIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={2} aria-label="1">
                            <EditorTextSizeIcon width={12} height={12} />
                        </ToggleButton>
                        <ToggleButton value={3} aria-label="1">
                            <EditorTextSizeIcon width={10.75} height={10.75} />
                        </ToggleButton>
                        <ToggleButton value={4} aria-label="1">
                            <EditorTextSizeIcon width={9.5} height={9.5} />
                        </ToggleButton>
                        <ToggleButton value={5} aria-label="1">
                            <EditorTextSizeIcon width={8} height={8} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
                <div className="w-full pt-4 px-5">
                    <div className="text-sm text-[#667085] mb-2">Alignment</div>
                    <ToggleButtonGroup
                        value={alignment_val}
                        exclusive
                        fullWidth
                        onChange={(_, newValue) => {
                            if (newValue) {
                                setValue("alignment", newValue);
                            }
                        }}
                        sx={{
                            // Applying styles using the sx prop
                            ".MuiToggleButtonGroup-grouped": {
                                height: 34,
                                flexGrow: 1, // Makes each toggle button grow to fill the available space
                                borderLeft: "1px solid #D0D5DD !important", // Removes the border
                                color: "#667085",
                                fontSize: "12px",
                                textTransform: "capitalize",

                                "&:first-of-type": {
                                    borderTopLeftRadius: "8px",
                                    borderBottomLeftRadius: "8px",
                                },

                                "&:last-of-type": {
                                    borderTopRightRadius: "8px",
                                    borderBottomRightRadius: "8px",
                                },

                                "&.Mui-selected": {
                                    color: "#7680FF",
                                    background: "#F2F3FD",
                                },
                            },
                        }}
                    >
                        <ToggleButton value={"left"} aria-label="Yes">
                            <EditorTextAlignLeftIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"center"} aria-label="No">
                            <EditorTextAlignCenterIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"right"} aria-label="No">
                            <EditorTextAlignRightIcon width={14} height={14} />
                        </ToggleButton>
                        <ToggleButton value={"justify"} aria-label="No">
                            <EditorTextAlignJustifyIcon width={14} height={14} />
                        </ToggleButton>
                    </ToggleButtonGroup>
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default QuoteBlockProperties;
