import { useEffect, useState } from "react";
import {
    Notification,
    useAcceptInvitationMutation,
    useApproveRequestMutation,
    useMarkNotificationsAsReadMutation,
} from "shared/graphql";
import { api } from "shared/graphql/baseApi";
import useGetProfileApi from "shared/hooks/useGetProfileApi";
import { useAppDispatch } from "shared/hooks/useRedux";
import { NotificationTypes } from "shared/types/notifications";
import { rtkHandler } from "shared/utils/handlers";
import LoaderButton from "../../ui/LoaderButton";

type NotificationWithContent = Notification & {
    content?: React.ReactNode | string;
};

interface Props {
    type: NotificationTypes.TEAM_INVITATION | NotificationTypes.TEAM_REQUEST;
    data: NotificationWithContent;
    visible: boolean;
    onSuccess?: () => void;
}

const NotificationStepperCard = (props: Props) => {
    const { type, data, visible } = props;
    const dispatch = useAppDispatch();
    const [profileApiCalled, setProfileApiCalled] = useState(false);
    const [callProfileApi] = useState(false);

    const [markAsRead] = useMarkNotificationsAsReadMutation();
    const [acceptInvitation, acceptRes] = useAcceptInvitationMutation();
    const [approveRequest, approveRes] = useApproveRequestMutation();
    const { profiles, isLoading: profileLoading } = useGetProfileApi({
        skip: !callProfileApi,
    });

    useEffect(() => {
        rtkHandler(acceptRes, {
            onSuccess() {
                dispatch(api.util.invalidateTags(["Profiles", "UserDetails", "Invitations"]));
                props.onSuccess && props.onSuccess();
            },
        });
    }, [acceptRes]);

    useEffect(() => {
        rtkHandler(approveRes, {
            onSuccess() {
                props.onSuccess && props.onSuccess();
            },
        });
    }, [approveRes]);

    useEffect(() => {
        if (profileApiCalled) return;

        if (profiles.length && !profileApiCalled) {
            setProfileApiCalled(true);
            if (profiles[0]?.profile_id) {
                // doSwitchProfile(parseInt(profiles[0]?.profile_id || )).finally(() => {
                //     props.onSuccess && props.onSuccess();
                // });
            }
        }
    }, [profiles, profileApiCalled]);

    useEffect(() => {
        if (visible) {
            markAsRead();
        }
    }, [visible]);

    if (!visible) return null;

    const action = type === NotificationTypes.TEAM_INVITATION ? "Accept Invitation" : "Accept Request";

    return (
        <>
            <div className="flex flex-col space-y-2">
                {/* <img src={DummyImage} className="h-7 w-7 rounded-md border-[#DFDFDF] border" alt="" /> */}
                <h4 className="text-md text-[#344054] leading-[18px] font-bold">{data.title}</h4>
                <p className="text-sm leading-[18px] text-[#667085]">{data.body || data.content || ""}</p>
            </div>
            <div className="text-center">
                <LoaderButton
                    loading={acceptRes.isLoading || profileLoading || approveRes.isLoading}
                    classes={{
                        root: "!bg-primary !bg-opacity-10 text-primary h-[25px] !px-2 py-[2px] text-sm leading-[18px] !rounded-[10px] !my-[14px]",
                    }}
                    onClick={() => {
                        if (data.type === NotificationTypes.TEAM_INVITATION) {
                            const inviteCode = data.metadata.link.split("/").pop();
                            acceptInvitation({ code: inviteCode });
                        } else {
                            approveRequest({
                                member_id: data.metadata.member_id,
                            });
                        }
                    }}
                >
                    {action}
                </LoaderButton>
            </div>
        </>
    );
};

export default NotificationStepperCard;
