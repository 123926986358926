export default [
    {
        title: "Guide to Capturing Optimal Focused Scans",
        description:
            "To achieve the best results with your NeRF (Neural Radiance Fields) scans, follow these essential guidelines when capturing your scenes:",
    },
    {
        title: "1. Select a Key Subject of Focus",
        description:
            "Identify one object or area that will be the central focus of your NeRF scan. This helps ensure that your generated 3D model will accurately capture the details and geometry of the chosen subject.",
    },
    {
        title: "2. Maintain Smooth and Controlled Camera Movements",
        description:
            "Keep your camera movements smooth and controlled during the scan. Avoid sudden jerks or shaky motions that could lead to distorted data. This will contribute to the overall quality of the final NeRF representation.",
    },
    {
        title: "3. Center the Subject in the Frame",
        description:
            "Position the key subject as close to the center of the frame as possible. This framing technique enhances the accuracy of the NeRF reconstruction and facilitates better alignment in the generated 3D model.",
    },
    {
        title: "4. Ensure Consistent and Bright Lighting",
        description:
            "Scanning in an environment with consistent and bright lighting is crucial. Well-lit scenes enable the NeRF tool to capture accurate color and texture information, resulting in a more realistic and detailed final output.",
    },
    {
        title: "5. Close Loop Paths for Accurate Mapping",
        description:
            "When capturing a scene along a path, always ensure you close the loop by returning to the starting location. This step is essential for accurate spatial mapping and alignment within the NeRF model.",
    },
    {
        title: "6. Minimize Subject Movement",
        description:
            "To achieve optimal results, minimize any movement of the key subject during the scan. The less the subject moves, the higher the quality of the final NeRF representation will be. Following these guidelines will significantly enhance the quality of your NeRF scans and contribute to the creation of accurate and visually appealing 3D models.",
    },
];
