import { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import Field from "shared/components/ui/Field";
import { GetProjectsQuery, useGetProjectsQuery } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

const ProjectFolderFields = () => {
    const { watch, setValue, formState } = useFormContext();
    const [projects, setProjects] = useState<GetProjectsQuery["projects"]>([]);

    const projectRes = useGetProjectsQuery({ filters: {} });

    useEffect(() => {
        rtkHandler(projectRes, {
            onSuccess: (data: GetProjectsQuery) => {
                setProjects(data.projects);
            },
        });
    }, [projectRes]);

    const selectedProjectId = watch("project");

    useEffect(() => {
        if (formState.isDirty) {
            setValue("folder", null);
        }
    }, [selectedProjectId]);

    return (
        <div className="grid grid-cols-2 gap-4">
            <Field
                name="project"
                label="Select Project:"
                placeholder="Select your project"
                type="select"
                options={projects.map((project) => ({
                    value: project.project_id.toString(),
                    label: project.name,
                }))}
            />
            <Field
                name="folder"
                label="Select Folder:"
                placeholder="Select your folder"
                type="select"
                options={(projects?.find((p) => p.project_id === parseInt(selectedProjectId))?.folders || []).map(
                    (folder) => ({
                        value: folder.folder_id.toString(),
                        label: folder.name,
                    })
                )}
                selectFieldProps={{
                    disabled: !selectedProjectId,
                }}
            />
        </div>
    );
};

export default ProjectFolderFields;
