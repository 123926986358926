import { Table, TableBody, TableCell, TableRow, styled } from "@mui/material";
import useImages from "assets/images";
import moment from "moment";
import { useEffect, useState } from "react";
import CommonModalActionButton from "shared/components/CommonModal/partials/actionButton";
import StripePaymentCard from "shared/components/StripePaymentCard/StripePaymentCard";
import LayerIcon from "shared/components/ui/LayerIcon";
import ThemeModal from "shared/components/ui/ThemeModal";
import ThemeSwitch from "shared/components/ui/ThemeSwitch";
import {
    CreateSubscriptionMutation,
    GetPlansQuery,
    PlanPeriod,
    useCreateSubscriptionMutation,
    useGetPlansQuery,
    useGetUserPromoCodeQuery,
} from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import NumberInput from "../components/NumberInput";

const PurchasePlanModal = (props: Props) => {
    const Images = useImages();
    const [seats, setSeats] = useState(0);
    const [projects, setProjects] = useState(0);
    const [stripeInfoData, setStripeInfoData] = useState<CreateSubscriptionMutation["create_subscription"]>();
    const [plan, setPlan] = useState(props.selectedPlan);
    const userPromoCode = useGetUserPromoCodeQuery();

    const promoData = userPromoCode.error ? null : userPromoCode.data?.user_promo_code.configuration || null;

    const { data } = useGetPlansQuery();

    const [createSubscription, createRes] = useCreateSubscriptionMutation();

    useEffect(() => {
        rtkHandler(createRes, {
            onSuccess(response: CreateSubscriptionMutation) {
                setStripeInfoData(response.create_subscription);
            },
        });
    }, [createRes]);

    useEffect(() => {
        setPlan(props.selectedPlan);
        setSeats(props.selectedPlan?.limit_changes.minimum_seats_limit.value ?? 0);
        setProjects(0);
        setStripeInfoData(undefined);
    }, [props.selectedPlan]);

    const onPurchasePlan = () => {
        if (!plan?.plan_id) return;

        createSubscription({
            id: plan.plan_id,
            data: {
                seats,
                projects,
                ...(promoData ? { promo_code_additions: promoData } : {}),
            },
        });
    };

    let projectPrice = 0;
    if (data?.plans.project_plan) {
        if (plan?.period === PlanPeriod.Monthly) {
            projectPrice = data?.plans.project_plan.monthly;
        } else {
            projectPrice = data?.plans.project_plan.annual;
        }
    }

    const totalPrice = (plan?.price ?? 1) * seats + projectPrice * projects;

    const period = plan?.period.toLocaleLowerCase();

    return (
        <ThemeModal
            open={props.open}
            onClose={props.onClose}
            icon={<LayerIcon icon={Images.DoubleLayer} iconClassName="h-6 w-6" className="h-12 w-12" />}
            title={`Purchase ${plan?.name || ""} Plan`}
            subTitle={!stripeInfoData ? "Select your options for upgrading your team settings." : ""}
            contentProps={{ className: "text-left" }}
            PaperProps={{ className: "min-w-[450px]" }}
        >
            {stripeInfoData ? (
                <StripePaymentCard
                    price={totalPrice}
                    currency="$"
                    onClose={() => {
                        props.onClose();
                        setStripeInfoData(undefined);
                    }}
                    clientSecret={stripeInfoData.client_secret}
                />
            ) : (
                <>
                    <CustomTable>
                        <TableBody>
                            <TableRow>
                                <TableCell width={"60%"}>Seats</TableCell>
                                <TableCell>
                                    <NumberInput
                                        min={plan?.limit_changes.minimum_seats_limit.value}
                                        max={
                                            plan?.limit_changes.maximum_seats_limit.value === -1
                                                ? undefined
                                                : plan?.limit_changes.maximum_seats_limit.value
                                        }
                                        onChange={setSeats}
                                    />
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell>Extra Projects</TableCell>
                                <TableCell>
                                    <NumberInput onChange={setProjects} />
                                </TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <hr className="!border-[#EAECF0]" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>
                                    Annual Billing <span className="text-primary font-normal">(33% off)</span>
                                </TableCell>
                                <TableCell>
                                    <ThemeSwitch
                                        checked={plan?.period === PlanPeriod.Annually}
                                        onChange={(_, checked) => {
                                            const intervalPlan = data?.plans.plans.find(
                                                (p) =>
                                                    p.name === plan?.name &&
                                                    p.period === (checked ? PlanPeriod.Annually : PlanPeriod.Monthly)
                                            );
                                            setPlan(intervalPlan);
                                        }}
                                    />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Price per seat</TableCell>
                                <TableCell>
                                    ${plan?.price} / {period}
                                </TableCell>
                            </TableRow>
                            {/* <TableRow>
                                <TableCell>Price per extra project</TableCell>
                                <TableCell>
                                    ${projectPrice} / {period}
                                </TableCell>
                            </TableRow> */}
                            <TableRow>
                                <TableCell colSpan={2}>
                                    <hr className="!border-[#EAECF0]" />
                                </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell>Billing Amount</TableCell>
                                <TableCell>
                                    ${totalPrice} / {period}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </CustomTable>
                    <p className="text-sm leading-[18px] text-[#98A2B3] mb-4 mt-3">
                        Your billing date will start from {moment().format("Do MMM, YYYY")} recurring every{" "}
                        {plan?.period === PlanPeriod.Monthly
                            ? "month"
                            : plan?.period === PlanPeriod.Annually
                            ? "year"
                            : ""}{" "}
                        on{" "}
                        {moment()
                            .add(plan?.period === PlanPeriod.Monthly ? 1 : 12, "month")
                            .format("Do MMM, YYYY")}
                    </p>
                </>
            )}
            {!stripeInfoData && (
                <CommonModalActionButton
                    cancelBtnProps={{
                        onClick: props.onClose,
                    }}
                    buttons={[{ children: "Purchase Plan", onClick: onPurchasePlan, loading: createRes.isLoading }]}
                />
            )}
        </ThemeModal>
    );
};

export default PurchasePlanModal;

interface Props {
    open: boolean;
    onClose: () => void;
    selectedPlan?: GetPlansQuery["plans"]["plans"][0];
}

const CustomTable = styled(Table)({
    "& .MuiTableCell-root": {
        paddingBlock: "8px !important",
        paddingInline: "0px !important",
        border: "none",
        lineHeight: "24px",
        fontSize: "16px",

        "&:first-child": {
            color: "#101828",
            fontWeight: 500,
        },

        "&:last-child": {
            textAlign: "right",
            color: "#475467",
        },
    },
});
