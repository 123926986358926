import { Backdrop, Badge, Divider, Fade, List, ListItem, Paper, Popper, styled } from "@mui/material";
import HelpCircle from "@untitled-ui/icons-react/build/esm/HelpCircle";
import Inbox01 from "@untitled-ui/icons-react/build/esm/Inbox01";
import LogOut03 from "@untitled-ui/icons-react/build/esm/LogOut03";
import PlusCircle from "@untitled-ui/icons-react/build/esm/PlusCircle";
import UserPlus01 from "@untitled-ui/icons-react/build/esm/UserPlus01";
import useImages from "assets/images";
import CreateTeamAccountModal from "screens/Dashboard/Settings/screens/Accounts/partials/PostModal/partials/CreateTeamModal";
import JoinTeamModalContent from "screens/Dashboard/Settings/screens/Accounts/partials/PostModal/partials/JoinTeamModalContent";
import PageLoader from "shared/components/ui/PageLoader";
import RenderImage from "shared/components/ui/RenderImage";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import ThemeModal from "shared/components/ui/ThemeModal";
import useLogout from "shared/hooks/useLogout";
import { useAppDispatch } from "shared/hooks/useRedux";
import { toggleCustomerSupportModal } from "store/slices/utils";
import { twMerge } from "tailwind-merge";
import useUserDropdown from "./hook";

const UserDropdown = () => {
    const hook = useUserDropdown();
    const { confirmLogout } = useLogout();
    const Images = useImages();
    const dispatch = useAppDispatch();

    const open = Boolean(hook.anchorEl);
    const id = open ? "simple-popover" : undefined;

    return (
        <>
            {(hook.switchLoading || hook.acceptRes.isLoading || hook.approveRes.isLoading) && (
                <PageLoader className="fixed top-0 left-0 w-full h-full" />
            )}
            <div>
                <div
                    className="flex items-center relative z-30 !bg-white"
                    onClick={hook.anchorEl ? hook.handleClose : hook.handleClick}
                >
                    <div className={"flex items-center space-x-1 ml-2"}>
                        <Images.ArrowChevronRight
                            className={hook.anchorEl ? "-rotate-90" : "rotate-90"}
                            color="#667085"
                            height={16}
                            width={16}
                        />
                        <RenderImage
                            image={hook.user?.profile_image?.url ?? ""}
                            containerClassName="h-[40px] w-[40px] rounded-full"
                        />
                    </div>
                </div>
                <Backdrop
                    className="!fixed !top-0 !left-0 !w-full !h-full !z-20"
                    open={open}
                    onClick={hook.handleClose}
                    sx={{ zIndex: 10, backgroundColor: "rgba(255, 255, 255, 0.7)", backdropFilter: "blur(4px)" }}
                />
                <Popper id={id} open={open} anchorEl={hook.anchorEl} placement="bottom" className="!-left-[38px] z-20">
                    <Paper className="mt-2 !pt-2 w-[220px] !shadow-[0px_12px_16px_-4px_#10182814] border border-[#EAECF0] rounded-lg">
                        <Fade in={hook.showMainMenu} style={{ display: hook.showMainMenu ? "block" : "none" }}>
                            <CustomList>
                                <MenuItem
                                    icon={Inbox01}
                                    label="Invitations"
                                    badge={hook.invitations.length}
                                    rightArrow
                                    onClick={() => hook.showMenu("invitations")}
                                />
                                <MenuItem
                                    icon={PlusCircle}
                                    label="New Team"
                                    onClick={() => hook.trggerOpenModal("new")}
                                />
                                <MenuItem
                                    icon={UserPlus01}
                                    label="Join Team"
                                    onClick={() => hook.trggerOpenModal("join")}
                                />
                                <Divider />
                                <MenuItem
                                    icon={HelpCircle}
                                    label="Customer Support"
                                    onClick={() => {
                                        hook.handleClose();
                                        dispatch(toggleCustomerSupportModal());
                                    }}
                                />
                                <Divider />
                                <MenuItem icon={LogOut03} label="Logout" onClick={() => confirmLogout()} />
                            </CustomList>
                        </Fade>
                        <Fade in={hook.showInvitations} style={{ display: hook.showInvitations ? "block" : "none" }}>
                            <div>
                                <CustomList>
                                    <MenuItem
                                        isHeader
                                        icon={Images.ArrowChevronLeft}
                                        label="Invitations"
                                        onClick={() => hook.showMenu("main")}
                                    />
                                    {hook.invitations.length ? (
                                        hook.invitations.map((invitation) => {
                                            return (
                                                <MenuItem
                                                    key={invitation.team_id}
                                                    img={invitation.logo.url ?? ""}
                                                    label={invitation.name}
                                                    rightArrow={
                                                        <ThemeIconButton
                                                            tooltipTitle="Accept Invitation"
                                                            tooltipPosition="top"
                                                            className="!shadow-none"
                                                            onClick={() =>
                                                                hook.doActOnInvitation(invitation.invite_code, "accept")
                                                            }
                                                            icon={
                                                                <Images.CircleCheck
                                                                    height={16}
                                                                    width={16}
                                                                    color="#7680FF"
                                                                />
                                                            }
                                                        />
                                                    }
                                                />
                                            );
                                        })
                                    ) : (
                                        <p className="text-p text-sm px-5 my-3">No invitations found</p>
                                    )}
                                </CustomList>
                            </div>
                        </Fade>
                    </Paper>
                </Popper>
            </div>
            <ThemeModal
                icon={<Images.TeamLayerIcon height={50} width={50} />}
                open={hook.openModal === "new"}
                onClose={() => hook.setOpenModal("")}
                title="Create a New Team"
                content="With a new team, you can invite people and collaborate on your scans."
                contentComponent={<CreateTeamAccountModal onClose={() => hook.setOpenModal("")} />}
            />
            <ThemeModal
                icon={<Images.JoinTeamLayerIcon height={50} width={50} />}
                open={hook.openModal === "join"}
                onClose={() => hook.setOpenModal("")}
                title="Join Another Team"
                content="You can join another team to collaborate on their scans using team invite codes."
                contentComponent={
                    <JoinTeamModalContent onClose={() => hook.setOpenModal("")} onSuccess={hook.onSuccessJoinTeam} />
                }
            />
        </>
    );
};

export default UserDropdown;

const CustomList = styled(List)(() => ({
    padding: 0,
    minHeight: "200px",

    ".MuiListItem-root": {
        padding: "3px 12px 3px 0",
        fontSize: "14px",
        fontWeight: "500",
        lineHeight: "20px",
        color: "#344054",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",

        "> div": {
            display: "flex",
            alignItems: "center",
            paddingLeft: "5px",
        },

        "&:not(.header)": {
            cursor: "pointer",

            "&:hover": {
                backgroundColor: "#F9FAFB",
            },
        },
    },

    ".MuiDivider-root": {
        margin: "5px 0",
        borderColor: "#EAECF0",
    },
}));

type Props = {
    label: string;
    icon?: any;
    img?: string;
    badge?: number;
    onClick?: (event: any) => void;
    rightArrow?: boolean | React.ReactNode;
    hoverRightArrow?: boolean;
    isHeader?: boolean;
    className?: string;
};

const MenuItem = (props: Props) => {
    const Icon = props.icon;
    const Images = useImages();

    return (
        <ListItem
            onClick={props.onClick}
            className={twMerge(
                "group min-h-[38px] cursor-pointer",
                props.isHeader ? "!text-primary border-b !pb-[10px] mb-1 header" : "!cursor-default",
                props.className
            )}
        >
            <div className="whitespace-nowrap flex-1 flex text-ellipsis overflow-hidden">
                <div
                    onClick={props.onClick}
                    className={twMerge(
                        "!h-[30px] !min-w-[30px] !w-[30px] flex items-center justify-center whitespace-nowrap overflow-hidden",
                        props.onClick && "cursor-pointer"
                    )}
                >
                    {props.icon && <Icon height={16} width={16} color="#667085" />}
                    {props.img && (
                        <RenderImage
                            image={props.img}
                            containerClassName="h-[20px] w-[20px] mr-1"
                            imgClassName="rounded-full"
                        />
                    )}
                </div>
                <span className="block whitespace-nowrap w-[calc(100%-40px)] text-ellipsis overflow-hidden">
                    {props.label}{" "}
                </span>
                {props.badge ? (
                    <div className="!min-w-[30px]">
                        <Badge
                            badgeContent={props.badge}
                            color="primary"
                            className="ml-4"
                            classes={{
                                badge: "!w-6 !h-5 !text-sm",
                            }}
                        />
                    </div>
                ) : (
                    <></>
                )}
            </div>
            <div className={twMerge(props.hoverRightArrow && "group-hover:!flex !hidden")}>
                {typeof props.rightArrow === "boolean" && props.rightArrow && (
                    <Images.ArrowChevronRight height={16} width={16} color="#4a4a4a" />
                )}
                {typeof props.rightArrow !== "boolean" && props.rightArrow}
            </div>
        </ListItem>
    );
};
