import { PropsWithChildren } from "react";
import { twMerge } from "tailwind-merge";

const ProjectContent = (
    props: PropsWithChildren & {
        className?: string;
    }
) => {
    return <div className={twMerge("p-6", props.className)}>{props.children}</div>;
};

export default ProjectContent;
