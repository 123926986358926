import useImages from "assets/images";
import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";
import HeaderActionBtns, { IHeaderMenuButtonProps } from "shared/components/HeaderActionBtns";
import TooltipTitles from "shared/constants/disabledButtonTooltipTitles";
import URL from "shared/constants/navigator";
import { useAppSelector } from "shared/hooks/useRedux";
import { encryptIdForUrl } from "shared/utils/encrypt-url";
import { MyScanVisibility } from "shared/utils/enums";
import { copyTextToClipboard, downloadFile } from "shared/utils/helpers";
import { successToast } from "shared/utils/toast";

const ScanActions = () => {
    const scan = useAppSelector((state) => state.scans.selected);
    const Images = useImages();

    const { DefaultScanTooltipTitle, ScanNotCompletedTooltipTitle, TeamMemberNoAccessTooltipTitle } = TooltipTitles;

    const Can_Use_Embeded_Scan = useHasPermission(Permissions.Can_Use_Embeded_Scan);
    const Can_Download_Scan_Model = useHasPermission(Permissions.Can_Download_Scan_Model);

    const Can_Team_Use_Embeded_Scan = useHasPermission({
        permission: Permissions.Can_Use_Embeded_Scan,
        type: "admin",
    });
    const Can_Team_Share_Scan_In_Public = useHasPermission({
        permission: Permissions.Can_Share_Scan_In_Public,
        type: "admin",
    });
    const Can_Team_Download_Scan_Model = useHasPermission({
        permission: Permissions.Can_Download_Scan_Model,
        type: "admin",
    });

    const model = scan?.model;

    const menus = [
        Can_Team_Download_Scan_Model && {
            disabled: !(model && !scan?.default && model.status === "Completed" && Can_Download_Scan_Model),
            tooltipTitle:
                "Download Model" +
                (scan?.default
                    ? DefaultScanTooltipTitle
                    : !Can_Download_Scan_Model
                    ? TeamMemberNoAccessTooltipTitle
                    : model && model.status !== "Completed"
                    ? ScanNotCompletedTooltipTitle
                    : ""),
            actionTitle: "Download",
            className: "!bg-[#949496] !bg-opacity-25",
            icon: <Images.DownloadIcon color="#3F3E3E" />,
            onClick: () => {
                const video = model?.splat_file?.url;
                downloadFile(video);
            },
        },
        Can_Team_Share_Scan_In_Public && {
            disabled: !(
                model &&
                model.status === "Completed" &&
                !scan?.default &&
                scan?.visibility !== MyScanVisibility.Private
            ),
            tooltipTitle:
                "Copy Preview Link" +
                (scan?.default
                    ? DefaultScanTooltipTitle
                    : !Can_Team_Use_Embeded_Scan
                    ? TeamMemberNoAccessTooltipTitle
                    : model && model.status !== "Completed"
                    ? ScanNotCompletedTooltipTitle
                    : scan?.visibility === MyScanVisibility.Private
                    ? ": Your scan is private. Please change the visibility to public to use this feature."
                    : ""),
            actionTitle: "Copy Preview Link",
            className: "!bg-[#949496] !bg-opacity-25",
            icon: <Images.CopyGrayIcon color="#3F3E3E" />,
            onClick() {
                const link = `${process.env.REACT_APP_NERF_STUDIO_LINK}/invitation?scan_id=${
                    scan?.scan_id ? encryptIdForUrl(scan?.scan_id) : null
                }`;
                copyTextToClipboard(link);
                successToast("Link copied to clipboard");
            },
        },
        Can_Team_Share_Scan_In_Public &&
            Can_Team_Use_Embeded_Scan && {
                disabled: !(
                    model &&
                    !scan?.default &&
                    scan?.model.status === "Completed" &&
                    scan?.visibility !== MyScanVisibility.Private &&
                    Can_Use_Embeded_Scan
                ),
                tooltipTitle:
                    "Embed" +
                    (scan?.default
                        ? DefaultScanTooltipTitle
                        : !Can_Use_Embeded_Scan
                        ? TeamMemberNoAccessTooltipTitle
                        : model && model.status !== "Completed"
                        ? ScanNotCompletedTooltipTitle
                        : scan?.visibility === MyScanVisibility.Private
                        ? ": Your scan is private. Please change the visibility to public to use this feature."
                        : ""),
                actionTitle: "Embed",
                className: "!bg-[#949496] !bg-opacity-25",
                icon: <Images.EmbedIcon color="#3F3E3E" />,
                url: URL.Media_Scan_Detail_Embed,
            },
    ].filter(Boolean) as IHeaderMenuButtonProps[];

    return <HeaderActionBtns buttons={menus} mergeLastButton={model?.status === "Completed"} closeUrl="" />;
};

export default ScanActions;
