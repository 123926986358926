import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import { useEffect, useMemo, useRef } from "react";
import { updateContentInEditor } from "screens/Dashboard/PublishableProjects/utils/utls";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";
import RepositioningHandler from "../RepositioningHandler";

export interface IQuoteBlockProps {
    id: string;
    quote_str: string | null;
    size: 1 | 2 | 3 | 4 | 5;
    alignment: "left" | "center" | "right" | "justify";
    caption: boolean;
    caption_str: string | null;
}

const QuoteBlock = (props: any) => {
    const { id, quote_str, size, alignment, caption, caption_str } = props.node.attrs as IQuoteBlockProps;
    const dispatch = useAppDispatch();

    const quoteRef = useRef(null);
    const quoteMutationObserverRef = useRef<any>(null);
    const captionRef = useRef(null);
    const captionMutationObserverRef = useRef<any>(null);

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);
    const isPreviewOpen = useAppSelector((state) => state.publishable_project.isPreviewOpen);

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const styling = useMemo(() => {
        let style = `text-size-${size} text-${alignment} `;

        if (alignment == "left" || alignment == "justify") {
            style += "justify-start";
        } else if (alignment == "right") {
            style += "justify-end";
        } else {
            style += "justify-center";
        }

        return style;
    }, [alignment, size]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = props.editor;

        props.editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    useEffect(() => {
        if (captionRef.current) {
            (captionRef.current as any).innerHTML = caption_str ?? "Add caption here";

            (captionRef.current as any).addEventListener("paste", function (event) {
                // Prevent the default paste action
                event.preventDefault();

                // Get the text content from the clipboard
                const text = event.clipboardData.getData("text/plain");

                // Insert the text at the current cursor position
                document.execCommand("insertText", false, text);
            });
        }
    }, [caption]);

    useEffect(() => {
        if (quoteRef.current) {
            (quoteRef.current as any).innerHTML = quote_str ?? "“Add quote here”";

            (quoteRef.current as any).addEventListener("paste", function (event) {
                // Prevent the default paste action
                event.preventDefault();

                // Get the text content from the clipboard
                const text = event.clipboardData.getData("text/plain");

                // Insert the text at the current cursor position
                document.execCommand("insertText", false, text);
            });
        }
    }, []);

    useEffect(() => {
        if (isSelected) {
            const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
            parentEditor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    useEffect(() => {
        if (quoteRef.current) {
            // Define what to do when mutations are observed
            const observerCallback = (mutationsList) => {
                if (mutationsList.length > 0) {
                    updateContentInEditor(parentEditor!, id, {
                        quote_str: (quoteRef.current as any).innerHTML,
                    });
                }
            };

            // Create a new observer instance
            quoteMutationObserverRef.current = new MutationObserver(observerCallback);

            // Start observing the contentEditable element
            quoteMutationObserverRef.current.observe(quoteRef.current, {
                characterData: true,
                childList: true,
                subtree: true,
                attributeFilter: ["style"],
            });
        }

        // Disconnect the observer when the component unmounts
        return () => {
            if (quoteMutationObserverRef.current) {
                quoteMutationObserverRef.current.disconnect();
            }
        };
    }, [quoteRef, parentEditor]);

    useEffect(() => {
        if (captionRef.current) {
            // Define what to do when mutations are observed
            const observerCallback = (mutationsList) => {
                if (mutationsList.length > 0) {
                    updateContentInEditor(parentEditor!, id, {
                        caption_str: (captionRef.current as any).innerHTML,
                    });
                }
            };

            // Create a new observer instance
            captionMutationObserverRef.current = new MutationObserver(observerCallback);

            // Start observing the contentEditable element
            captionMutationObserverRef.current.observe(captionRef.current, {
                characterData: true,
                childList: true,
                subtree: true,
                attributeFilter: ["style"],
            });
        }

        // Disconnect the observer when the component unmounts
        return () => {
            if (captionMutationObserverRef.current) {
                captionMutationObserverRef.current.disconnect();
            }
        };
    }, [captionRef, parentEditor]);

    return (
        <NodeViewWrapper
            id={id}
            className={`editor-block quote-component relative ${isSelected && "editor-block-selected"}`}
            onClick={
                isPreviewOpen
                    ? () => {}
                    : (e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          dispatch(
                              setSelectedBlock({
                                  type: "quoteComponent",
                                  props: { id: props.node.attrs.id, attrs: props.node.attrs },
                              })
                          );
                      }
            }
        >
            <div
                ref={quoteRef}
                contentEditable={!isPreviewOpen}
                className={"text-[#101828] italic font-medium editor-block-with-text " + styling}
            ></div>
            {caption && (
                <div className={"w-full flex items-start text-md mt-8 gap-x-2 " + styling}>
                    <span className="text-[#475467]">—</span>
                    <div
                        ref={captionRef}
                        contentEditable={!isPreviewOpen}
                        className="text-[#475467] w-fit editor-block-with-text"
                    ></div>
                </div>
            )}
            {parentEditor && !isPreviewOpen && <RepositioningHandler node_id={id} editor={parentEditor} />}
        </NodeViewWrapper>
    );
};

export default QuoteBlock;
