import { useEffect, useState } from "react";
import { useController } from "react-hook-form";
import { ILocationCords, IMapProps } from "./type";

// Custom hook to conditionally use the useController hook
const useConditionalController = (control: any, name: string, defaultValue: any) => {
    return control
        ? // eslint-disable-next-line react-hooks/rules-of-hooks
          useController({ control, name, defaultValue })
        : { field: { value: "", onChange: () => {} } };
};

const useMap = (props: IMapProps) => {
    const [viewport, setViewport] = useState({
        width: "100%",
        height: "100%",
        latitude: props.location?.latitude ?? 40.7018596,
        longitude: props.location?.longitude ?? -74.0411671,
        zoom: props.location?.zoom ?? 14,
    });

    const methods = useConditionalController(props.control, props.name ?? "location", {
        latitude: props.location?.latitude ?? "40.7018596",
        longitude: props.location?.longitude ?? "-74.0411671",
    });

    const {
        field: { onChange },
    } = methods;

    const updateLocation = (cords: ILocationCords) => {
        if (props.control) {
            onChange({
                latitude: cords.latitude.toString(),
                longitude: cords.longitude.toString(),
            });
        }
        setViewport((prevViewport) => ({ ...prevViewport, ...cords }));
    };

    const onMove = (event: any) => {
        updateLocation({
            latitude: event.viewState.latitude,
            longitude: event.viewState.longitude,
            zoom: event.viewState.zoom,
        });
    };

    const onClick = (event: any) => {
        updateLocation({
            latitude: event.lngLat.lat,
            longitude: event.lngLat.lng,
        });
    };

    const onLocationSelect = (location: any) => {
        updateLocation({
            latitude: location["center"][1],
            longitude: location["center"][0],
            zoom: 14,
        });
    };

    useEffect(() => {
        if (props.autoCurrentLocation) {
            navigator.geolocation.getCurrentPosition((position) => {
                const cords: ILocationCords = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                };

                updateLocation(cords);
            });

            navigator.geolocation.watchPosition((position) => {
                const cords: ILocationCords = {
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                };
                updateLocation(cords);
            });
        }
    }, [props.autoCurrentLocation]);

    useEffect(() => {
        if (props.center) {
            updateLocation(props.center);
        }
    }, [props.center?.latitude, props.center?.longitude, props.center?.zoom]);

    useEffect(() => {}, [viewport]);

    return { viewport, onMove, onClick, onLocationSelect };
};

export default useMap;
