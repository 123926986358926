import { Tooltip } from "@mui/material";
import { useEffect, useState } from "react";

interface IBlockMenuItemProps {
    id: string;
    Icon: any;
    label: string;
    onAction: any;
}

const BlockMenuItem = (props: IBlockMenuItemProps) => {
    const { Icon, label, onAction } = props;

    const [isTooltipOpened, setIsTooltipOpened] = useState(false);

    useEffect(() => {
        if (isTooltipOpened) {
            setTimeout(() => {
                setIsTooltipOpened(false);
            }, 2000);
        }
    }, [isTooltipOpened]);

    return (
        <Tooltip
            title="Drag and Drop in Editor"
            open={isTooltipOpened}
            placement="right"
            componentsProps={{
                tooltip: {
                    sx: {
                        bgcolor: "#0C111D",
                        padding: "8px 12px",
                        borderRadius: "8px",
                    },
                },
                arrow: {
                    sx: {
                        color: "#0C111D",
                    },
                },
            }}
        >
            <div
                className="w-fit flex items-center gap-x-4 py-2 select-none cursor-pointer px-2 rounded-sm"
                draggable={true}
                onClick={() => setIsTooltipOpened(true)}
                onDragStart={(e) => onAction(e)}
            >
                <Icon width={16} height={16} />
                <span className="text-[#667085] text-md">{label}</span>
            </div>
        </Tooltip>
    );
};

export default BlockMenuItem;
