import { useEffect, useRef, useState } from "react";
import { NavigateOptions, useNavigate } from "react-router-dom";
import {
    GetInvitationsQuery,
    GetProfilesQuery,
    Profile,
    api,
    useAcceptInvitationMutation,
    useApproveRequestMutation,
    useGetInvitationsQuery,
    useGetProfilesQuery,
} from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import useSwitchProfileApi from "shared/hooks/useSwitchProfileApi";
import { rtkHandler } from "shared/utils/handlers";
import { setOpenUserSideMenu } from "store/slices/utils";

const useLoggedUserDetail = () => {
    const { user, team, profile } = useAuth();
    const [showMainMenu, setShowMainMenu] = useState(true);
    const [showProfiles, setShowProfiles] = useState(false);
    const [showInvitations, setShowInvitations] = useState(false);
    const [showJoinRequests, setShowJoinRequests] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [invitations, setInvitations] = useState<GetInvitationsQuery["invitations"]>([]);
    const { doSwitchProfile, isLoading: switchLoading } = useSwitchProfileApi({
        doNavigate: true,
    });

    const userCardEle = useRef(null);

    const [acceptInvitation, acceptRes] = useAcceptInvitationMutation();

    const [approveMember, approveRes] = useApproveRequestMutation();

    const { isOpenUserSideMenu } = useAppSelector((state) => state.utils);

    useEffect(() => {
        rtkHandler(approveRes, {
            successMessage: "Member approved successfully",
        });
    }, [approveRes]);

    useEffect(() => {
        rtkHandler(acceptRes, {
            onSuccess() {
                dispatch(api.util.invalidateTags(["Profiles", "UserDetails", "Invitations"]));
            },
        });
    }, [acceptRes]);

    const invRes = useGetInvitationsQuery(undefined);

    const profileRes = useGetProfilesQuery(undefined);

    useEffect(() => {
        rtkHandler(invRes, {
            onSuccess(res: GetInvitationsQuery) {
                setInvitations(res.invitations);
            },
        });
    }, [invRes]);

    useEffect(() => {
        rtkHandler(profileRes, {
            onSuccess(res: GetProfilesQuery) {
                setProfiles(res.profiles.filter((item) => item.type !== "Default") as Profile[]);
            },
        });
    }, [profileRes]);

    const handleClick = (event) => {
        setShowMainMenu(true);
        setShowInvitations(false);
        setShowProfiles(false);
        setAnchorEl(event.currentTarget);
    };

    useEffect(() => {
        if (isOpenUserSideMenu && userCardEle.current) {
            setAnchorEl(userCardEle.current);
            dispatch(setOpenUserSideMenu(false));
        }
    }, [isOpenUserSideMenu]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const goTo = (url: string, options?: NavigateOptions) => {
        handleClose();
        navigate(url, options);
    };

    const joinRequests = team?.members.filter((item) => item.status === "Requested");

    const showMenu = (type: "main" | "invitations" | "profiles" | "joinRequests") => {
        setShowInvitations(false);
        setShowProfiles(false);
        setShowMainMenu(false);
        setShowJoinRequests(false);

        if (type === "main") {
            setShowMainMenu(true);
        } else if (type === "invitations") {
            setShowInvitations(true);
        } else if (type === "profiles") {
            setShowProfiles(true);
        } else if (type === "joinRequests") {
            setShowJoinRequests(true);
        }
    };

    const doActOnInvitation = (code: string, action: "accept") => {
        if (action === "accept") acceptInvitation({ code });
    };

    const doActOnRequest = (member_id: number, action: "approve") => {
        if (action === "approve") approveMember({ member_id });
    };

    return {
        user,
        profile,
        team,
        joinRequests,
        showMainMenu,
        showJoinRequests,
        showInvitations,
        switchLoading,
        anchorEl,
        showProfiles,
        profiles,
        invitations,
        acceptRes,
        approveRes,
        userCardEle,
        doActOnRequest,
        doActOnInvitation,
        doSwitchProfile,
        goTo,
        showMenu,
        handleClick,
        handleClose,
    };
};

export default useLoggedUserDetail;
