import { Checkbox, CheckboxProps } from "@mui/material";
import Minus from "@untitled-ui/icons-react/build/esm/Minus";
import useImages from "assets/images";
import { twMerge } from "tailwind-merge";

interface ThemeCheckboxProps extends CheckboxProps {
    variant?: "circle" | "square" | "filled";
    disabled?: boolean;
}

const ThemeCheckbox = (props: ThemeCheckboxProps) => {
    const Images = useImages();

    const variant = props.variant ?? "square";
    const wrapperClass = twMerge("h-5 w-5 inline-flex items-center justify-center mr-2");

    let activeIcon = <Images.CheckboxActive />;
    let inActiveIcon = <Images.CheckboxInActive />;

    if (variant === "circle") {
        activeIcon = <Images.CheckCheckboxCircleIcon color="#7680FF" />;
        inActiveIcon = <Images.UncheckCheckboxCircleIcon color="#7680FF" />;
    }

    if (variant == "filled") {
        activeIcon = <Images.CheckboxFilledActive color="#7680FF" />;
        inActiveIcon = <Images.CheckboxFilledInactive color="#D0D5DD" />;
    }

    if (props.disabled) {
        activeIcon = (
            <div className="w-5 h-5 rounded-md border border-[#D0D5DD] flex items-center justify-center pointer-events-none">
                {" "}
                <Minus width={16} height={16} color="#7680FF" />
            </div>
        );
        inActiveIcon = (
            <div className="w-5 h-5 rounded-md border border-[#D0D5DD] flex items-center justify-center pointer-events-none">
                {" "}
                <Minus width={16} height={16} color="#7680FF" />
            </div>
        );
    }

    return (
        <div className={wrapperClass}>
            <Checkbox icon={inActiveIcon} checkedIcon={activeIcon} {...props} />
        </div>
    );
};

export default ThemeCheckbox;
