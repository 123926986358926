import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import { omit } from "lodash";
import {
    CreateAssetInput,
    EditAssetInput,
    useAddAssetMutation,
    useEditAssetMutation,
    useGetAssetsQuery,
} from "shared/graphql";
import { useAppSelector } from "shared/hooks/useRedux";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";
import { PostAssetFormSteps, SubmitAssetFormValues, submitAssetSchema } from "./utils/validations";
import * as amplitude from '@amplitude/analytics-browser';
amplitude.init(process.env.REACT_APP_AMPLITUDE_API_KEY || "");

const useAssetPostInner = (props: { onSuccess: () => void }) => {
    const { asset_id, type, folder_id } = useEncryptedParams<{ type: string; folder_id: string; asset_id: string }>();
    
    const [addAsset, addRes] = useAddAssetMutation();
    const [editAsset, updateRes] = useEditAssetMutation();
    const selectedAsset = useAppSelector((state) => state.scans.asset);
    const selectedProject = useAppSelector((state) => state.projects.selected);
    const folder = selectedProject?.folders.find((folder) => folder.folder_id === parseInt(folder_id as string));
    const { data } = useGetAssetsQuery({ filters: {} });

    const id = asset_id;

    const [options] = useState<MyScanOptions>({
        category: [{ value: "3d Scan", label: "3D Scan" }],
        object: [
            { value: "Object", label: "Object" },
            { value: "Building", label: "Building" },
            { value: "Scene", label: "Scene" },
            { value: "Pose", label: "Pose" },
            { value: "Room", label: "Room" },
        ],
    });

    const form = useForm<SubmitAssetFormValues>({
        resolver: yupResolver(submitAssetSchema),
        defaultValues: {
            step: id ? PostAssetFormSteps.Basic : PostAssetFormSteps.Upload,
            name: "",
            existingAssets: data?.assets.map((asset) => asset.name) || [],
            focus: "",
            location: {
                latitude: "40.7018596",
                longitude: "-74.0411671",
            },
            project: selectedProject?.project_id ?? null,
            folder: folder?.folder_id ?? null,
            id,
        },
    });

    useEffect(() => {
        if (id && selectedAsset) {
            const currentFolder = selectedProject?.folders.find((f) => f.folder_id === selectedAsset.folder_id);
            if (id && selectedAsset) {
                form.setValue("name", selectedAsset.name);
                selectedProject && form.setValue("project", selectedProject.project_id);
                currentFolder && form.setValue("folder", currentFolder.folder_id);
                form.setValue("focus", selectedAsset.focus);
                form.setValue("location", {
                    latitude: selectedAsset.location.latitude,
                    longitude: selectedAsset.location.longitude,
                });
            }
        }
    }, [id, selectedAsset, selectedProject]);

    useEffect(() => {
        rtkHandler(addRes, {
            successMessage: "Asset added successfully",
            setError: form.setError,
            onSuccess: () => {
                amplitude.track('Asset', addRes.data);
                props.onSuccess();
            },
        });
    }, [addRes]);

    useEffect(() => {
        rtkHandler(updateRes, {
            successMessage: "Asset updated successfully",
            setError: form.setError,
            onSuccess: () => {
                props.onSuccess();
            },
        });
    }, [updateRes]);

    useEffect(() => {
        if (id) return;
        form.reset();
    }, [id, type]);

    const onSubmit = (data: SubmitAssetFormValues) => {
        const folder_id = data.folder ?? undefined;
        const project_id = data.project ?? undefined;
        const payload: CreateAssetInput | EditAssetInput = {
            name: data.name!,
            focus: data.focus!,
            location: data.location,
            folder_id: folder_id,
            project_id: project_id,
            scanning_device_id: undefined,
            file_id: data.file_id!,
        };
        if (id) {
            editAsset({ asset_id: parseInt(id), data: omit(payload, "file_id") });
        } else {
            addAsset({ data: payload as CreateAssetInput });
        }
    };

    const onStepChange = async (move: "next" | "back") => {
        if (move === "next" && !(await form.trigger())) return;

        let steps = Object.values(PostAssetFormSteps);
        if (id) {
            steps = steps.filter((step) => step === PostAssetFormSteps.Basic);
        }
        const currentStep = steps.indexOf(form.getValues("step"));
        const nextStep = steps[currentStep + (move === "next" ? 1 : -1)];
        form.setValue("step", nextStep);
    };

    return {
        form,
        onSubmit,
        submitLoading: addRes.isLoading,
        updateLoading: updateRes.isLoading,
        options,
        onStepChange,
    };
};

export default useAssetPostInner;

export interface MyScanOptions {
    category: ISelectMenuList[];
    object: ISelectMenuList[];
}
