import ProjectBreadcrumbs from "./ProjectBreadcrumbs";

const AssetScreenBreadcrumbs = (props: { asset_name: string; img: string; public?: boolean }) => {
    return (
        <ProjectBreadcrumbs
            breadcrumbs={[
                {
                    name: props.asset_name || "",
                    image: props.img,
                    status: props.public,
                },
            ]}
        />
    );
};

export default AssetScreenBreadcrumbs;
