import { Editor } from "@tiptap/core";
import { NodeViewWrapper } from "@tiptap/react";
import { forwardRef, useEffect, useMemo, useRef } from "react";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { setSelectedBlock } from "store/slices/publishable_project";
import RepositioningHandler from "../RepositioningHandler";
import { updateContentInEditor } from "screens/Dashboard/PublishableProjects/utils/utls";

export interface IStyledTitleProps {
    id: string;
    size: 1 | 2 | 3 | 4 | 5;
    color: string;
    alignment: "left" | "center" | "right" | "justify";
    content: string | null;
    custom_class: string;
}

const StyledTitle = forwardRef((props: any, ref) => {
    const { id, size, color, alignment, content, custom_class } = props.node.attrs as IStyledTitleProps;
    const dispatch = useAppDispatch();

    const selectedBlock = useAppSelector((state) => state.publishable_project.selectedBlock);
    const isPreviewOpen = useAppSelector((state) => state.publishable_project.isPreviewOpen);

    const project = useAppSelector((state) => state.projects.selected);

    const contentRef = useRef(null);
    const mutationObserverRef = useRef<any>(null);

    const isSelected = useMemo(() => {
        return selectedBlock && id == selectedBlock.props.id;
    }, [selectedBlock]);

    const styling = useMemo(() => {
        return `heading-size-${size} text-${alignment}`;
    }, [size, color, alignment]);

    const parentEditor = useMemo<Editor | null>(() => {
        let correctEditor = props.editor;

        props.editor!.view.state.doc.descendants((node, _) => {
            if (node.type.name == "box" && node.attrs.editor) {
                node.attrs.editor.view.state.doc.descendants((internalNode, _) => {
                    if (internalNode.attrs.id == id) {
                        correctEditor = node.attrs.editor;

                        return false;
                    }
                });
            }
        });

        return correctEditor;
    }, []);

    useEffect(() => {
        if (isSelected) {
            const transaction = parentEditor!.state.tr.setMeta("updateDecorations", true);
            parentEditor!.view.dispatch(transaction);
        }
    }, [isSelected]);

    useEffect(() => {
        if (contentRef.current) {
            (contentRef.current as any).innerHTML = content ?? "Add Title Here";

            (contentRef.current as any).addEventListener("paste", function (event) {
                // Prevent the default paste action
                event.preventDefault();

                // Get the text content from the clipboard
                const text = event.clipboardData.getData("text/plain");

                // Insert the text at the current cursor position
                document.execCommand("insertText", false, text);
            });
        }
    }, []);

    useEffect(() => {
        if (project && contentRef.current && content == "UX review presentations" && id == "mainHeading") {
            (contentRef.current as any).innerHTML = project.name;

            updateContentInEditor(parentEditor!, id, {
                content: project.name,
            });
        }
    }, [project]);

    useEffect(() => {
        if (contentRef.current) {
            // Define what to do when mutations are observed
            const observerCallback = (mutationsList) => {
                if (mutationsList.length > 0) {
                    updateContentInEditor(parentEditor!, id, {
                        content: (contentRef.current as any).innerHTML,
                    });
                }
            };

            // Create a new observer instance
            mutationObserverRef.current = new MutationObserver(observerCallback);

            // Start observing the contentEditable element
            mutationObserverRef.current.observe(contentRef.current, {
                characterData: true,
                childList: true,
                subtree: true,
                attributeFilter: ["style"],
            });
        }

        // Disconnect the observer when the component unmounts
        return () => {
            if (mutationObserverRef.current) {
                mutationObserverRef.current.disconnect();
            }
        };
    }, [contentRef, parentEditor]);

    useEffect(() => {}, [content]);

    return (
        <NodeViewWrapper
            ref={ref}
            id={id}
            onClick={
                isPreviewOpen
                    ? () => {}
                    : (e) => {
                          e.preventDefault();
                          e.stopPropagation();

                          dispatch(
                              setSelectedBlock({
                                  type: "styledTitle",
                                  props: { id: props.node.attrs.id, attrs: props.node.attrs },
                              })
                          );
                      }
            }
            contentEditable={false}
            className="relative"
        >
            <div
                ref={contentRef}
                contentEditable={!isPreviewOpen}
                className={`editor-block editor-block-with-text heading ${custom_class} ${styling} ${
                    isSelected && "editor-block-selected"
                } ${id == "mainHeading" && "!w-full no-further-block "}`}
                style={{
                    color: color,
                }}
            ></div>
            {parentEditor && !isPreviewOpen && id != "mainHeading" && (
                <RepositioningHandler node_id={id} editor={parentEditor} />
            )}
        </NodeViewWrapper>
    );
});

export default StyledTitle;
