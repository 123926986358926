import { Button, IconButton } from "@mui/material";
import useImages from "assets/images";
import moment from "moment";
import { useEffect, useState } from "react";
import { GetInvitationsQuery, Notification, useGetInvitationsQuery } from "shared/graphql";
import { NotificationTypes } from "shared/types/notifications";
import { rtkHandler } from "shared/utils/handlers";
import ThemeModal from "../ui/ThemeModal";
import NotificationStepperCard from "./utils/NotificationStepperCard";
import { capitalize } from "lodash";

type NotificationWithContent = Notification & {
    content?: React.ReactNode | string;
};

const TeamInvitationRequestModal = (props: Props) => {
    const invitationsRes = useGetInvitationsQuery(
        {},
        {
            skip: !props.open,
        }
    );
    const [invitations, setInvitations] = useState<NotificationWithContent[]>([]);

    useEffect(() => {
        rtkHandler(invitationsRes, {
            onSuccess(res: GetInvitationsQuery) {
                setInvitations(
                    res.invitations.map((item) => {
                        return {
                            metadata: {
                                link: `${window.location.href}/invitecode/${item.invite_code}`,
                                member_id: item.team_id,
                            },
                            created_at: moment().format(),
                            notification_id: item.team_id,
                            title: `Invitation to Join ${capitalize(item.name)}`,
                            body: "",
                            content: (
                                <p>
                                    We are thrilled to extend an invitation on behalf of{" "}
                                    <b className="text-primary capitalize">{item.name}</b> in XSpada to join their team
                                    of talented individuals.
                                </p>
                            ),
                            type: NotificationTypes.TEAM_INVITATION,
                            read: false,
                        };
                    })
                );
            },
        });
    }, [invitationsRes]);

    return (
        <ThemeModal
            maxWidth="md"
            open={props.open}
            onClose={props.onClose}
            classes={{
                container: "!h-auto !items-start",
            }}
            contentComponent={<TeamInvitationRequestModalContent data={invitations} onClose={props.onClose} />}
        />
    );
};

const TeamInvitationRequestModalContent = (props: { onClose: () => void; data: Notification[] }) => {
    const Images = useImages();
    const [step, setStep] = useState(1);

    const nextStep = () => {
        if (step === props.data.length) {
            return props.onClose();
        }
        setStep(step + 1);
    };

    const prevStep = () => {
        setStep(step - 1);
    };

    return (
        <div className="text-left">
            <IconButton
                className="h-[20px] w-[20px] rounded-full !bg-primary !bg-opacity-10 flex items-center justify-center !p-0 !absolute top-[14px] right-[14px]"
                onClick={props.onClose}
            >
                <Images.CloseIcon color="#EB617A" width={14} height={14} />
            </IconButton>
            {props.data.map((notification, index) => (
                <NotificationStepperCard
                    type={notification.type as NotificationTypes.TEAM_INVITATION | NotificationTypes.TEAM_REQUEST}
                    data={notification}
                    key={index}
                    onSuccess={nextStep}
                    visible={step === index + 1}
                />
            ))}

            <div className="flex items-center justify-between border-t border-[#EAECF0] pt-2">
                <Button onClick={prevStep} disabled={step === 1} className="!ml-[10px]">
                    <Images.ArrowLeftNav height={10} width={10} className="mr-[10px]" />
                    <span>Previous</span>
                </Button>
                <span className="text-sm leading-4 text-[#667085]">
                    {props.data.length ? step : 0}/{props.data.length}
                </span>
                <Button onClick={nextStep}>
                    <span>Skip</span>{" "}
                    <Images.ArrowRightNav color="#667085" height={10} width={10} className="ml-[10px]" />
                </Button>
            </div>
        </div>
    );
};

export default TeamInvitationRequestModal;

interface Props {
    open: boolean;
    onClose: () => void;
}
