import { Tooltip } from "@mui/material";
import Flash from "@untitled-ui/icons-react/build/esm/Flash";
import useScanCredits from "shared/hooks/useScanCredits";

const ScanCountBox = () => {
    const { creditsCount } = useScanCredits();

    return (
        <Tooltip title="Scan Credits">
            <div className="flex items-center space-x-1 px-[6px] h-7 rounded-lg border-[#FFC107] border text-[#5E5D5D] text-md">
                <Flash color="#FFC107" height={16} width={16} />{" "}
                <span className="ml-4 text-[12px] font-medium text-p">{creditsCount !== -1 ? creditsCount : "Unlimited"}</span>
            </div>
        </Tooltip>
    );
};

export default ScanCountBox;
