import { Button, Tooltip } from "@mui/material";
import ChevronDown from "@untitled-ui/icons-react/build/esm/ChevronDown";
import Share07 from "@untitled-ui/icons-react/build/esm/Share07";
import { Outlet, useNavigate } from "react-router";
import AssetLoading from "screens/Dashboard/Projects/components/AssetLoading";
import AssetScreenBreadcrumbs from "screens/Dashboard/Projects/components/AssetScreenBreadcrumbs";
import ProjectContent from "screens/Dashboard/Projects/components/ProjectContent";
import ScanActionDropdown from "screens/Dashboard/Projects/components/ScanActionDropdown";
import StartViewer from "screens/Dashboard/Projects/components/StartViewer";
import Permissions from "shared/components/HasPermission/Permissions";
import useHasPermission from "shared/components/HasPermission/hook";
import ViewerModal from "shared/components/ViewerModal";
import PageHeader from "shared/components/ui/PageHeader";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import TooltipTitles from "shared/constants/disabledButtonTooltipTitles";
import URL from "shared/constants/navigator";
import useScanSingleScreen from "./hook";
import ScanActions from "./partials/ScanActions";
import ScanDetailCards from "./partials/ScanDetailCards";
import ScanVideoDetail from "./partials/ScanVideoDetail";
import { twMerge } from "tailwind-merge";

const ScanSingleScreen = () => {
    const hook = useScanSingleScreen();
    const navigate = useNavigate();
    const Can_Share_Scan_In_Public = useHasPermission(Permissions.Can_Share_Scan_In_Public);

    if (hook.loading) return <AssetLoading />;

    if (!hook.scan) return <></>;

    const { ScanNotCompletedTooltipTitle, TeamMemberNoAccessTooltipTitle } = TooltipTitles;

    const scan = hook.scan;

    const shareDisabled = !(hook.scan.model && hook.scan.model.status === "Completed" && Can_Share_Scan_In_Public);

    const tooltipTitle =
        "Share Scan" +
        (!Can_Share_Scan_In_Public
            ? TeamMemberNoAccessTooltipTitle
            : hook.scan.model && hook.scan.model.status !== "Completed"
            ? ScanNotCompletedTooltipTitle
            : "");

    return (
        <>
            <div className="h-full flex flex-col scan-screen">
                <PageHeader
                    title={
                        <AssetScreenBreadcrumbs
                            asset_name={scan.name}
                            img={scan.input_file.thumbnail || ""}
                            public={scan.visibility === "public"}
                        />
                    }
                >
                    <div className="flex items-center space-x-3 scan-header-action-btn">
                        <Tooltip title={tooltipTitle}>
                            <div>
                                <Button
                                    disabled={shareDisabled}
                                    startIcon={<Share07 height={20} width={20} color="#344054" />}
                                    variant="outlined"
                                    color="inherit"
                                    className={twMerge("project-btn", shareDisabled && "opacity-50")}
                                    onClick={() => {
                                        if (shareDisabled) return;
                                        navigate(URL.Media_Scan_Publish_Screen);
                                    }}
                                >
                                    Share
                                </Button>
                            </div>
                        </Tooltip>
                        <StartViewer setOpenViewer={hook.setOpenViewer} />
                    </div>
                </PageHeader>
                <div className="flex-1 flex">
                    <ProjectContent className="w-full">
                        <div className="bg-white rounded-xl flex flex-col h-full">
                            <div className="flex items-center justify-between space-x-3 mb-5 border-b pb-4">
                                <div className="flex item-center space-x-3">
                                    <h1 className="text-gray-900 text-[30px] font-medium leading-10 whitespace-nowrap">
                                        {scan.name.length > 40 ? scan.name.substring(0, 40).trim() + "..." : scan.name}
                                    </h1>
                                    {scan.visibility === "public" && (
                                        <span className="items-center border border-[#D0D5DD] shadow-sm bg-white self-center flex gap-1 px-1.5 py-0.5 rounded-md border-solid">
                                            <div className="bg-[#15AA2C] w-[6px] h-[6px] rounded-full" />
                                            <div className="text-slate-700 text-center text-xs font-medium leading-5 self-stretch grow whitespace-nowrap">
                                                Public
                                            </div>
                                        </span>
                                    )}
                                    <ScanActionDropdown>
                                        <ThemeIconButton
                                            icon={<ChevronDown color="#344054" height={18} width={18} />}
                                            className="!shadow-none !mt-1"
                                        />
                                    </ScanActionDropdown>
                                </div>
                                <div className={`flex items-center space-x-8 scan-action-btns`}>
                                    <ScanActions />
                                </div>
                            </div>

                            <div className="grid grid-cols-[minmax(0,1fr),1px,350px] gap-8">
                                <div className="scan-detail-card w-full">
                                    <ScanDetailCards />
                                </div>
                                <div className="border-l" />
                                <div className="scan-video-detail">
                                    <ScanVideoDetail />
                                </div>
                            </div>
                        </div>
                    </ProjectContent>
                </div>
            </div>
            <ViewerModal viewerLink={hook.openViewer} onClose={() => hook.setOpenViewer("")} scan_name={scan.name} />
            <Outlet />
        </>
    );
};

export default ScanSingleScreen;
