import { useEffect, useState } from "react";
import { GetKnowledgeLibraryQuery, useGetKnowledgeLibraryQuery } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import groupBy from "lodash/groupBy";

export interface LibraryDataInterface {
    category: string;
    data: GetKnowledgeLibraryQuery["knowledge_library"];
}

const useKnowledgeLibraryScreen = () => {
    const [libraryData, setLibraryData] = useState<LibraryDataInterface[]>([]);
    const response = useGetKnowledgeLibraryQuery();

    useEffect(() => {
        rtkHandler(response, {
            onSuccess(response: GetKnowledgeLibraryQuery) {
                const groupedData = groupBy(response.knowledge_library, "category");
                const formattedData = Object.keys(groupedData).map((category) => ({
                    category,
                    data: groupedData[category],
                }));
                setLibraryData(formattedData);
            },
        });
    }, [response]);

    return { libraryData, response };
};

export default useKnowledgeLibraryScreen;
