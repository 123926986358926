import { useMemo } from "react";
import { useAppSelector } from "shared/hooks/useRedux";
import PostPublishScreen from "../utils/PostPublish";
import ComponentsSidebar from "./partials/ComponentsSidebar/ComponentsSidebar";
import Editor from "./partials/Editor/Editor";
import TemplatesSidebar from "./partials/TemplatesSidebar/TemplatesSidebar";

const PublishableProjectScreen = () => {
    const { isSelectingTemplate, isSidebarOpen, isReselectingTemplate, isReselected } = useAppSelector((state) => ({
        isSelectingTemplate: state.publishable_project.isSelectingTemplate,
        isSidebarOpen: state.publishable_project.isSidebarOpen,
        isReselectingTemplate: state.publishable_project.isReselectingTemplate,
        isReselected: state.publishable_project.isReselected,
    }));

    const project = useAppSelector((state) => state.projects.selected);

    const isShowTemplateBar = useMemo(() => {
        return !(project?.unpublished_file && !isReselectingTemplate && !isReselected);
    }, [project, isReselectingTemplate, isReselected]);

    return (
        <>
            <div className="h-full w-full flex items-center gap-x-4">
                {isSelectingTemplate && isShowTemplateBar ? (
                    <TemplatesSidebar />
                ) : (
                    isSidebarOpen && <ComponentsSidebar />
                )}
                <Editor />
            </div>
            <PostPublishScreen />
        </>
    );
};

export default PublishableProjectScreen;
