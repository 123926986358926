import Camera01 from "@untitled-ui/icons-react/build/esm/Camera01";
import Camera02 from "@untitled-ui/icons-react/build/esm/Camera03";
import Phone02 from "@untitled-ui/icons-react/build/esm/Phone02";
import Radio from "@untitled-ui/icons-react/build/esm/Signal01";
import Tablet01 from "@untitled-ui/icons-react/build/esm/Tablet01";
import useImages from "assets/images";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmButton from "shared/components/ui/ConfirmButton";
import RenderImage from "shared/components/ui/RenderImage";
import ThemeIconButton from "shared/components/ui/ThemeIconButton";
import URL from "shared/constants/navigator";
import { TeamMember, useAddTeamManagerMutation } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { ScanningDeviceCategory } from "shared/utils/enums";
import { rtkHandler } from "shared/utils/handlers";
import DashboardCard from "../components/DashboardCard";

const TeamMemberDetailDrawer = ({ data }: { data?: TeamMember }) => {
    const Images = useImages();
    const navigate = useNavigate();
    const { isTeamMember, user, team } = useAuth();
    const [addManager, addRes] = useAddTeamManagerMutation();

    const member = team?.members.find((member) => member.member_id === data?.member_id);
    const scanning_device = user?.scanning_devices;

    useEffect(() => {
        rtkHandler(addRes, {
            successMessage: "Member promoted to manager successfully",
        });
    }, [addRes]);

    const closeViewer = () => {
        const dashboardLayout = document.getElementById("dashboard-layout");
        dashboardLayout?.scrollTo({
            top: 0,
            left: 0,
            behavior: "smooth",
        });
    };

    if (!member) return <></>;

    return (
        <DashboardCard className="!p-0 overflow-hidden">
            <div
                className="h-[120px] relative"
                style={{
                    background: "linear-gradient(45deg, #A6C0FE 0%, #FFEAF6 100%)",
                }}
            >
                <div className="absolute top-2 left-2">
                    <ThemeIconButton
                        icon={<Images.CloseIcon color="#475467" height={15} width={15} />}
                        className="!shadow-none"
                        onClick={closeViewer}
                    />
                </div>
                {member.profile && (
                    <div className="shadow-[0px_1px_2px_px_#1018280D] text-[#344054] leading-5 font-medium h-6 inline-flex items-center justify-center text-md bg-white bg-opacity-50 rounded-md absolute top-2 right-2 px-2">
                        {member.profile.type}
                    </div>
                )}
            </div>
            <div className="">
                <div className="flex-center flex-col -mt-8">
                    <RenderImage
                        image={member.user?.profile_image.url || ""}
                        containerClassName="w-[96px] h-[96px] rounded-full border-[5px] border-white mb-4"
                        imgClassName="border-2 border-[#dbdbdb] rounded-full"
                    />
                    <div className="flex flex-col space-y-1 text-center mb-4">
                        <h4 className="text-[#101828] text-[24px] leading-8">{member.user?.name}</h4>
                        <div className="leading-6 text-lg font-normal text-[#475467]">
                            <h5 className="mb-1">{member.user?.email}</h5>
                            <h6>
                                {[
                                    member.user?.address.street,
                                    member.user?.address.city,
                                    member.user?.address.country,
                                    member.user?.address.zip,
                                ]
                                    .filter((val) => val)
                                    .join(", ")}
                            </h6>
                        </div>
                    </div>
                    {member.user?.user_id !== user?.user_id && (
                        <>
                            <div className="flex items-center space-x-6 mb-6">
                                <div className="flex-center flex-col space-y-2 text-[#5E5D5D]">
                                    <ThemeIconButton
                                        className="!bg-[#F2F3FD] !rounded-full !h-[52px] !w-[52px]"
                                        icon={<Images.MessageSquareIcon color="#7680FF" height={20} width={20} />}
                                        onClick={() =>
                                            navigate(URL.Messages, {
                                                state: {
                                                    user: data,
                                                },
                                            })
                                        }
                                    />
                                    <h6 className="text-[12px] leading-[14px] font-medium">Send Message</h6>
                                </div>

                                {!isTeamMember && member.role === "Member" && (
                                    <ConfirmButton
                                        title="Promote To Manager"
                                        content="Are you sure you want to promote this member to manager?"
                                        icon={<Images.TeamMembersLayerIconLightMode />}
                                        okBtnText="Confirm"
                                        okBtnProps={{
                                            loading: addRes.isLoading,
                                            onClick: () =>
                                                addManager({
                                                    member_id: member.member_id,
                                                }),
                                        }}
                                    >
                                        <div className="flex-center flex-col space-y-2 text-[#5E5D5D]">
                                            <ThemeIconButton
                                                className="!bg-[#F2F3FD] !rounded-full !h-[52px] !w-[52px]"
                                                icon={<Images.UserCheck color="#7680FF" height={20} width={20} />}
                                            />
                                            <h6 className="text-[12px] leading-[14px] font-medium">Change Role</h6>
                                        </div>
                                    </ConfirmButton>
                                )}
                            </div>
                        </>
                    )}
                </div>
                {scanning_device?.length ? (
                    <div className="p-6  border-t border-[#EAECF0]">
                        <h3 className="text-[#101828] text-lg leading-6 mb-4 font-medium">Scanning Devices</h3>
                        <div className="flex flex-wrap gap-3">
                            {scanning_device?.map((device) => (
                                <div
                                    className="text-[#667085] text-sm leading-5 border border-[#D0D5DD] px-3 py-[6px] rounded-lg inline-flex items-center space-x-1 whitespace-nowrap"
                                    key={device.scanning_device_id}
                                >
                                    {device.category === ScanningDeviceCategory.MobilePhone ? (
                                        <Phone02 color="#667085" height={15} width={15} />
                                    ) : device.category === ScanningDeviceCategory.Tablet ? (
                                        <Tablet01 color="#667085" height={15} width={15} />
                                    ) : device.category === ScanningDeviceCategory.Drones ? (
                                        <Radio color="#667085" height={15} width={15} />
                                    ) : device.category === ScanningDeviceCategory.TypicalCamera ? (
                                        <Camera01 color="#667085" height={15} width={15} />
                                    ) : (
                                        <Camera02 className="#667085" height={15} width={15} />
                                    )}
                                    <span>{device.name}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                ) : (
                    <></>
                )}
            </div>
        </DashboardCard>
    );
};

export default TeamMemberDetailDrawer;
