import { Button } from "@mui/material";
import MobileStepper from "@mui/material/MobileStepper";
import RenderIf from "shared/components/RenderIf";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { twMerge } from "tailwind-merge";
import ScanForm from "./form/ScanForm";
import ScanUpload from "./form/ScanUpload";
import useScanPostInner from "./hook";
import { PostScanFormSteps } from "./utils/validations";

const ScanPostInner = (props: { onSuccess: () => void }) => {
    const hook = useScanPostInner(props);
    const step = hook.form.watch("step");
    const file = hook.form.watch("input_file_id");
    const { scan_id } = useEncryptedParams<{ scan_id: string }>();
    const id = scan_id;
    const isBasicStep = step === PostScanFormSteps.Basic;
    const isUploadStep = step === PostScanFormSteps.Upload;

    let steps = Object.values(PostScanFormSteps);

    if (id) {
        steps = steps.filter((step) => step === PostScanFormSteps.Basic);
    }

    return (
        <div>
            <ThemeFormProvider
                contextValue={{
                    options: hook.options,
                }}
                form={hook.form}
                onSubmit={hook.onSubmit}
                className="h-full"
            >
                <div className="h-full flex items-center justify-center">
                    <div className="h-full w-full flex flex-col relative">
                        <div className="overflow-auto overflow-x-hidden flex-1 flex flex-col pb-2">
                            <div className="flex-1">
                                <RenderIf condition={isBasicStep}>
                                    <ScanForm />
                                </RenderIf>
                                <RenderIf condition={isUploadStep}>
                                    <ScanUpload />
                                </RenderIf>
                                {/* <RenderIf condition={isScanModel}>
                                    <ScanModel />
                                </RenderIf>
                                <RenderIf condition={isLocationStep}>
                                    <ScanLocation />
                                </RenderIf> */}
                            </div>
                            <MobileStepper
                                variant="dots"
                                steps={steps.length}
                                activeStep={steps.indexOf(step)}
                                position="static"
                                classes={{
                                    root: "!grid !grid-cols-3 items-center gap-x-3 !p-0 mt-6 !h-[36px]",
                                    dots: twMerge(
                                        "flex items-center justify-center",
                                        steps.length > 1 ? "" : "!opacity-0"
                                    ),
                                    dotActive: "!h-[10px] !w-[10px]",
                                }}
                                nextButton={
                                    <div className="flex items-center justify-end">
                                        {steps.indexOf(step) === steps.length - 1 ? (
                                            <LoaderButton
                                                loading={hook.submitLoading || hook.updateLoading}
                                                type="submit"
                                                variant="contained"
                                            >
                                                {id ? "Save Changes" : "Submit"}
                                            </LoaderButton>
                                        ) : (
                                            <Button
                                                disabled={!file && isUploadStep}
                                                onClick={() => hook.onStepChange("next")}
                                                variant="contained"
                                            >
                                                Next
                                            </Button>
                                        )}
                                    </div>
                                }
                                backButton={
                                    <div className="">
                                        {steps.indexOf(step) === 0 ? (
                                            <div></div>
                                        ) : (
                                            <Button onClick={() => hook.onStepChange("back")} variant="outlined">
                                                Previous
                                            </Button>
                                        )}
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>
            </ThemeFormProvider>
        </div>
    );
};

export default ScanPostInner;
