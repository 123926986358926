import { useFormContext } from "react-hook-form";
import ProjectFolderFields from "screens/Dashboard/Projects/components/ProjectFolderFields";
import Map from "shared/components/Map";
import Field from "shared/components/ui/Field";
import FieldLabel from "shared/components/ui/FieldLabel";
import { useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { validCoordinates } from "shared/utils/helpers";

const ScanForm = () => {
    const { options, watch }: any = useFormContext();
    const location = watch("location");
    const { scan_id: id } = useEncryptedParams<{ scan_id: string }>();
    const selectedScan = useAppSelector((state) => state.scans.selected);

    return (
        <>
            <Field name="name" label="Scan Name" placeholder="Introduce your scan with a name" />
            <Field
                name="capture_intent"
                label="Scan Object:"
                placeholder="Select object of intent for your scan"
                type="select"
                options={options.object}
            />
            <Field
                type="select"
                name="scanning_device_id"
                options={options.scanning_device}
                selectFieldProps={{
                    noOptionsText: "No devices to select. Please add at least one device in profile settings.",
                }}
                label="Scanning Device:"
                placeholder="Select your scanning device"
            />
            <ProjectFolderFields />
            <FieldLabel>Location</FieldLabel>
            <div className="w-full h-[400px] border border-[#D0D5DD] rounded-md p-4">
                <Map
                    autoCurrentLocation={!(id && selectedScan)}
                    showLocationMarker={true}
                    isInteract={true}
                    center={
                        id && selectedScan
                            ? validCoordinates(location, selectedScan.location)
                            : validCoordinates(location)
                    }
                    name="location"
                />
            </div>
        </>
    );
};

export default ScanForm;
