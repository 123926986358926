import { Close } from "@mui/icons-material";
import { CircularProgress, Modal } from "@mui/material";
import Download01 from "@untitled-ui/icons-react/build/esm/Download01";
import Maximize02 from "@untitled-ui/icons-react/build/esm/Maximize02";
import Play from "@untitled-ui/icons-react/build/esm/Play";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactPlayer from "react-player/lazy";
import RenderImage from "shared/components/ui/RenderImage";
import { useAppSelector } from "shared/hooks/useRedux";
import { convertSizeToText, mimeTypeName } from "shared/utils/helpers";

const ScanVideoDetail = () => {
    const scan = useAppSelector((state) => state.scans.selected);

    const [play, setPlay] = useState(false);
    const [viewImage, setViewImage] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);

    const downloadVideo = () => {
        if (scan) {
            setIsDownloading(true);
            const video = scan.input_file.url;
            // download video here
            const a = document.createElement("a");
            a.href = video;
            a.download = "video.mp4";
            a.click();

            // remove a tag
            a.remove();
            setIsDownloading(false);
        }
    };

    const togglePlay = () => {
        setPlay((prev) => !prev);
    };

    if (!scan) return <></>;

    return (
        <PerfectScrollbar className="h-full">
            <div className="border border-[#EAECF0] p-4 rounded-[12px]">
                <div className={`relative h-[461px] group ${play ? "play-video" : ""}`}>
                    {!play && (
                        <RenderImage
                            image={
                                (scan.dataset_type === "video" ? scan.input_file?.thumbnail : scan.input_file?.url) ||
                                ""
                            }
                            imgClassName="rounded-[16px]"
                            containerClassName="h-[461px] w-full absolute top-0 left-0 group-[.play-video]:opacity-0 group-[.play-video]:invisible group-[.play-video]:h-0 z-[1] rounded-[15px] bg-white"
                        />
                    )}
                    {scan.dataset_type === "video" && scan.input_file?.url && (
                        <div className="video-player animate-fade-in h-[461px] w-full group-[.play-video]:visible group-[.play-video]:opacity-100 opacity-0 invisible absolute top-0 left-0 z-">
                            <ReactPlayer
                                url={scan.input_file.url}
                                width="100%"
                                height="100%"
                                playing={play}
                                loop
                                muted
                                controls
                            />
                        </div>
                    )}
                    {!play && (
                        <>
                            {scan.scanning_device && (
                                <>
                                    <div
                                        className={`absolute top-0 left-0 w-full h-full z-[2] rounded-lg rotate-180`}
                                        style={{
                                            background:
                                                "linear-gradient(180deg, rgba(0, 0, 0, 0) 67.25%, rgba(0, 0, 0, 0.4) 100%)",
                                        }}
                                    />
                                    <div className="absolute top-4 left-4 w-[calc(100%-80px)] z-[3]">
                                        <h4 className="w-full text-white font-bold text-lg leading-6 mb-[2px] text-ellipsis whitespace-nowrap break-all overflow-hidden">
                                            {scan.scanning_device.name}
                                        </h4>
                                        <p className="text-white opacity-80 text-md leading-4 font-normal">
                                            Scanning Device
                                        </p>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {scan.dataset_type === "video" ? (
                        play ? (
                            <div
                                className="absolute top-[22px] right-4 bg-white bg-opacity-70 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer z-[4]"
                                onClick={togglePlay}
                            >
                                <Close className="text-[#344054]" />
                            </div>
                        ) : (
                            scan.input_file?.url && (
                                <div className="absolute bottom-[34px] flex items-center justify-center space-x-10 w-full">
                                    <div
                                        className="bg-white bg-opacity-70 rounded-full h-11 w-11 flex items-center justify-center cursor-pointer z-[4]"
                                        onClick={togglePlay}
                                    >
                                        <Play color="#554AF0" />
                                    </div>
                                </div>
                            )
                        )
                    ) : (
                        <div className="absolute bottom-[34px] flex items-center justify-center space-x-10 w-full">
                            <div
                                className="bg-white bg-opacity-70 rounded-full h-11 w-11 flex items-center justify-center cursor-pointer z-[4]"
                                onClick={() => setViewImage(true)}
                            >
                                <Maximize02 color="#7680FF" />
                            </div>
                        </div>
                    )}
                </div>
                <table className="w-full text-md text-[#475467] leading-5 font-medium mt-4">
                    <tbody>
                        {scan.dataset_type === "video" && !scan.default && (
                            <tr>
                                <td width="50%">File</td>
                                <td className="py-2 text-right text-[#475467]">
                                    <div className="flex items-center justify-end space-x-2 cursor-pointer">
                                        <span>Download</span>
                                        {isDownloading ? (
                                            <CircularProgress size={25} />
                                        ) : (
                                            <Download01
                                                onClick={downloadVideo}
                                                height={20}
                                                width={20}
                                                color="#554AF0"
                                            />
                                        )}
                                    </div>
                                </td>
                            </tr>
                        )}
                        <tr>
                            <td width="50%">File Type</td>
                            <td className="py-2 text-right text-[#475467]">{mimeTypeName(scan.input_file.mimetype)}</td>
                        </tr>
                        <tr>
                            <td width="50%">
                                {scan.dataset_type === "video" || scan.dataset_type === "video360"
                                    ? "Video Size"
                                    : "Zipped Images Size"}
                            </td>
                            <td className="py-2 text-right text-[#475467]">
                                {convertSizeToText(scan.input_file.size)}
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">Processing Time</td>
                            <td className="py-2 text-right text-[#475467]">
                                {new Date(scan.model.processing_time * 1000).toISOString().substr(11, 8)}
                            </td>
                        </tr>
                    </tbody>
                </table>
                <Modal
                    open={viewImage}
                    onClose={() => setViewImage(false)}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                    classes={{ root: "max-h-[90%] max-w-[70%] m-auto" }}
                >
                    <RenderImage
                        image={scan.input_file.url || ""}
                        imgClassName="rounded-[16px] h-full !object-contain"
                        containerClassName="h-full"
                    />
                </Modal>
            </div>
        </PerfectScrollbar>
    );
};

export default ScanVideoDetail;
