import { Button } from "@mui/material";
import useImages from "assets/images";
import Banner from "assets/images/banners/Auth-Banner-2.png";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Outlet, useNavigate } from "react-router-dom";
import RenderImage from "shared/components/ui/RenderImage";
import URL from "shared/constants/navigator";
import useAuth from "shared/hooks/useAuth";
import AuthSlider from "../components/AuthSlider";
import UserDropdown from "./components/UserDropdown";

const LoginLayout = () => {
    const Images = useImages();
    const navigate = useNavigate();
    const { user } = useAuth();

    return (
        <div className="grid grid-cols-[1fr,640px] h-screen overflow-hidden">
            <div className="bg-[#F2F4F7] pt-[96px] pr-[80px] h-screen flex flex-col">
                <div className="pl-[80px]">
                    <AuthSlider
                        steps={[
                            <div key={0}>
                                <h3 className="text-[#344054] leading-[38px] font-medium text-[30px] mb-6">
                                    Snap angles you want to save for later. Work with your team by marking up images to
                                    better enhance your planning.
                                </h3>
                                <div className="flex items-start justify-between">
                                    <div>
                                        <h4 className="text-[#344054] text-xl leading-7 font-medium">— Snapshots</h4>
                                        <p className="text-[#98A2B3] text-lg leading-6 font-medium">Feature</p>
                                    </div>
                                    <div className="flex items-center space-x-[2px]">
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                    </div>
                                </div>
                            </div>,
                            <div key={1}>
                                <h3 className="text-[#344054] leading-[38px] font-medium text-[30px] mb-6">
                                    Showcase and Collaborate on Your Projects. Plan, Annotate, and Refine Your
                                    Team&apos;s Work and Goals.
                                </h3>
                                <div className="flex items-start justify-between">
                                    <div>
                                        <h4 className="text-[#344054] text-xl leading-7 font-medium">
                                            — Published Projects
                                        </h4>
                                        <p className="text-[#98A2B3] text-lg leading-6 font-medium">Feature</p>
                                    </div>
                                    <div className="flex items-center space-x-[2px]">
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                    </div>
                                </div>
                            </div>,
                            <div key={2}>
                                <h3 className="text-[#344054] leading-[38px] font-medium text-[30px] mb-6">
                                    Design Dynamic Camera Paths and Animations with Your Team for Enhanced Visual
                                    Storytelling.
                                </h3>
                                <div className="flex items-start justify-between">
                                    <div>
                                        <h4 className="text-[#344054] text-xl leading-7 font-medium">
                                            — Camera Paths
                                        </h4>
                                        <p className="text-[#98A2B3] text-lg leading-6 font-medium">Feature</p>
                                    </div>
                                    <div className="flex items-center space-x-[2px]">
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                        <Images.FourStar height={20} width={20} color="#7680FF" />
                                    </div>
                                </div>
                            </div>,
                        ]}
                    />
                </div>
                <RenderImage
                    image={Banner}
                    containerClassName="flex-1 border-[#667085] border-t-[2px] border-r-[2px] rounded-tr-[20px]"
                    imgClassName="!object-left-top"
                />
            </div>
            <div className="py-7 px-8 flex flex-col h-screen">
                <div className="flex items-center justify-between">
                    <Images.Logo />
                    {user ? (
                        <UserDropdown />
                    ) : (
                        <Button
                            className="!h-8 !rounded-[30px] text-[#5E5D5D] !text-[14px] !leading-5 !px-[14px] !min-w-0"
                            endIcon={<Images.ArrowRightPrimary />}
                            color="primary"
                            variant="outlined"
                            onClick={() => navigate(URL.Home)}
                        >
                            Sign Up
                        </Button>
                    )}
                </div>
                <PerfectScrollbar className="flex-1 py-4">
                    <div className="flex justify-center items-center h-full flex-wrap">
                        <Outlet />
                    </div>
                </PerfectScrollbar>
                <div className="flex items-center justify-between text-[#667085] text-md leading-5">
                    <p>© XSpada 2024</p>
                    <p>
                        <a href="mailto:support@xspada.com" className="flex items-center space-x-1">
                            <Images.EmailIcon height={13} color="#667085" />
                            <span>support@xspada.com</span>
                        </a>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginLayout;
