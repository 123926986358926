import { Button } from "@mui/material";
import Plus from "@untitled-ui/icons-react/build/esm/Plus";
import Obstacle from "assets/images/banners/Circle-Bg.png";
import { Link } from "react-router-dom";

const EmptyCard: React.FC<{
    icon?: React.ReactNode;
    title: string;
    description: string;
    btnText?: string;
    url?: string;
}> = (props) => {
    return (
        <div className="flex flex-col items-center relative h-[480px] w-[480px] pt-[216px] mx-auto -mt-12">
            <img
                src={Obstacle}
                className="h-full w-full !absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
            <div className="relative z-10 text-center">
                <div className="h-12 w-12 border border-[#EAECF0] flex items-center justify-center rounded-[10px] mx-auto">
                    {props.icon}
                </div>
                <div className="header items-center flex w-full flex-col mb-6 max-w-[350px]">
                    <div className="self-stretch text-[#101828] text-center text-[18px] font-medium leading-7 whitespace-nowrap mt-5">
                        {props.title}
                    </div>
                    <div className="self-stretch text-[#475467] text-center text-md leading-5 mt-2">
                        {props.description}
                    </div>
                </div>
                {props.url && props.btnText && (
                    <Link to={props.url || ""}>
                        <Button variant="contained" color="primary" startIcon={<Plus />}>
                            {props.btnText}
                        </Button>
                    </Link>
                )}
            </div>
        </div>
    );
};

export default EmptyCard;
