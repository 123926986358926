import { Button } from "@mui/material";
import ProjectBreadcrumbs from "screens/Dashboard/Projects/components/ProjectBreadcrumbs";
import ProjectDrawer from "screens/Dashboard/Projects/components/ProjectDrawer";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import URL from "shared/constants/navigator";
import { routeWithParams } from "shared/utils/helpers";
import usePostFolderScreen from "./hook";

const PostFolderScreen = () => {
    const hook = usePostFolderScreen();

    const editProjectUrl = routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
        id: hook.id,
    });

    const projectDetailUrl = routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
        id: hook.id,
    });

    return (
        <ProjectDrawer
            header={<ProjectBreadcrumbs drawer={true} />}
            open={hook.open}
            onClose={() => hook.setOpen(false)}
            backUrl={hook.id && hook.folder_id && hook.project ? editProjectUrl : projectDetailUrl}
            title={hook.folder_id && hook.project ? "Edit Folder" : "New Folder"}
        >
            <ThemeFormProvider form={hook.form} onSubmit={hook.onSubmit} className="text-left flex flex-col grow">
                <div className="flex-1">
                    <Field name="name" label="Folder Name:" placeholder="Write a name for your new folder" />
                    <Field label="Folder Thumbnail:" name="thumbnail" type="image-picker" />
                </div>
                <div className="w-full flex items-center justify-end space-x-3">
                    <Button variant="outlined" disabled={hook.isLoading} onClick={() => hook.setOpen(false)}>
                        Cancel
                    </Button>
                    <LoaderButton variant="contained" loading={hook.isLoading}>
                        {hook.folder_id && hook.project ? "Save Changes" : "Submit"}
                    </LoaderButton>
                </div>
            </ThemeFormProvider>
        </ProjectDrawer>
    );
};

export default PostFolderScreen;
