import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "shared/hooks/useRedux";
import { encryptIdForUrl, useEncryptedParams } from "shared/utils/encrypt-url";
import yup from "shared/utils/yup";

const useEmbedScan = () => {
    const navigate = useNavigate();
    const { id, folder_id } = useEncryptedParams<{ folder_id: string; id: string; scan_id: string }>();
    const { selected, project } = useAppSelector((state) => ({
        selected: state.scans.selected,
        project: state.projects.selected,
    }));
    const [open, setOpen] = useState(false);

    const folder = project?.folders.find((folder) => folder.folder_id === parseInt(folder_id as string));

    const schema = yup.object().shape({
        embed_width: yup.number().min(0).required(),
        embed_height: yup.number().min(0).required(),
    });

    const scan_name = useMemo(() => selected?.name ?? "", [selected]);

    const link = `${process.env.REACT_APP_NERF_STUDIO_LINK}/dashboard?scan_id=${
        selected?.scan_id ? encryptIdForUrl(selected?.scan_id) : null
    }&embed=true`;

    const form = useForm<any>({
        resolver: yupResolver(schema),
        defaultValues: {
            embed_width: 800,
            embed_height: 800,
        },
    });

    useEffect(() => {
        if (!folder && folder_id) {
            navigate(-1);
        } else {
            setOpen(true);
        }
    }, [folder, folder_id]);

    const embedWidth = form.watch("embed_width");
    const embedHeight = form.watch("embed_height");

    return { id, folder, folder_id, project, open, setOpen, link, form, embedWidth, embedHeight, scan_name };
};

export default useEmbedScan;
