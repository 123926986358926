import { Table, TableCell, TableRow, styled } from "@mui/material";
import React from "react";
import { BenefitItemName } from "../../utils/BenefitName";
import CheckCircle from "@untitled-ui/icons-react/build/esm/CheckCircle";
import { twMerge } from "tailwind-merge";
import { GetPlansQuery, GetSubscriptionsQuery, PlanPeriod } from "shared/graphql";

type PlanCard = GetPlansQuery["plans"]["plans"][0];

export interface PlanCardInteractions extends PlanCard {
    image?: React.ReactNode;
    active?: boolean;
    btnText?: string;
    currency: string;
    onClick?: () => void;
    disabled?: boolean;
}

type BenefitLimitType = keyof PlanCardInteractions["benefits"]["limits"];
type BenefitRoleType = keyof PlanCardInteractions["benefits"]["roles"];
type BenefitSharingType = keyof PlanCardInteractions["benefits"]["sharing_permissions"];
type BenefitCustomerSupportType = keyof PlanCardInteractions["benefits"]["customer_support"];
type BenefitAddOnsType = keyof PlanCardInteractions["benefits"]["add_ons"];
export type BenefitType = BenefitLimitType | BenefitRoleType | BenefitCustomerSupportType | BenefitSharingType | BenefitAddOnsType;

export interface PlanBenefitCellsProps {
    plans: PlanCardInteractions[];
    indexKey: keyof Omit<PlanCardInteractions["benefits"], "__typename">;
    type: BenefitType;
    prefix?: string;
    suffix?: string;
    renderCell?: (value: any) => React.ReactNode;
}

export const TitleRowItem = (props: { title: string }) => (
    <TableRow>
        <TableCell className="!text-primary !pt-10">{props.title}</TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
        <TableCell></TableCell>
    </TableRow>
);

export const PlanBenefitCells = (props: PlanBenefitCellsProps) => {
    if (!props.plans) return <></>;

    const lineDiv = <div className="bg-[#98A2B3] h-[2px] w-3 mx-auto" />;

    return (
        <TableRow>
            <TableCell>{BenefitItemName[props.type]}</TableCell>
            {props.plans.map((plan) => {
                let value =
                    plan.benefits[props.indexKey][props.type].label || plan.benefits[props.indexKey][props.type].value;

                if (typeof value === "boolean") {
                    value = value ? <CheckCircle color="#079455" height={20} width={20} /> : lineDiv;
                } else if (value === "-") {
                    value = lineDiv;
                } else {
                    value = props.renderCell ? props.renderCell(value) : value;
                }

                return (
                    <TableCell className={twMerge(plan.disabled && "!opacity-50")} key={plan.name + plan.period}>
                        {value}
                    </TableCell>
                );
            })}
        </TableRow>
    );
};

export const generatePlanArray = ({
    plans,
    currentPlan,
    enableEnterprisePlan,
    ...props
}: {
    plans: PlanCardInteractions[];
    currentPlan?: GetSubscriptionsQuery["subscriptions"][0] | null;
    enableEnterprisePlan: boolean;
    interval: PlanPeriod;
}) => {
    return plans
        .filter((plan) => plan.period === props.interval || plan.period === PlanPeriod.Free)
        .sort((a, b) => {
            if (a.period === PlanPeriod.Free) return -1;
            if (b.period === PlanPeriod.Free) return 1;
            return a.price - b.price;
        })
        .map((plan) => {
            const isActive = currentPlan
                ? currentPlan?.plan.name === plan.name && currentPlan?.plan.period === plan.period
                : plan.period === PlanPeriod.Free;

            const is_enterprise = plan.name === "Enterprise";

            return {
                ...plan,
                disabled: currentPlan
                    ? props.interval === PlanPeriod.Monthly
                        ? !isActive
                        : isActive || plan.name !== "Enterprise"
                    : false,
                is_enterprise,
                active: isActive,
                btnText: isActive
                    ? "Current Plan"
                    : is_enterprise && !enableEnterprisePlan
                    ? "Speak to a Sales Rep"
                    : plan.btnText,
                onClick: isActive ? () => {} : plan.onClick,
            };
        });
};

export const PlanTable = styled(Table)(() => ({
    overflow: "hidden",
    position: "relative",
    // header
    "& .MuiTableHead-root": {
        "& .MuiTableCell-root": {
            padding: "24px",
        },
    },

    "& .MuiTableCell-root": {
        textAlign: "center",
        border: "none",
        padding: "22px 16px",

        "&:first-child": {
            borderTopLeftRadius: "8px",
            borderBottomLeftRadius: "8px",
            textAlign: "left !important",
        },

        "&:last-child": {
            borderTopRightRadius: "8px",
            borderBottomRightRadius: "8px",
        },
    },

    "& .MuiTableRow-root": {
        "&:nth-child(even)": {
            backgroundColor: "#F9FAFB",
        },
    },

    "& .MuiButton-root": {
        height: "48px !important",
    },

    // last row
    "& .MuiTableRow-root:last-child": {
        "& .MuiTableCell-root": {
            paddingInline: "24px",
            backgroundColor: "white",
        },
    },
}));
