import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useOpenTour } from "shared/components/Walkthrough";
import { StepLocalStorageKeys } from "shared/components/Walkthrough/const";
import { ScanScreenSteps } from "shared/components/Walkthrough/steps";
import URL from "shared/constants/navigator";
import {
    GetMyScansByIdQuery,
    GetProjectByIdQuery,
    useGetMyScansByIDQuery,
    useGetProjectByIdQuery,
} from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";
import { setSelectedProject } from "store/slices/projects";
import { setSelectedScan } from "store/slices/scans";

const useScanSingleScreen = () => {
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { scan_id } = useEncryptedParams<{ scan_id: string }>();
    const [isPageLoaded, setIsPageLoaded] = useState(false);
    const localKey = StepLocalStorageKeys.ScanScreen;
    const steps = ScanScreenSteps;

    const scan = useAppSelector((state) => state.scans.selected);
    const projectRes = useGetProjectByIdQuery(
        { id: scan?.project_id ?? 0 },
        {
            skip: !scan?.project_id,
        }
    );
    const { selected: project } = useAppSelector((state) => state.projects);
    const scanRes = useGetMyScansByIDQuery({ id: parseInt(scan_id || "0") });
    const [openViewer, setOpenViewer] = useState("");

    const folder = project?.folders.find((folder) => folder.folder_id === scan?.folder_id ?? 0);

    const loading = scanRes.isLoading || projectRes.isLoading;

    useOpenTour({ localKey, steps, skip: !isPageLoaded });

    useEffect(() => {
        return () => {
            dispatch(setSelectedProject(null));
        };
    }, []);

    useEffect(() => {
        rtkHandler(scanRes, {
            onSuccess: (res: GetMyScansByIdQuery) => {
                setTimeout(() => {
                    setIsPageLoaded(true);
                }, 300);
                dispatch(setSelectedScan(res.scan));
            },
        });
    }, [scanRes]);

    useEffect(() => {
        rtkHandler(projectRes, {
            onSuccess: (res: GetProjectByIdQuery) => {
                dispatch(setSelectedProject(res.project));
            },
            onError: () => {
                navigate(URL.Media);
            },
        });
    }, [projectRes]);

    return { project, folder, scan, loading, openViewer, setIsPageLoaded, setOpenViewer };
};

export default useScanSingleScreen;
