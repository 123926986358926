import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import URL from "shared/constants/navigator";
import { useAppSelector } from "shared/hooks/useRedux";

const Guest = () => {
    const token = useAppSelector((state) => state.token.current);
    const navigate = useNavigate();

    useEffect(() => {
        if (token) {
            navigate(URL.Dashboard);
        }
    }, []);

    return <Outlet />;
};

export default Guest;
