import ChevronRight from "@untitled-ui/icons-react/build/esm/ChevronRight";
import Plus from "@untitled-ui/icons-react/build/esm/Plus";
import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AssetsList, { AssetData } from "screens/Dashboard/Projects/components/AssetsList";
import ProjectContent from "screens/Dashboard/Projects/components/ProjectContent";
import useGetAssetsScans from "screens/Dashboard/Projects/hook/useGetAssetsScans";
import URL from "shared/constants/navigator";
import { AssetVisibility } from "shared/graphql";
import { useAppSelector } from "shared/hooks/useRedux";
import { extractLocationName, routeWithParams } from "shared/utils/helpers";
import { twMerge } from "tailwind-merge";
import FolderActionButton from "./FolderActionButton";
import { encryptIdForUrl } from "shared/utils/encrypt-url";

const FolderTabs = () => {
    const [selected, setSelected] = React.useState(0);
    const project = useAppSelector((state) => state.projects.selected);
    const folders = project?.folders || [];
    const [listData, setListData] = React.useState<AssetData[]>([]);
    const { assets, scans, loading } = useGetAssetsScans();
    // get folder id from state
    const { state } = useLocation();
    const selected_folder_id = state?.folder_id;

    useEffect(() => {
        // Fetch assets and scans
        if (project) {
            const scanLists = scans.map((scan) => ({
                name: scan.name,
                created_at: scan.created_at || "",
                thumbnail: scan.input_file.thumbnail || "",
                id: encryptIdForUrl(scan.scan_id),
                location: extractLocationName(scan.location.name) || "",
                visible: scan.visibility === "public",
                method: scan.method,
                status: scan.model.status,
                splat_file: scan.model.splat_file?.file_id,
                type: "scan",
            }));

            const assetList = assets.map((asset) => ({
                name: asset.name,
                created_at: asset.created_at || "",
                thumbnail: (asset.file.type === "Video" ? asset.file.thumbnail : asset.file.url) || "",
                id: encryptIdForUrl(asset.asset_id),
                visible: asset.visibility === AssetVisibility.Public,
                method: asset.focus,
                location: extractLocationName(asset.location.name) || "",
                type: "asset",
            }));

            const folderScans = folders
                .map((folder) => folder.scans)
                .flat()
                .map((scan) => ({
                    name: scan.name,
                    created_at: scan.created_at || "",
                    thumbnail: scan.input_file.thumbnail || "",
                    id: encryptIdForUrl(scan.scan_id),
                    location: extractLocationName(scan.location.name) || "",
                    visible: scan.visibility === "public",
                    method: scan.method,
                    status: scan.model.status,
                    splat_file: scan.model.splat_file?.file_id,
                    folder_id: scan.folder_id,
                    type: "scan",
                }));

            const folderAssets = folders
                .map((folder) => folder.asset)
                .flat()
                .map((asset) => ({
                    name: asset.name,
                    created_at: asset.created_at || "",
                    thumbnail: (asset.file.type === "Video" ? asset.file.thumbnail : asset.file.url) || "",
                    id: encryptIdForUrl(asset.asset_id),
                    visible: asset.visibility === AssetVisibility.Public,
                    method: asset.focus,
                    location: extractLocationName(asset.location.name) || "",
                    folder_id: asset.folder_id,
                    type: "asset",
                }));

            if (selected === 0) setListData([...folderScans, ...folderAssets, ...scanLists, ...assetList]);
            else setListData([...folderScans, ...folderAssets].filter((item) => item.folder_id === selected));
        }
    }, [loading, selected, folders, scans, assets, project]);

    useEffect(() => {
        if (selected_folder_id) {
            const folder = folders.find((folder) => folder.folder_id === parseInt(selected_folder_id));
            if (folder) setSelected(folder.folder_id);
            window.history.replaceState(null, "");
        }
    }, [selected_folder_id, folders]);

    useEffect(() => {
        const selectedFolder = folders.find((folder) => folder.folder_id === selected);
        if (!selectedFolder && selected !== 0) setSelected(0);
    }, [folders]);

    if (!project) return null;

    return (
        <ProjectContent className="!pt-0">
            <div className="flex items-center pt-1 pb-4 border-b border-[#EAECF0] text-[#344054] text-[20px] leading-6 font-medium">
                <div className="flex items-center space-x-1">
                    <h4
                        onClick={() => setSelected(0)}
                        className={twMerge(
                            "text-[#98A2B3] h-10 flex-center w-11 text-lg leading-6 font-medium rounded-xl cursor-pointer",
                            selected === 0 && "bg-[#F9FAFB] text-primary"
                        )}
                    >
                        All
                    </h4>
                    <ChevronRight color="#98A2B3" height={20} width={20} />
                </div>
                <div className="flex items-center space-x-1 overflow-scroll">
                    {folders.map((folder) => {
                        return (
                            <div
                                key={folder.folder_id}
                                onClick={() => setSelected(folder.folder_id)}
                                className={twMerge(
                                    "text-[#98A2B3] text-lg leading-6 font-medium px-3 py-2 cursor-pointer rounded-xl whitespace-nowrap",
                                    selected === folder.folder_id && "bg-[#F9FAFB] text-primary"
                                )}
                            >
                                {folder.name}
                            </div>
                        );
                    })}
                </div>
                <Link
                    to={routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}/${URL.Media_Folder_Post}`, {
                        id: project.project_id,
                    })}
                    className={
                        "text-[#98A2B3] h-10 flex-center w-11 text-lg leading-6 font-medium rounded-xl cursor-pointer"
                    }
                >
                    <Plus height={20} width={20} color="#98A2B3" />
                </Link>
            </div>
            {selected !== 0 && <FolderActionButton folder_id={selected} />}
            <AssetsList
                containerClassName="!px-0"
                data={listData.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime())}
            />
        </ProjectContent>
    );
};

export default FolderTabs;
