import { useEffect, useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { matchRoutes, Outlet, useLocation, useNavigate } from "react-router-dom";

import ErrorBoundaryContent from "shared/components/ErrorBoundary/content";
import PageLoader from "shared/components/ui/PageLoader";
import WalkthroughLayout from "shared/components/Walkthrough";
import { useGetUserDetailsQuery } from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";

import useAuth from "shared/hooks/useAuth";
import NotificationSidebar from "../partials/NotificationSidebar";
import Sidebar from "../partials/Sidebar";
import TalkJSProvider from "shared/components/TalkJSProvider";
import { useAppSelector } from "shared/hooks/useRedux";

const DashboardLayout = () => {
    const [Component, setComponent] = useState(<PageLoader />);
    const res = useGetUserDetailsQuery();

    useEffect(() => {
        rtkHandler(res, {
            onSuccess() {
                setComponent(<DashboardContent />);
            },
            onError() {
                setComponent(<ErrorBoundaryContent />);
            },
        });
    }, [res]);

    return Component;
};

export default DashboardLayout;

const DashboardContent = () => {
    const isSidebarCollapsed = useAppSelector((state) => state.utils.isSidebarCollapsed);

    const { isTeamExpired } = useAuth();
    const navigate = useNavigate();
    const location = useLocation();
    const [showDashboard, setShowDashboard] = useState(false);

    const isValidExpiredLinks = matchRoutes(
        [{ path: `/dashboard` }, { path: `/settings/shop` }, { path: `/settings/shop/payment/success` }],
        location.pathname
    )?.length;

    useEffect(() => {
        if (isTeamExpired && !isValidExpiredLinks) {
            navigate("/dashboard");
            setShowDashboard(true);
        } else {
            setShowDashboard(true);
        }
    }, [isTeamExpired, isValidExpiredLinks]);

    if (!showDashboard) {
        return <PageLoader />;
    }

    return (
        <WalkthroughLayout>
            <TalkJSProvider>
                <div className="flex">
                    <div className={"sidebar " + (isSidebarCollapsed && "!hidden group sidebar-collapsed")}>
                        {isSidebarCollapsed && <div className="sidebar-collapsed-placeholder"></div>}
                        <PerfectScrollbar>
                            <Sidebar />
                        </PerfectScrollbar>
                    </div>
                    <div className="h-screen overflow-auto w-full relative">
                        <NotificationSidebar />
                        <Outlet />
                    </div>
                </div>
            </TalkJSProvider>
        </WalkthroughLayout>
    );
};
