import { NodeViewWrapper } from "@tiptap/react";
import useImages from "assets/images";
import { forwardRef, useEffect, useRef } from "react";
import { useAppSelector } from "shared/hooks/useRedux";
import { copyTextToClipboard } from "shared/utils/helpers";
import { successToast } from "shared/utils/toast";

export interface ProjectLinksColumnBlock {
    id: string;
    link: string | null;
    facebook: string | null;
    linkedin: string | null;
    twitter: string | null;
}

const ProjectLinksColumnBlock = forwardRef((props: any, ref) => {
    const { id, link } = props.node.attrs as ProjectLinksColumnBlock;
    const { CopyGrayIcon, SocialFacebookIcon, SocialLinkedInIcon, SocialNewTwitterIcon } = useImages();

    const linksRef = useRef(null);

    const project = useAppSelector((state) => state.projects.selected);
    const isPreviewOpen = useAppSelector((state) => state.publishable_project.isPreviewOpen);
    const selectedTemplate = useAppSelector((state) => state.publishable_project.selectedTemplate);

    useEffect(() => {
        setInterval(() => {
            if (linksRef.current as any)
                (linksRef.current as any).style.top =
                    ((document.querySelector(".editor-divider") as any)?.offsetTop ?? 0) + 32 + "px";
        }, 1000);
    }, [isPreviewOpen, selectedTemplate]);

    useEffect(() => {
        if (project) {
            setTimeout(() => {
                const data = {
                    id,
                    link: `${process.env.REACT_APP_PUBLISHED_PROJECT_HOST_URL}${project.slug}`,
                    facebook: "",
                    linkedin: "",
                    twitter: "",
                };

                let pos = 0;

                props.editor!.view.state.doc.descendants((node, position) => {
                    if (node.attrs.id == id) {
                        pos = position;
                        return false;
                    }
                });

                const transaction = props.editor!.view.state.tr.setNodeMarkup(pos, null, data);

                props.editor!.view.dispatch(transaction);
            }, 0);
        }
    }, [project]);

    return (
        <NodeViewWrapper ref={ref} id={id} contentEditable={false}>
            <div
                ref={linksRef}
                className={
                    "project-links-column flex flex-col gap-y-3 absolute right-0 z-10 " +
                    (!isPreviewOpen && "!pointer-events-none")
                }
            >
                <div
                    className="w-[40px] h-[40px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center cursor-pointer hover:brightness-90"
                    style={{
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        filter:
                            "brightness(0%) invert(75%) sepia(14%) saturate(342%) hue-rotate(179deg) brightness(85%) contrast(83%)",
                    }}
                    onClick={() => {
                        copyTextToClipboard(link ?? "");
                        successToast("Link copied to clipboard");
                    }}
                >
                    <CopyGrayIcon width={18} height={18} />
                </div>
                <div
                    className="w-[40px] h-[40px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center cursor-pointer hover:brightness-90"
                    style={{
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        filter:
                            "brightness(0%) invert(75%) sepia(14%) saturate(342%) hue-rotate(179deg) brightness(85%) contrast(83%)",
                    }}
                    onClick={() => {
                        const url = link ?? "";
                        window.open(
                            `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(url)}`,
                            "facebook-share-dialog",
                            "width=800,height=600"
                        );
                    }}
                >
                    <SocialFacebookIcon width={18} height={18} />
                </div>
                <div
                    className="w-[40px] h-[40px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center cursor-pointer hover:brightness-90"
                    style={{
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        filter:
                            "brightness(0%) invert(75%) sepia(14%) saturate(342%) hue-rotate(179deg) brightness(85%) contrast(83%)",
                    }}
                    onClick={() => {
                        const url = link ?? "";
                        const text = document.getElementById("mainHeading")
                            ? document.getElementById("mainHeading")?.innerText ?? ""
                            : "";
                        window.open(
                            `https://twitter.com/intent/tweet?url=${encodeURIComponent(url)}&text=${encodeURIComponent(
                                text
                            )}`,
                            "twitter-share-dialog",
                            "width=800,height=600"
                        );
                    }}
                >
                    <SocialNewTwitterIcon width={18} height={18} />
                </div>
                <div
                    className="w-[40px] h-[40px] rounded-[8px] border border-[#D0D5DD] flex items-center justify-center cursor-pointer hover:brightness-90"
                    style={{
                        boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        filter:
                            "brightness(0%) invert(75%) sepia(14%) saturate(342%) hue-rotate(179deg) brightness(85%) contrast(83%)",
                    }}
                    onClick={() => {
                        const url = link ?? "";
                        window.open(
                            `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(url)}`,
                            "linkedin-share-dialog",
                            "width=800,height=600"
                        );
                    }}
                >
                    <SocialLinkedInIcon width={18} height={18} />
                </div>
            </div>
        </NodeViewWrapper>
    );
});

export default ProjectLinksColumnBlock;
