import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "shared/hooks/useRedux";
import useScanCredits from "shared/hooks/useScanCredits";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { errorToast } from "shared/utils/toast";

const useScanPost = () => {
    const navigate = useNavigate();
    const { id, folder_id, scan_id } = useEncryptedParams<{ id: string; folder_id: string; scan_id: string }>();
    const { creditsCount } = useScanCredits();
    const [open, setOpen] = useState(false);
    const project = useAppSelector((state) => state.projects.selected);
    const folder = project?.folders.find((folder) => folder.folder_id === parseInt(folder_id as string));

    useEffect(() => {
        if (!folder && folder_id) {
            navigate(-1);
        } else {
            if (creditsCount !== -1 && creditsCount <= 0) {
                errorToast("You don't have enough credits to perform this action");
                navigate(-1);
            } else {
                setOpen(true);
            }
        }
    }, []);

    return { open, id, folder_id, setOpen, project, folder, scan_id };
};

export default useScanPost;
