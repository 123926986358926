import { useMemo } from "react";
import { PlanBenefitCells, generatePlanArray } from "./utils";

import useImages from "assets/images";
import { useEffect, useRef, useState } from "react";
import {
    GetPlansQuery,
    PlanPeriod,
    useGetPlansQuery,
    useGetSubscriptionsQuery,
    useGetUserPromoCodeQuery,
} from "shared/graphql";
import { rtkHandler } from "shared/utils/handlers";
import { BenefitType, PlanBenefitCellsProps, PlanCardInteractions } from "./utils";

const usePlanCards = (props: { interval: PlanPeriod }) => {
    const Images = useImages();
    const userPromoCode = useGetUserPromoCodeQuery();
    const [purchaseModalOpen, setPurchaseModalOpen] = useState(false);
    const [selectedPlan, setSelectedPlan] = useState<PlanCardInteractions>();
    const [enterpriseModalOpen, setEnterpriseModalOpen] = useState(false);
    const [plans, setPlans] = useState<PlanCardInteractions[]>([]);
    const [selectedDivHeight, setSelectedDivHeight] = useState(0);

    const tableRef = useRef<HTMLTableElement>(null);

    let currentPlan = useGetSubscriptionsQuery().data?.subscriptions[0] || null;
    currentPlan = currentPlan?.status === "Active" ? currentPlan : null;

    const plansRes = useGetPlansQuery();

    const enableEnterprisePlan = userPromoCode.error
        ? false
        : userPromoCode.data?.user_promo_code.configuration.enable_enterprise || false;

    const storageIncrease = userPromoCode.error
        ? 0
        : userPromoCode.data?.user_promo_code.configuration.storage_increase_by_times || 0;

    useEffect(() => {
        rtkHandler(plansRes, {
            onSuccess(response: GetPlansQuery) {
                setPlans(
                    response.plans.plans.map((plan) => ({
                        ...plan,
                        image: ["Free"].includes(plan.name) ? (
                            <Images.Layer />
                        ) : plan.name === "Enterprise" ? (
                            <Images.TripleLayer />
                        ) : (
                            <Images.DoubleLayer />
                        ),
                        currency: plan.period === "Custom" ? "" : "$",
                        active: false,
                        btnText: "Get Started",
                        onClick() {},
                    }))
                );
            },
        });
    }, [plansRes]);

    const intervalPlans = useMemo(() => {
        return generatePlanArray({
            plans,
            currentPlan,
            enableEnterprisePlan,
            interval: props.interval,
        });
    }, [plans, currentPlan, enableEnterprisePlan, props.interval]);

    useEffect(() => {
        if (!tableRef.current || !intervalPlans.length) return;

        setSelectedDivHeight(tableRef.current?.clientHeight || 0);
    }, [tableRef.current, intervalPlans]);

    const RolesItem = (props: { type: BenefitType }) =>
        useMemo(() => {
            return <PlanBenefitCells plans={intervalPlans} indexKey="roles" {...props} />;
        }, [intervalPlans]);

    const CustomerSupportItem = (props: { type: BenefitType }) =>
        useMemo(() => {
            return <PlanBenefitCells plans={intervalPlans} indexKey="customer_support" {...props} />;
        }, [intervalPlans]);

    const SharingPermissionsItem = (props: { type: BenefitType }) =>
        useMemo(() => {
            return <PlanBenefitCells plans={intervalPlans} indexKey="sharing_permissions" {...props} />;
        }, [intervalPlans]);

    const LimitItem = (props: Pick<PlanBenefitCellsProps, "type" | "renderCell">) =>
        useMemo(() => {
            return <PlanBenefitCells plans={intervalPlans} indexKey="limits" {...props} />;
        }, [intervalPlans]);

    const AddOnsItem = (props: Pick<PlanBenefitCellsProps, "type" | "renderCell">) =>
        useMemo(() => {
            return <PlanBenefitCells plans={intervalPlans} indexKey="add_ons" {...props} />;
        }, [intervalPlans]);

    return {
        intervalPlans,
        selectedDivHeight,
        tableRef,
        RolesItem,
        CustomerSupportItem,
        SharingPermissionsItem,
        LimitItem,
        AddOnsItem,
        purchaseModalOpen,
        setPurchaseModalOpen,
        selectedPlan,
        setSelectedPlan,
        enterpriseModalOpen,
        setEnterpriseModalOpen,
        storageIncrease,
        enableEnterprisePlan,
        plansRes,
    };
};

export default usePlanCards;
