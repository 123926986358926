import { Close } from "@mui/icons-material";
import { CircularProgress, Modal } from "@mui/material";
import Download01 from "@untitled-ui/icons-react/build/esm/Download01";
import Maximize02 from "@untitled-ui/icons-react/build/esm/Maximize02";
import Play from "@untitled-ui/icons-react/build/esm/Play";
import { useState } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import ReactPlayer from "react-player/lazy";
import RenderImage from "shared/components/ui/RenderImage";
import { useAppSelector } from "shared/hooks/useRedux";
import { convertSizeToText, mimeTypeName } from "shared/utils/helpers";

const AssetVideoDetail = () => {
    const asset = useAppSelector((state) => state.scans.asset);
    const [play, setPlay] = useState(false);
    const [viewImage, setViewImage] = useState(false);
    const [isDownloading, setIsDownloading] = useState(false);
    const [videoLength, setVideoLength] = useState("00:00:00");

    const downloadVideo = () => {
        if (asset) {
            setIsDownloading(true);
            const video = asset.file.url;

            // download video here
            const a = document.createElement("a");
            a.href = video;
            if(asset.file.type === "Video")
                a.download = asset.name + ".mp4";
            else
                a.download = asset.name + ".png";
            a.click();

            // remove a tag
            a.remove();
            setIsDownloading(false);
        }
    };

    const togglePlay = () => {
        setPlay((prev) => !prev);
    };

    if (!asset) return <></>;

    return (
        <PerfectScrollbar className="h-full">
            <div className="border border-[#EAECF0] p-4 rounded-[12px]">
                <div className={`relative h-[461px] group ${play ? "play-video" : ""}`}>
                    {!play && (
                        <RenderImage
                            image={(asset.file.type === "Video" ? asset.file?.thumbnail : asset.file?.url) || ""}
                            imgClassName="rounded-[16px]"
                            containerClassName="h-[461px] w-full absolute top-0 left-0 group-[.play-video]:opacity-0 group-[.play-video]:invisible group-[.play-video]:h-0 z-[1] rounded-[15px] bg-white"
                        />
                    )}
                    {asset.file.type === "Video" && asset.file?.url && (
                        <div className="video-player animate-fade-in h-[461px] w-full group-[.play-video]:block absolute top-0 left-0 z-">
                            <ReactPlayer
                                url={asset.file.url}
                                width="100%"
                                height="100%"
                                playing={play}
                                loop
                                muted
                                controls
                                onReady={(player) => {
                                    // convert seconds to hh:mm:ss
                                    const time = player.getDuration();
                                    const hours = Math.floor(time / 3600);
                                    const minutes = Math.floor((time % 3600) / 60);
                                    const seconds = Math.floor((time % 3600) % 60);
                                    const videoLength = `${hours < 10 ? "0" + hours : hours}:${
                                        minutes < 10 ? "0" + minutes : minutes
                                    }:${seconds < 10 ? "0" + seconds : seconds}`;
                                    setVideoLength(videoLength);
                                }}
                            />
                        </div>
                    )}
                    {!play && (
                        <>
                            {asset.scanning_device && (
                                <>
                                    <div
                                        className={`absolute top-0 left-0 w-full h-full z-[2] rounded-lg rotate-180`}
                                        style={{
                                            background:
                                                "linear-gradient(180deg, rgba(0, 0, 0, 0) 67.25%, rgba(0, 0, 0, 0.4) 100%)",
                                        }}
                                    />
                                    <div className="absolute top-4 left-4 w-[calc(100%-80px)] z-[3]">
                                        <h4 className="w-full text-white font-bold text-lg leading-6 mb-[2px] text-ellipsis whitespace-nowrap break-all overflow-hidden">
                                            {asset.scanning_device.name}
                                        </h4>
                                        <p className="text-white opacity-80 text-md leading-4 font-normal">
                                            Scanning Device
                                        </p>
                                    </div>
                                </>
                            )}
                        </>
                    )}
                    {asset.file.type === "Video" ? (
                        play ? (
                            <div
                                className="absolute top-[22px] right-4 bg-white bg-opacity-70 rounded-full h-8 w-8 flex items-center justify-center cursor-pointer z-[4]"
                                onClick={togglePlay}
                            >
                                <Close className="text-[#344054]" />
                            </div>
                        ) : (
                            asset.file?.url && (
                                <div className="absolute bottom-[34px] flex items-center justify-center space-x-10 w-full">
                                    <div
                                        className="bg-white bg-opacity-70 rounded-full h-11 w-11 flex items-center justify-center cursor-pointer z-[4]"
                                        onClick={togglePlay}
                                    >
                                        <Play color="#554AF0" />
                                    </div>
                                </div>
                            )
                        )
                    ) : (
                        <div className="absolute bottom-[34px] flex items-center justify-center space-x-10 w-full">
                            <div
                                className="bg-white bg-opacity-70 rounded-full h-11 w-11 flex items-center justify-center cursor-pointer z-[4]"
                                onClick={() => setViewImage(true)}
                            >
                                <Maximize02 color="#7680FF" />
                            </div>
                        </div>
                    )}
                </div>
                <table className="w-full text-md text-[#475467] leading-5 font-medium mt-4">
                    <tbody>
                        <tr>
                            <td width="50%">File</td>
                            <td className="py-2 text-right text-[#475467]">
                                <div className="flex items-center justify-end space-x-2 cursor-pointer">
                                    <span>Download</span>
                                    {isDownloading ? (
                                        <CircularProgress size={20} />
                                    ) : (
                                        <Download01
                                            onClick={downloadVideo}
                                            height={20}
                                            width={20}
                                            color="#554AF0"
                                        />
                                    )}
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td width="50%">File Type</td>
                            <td className="py-2 text-right text-[#475467]">{mimeTypeName(asset.file.mimetype)}</td>
                        </tr>
                        <tr>
                            <td width="50%">{asset.file.type === "Video" ? "Video Size" : "Image Size"}</td>
                            <td className="py-2 text-right text-[#475467]">{convertSizeToText(asset.file.size)}</td>
                        </tr>
                        {asset.file.type === "Video" && (
                            <tr>
                                <td width="50%">Video Length</td>
                                <td className="py-2 text-right text-[#475467]">{videoLength}</td>
                            </tr>
                        )}
                    </tbody>
                </table>
                <Modal
                    open={viewImage}
                    onClose={() => setViewImage(false)}
                    aria-labelledby="parent-modal-title"
                    aria-describedby="parent-modal-description"
                    classes={{ root: "max-h-[90%] max-w-[70%] m-auto" }}
                >
                    <RenderImage
                        image={asset.file.url || ""}
                        imgClassName="rounded-[16px] h-full !object-contain"
                        containerClassName="h-full"
                    />
                </Modal>
            </div>
        </PerfectScrollbar>
    );
};

export default AssetVideoDetail;
