import { useMemo } from "react";

import URL from "shared/constants/navigator";
import useAuth from "shared/hooks/useAuth";
import { ProfileTypes } from "shared/utils/enums";

interface SettingTabList {
    name: string;
    path: string;
}

const useSettingScreenLayout = () => {
    const { isTeamExpired, profile } = useAuth();

    const isTeam = [ProfileTypes.TeamMember, ProfileTypes.Admin].includes(profile?.type as any);

    const Tabs: SettingTabList[] = useMemo(() => {
        return [
            !isTeamExpired && {
                name: "Account",
                path: URL.Profile_Settings,
            },
            !isTeamExpired && {
                name: "Password",
                path: URL.Security_Settings,
            },
            {
                name: "Shop",
                path: URL.Shop,
            },
            // ...((IsStagingServer && [
            //     {
            //         name: "Subscription",
            //         path: URL.Subscription_Settings,
            //     },
            //     {
            //         name: "Imagine-X",
            //         path: URL.Imagine_X_Settings,
            //     },
            //     {
            //         name: "Scan Credits",
            //         path: URL.Scan_Credits,
            //     },
            // ]) ||
            //     []),
            // ,
            isTeam &&
                !isTeamExpired && {
                    name: "Team",
                    path: URL.team,
                },
            !isTeamExpired && {
                name: "Profiles",
                path: URL.Accounts,
            },
            // ...(IsStagingServer ? [{ name: "Developer", path: URL.Developer }] : []),
        ].filter((item) => item) as SettingTabList[];
    }, [isTeamExpired, isTeam]);

    return { Tabs };
};

export default useSettingScreenLayout;
