import { useEffect, useRef, useState } from "react";
import { matchRoutes, useLocation, useNavigate, useParams } from "react-router-dom";
import URL from "shared/constants/navigator";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { copyTextToClipboard, routeWithParams } from "shared/utils/helpers";

import { EditProjectMutation, useEditProjectMutation } from "shared/graphql";
import useFileUploader from "shared/hooks/useFileUploader";
import { rtkHandler } from "shared/utils/handlers";
import { setCommonModalLoadingBtn } from "store/slices/commonModal";
import { setProjectRefetchApi, setSelectedProject } from "store/slices/projects";
import {
    resetEditor,
    setDefaultContent,
    setSelectedBlock,
    togglePreview,
    toggleShareSidebarOpen,
    closeShareSidebar,
} from "store/slices/publishable_project";
import { v4 as uuidv4 } from "uuid";
import { Types, deleteBlockHandler } from "./PublishableProject/partials/PropertiesSidebar/PropertiesSidebar";
import { useTour } from "@reactour/tour";
import { Content } from "shared/components/Walkthrough/components/Elements";

const usePublishableProjectLayout = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const dispatch = useAppDispatch();
    const { id } = useParams();

    const {
        setSteps,
        setIsOpen,
        isOpen: isTutorialOpen,
        currentStep,
        // setCurrentStep,
    } = useTour();

    const { uploadFile, loading: isUploading, loader_id: uploadId } = useFileUploader();
    const [updateProject, updateRes] = useEditProjectMutation();

    const { isSelectingTemplate, isPreviewOpen, defaultContent, selectedBlock } = useAppSelector((state) => ({
        isSelectingTemplate: state.publishable_project.isSelectingTemplate,
        isPreviewOpen: state.publishable_project.isPreviewOpen,
        defaultContent: state.publishable_project.defaultContent,
        selectedBlock: state.publishable_project.selectedBlock,
    }));

    const project = useAppSelector((state) => state.projects.selected);

    const editor = useAppSelector((state) => state.publishable_project.main_editor);

    const [isShareDialogOpen, setIsShareDialogOpen] = useState(false);
    const [isSavedOnce, setIsSavedOnce] = useState(false);
    const [isShowPageLoader, setIsShowPageLoader] = useState(false);
    const [isEditorDirty, setIsEditorDirty] = useState(true);
    const [saveStatus, setSaveStatus] = useState("");

    const saveFrom = useRef("save");

    const currentEditable = useRef<any>(null);

    const isOnboardingFlow = Boolean(
        matchRoutes(
            [
                {
                    path: "/complete-profile/project/:id/editor",
                },
            ],
            location
        )
    );

    const onOpenProject = () => {
        editor?.destroy();
        dispatch(resetEditor());

        setTimeout(() => {
            navigate(
                routeWithParams(`${URL.Media}/${URL.Media_Project_Detail}`, {
                    id,
                })
            );
        }, 0);
    };

    const onSkipOrNextStep = () => {
        navigate("/complete-profile/final");
    };

    const onOpenShareSidebar = () => {
        dispatch(toggleShareSidebarOpen());
    };

    const onSaveAndPreviewClickedHandler = (
        from?: "back" | "share" | "auto",
        isPublic?: boolean,
        showMessage?: boolean
    ) => {
        if (from) {
            if (from == "back") {
                setIsShowPageLoader(true);
            } else if (from == "share") {
                dispatch(setCommonModalLoadingBtn("change-visibility-btn"));
            }

            if (from == "auto" && isPublic && showMessage) {
                saveFrom.current = from + "_public";
            } else {
                saveFrom.current = from;
            }
        }

        const contentJSON = { ...editor?.getJSON() };
        const finalJSON = {
            type: contentJSON["type"],
            content: [] as any[],
        };

        for (let i = 0; i < (contentJSON as any)["content"]?.length; i++) {
            if (
                ((contentJSON as any)["content"][i] as any)["attrs"] &&
                "editor" in ((contentJSON as any)["content"][i] as any)["attrs"]
            ) {
                const tempAttrs = { ...((contentJSON as any)["content"][i] as any)["attrs"] };

                if ("editor" in tempAttrs) {
                    delete tempAttrs["editor"];
                }

                finalJSON.content.push({
                    type: ((contentJSON as any)["content"][i] as any)["type"],
                    attrs: tempAttrs,
                });
            } else {
                finalJSON.content.push({ ...((contentJSON as any)["content"][i] as any) });
            }
        }

        const contentString = JSON.stringify(finalJSON);

        if (contentString != defaultContent || from == "share" || isPublic != undefined) {
            const blob = new Blob([contentString], { type: "application/json" });

            // Create a File from the Blob
            const fileName = `${project?.name}_${uuidv4()}.json`; // Name your file
            const file = new File([blob], fileName, { type: "application/json" });

            if (file) {
                uploadFile(file, "project-content-upload")
                    .then((res: any) => {
                        dispatch(setDefaultContent(contentString));

                        setTimeout(() => {
                            updateProject({
                                id: project!.project_id,
                                data: {
                                    unpublished_file_id: res.file_id,
                                    ...(isPublic
                                        ? { published_file_id: res.file_id }
                                        : isPublic != undefined && !isPublic
                                        ? { published_file_id: null }
                                        : {}),
                                    ...(isOnboardingFlow ? { visible: true } : {}),
                                    ...(from == "share" ? { visible: !project!.visible } : {}),
                                },
                            });
                        }, 0);
                    })
                    .catch(() => {
                        setIsShowPageLoader(false);
                        dispatch(setCommonModalLoadingBtn(""));
                    });
            }
        } else {
            setIsShowPageLoader(false);
            dispatch(setCommonModalLoadingBtn(""));

            if (from == "back") {
                onOpenProject();
            }
        }

        if (!from) {
            dispatch(togglePreview());
        }
    };

    const onClosePreviewClickedHandler = () => {
        dispatch(togglePreview());
    };

    const showShareDialog = () => {
        setIsShareDialogOpen(true);
    };

    const hideShareDialog = () => {
        setIsShareDialogOpen(false);
    };

    const isSaving = updateRes.isLoading || (isUploading && uploadId == "project-content-upload");

    useEffect(() => {
        if (!project) {
            onOpenProject();
        }
    }, [project]);

    useEffect(() => {
        rtkHandler(updateRes, {
            successMessage:
                saveFrom.current == "auto_public"
                    ? "Project published successfully."
                    : saveFrom.current == "auto" || saveFrom.current == "share"
                    ? undefined
                    : "Saved successfully.",
            onSuccess({ edit_project }: EditProjectMutation) {
                setIsShowPageLoader(false);

                dispatch(setProjectRefetchApi(true));

                setIsSavedOnce(true);

                dispatch(setCommonModalLoadingBtn(""));
                if (saveFrom.current == "back") {
                    onOpenProject();
                } else if (saveFrom.current == "share") {
                    dispatch(setSelectedProject({ ...project, visible: edit_project.visible }));
                } else {
                    dispatch(setSelectedProject({ ...edit_project }));
                }
            },
            onError() {
                setIsShowPageLoader(false);
                dispatch(setCommonModalLoadingBtn(""));
            },
        });
    }, [updateRes, navigate]);

    useEffect(() => {
        let periodicSaveTimer: any = null;

        const timerFunc = () => {
            onSaveAndPreviewClickedHandler("auto");

            periodicSaveTimer = setTimeout(timerFunc, 5 * 1000);
        };

        if (!isSelectingTemplate) {
            periodicSaveTimer = setTimeout(timerFunc, 5 * 1000);
        }

        return () => {
            if (!isSelectingTemplate) {
                clearTimeout(periodicSaveTimer);
            }
        };
    }, [isSelectingTemplate, project, defaultContent]);

    useEffect(() => {
        if (editor && defaultContent) {
            const updatedEditorDirtyStatus = () => {
                const contentJSON = JSON.stringify(editor.getJSON());

                setIsEditorDirty(contentJSON != defaultContent);
                setSaveStatus(contentJSON != defaultContent ? "Saving..." : "Changes Saved");
            };

            editor.on("update", updatedEditorDirtyStatus);

            updatedEditorDirtyStatus();
        }
    }, [editor, defaultContent]);

    useEffect(() => {
        if (saveStatus == "Changes Saved") {
            setTimeout(() => {
                setSaveStatus("");
            }, 2000);
        }
    }, [isEditorDirty, saveStatus]);

    function selectAllText(element) {
        if (element.isContentEditable) {
            const range = document.createRange();
            range.selectNodeContents(element);
            const selection = window.getSelection();
            if (selection) {
                selection.removeAllRanges();
                selection.addRange(range);
            }
        }
    }

    const isCursorWithinEditor = () => {
        const editorElement = editor?.view?.dom; // Get the editor's DOM element
        let currentElement = document.activeElement;

        while (currentElement) {
            if (currentElement === editorElement) {
                return true; // Cursor is within the editor
            }
            currentElement = currentElement.parentElement;
        }

        return false; // Cursor is not within the editor
    };

    useEffect(() => {
        const handleKeyDown = (event: any) => {
            if (event.key == "Escape") {
                dispatch(setSelectedBlock(null));
                if (editor) {
                    setTimeout(() => {
                        const decoration_transaction = editor!.view.state.tr.setMeta("updateDecorations", true);
                        editor!.view.dispatch(decoration_transaction);
                    }, 0);
                }
            } else if (event.key == "Backspace") {
                if (selectedBlock && editor && !currentEditable.current && isCursorWithinEditor()) {
                    deleteBlockHandler(selectedBlock, editor, dispatch);
                }
            } else if (event.shiftKey && event.key == "Enter") {
                event.preventDefault();
                event.stopPropagation();
            } else if (event.shiftKey && event.key == "Tab" && selectedBlock && editor) {
                const selectedElement = document.getElementById(selectedBlock!.props!.id)?.parentElement;
                const prevSiblingId =
                    selectedElement &&
                    selectedElement.previousSibling &&
                    selectedElement.previousSibling.previousSibling?.firstChild &&
                    (selectedElement.previousSibling.previousSibling?.firstChild as any).getAttribute("id");

                if (prevSiblingId) {
                    let prevNode: any = null;
                    let nodeAttrs: any = null;

                    editor!.view.state.doc.descendants((node) => {
                        if (node.attrs.id == prevSiblingId) {
                            prevNode = node;
                            nodeAttrs = node.attrs;
                            return false;
                        }
                    });

                    if (prevNode && Object.keys(Types).includes(prevNode.type.name)) {
                        dispatch(
                            setSelectedBlock({
                                type: prevNode.type.name,
                                props: { id: prevSiblingId, attrs: nodeAttrs },
                            })
                        );
                    }
                }
            } else if (event.key == "Tab" && selectedBlock && editor) {
                const selectedElement = document.getElementById(selectedBlock!.props!.id)?.parentElement;
                const nextSiblingId =
                    selectedElement &&
                    selectedElement.nextSibling &&
                    selectedElement.nextSibling.nextSibling?.firstChild &&
                    (selectedElement.nextSibling.nextSibling?.firstChild as any).getAttribute("id");

                if (nextSiblingId && nextSiblingId != "footerComponent") {
                    let nextNode: any = null;
                    let nodeAttrs: any = null;

                    editor!.view.state.doc.descendants((node) => {
                        if (node.attrs.id == nextSiblingId) {
                            nextNode = node;
                            nodeAttrs = node.attrs;
                            return false;
                        }
                    });

                    if (nextNode && Object.keys(Types).includes(nextNode.type.name)) {
                        dispatch(
                            setSelectedBlock({
                                type: nextNode.type.name,
                                props: { id: nextSiblingId, attrs: nodeAttrs },
                            })
                        );
                    }
                } else if (
                    !selectedElement ||
                    (selectedElement && !selectedElement.classList.contains("node-placeholder"))
                ) {
                    let lastNodePos = -1;
                    let lastNode: any = -1;

                    const excluding = [
                        "tabsBlock",
                        "scansOrSnapshotsBlockComponent",
                        "projectLinksColumnComponent",
                        "footerComponent",
                    ];

                    editor.view.state.doc.descendants((node, position) => {
                        if (!excluding.includes(node.type.name)) {
                            lastNode = node;
                            lastNodePos = position;
                        }
                    });

                    const insertAt = lastNodePos + lastNode.nodeSize;

                    const nodeType = editor.view.state.schema.nodes.placeholder;
                    const placeholderId = uuidv4();
                    const transaction = editor.view.state.tr.insert(
                        insertAt,
                        (nodeType as any).createAndFill({
                            id: placeholderId,
                        })
                    );

                    editor.view.dispatch(transaction);

                    dispatch(
                        setSelectedBlock({
                            type: "placeholder",
                            props: { id: placeholderId, attrs: { id: placeholderId } },
                        })
                    );
                }
            } else if ((event.ctrlKey || event.metaKey) && event.key == "a" && currentEditable.current) {
                event.preventDefault();

                selectAllText(currentEditable.current);
            } else if ((event.ctrlKey || event.metaKey) && event.key == "c") {
                event.preventDefault();

                const selection = window.getSelection()?.toString();

                copyTextToClipboard(selection ?? "", false);
            }
        };

        const handleFocus = (event: any) => {
            if (event.target.isContentEditable) {
                if (!event.target.classList.contains("tiptap")) {
                    currentEditable.current = event.target;
                } else {
                    currentEditable.current = null;
                }
            } else {
                currentEditable.current = null;
            }
        };

        const handleBlur = (event) => {
            if (currentEditable === event.target) {
                currentEditable.current = null;
            }
        };

        window.addEventListener("focus", handleFocus, true);
        window.addEventListener("blur", handleBlur, true);

        window.addEventListener("keydown", handleKeyDown);

        return () => {
            window.removeEventListener("keydown", handleKeyDown);
            window.removeEventListener("focus", handleFocus, true);
            window.removeEventListener("blur", handleBlur, true);
        };
    }, [editor, selectedBlock]);

    useEffect(() => {
        if (setSteps) {
            setSteps([
                {
                    selector: ".blocks-sidebar",
                    content: () => (
                        <Content
                            title="Blocks"
                            body="When publishing a project, we first help you create a cover page. A cover page is comprised of blocks. Take a look at the list that’s been prepared for you on the left. You can drag + drop blocks anywhere below the project header."
                        />
                    ),
                    position: [290, 87],
                    styles: {
                        maskWrapper: (props: any) => ({
                            ...props,
                            color: "#848993",
                        }),
                    },
                },
                {
                    selector: ".editor-parent",
                    content: () => (
                        <Content
                            title="Editor"
                            body="Once you have your blocks in the editor, you can begin editing the content inside of them. Go one by one and edit content, or make an outline of blocks to come back to later & populate."
                        />
                    ),
                    position: [-24, 24],
                    styles: {
                        popover: (props: any) => ({
                            ...props,
                            right: 0,
                            left: "unset",
                        }),
                        maskWrapper: (props: any) => ({
                            ...props,
                            color: "#848993",
                        }),
                    },
                },
                {
                    selector: ".properties-sidebar",
                    content: () => (
                        <Content
                            title="Properties"
                            body="Every block you add in the editor, has some properties that you can edit using the properties panel like color, width, and position. If there are any properties not made available that you would like to see, please don’t hesitate to submit a feature request ticket!"
                        />
                    ),
                    position: [-290, 87],
                    styles: {
                        popover: (props: any) => ({
                            ...props,
                            right: 0,
                            left: "unset",
                        }),
                        maskWrapper: (props: any) => ({
                            ...props,
                            color: "#848993",
                        }),
                    },
                },
                {
                    selector: ".editor-topbar-controls",
                    content: () => (
                        <Content
                            title="Publishing Board"
                            body="Finally, when you are ready to share your project with the world, you will enter the publishing section."
                        />
                    ),
                    position: [-16, 77],
                    styles: {
                        popover: (props: any) => ({
                            ...props,
                            right: 0,
                            left: "unset",
                        }),
                        maskWrapper: (props: any) => ({
                            ...props,
                            color: "#848993",
                        }),
                    },
                },
                {
                    selector: ".publish-board-sidebar",
                    content: () => (
                        <Content
                            title="Publishing Board"
                            body="Finally, you are able to publish your first project. You can chose the privacy settings of the generated link itself, as well as the assets inside. Go ahead and try to make your project public. No one can see a public project unless they possess the newly created link. You can always add updates to your project that get published in real-time."
                        />
                    ),
                    position: [-652, 12],
                    styles: {
                        popover: (props: any) => ({
                            ...props,
                            right: 0,
                            left: "unset",
                        }),
                        maskWrapper: (props: any) => ({
                            ...props,
                            color: "#848993",
                            opacity: 0,
                        }),
                    },
                },
            ]);

            let timer: any = null;

            const checkIfInitialized = () => {
                if (document.querySelector(".blocks-sidebar")) {
                    if (!window.sessionStorage.getItem("editor-tutorial-show")) {
                        setIsOpen(true);
                    }

                    window.sessionStorage.setItem("editor-tutorial-show", "true");
                    clearInterval(timer);
                }
            };

            timer = setInterval(checkIfInitialized, 500);
        }
    }, [setSteps, setIsOpen]);

    useEffect(() => {
        if (currentStep == 4) {
            if (!document.querySelector(".publish-board-sidebar")) {
                setIsOpen(false);
            }

            // onOpenShareSidebar();

            // setTimeout(() => {
            //     setIsOpen(true);
            //     if (document.querySelector(".reactour__popover")) {
            //         (document.querySelector(".reactour__popover") as any)!.style.display = "block";
            //     }
            // }, 0);
        } else if (currentStep == 3) {
            dispatch(closeShareSidebar());
        }
    }, [currentStep]);

    return {
        isSelectingTemplate,
        project,
        onOpenProject,
        onSaveAndPreviewClickedHandler,
        onClosePreviewClickedHandler,
        isShareDialogOpen,
        showShareDialog,
        hideShareDialog,
        isPreviewOpen,
        isSaving,
        isOnboardingFlow,
        updateRes,
        onSkipOrNextStep,
        isSavedOnce,
        isShowPageLoader,
        onOpenShareSidebar,
        isEditorDirty,
        saveStatus,
        isTutorialOpen,
    };
};

export default usePublishableProjectLayout;
