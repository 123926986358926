import moment from "moment";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import RenderImage from "shared/components/ui/RenderImage";
import URL from "shared/constants/navigator";
import { GetMyScansQuery, useGetMyScansQuery } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { rtkHandler } from "shared/utils/handlers";
import { routeWithParams } from "shared/utils/helpers";
import DashboardCard from "../components/DashboardCard";
import SwiperSlider from "../components/Slider";

const RecentScans = () => {
    const navigate = useNavigate();
    const [scans, setScans] = useState<GetMyScansQuery["scans"]>([]);
    const { isTeamExpired } = useAuth();

    const scanRes = useGetMyScansQuery({
        filters: {
            sort: "ascending",
        },
    });

    useEffect(() => {
        rtkHandler(scanRes, {
            onSuccess({ scans }: GetMyScansQuery) {
                setScans(scans);
            },
        });
    }, [scanRes]);

    if (scans.length === 0) return <></>;

    return (
        <DashboardCard>
            <h4 className="text-[#101828] text-[18px] font-medium leading-7 mb-5">Recent Scans</h4>
            <div className="relative">
                <SwiperSlider
                    data={scans}
                    renderItem={(item) => {
                        return (
                            <div
                                key={item.scan_id}
                                onClick={() =>
                                    !isTeamExpired &&
                                    navigate(
                                        routeWithParams(
                                            `${URL.Media}/${URL.Media_Project_Detail}/${URL.Media_Folder_Detail}/${URL.Media_Folder_Scan_Detail}`,
                                            {
                                                id: item.project_id,
                                                folder_id: item.folder_id,
                                                scan_id: item.scan_id,
                                            }
                                        )
                                    )
                                }
                                className={!isTeamExpired ? "cursor-pointer" : ""}
                            >
                                <RenderImage
                                    image={item.input_file.thumbnail || ""}
                                    containerClassName="h-[200px] w-full rounded-lg mb-[6px]"
                                />
                                <h5 className="text-[#101828] text-md font-medium leading-5 mb-1">{item.name}</h5>
                                <p className="text-primary text-sm leading-5 text-ellipsis overflow-hidden whitespace-nowrap">
                                    {moment(item.created_at).format("DD MMM YYYY")}
                                    {item.scanning_device ? "• " + item.scanning_device.name : ""}
                                </p>
                            </div>
                        );
                    }}
                />
            </div>
        </DashboardCard>
    );
};

export default RecentScans;
