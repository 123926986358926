import { useEffect, useState } from "react";
import { GetAssetsQuery, GetMyScansQuery, useGetAssetsQuery, useGetMyScansQuery } from "shared/graphql";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";

const useGetAssetsScans = () => {
    const { id, folder_id } = useEncryptedParams<{ id: string; folder_id: string }>();

    const [loading, setLoading] = useState(true);
    const [scans, setScans] = useState<GetMyScansQuery["scans"]>([]);
    const [assets, setAssets] = useState<GetAssetsQuery["assets"]>([]);
    
    const scanRes = useGetMyScansQuery({
        filters: {
            project_id: id ? parseInt(id) : -1,
            folder_id: folder_id ? parseInt(folder_id) : -1,
        },
    });

    const assetRes = useGetAssetsQuery({
        filters: {
            project_id: id ? parseInt(id) : -1,
            folder_id: -1,
        },
    });

    useEffect(() => {
        setLoading(scanRes.isLoading || assetRes.isLoading);
    }, [scanRes.isLoading, assetRes.isLoading]);

    useEffect(() => {
        rtkHandler(scanRes, {
            onSuccess: (res) => {
                setScans(res.scans);
            },
        });
    }, [scanRes]);

    useEffect(() => {
        rtkHandler(assetRes, {
            onSuccess: (res) => {
                setAssets(res.assets);
            },
        });
    }, [assetRes]);

    return { assets, scans, loading };
};

export default useGetAssetsScans;
