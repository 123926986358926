import Bookmark from "@untitled-ui/icons-react/build/esm/Bookmark";
import PageHeader from "shared/components/ui/PageHeader";
import useKnowledgeLibraryScreen from "./hook";
import RenderImage from "shared/components/ui/RenderImage";
import moment from "moment";
import SwiperSlider from "./components";
import LibraryLoading from "./components/loading";
import EmptyCard from "./components/empty";

const KnowledgeLibraryScreen = () => {
    const hook = useKnowledgeLibraryScreen();

    return (
        <>
            <PageHeader
                title={
                    <div className="flex items-center space-x-1">
                        <Bookmark height={22} width={22} />
                        <span>Knowledge Library</span>
                    </div>
                }
            />
            <div className="px-8 py-6">
                {hook.response.isLoading ? (
                    <LibraryLoading />
                ) : hook.libraryData.length ? (
                    hook.libraryData.map((data) => (
                        <div key={data.category} className="border rounded-md p-5 border-[#EAECF0] mb-6">
                            <h2 className="mb-6 text-[#344054] text-[20px] leading-6">{data.category}</h2>
                            <SwiperSlider
                                data={data.data}
                                renderItem={(item) => {
                                    return (
                                        <a
                                            href={item.url}
                                            target="_blank"
                                            rel="noreferrer"
                                            key={item.knowledge_library_id}
                                        >
                                            <div className="w-full">
                                                <RenderImage
                                                    image={item.metadata?.image ?? ""}
                                                    containerClassName="h-[190px] rounded-lg"
                                                />
                                                <div className="mt-2">
                                                    <h5 className="text-[#475467] text-md leading-5 mb-1 font-medium">
                                                        {item?.metadata?.title ?? ""}
                                                    </h5>
                                                    <h5 className="text-[#98A2B3] text-sm leading-5 mb-1 font-medium">
                                                        {item?.metadata?.date
                                                            ? moment(item?.metadata?.date).format("DD MMM YYYY")
                                                            : ""}
                                                    </h5>
                                                </div>
                                            </div>
                                        </a>
                                    );
                                }}
                            />
                        </div>
                    ))
                ) : (
                    <EmptyCard
                        title="Knowledge Library"
                        description="No Articles Found"
                        icon={<Bookmark height={22} width={22} />}
                    />
                )}
            </div>
        </>
    );
};

export default KnowledgeLibraryScreen;
