import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { AssetVisibility, useEditAssetMutation } from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { useEncryptedParams } from "shared/utils/encrypt-url";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setSelectedAsset } from "store/slices/scans";

const schema = yup.object().shape({
    visibility: yup.string().oneOf(Object.values(AssetVisibility)).required(),
});

type FormValues = yup.InferType<typeof schema>;

const usePublishAsset = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { id, folder_id } = useEncryptedParams<{ folder_id: string; id: string; asset_id: string }>();
    const [updateAsset, updateRes] = useEditAssetMutation();
    const [clickedEmail, setClickedEmail] = useState("");

    const selected = useAppSelector((state) => state.scans.asset);
    const project = useAppSelector((state) => state.projects.selected);
    const folder = project?.folders.find((folder) => folder.folder_id === parseInt(folder_id as string));

    const [open, setOpen] = useState(false);

    const form = useForm<FormValues>({
        defaultValues: {
            visibility: (selected?.visibility || AssetVisibility.Private) as any,
        },
        resolver: yupResolver(schema),
    });

    const visibility = form.watch("visibility");

    useEffect(() => {
        rtkHandler(updateRes, {
            successMessage: "Share settings updated successfully",
            onSuccess() {
                if (selected) dispatch(setSelectedAsset({ ...selected, visibility: visibility }));
                setOpen(false);
            },
        });
    }, [updateRes]);

    useEffect(() => {
        if (!folder && folder_id) {
            navigate(-1);
        } else {
            setOpen(true);
        }
    }, []);

    const onSubmit = (data: FormValues) => {
        if (!selected) return;
        updateAsset({
            asset_id: selected.asset_id,
            data: {
                visibility: data.visibility,
            },
        });
    };

    return {
        onSubmit,
        setClickedEmail,
        setOpen,
        form,
        clickedEmail,
        updateRes,
        selected,
        project,
        folder,
        folder_id,
        id,
        open,
        visibility,
    };
};

export default usePublishAsset;
