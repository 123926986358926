import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import {
    ChangeRegisterEmailMutation,
    ResendEmailOtpMutation,
    VerifyOtpMutation,
    useChangeRegisterEmailMutation,
    useResendEmailOtpMutation,
    useVerifyOtpMutation,
} from "shared/graphql";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";
import { rtkHandler } from "shared/utils/handlers";
import yup from "shared/utils/yup";
import { setVerificationData } from "store/slices/verifyUser";

const schema = yup.object().shape({
    email: yup.string().email().required(),
});

const otpSchema = yup.object().shape({
    otp: yup
        .string()
        .required("This field is required")
        .min(6, "Otp must be at least 6 number")
        .max(6, "Otp must be at least 6 number"),
});

type FormValues = yup.InferType<typeof schema>;

type OtpFormValues = yup.InferType<typeof otpSchema>;

const useRegisterSuccess = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const { email, email_verification_id } = useAppSelector((state) => state.verifyUser.verificationData);
    const [verifyOtp, verifyRes] = useVerifyOtpMutation();
    const [changeEmail, res] = useChangeRegisterEmailMutation();
    const [resendEmailOtp, resendEmailRes] = useResendEmailOtpMutation();
    const [doRefresh, setDoRefresh] = useState(false);

    const emailForm = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: "",
        },
    });

    const otpForm = useForm<OtpFormValues>({
        resolver: yupResolver(otpSchema),
        defaultValues: {
            otp: "",
        },
    });

    useEffect(() => {
        rtkHandler(verifyRes, {
            successMessage: "Email verified successfully",
            onSuccess({ verify_otp }: VerifyOtpMutation) {
                if (verify_otp.user.waiting_list) {
                    navigate(URL.Waiting_List, {
                        state: {
                            waiting_list: true,
                        },
                    });
                } else {
                    navigate(URL.GetStarted);
                }
            },
        });
    }, [verifyRes, dispatch]);

    useEffect(() => {
        rtkHandler(res, {
            onSuccess(response: ChangeRegisterEmailMutation) {
                setOpenEmailModal(false);
                setDoRefresh(true);
                dispatch(
                    setVerificationData({
                        email: response.change_signup_email.data,
                        email_verification_id: response.change_signup_email.verification_id,
                    })
                );
            },
        });
    }, [res]);

    useEffect(() => {
        rtkHandler(resendEmailRes, {
            successMessage: "Email OTP sent successfully",
            onSuccess(response: ResendEmailOtpMutation) {
                setOpenEmailModal(false);
                setDoRefresh(true);
                dispatch(
                    setVerificationData({
                        email: response.resend_email_otp.data,
                        email_verification_id: response.resend_email_otp.verification_id,
                    })
                );
            },
        });
    }, [resendEmailRes]);

    const onOtpSubmit = (values: OtpFormValues) => {
        if (email_verification_id)
            verifyOtp({
                data: {
                    email_otp: values.otp,
                    email_verification_id,
                },
            });
    };

    const onEmailSubmit = (values: FormValues) => {
        if (email_verification_id)
            changeEmail({
                email,
                new_email: values.email,
                email_verification_id,
            });
    };

    const onResendEmailOtp = async () => {
        try {
            const { resend_email_otp } = await resendEmailOtp({ email }).then((res: any) => res.data);
            dispatch(
                setVerificationData({
                    email_verification_id: resend_email_otp.verification_id,
                })
            );
            return true;
        } catch (error) {
            return false;
        }
    };

    return {
        email,
        email_verification_id,
        otpForm,
        emailForm,
        res,
        onResendEmailOtp,
        resendEmailRes,
        openEmailModal,
        doRefresh,
        setOpenEmailModal,
        onOtpSubmit,
        onEmailSubmit,
    };
};

export default useRegisterSuccess;
