import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material";
import useImages from "assets/images";
import ThemeAutoComplete from "shared/components/Fields/ThemeAutoComplete";
import TeamInvitationRequestModal from "shared/components/TeamInvitationRequestModal";
import UserProfileCard from "shared/components/UserProfileCard";
import PageLoader from "shared/components/ui/PageLoader";
import URL from "shared/constants/navigator";
import {
    GetInvitationsQuery,
    GetProfilesQuery,
    Profile,
    useGetInvitationsQuery,
    useGetProfilesQuery,
    useSwitchProfileMutation,
} from "shared/graphql";
import useAuth from "shared/hooks/useAuth";
import { useAppSelector } from "shared/hooks/useRedux";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import { rtkHandler } from "shared/utils/handlers";
import { twMerge } from "tailwind-merge";

const ProfileSwitcher = () => {
    const { ProfileIcon } = useImages();
    const { profile } = useAuth();
    const navigate = useNavigate();

    const isSidebarCollapsed = useAppSelector((state) => state.utils.isSidebarCollapsed);

    const [selectedProfile, setSelectedProfile] = useState<ISelectMenuList>({
        value: "",
        label: "",
    });

    const [profiles, setProfiles] = useState<Profile[]>([]);
    const [invitations, setInvitations] = useState<GetInvitationsQuery["invitations"]>([]);
    const [skip, setSkip] = useState(true);
    const [profileOptions, setProfileOptions] = useState<OptionArray[]>([]);

    const [openInvitationModal, setOpenInvitationModal] = useState(false);

    const [switchProfile, switchRes] = useSwitchProfileMutation();
    const invitationsRes = useGetInvitationsQuery(
        {},
        {
            skip,
        }
    );

    const profileRes = useGetProfilesQuery(
        {},
        {
            skip,
        }
    );

    useEffect(() => {
        if (!profile) return;
        setSelectedProfile({
            value: profile?.profile_id.toString() || "",
            label: profile?.name || "",
        });
    }, [profile]);

    const onProfileChange = (val: OptionArray) => {
        if (val.type === "Invitations") {
            setOpenInvitationModal(true);
            return;
        }

        setSelectedProfile({
            value: val.id,
            label: val.name,
        });
        if (val && val.id !== profile?.profile_id.toString()) {
            switchProfile({ id: parseInt(val.id) });
        }
    };

    const onOpenProject = () => {
        setSkip(false);
    };

    const profileImage = profile?.profile_image?.url;

    useEffect(() => {
        rtkHandler(switchRes, {
            onSuccess() {
                navigate(URL.Dashboard, { replace: true });
            },
        });
    }, [switchRes]);

    useEffect(() => {
        rtkHandler(profileRes, {
            onSuccess(res: GetProfilesQuery) {
                setProfiles(res.profiles as Profile[]);
            },
        });
    }, [profileRes]);

    useEffect(() => {
        rtkHandler(invitationsRes, {
            onSuccess(res: GetInvitationsQuery) {
                setInvitations(res.invitations);
            },
        });
    }, [invitationsRes]);

    useEffect(() => {
        const profileArr: OptionArray[] = [];
        if (profiles.length > 0) {
            profiles
                .filter((p) => p.type !== "Default")
                .forEach((item) => {
                    profileArr.push({
                        email: [item.username, item.type].filter((i) => i).join(" - "),
                        name: item.profile_id === profile?.profile_id ? profile.name : item.name,
                        image: item.profile_image?.url || "",
                        id: item.profile_id.toString(),
                        type: "Active",
                    });
                });
        }

        if (invitations.length > 0) {
            invitations.forEach((item) => {
                profileArr.push({
                    email: "Team Member",
                    name: item.name,
                    image: item.logo.url || "",
                    id: item.team_id.toString(),
                    type: "Invitations",
                });
            });
        }

        setProfileOptions(profileArr);
    }, [profiles, profile, profileOptions, invitations]);

    return (
        <>
            {switchRes.isLoading && <PageLoader className="fixed top-0 left-0 w-screen h-screen z-50" />}
            <ThemeAutoComplete
                placeholder="Profile"
                value={selectedProfile}
                onChange={(_, value) => onProfileChange(value)}
                inputTextProps={{
                    startAdornment: profileImage ? (
                        <img
                            src={profileImage}
                            className={twMerge(
                                "w-[20px] h-[20px] rounded-full object-cover object-center !ml-3",
                                isSidebarCollapsed && "-ml-[2px] group-hover:ml-0"
                            )}
                        />
                    ) : (
                        <span className={"" + (isSidebarCollapsed && "-ml-[2px] group-hover:ml-0")}>
                            <ProfileIcon width={20} height={20} />
                        </span>
                    ),
                    readOnly: true,
                    sx: {
                        ".MuiInputBase-input": {
                            paddingRight: "35px!important",
                        },
                    },
                }}
                classes={
                    isSidebarCollapsed
                        ? {
                              endAdornment: "hidden group-hover:inline-block",
                              input: "!hidden group-hover:!block",
                          }
                        : {}
                }
                options={profileOptions
                    .sort((a) => {
                        if (a.type === "Invitations") return -1;
                        return 1;
                    })
                    .map((item) => {
                        return {
                            value: item.id.toString(),
                            label: item.name,
                            ...item,
                        };
                    })}
                autoCompleteProps={{
                    loading: profileRes.isLoading || invitationsRes.isLoading,
                    loadingText: "Loading...",
                    onOpen: onOpenProject,
                    getOptionLabel(option: ISelectMenuList) {
                        return option ? option.label : "";
                    },
                    groupBy: (option) => option.type,
                    renderGroup: (params) => {
                        return (
                            <li className={"mb-[10px] last:mb-0"} key={params.key}>
                                <GroupHeader>{params.group}</GroupHeader>
                                <GroupItems>{params.children}</GroupItems>
                            </li>
                        );
                    },
                    renderOption(props, option: ISelectMenuList) {
                        const { value } = option;
                        const profile = profileOptions.find((item) => item.id.toString() === value);
                        if (!profile) return;

                        return (
                            <li {...props}>
                                <UserProfileCard
                                    image={profile?.image}
                                    name={profile.name}
                                    email={profile.email}
                                    classes={{
                                        name: "font-medium text-ellipsis overflow-hidden w-[125px] whitespace-nowrap",
                                        email: "text-sm",
                                        image: "!w-[25px] !h-[25px]",
                                    }}
                                />
                            </li>
                        );
                    },
                    isOptionEqualToValue(option: ISelectMenuList, value: ISelectMenuList) {
                        return option.value === value.value;
                    },
                    disableClearable: true,
                }}
            />
            <TeamInvitationRequestModal open={openInvitationModal} onClose={() => setOpenInvitationModal(false)} />
        </>
    );
};

export default ProfileSwitcher;

interface OptionArray {
    email: string;
    name: string;
    image: string;
    id: string;
    type?: string;
}

const GroupHeader = styled("div")(() => ({
    position: "sticky",
    top: "-8px",
    padding: "4px 10px 6px",
    color: "#98A2B3",
    backgroundColor: "#ffffff",
}));

const GroupItems = styled("ul")({
    padding: 0,
});
