import dedent from "dedent";

const EmbedData = (scan_name: string, link: string, width: number, height: number) => {
    return dedent`<html>
    <body>
        <iframe src="${link}"
            title="${scan_name}"
            width="${width}px"
            height="${height}px"
        ></iframe>
    </body>
</html>`;
};

export default EmbedData;
