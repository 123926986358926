import ArrowUpRight from "@untitled-ui/icons-react/build/esm/ArrowUpRight";
import Copy02 from "@untitled-ui/icons-react/build/esm/Copy02";
import HeaderActionBtns, { IHeaderMenuButtonProps } from "shared/components/HeaderActionBtns";
import { useAppSelector } from "shared/hooks/useRedux";
import { copyTextToClipboard } from "shared/utils/helpers";
import { successToast } from "shared/utils/toast";

const ProjectHeaderActions = () => {
    const project = useAppSelector((state) => state.projects.selected);

    const menus = [
        project?.visible &&
            project.published_file && {
                icon: <Copy02 color="#667085" height={20} width={20} />,
                actionTitle: "Copy Published Project Link",
                className: "!border-[#D0D5DD] !border !border-solid !border-[1px] !mr-3 !h-[36px] !w-[40px]",
                onClick: () => {
                    successToast("Copied to clipboard");
                    copyTextToClipboard(`${process.env.REACT_APP_PUBLISHED_PROJECT_HOST_URL}${project?.slug}`);
                },
            },
        project?.visible &&
            project.published_file && {
                icon: <ArrowUpRight color="#667085" height={20} width={20} />,
                actionTitle: "Copy Published Project Link",
                className: "!border-[#D0D5DD] !border !border-solid !border-[1px] !text-[#344054] !h-[36px] !px-2",
                text: "View Published Project",
                onClick: () => {
                    window.open(`${process.env.REACT_APP_PUBLISHED_PROJECT_HOST_URL}${project?.slug}`, "_blank");
                },
            },
    ].filter(Boolean) as IHeaderMenuButtonProps[];

    return <HeaderActionBtns buttons={menus} mergeLastButton={true} closeUrl="" />;
};

export default ProjectHeaderActions;
