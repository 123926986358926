import yup from "shared/utils/yup";

export enum PostAssetFormSteps {
    Upload = "upload",
    Basic = "basic",
}

export const submitAssetSchema = yup.object().shape({
    step: yup.string().required().oneOf(Object.values(PostAssetFormSteps)),
    id: yup.string(),
    existingAssets: yup.array().of(yup.string()),
    project: yup.number().nullable(),
    folder: yup.number().nullable(),
    name: yup
        .string()
        .when("step", {
            is: PostAssetFormSteps.Basic,
            then: (schema) => schema.required(),
        })
        .test("unique", "Video Name already exists", function (value, ctx) {
            const { existingAssets, id } = ctx.parent;
            return !(existingAssets && existingAssets.includes(value) && !id);
        }),
    focus: yup.string().when("step", {
        is: PostAssetFormSteps.Basic,
        then: (schema) => schema.required(),
    }),
    file_id: yup
        .number()
        .nullable()
        .when("step", {
            is: PostAssetFormSteps.Upload,
            then: (schema) => schema.required(),
        }),
    location: yup.object().shape({
        longitude: yup.string().required(),
        latitude: yup.string().required(),
    }),
});

export type SubmitAssetFormValues = yup.InferType<typeof submitAssetSchema>;
