import { Button } from "@mui/material";
import ProjectBreadcrumbs from "screens/Dashboard/Projects/components/ProjectBreadcrumbs";
import ProjectDrawer from "screens/Dashboard/Projects/components/ProjectDrawer";
import Field from "shared/components/ui/Field";
import LoaderButton from "shared/components/ui/LoaderButton";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { AssetVisibility } from "shared/graphql";
import { ISelectMenuList } from "shared/types/utils/utils.type";
import usePublishAsset from "./hook";

const PublishAssetScreen = () => {
    const hook = usePublishAsset();

    return (
        <ProjectDrawer
            header={<ProjectBreadcrumbs drawer={true} />}
            open={hook.open}
            onClose={() => hook.setOpen(false)}
            title="Share Asset"
            description="Select one of the options below to change the visibility of your asset."
        >
            <ThemeFormProvider form={hook.form} onSubmit={hook.onSubmit} className="text-left">
                <Field
                    name="visibility"
                    label="Visibility"
                    type="select"
                    options={
                        [
                            { label: "Private", value: AssetVisibility.Private },
                            { label: "Public", value: AssetVisibility.Public },
                        ].filter((i) => i) as ISelectMenuList[]
                    }
                />
                <div className="flex items-center justify-end space-x-4 mt-6">
                    <Button onClick={() => hook.setOpen(false)} variant="outlined" color="inherit">
                        Cancel
                    </Button>
                    <LoaderButton loading={hook.updateRes.isLoading} variant="contained">
                        Save Changes
                    </LoaderButton>
                </div>
            </ThemeFormProvider>
        </ProjectDrawer>
    );
};

export default PublishAssetScreen;
