import { useEffect, useState } from "react";
import { matchRoutes, useLocation, useNavigate, useParams } from "react-router-dom";

import useImages from "assets/images";
import useSRs from "screens/Dashboard/ScanRequests/common/hooks/useSRs";
import CollapseableList from "shared/components/CollapseableList";
import RequestorSwitch, { RequestorTypes } from "shared/components/RequestorSwitch";
import ScanRequestListItem from "shared/components/ScanRequestListItem/item";
import ScanRequestListItemLoader from "shared/components/ScanRequestListItem/loading";
import SRScanListItem from "shared/components/SRScanListItem";
import ThemeListCard from "shared/components/ui/ThemeListCard";
import { GetScanRequestByIdQuery } from "shared/graphql";
import useAuth from "shared/hooks/useAuth";

import useHasPermission from "shared/components/HasPermission/hook";
import Permissions from "shared/components/HasPermission/Permissions";
import useSingleSR from "../../hooks/useSingleSR";

interface Props {
    type: "new" | "in-progress" | "delivered";
}

type ScanSubmissions = GetScanRequestByIdQuery["scan_request"]["applications"][0]["submissions"][0];

const SRLists = (props: Props) => {
    const { id, type } = useParams<{ id?: string; type: string }>();
    const { requests, requestByType, scanReq } = useSRs();
    const { request } = useSingleSR();
    const { user, isTeamMember } = useAuth();
    const Images = useImages();
    const navigate = useNavigate();
    const UserHasRequesteeAccess = useHasPermission(Permissions.Can_View_Scan_Request_Requestee);
    const [mode, setMode] = useState<RequestorTypes>("requestee");

    const currentLocation = useLocation();
    const scanRoute = matchRoutes(
        [
            { path: `/requests/${type}/detail/:id/scans/:scanId` },
            { path: `/requests/${type}/detail/:id/scans/:scanId/edit` },
            { path: `/requests/${type}/detail/:id/scans/:scanId/delete` },
            { path: `/requests/${type}/detail/:id/scans/:scanId/download` },
        ],
        currentLocation
    )?.length;

    const scans = scanRoute
        ? request?.applications.reduce((acc, item) => [...acc, ...item.submissions], [] as ScanSubmissions[]) || []
        : [];

    useEffect(() => {
        if (id && requests) {
            const cReq = requests.find((request) => request.scan_request_id === parseInt(id));
            if (cReq?.requestor.user_id === user?.user_id) {
                setMode("requestor");
            } else {
                setMode("requestee");
            }
        } else {
            setMode(UserHasRequesteeAccess ? "requestee" : "requestor");
        }
    }, [requests, id]);

    const modeRequests = requestByType[mode];

    return (
        <>
            <ThemeListCard
                listHeader={
                    <div className="flex items-center justify-between flex-1">
                        <h4 className="text-p text-[20px] font-medium leading-6 flex flex-wrap items-center">
                            <span
                                className={scanRoute ? "cursor-pointer" : ""}
                                onClick={() => {
                                    navigate(`/requests/${props.type}/detail/${id}`);
                                }}
                            >
                                Project Requests
                            </span>
                            {scanRoute && (
                                <>
                                    <Images.ArrowChevronRight color="#7680FF" />
                                    {request?.title}
                                </>
                            )}
                        </h4>
                        {!scanRoute && props.type !== "delivered" && !isTeamMember && (
                            <RequestorSwitch value={mode} onChange={(type) => setMode(type)} />
                        )}
                    </div>
                }
                options={{
                    onSearch: (value) =>
                        navigate({
                            search: value ? `?search_key=${value}` : "",
                        }),
                    onAddClick:
                        !isTeamMember && props.type === "new" ? () => navigate("/requests/new/post") : undefined,
                }}
                renderList={() => {
                    return scanRoute ? (
                        <div className="flex flex-col space-y-4">
                            {scans.map((scan) => {
                                return (
                                    <SRScanListItem
                                        key={scan.scan_id}
                                        url={`/requests/${props.type}/detail/${request?.scan_request_id}/scans/${scan.scan_id}`}
                                        request={request!}
                                        scan={scan}
                                    />
                                );
                            })}
                        </div>
                    ) : (
                        <div className="flex flex-col space-y-4 pb-5">
                            {scanReq.isLoading ? (
                                <ScanRequestListItemLoader label={false} />
                            ) : (
                                <>
                                    {type === "delivered" ? (
                                        <>
                                            {requests.filter((req) => req.status === "Delivered").length ? (
                                                requests
                                                    .filter((req) => req.status === "Delivered")
                                                    .map((req) => {
                                                        return (
                                                            <ScanRequestListItem
                                                                key={req.scan_request_id}
                                                                url={`/requests/${props.type}/detail/${req.scan_request_id}`}
                                                                mode={mode}
                                                                request={req}
                                                            />
                                                        );
                                                    })
                                            ) : (
                                                <h4 className="text-p text-[14px] leading-6 text-center">
                                                    No project requests found
                                                </h4>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            {Object.keys(modeRequests).length ? (
                                                Object.keys(modeRequests).map((req, i) => {
                                                    return (
                                                        <CollapseableList title={req} key={i}>
                                                            <div className="flex flex-col space-y-4">
                                                                {modeRequests[req].map((request) => {
                                                                    return (
                                                                        <ScanRequestListItem
                                                                            key={request.scan_request_id}
                                                                            url={`/requests/${props.type}/detail/${request.scan_request_id}`}
                                                                            mode={mode}
                                                                            request={request}
                                                                        />
                                                                    );
                                                                })}
                                                            </div>
                                                        </CollapseableList>
                                                    );
                                                })
                                            ) : (
                                                <h4 className="text-p text-[14px] leading-6 text-center">
                                                    No project requests found
                                                </h4>
                                            )}
                                        </>
                                    )}
                                </>
                            )}
                        </div>
                    );
                }}
            />
        </>
    );
};

export default SRLists;
