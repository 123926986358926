import { Button } from "@mui/material";
import useImages from "assets/images";
import Banner from "assets/images/banners/Join-Team-Request-Submitted-Banner.png";
import { useNavigate } from "react-router-dom";
import URL from "shared/constants/navigator";
import useAuth from "shared/hooks/useAuth";

const JoinTeamRequestSubmittedScreen = () => {
    const { LogoPNG } = useImages();
    const navigate = useNavigate();
    const { user } = useAuth();

    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const teamName = urlParams.get("team_name");

    return (
        <div className="w-full h-screen p-8">
            {user && (
                <Button
                    className="!fixed top-8 right-8 !rounded-[40px]"
                    variant="outlined"
                    color="inherit"
                    onClick={() => {
                        navigate(URL.Home, {
                            replace: true,
                        });
                    }}
                >
                    Back
                </Button>
            )}
            <div className="h-1/2 flex flex-col items-center">
                <div className="w-full">
                    <img src={LogoPNG} className="w-[150px]" alt="logo" />
                </div>
                <div className="flex-1 w-full flex items-center justify-center">
                    <div className="w-[510px] text-center">
                        <h4 className="text-[20px] text-[#5E5D5D] font-medium">Request Submitted</h4>
                        <p className="text-[#667085] mt-2 mb-4">
                            Your request to join team <span className="text-[#7680FF] font-medium">{teamName}</span>{" "}
                            has been submitted successfully. You will be notified once your request is accepted by the
                            team admins.
                        </p>
                    </div>
                </div>
            </div>
            <img src={Banner} className="w-full h-1/2 object-cover rounded-t-[24px]" />
        </div>
    );
};

export default JoinTeamRequestSubmittedScreen;
