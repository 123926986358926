import useImages from "assets/images";
import Obstacle2 from "assets/images/banners/Complete-trip-Obstacle.png";
import { useEffect } from "react";
import PerfectScrollbar from "react-perfect-scrollbar";
import { Outlet, matchRoutes, useLocation, useNavigate } from "react-router-dom";
import useAuth from "shared/hooks/useAuth";
import { twMerge } from "tailwind-merge";
import AuthHeader from "../../components/AuthHeader";
import URL from "shared/constants/navigator";

const CompleteProfileLayout = () => {
    const Images = useImages();
    const { user } = useAuth();
    const navigate = useNavigate();
    const { isProjectEditor, isProjectFinal } = useIsProjectEditor();

    useEffect(() => {
        if (
            (user?.user_registration_history?.completed_steps ?? 0) >=
            (user?.user_registration_history?.total_steps ?? 0)
        ) {
            navigate(URL.Dashboard, { replace: true });
        }
    }, [user]);

    return (
        <div
            className={twMerge(
                "grid relative h-screen transition-300",
                isProjectEditor ? "grid-cols-[112px,1fr]" : isProjectFinal ? "grid-cols-1" : "grid-cols-[400px,1fr]"
            )}
        >
            {!isProjectFinal && (
                <>
                    <div className="absolute w-full left-0 top-0">
                        <AuthHeader />
                    </div>
                    <div className="bg-[#F9FAFB] h-full pt-[136px] px-8">
                        <StepItem
                            icon={Images.UserIcon}
                            title="Your Personal Details"
                            description="Start with creating an account"
                            href={"/complete-profile/personal"}
                        />
                        <StepItem
                            icon={Images.Passcode}
                            title="Getting to Know You"
                            description="What describes you the best"
                            href={"/complete-profile/identity"}
                            isLast={true}
                        />
                        {/* <StepItem
                            icon={Images.UsersIcon}
                            title="Your Team Account"
                            description="Start collaborating with your team"
                            href={"/complete-profile/team"}
                        />
                        <StepItem
                            icon={Images.ModelIcon}
                            title="Publish Your First Project"
                            description="Take a quick walkthrough of the platform"
                            isLast={true}
                            href={"/complete-profile/project"}
                        /> */}
                    </div>
                </>
            )}
            <PerfectScrollbar className="relative">
                <img
                    src={Obstacle2}
                    className="h-[768px] w-[768px] !absolute top-0 left-1/2 transform -translate-x-1/2 -z-10"
                />
                <Outlet />
            </PerfectScrollbar>
        </div>
    );
};

export default CompleteProfileLayout;

interface StepProps {
    title: string;
    description: string;
    icon: any;
    isLast?: boolean;
    href: string;
}

const StepItem = (props: StepProps) => {
    const Icon = props.icon;
    const location = useLocation();
    const { isProjectEditor } = useIsProjectEditor();

    const isActive = location.pathname === props.href;

    return (
        <div
            className={twMerge(
                "flex items-center space-x-4 mb-8 relative whitespace-nowrap",
                !isActive && "opacity-60"
            )}
        >
            <div className="shadow-[0px_1px_2px_0px_#1018280D] border border-[#EAECF0] h-12 min-w-[48px] w-12 rounded-xl flex items-center justify-center">
                <Icon heigth={24} width={24} color="#344054" />
            </div>
            {!props.isLast && (
                <div className="w-[2px] h-[26px] bg-[#EAECF0] rounded-[2px] absolute left-2 top-[53px]" />
            )}
            {!isProjectEditor && (
                <div className="text-lg leading-6">
                    <h5 className="font-medium text-[#344054] mb-[2px]">{props.title}</h5>
                    <p className="text-[#475467] whitespace-normal">{props.description}</p>
                </div>
            )}
        </div>
    );
};

export const useIsProjectEditor = () => {
    const location = useLocation();
    const isProjectEditor = Boolean(
        matchRoutes(
            [
                {
                    path: "/complete-profile/project/:id/editor",
                },
            ],
            location
        )
    );

    const isProjectFinal = Boolean(
        matchRoutes(
            [
                {
                    path: "/complete-profile/final",
                },
            ],
            location
        )
    );

    return { isProjectEditor, isProjectFinal };
};
