import yup from "shared/utils/yup";

export enum PostScanFormSteps {
    Upload = "upload",
    Basic = "basic",
}

export const submitScanSchema = yup.object().shape({
    step: yup.string().required().oneOf(Object.values(PostScanFormSteps)),
    dataset_type: yup.string().required().oneOf(["images", "video"]),
    scan_id: yup.string(),
    category: yup.string(),
    existing_scans: yup.array().of(yup.string()),
    project: yup.number().nullable(),
    folder: yup.number().nullable(),
    name: yup
        .string()
        .when("step", {
            is: PostScanFormSteps.Basic,
            then: (schema) => schema.required(),
        })
        .test("unique", "Scan name already exists", function (value, ctx) {
            const { existing_scans, scan_id } = ctx.parent;
            return !(existing_scans && existing_scans.includes(value) && !scan_id);
        }),
    capture_intent: yup.string().when("step", {
        is: PostScanFormSteps.Basic,
        then: (schema) => schema.required(),
    }),
    input_file_id: yup
        .number()
        .nullable()
        .when("step", {
            is: PostScanFormSteps.Upload,
            then: (schema) => schema.required(),
        }),
    location: yup.object().shape({
        longitude: yup.string().required(),
        latitude: yup.string().required(),
    }),
    scanning_device_id: yup.string(),
    method: yup.string().default("nerfacto"),
});

export type SubmitScanFormValues = yup.InferType<typeof submitScanSchema>;
