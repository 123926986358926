import BlockMenuItem from "./BlockMenuItem";

interface IBlockMenuProps {
    title: string;
    items: {
        id: string;
        Icon: any;
        label: string;
        onAction: any;
    }[];
}

const BlockMenu = (props: IBlockMenuProps) => {
    const { title, items } = props;

    return (
        <div className="mb-6">
            <div className="mb-2 text-[#475467] text-md opacity-50 px-5 capitalize">{title}</div>
            <div className="flex flex-col w-full px-3">
                {items.map((item, i) => (
                    <BlockMenuItem key={i} {...item} />
                ))}
            </div>
        </div>
    );
};

export default BlockMenu;
