import { Editor } from "@tiptap/core";
import useImages from "assets/images";
import React, { useContext, useEffect, useRef } from "react";
import { useForm } from "react-hook-form";
import Field from "shared/components/ui/Field";
import ThemeFormProvider from "shared/components/ui/ThemeFormProvider";
import { useAppDispatch, useAppSelector } from "shared/hooks/useRedux";

import Template0 from "assets/files/template_0.json";
import Template1 from "assets/files/template_1.json";
import Template2 from "assets/files/template_2.json";
import { setSelectedTemplate } from "store/slices/publishable_project";
import { v4 as uuidv4 } from "uuid";
import { PublishableProjectsLayoutContext } from "screens/Dashboard/PublishableProjects/layout";

const BoardProperties = ({ editor }: { editor: Editor | null }) => {
    const dispatch = useAppDispatch();
    const { ArrowDown } = useImages();

    const { onSave } = useContext(PublishableProjectsLayoutContext);

    const selectedTemplate = useAppSelector((state) => state.publishable_project.selectedTemplate);

    const isInit = useRef(false);

    const { control, watch, setValue } = useForm<{
        template: string;
    }>({
        defaultValues: {
            template: selectedTemplate == -1 ? "0" : "" + selectedTemplate,
        },
    });

    const template_val = watch("template");

    const compareAndReplace = () => {
        if (!editor || selectedTemplate.toString() == template_val) return;

        let actualTemplate: any = Template0;
        if (template_val == "1") {
            actualTemplate = Template1;
        } else if (template_val == "2") {
            actualTemplate = Template2;
        }

        const current = editor.getJSON();

        const templateContent: any[] = [...actualTemplate["content"]];
        const currentContent: any[] = [...(current["content"] as any)];

        const currentStartIndex = currentContent.findIndex((node) => node.type == "projectLinksColumnComponent");
        const templateStartIndex = currentContent.findIndex((node) => node.type == "projectLinksColumnComponent");

        let finalContent: any[] = [];

        for (let i = 0; i <= templateStartIndex; i++) {
            const templateNode = templateContent[i];
            if (!templateNode) continue;

            for (let j = 0; j <= currentStartIndex; j++) {
                const currentNode = currentContent[j];
                if (
                    currentNode.type == templateNode.type &&
                    currentNode.attrs &&
                    (currentNode.attrs.id == undefined ||
                        (currentNode.attrs.id && currentNode.attrs.id == templateNode.attrs.id))
                ) {
                    finalContent.push({
                        type: currentNode.type,
                        attrs: {
                            ...currentNode.attrs,
                            ...("template" in currentNode.attrs ? { template: parseInt(template_val) } : {}),
                            ...("alignment" in currentNode.attrs ? { alignment: templateNode.attrs.alignment } : {}),
                            ...("size" in currentNode.attrs ? { size: templateNode.attrs.size } : {}),
                        },
                    });
                    break;
                } else {
                    if (templateNode.type == "divider" && currentNode.type == "divider") {
                        finalContent.push({
                            type: currentNode.type,
                        });
                        break;
                    }
                }
            }
        }

        const footerTemplateComponentIndex = templateContent.findIndex((node) => node.type == "footerComponent");

        const footerCurrentContentComponentIndex = currentContent.findIndex((node) => node.type == "footerComponent");
        const footerTemplateComponent =
            footerTemplateComponentIndex != -1 ? templateContent[footerTemplateComponentIndex] : null;
        const footerCurrentContentComponent =
            footerCurrentContentComponentIndex != -1 ? currentContent[footerCurrentContentComponentIndex] : null;

        const isFooterRequired = footerTemplateComponentIndex != -1;

        const existingContent = currentContent
            .slice(currentStartIndex + 1, currentContent.length)
            .filter((node) => node.type != "placeholder" && node.type != "footerComponent");

        finalContent = [...finalContent, ...existingContent];

        if (existingContent.length == 0) {
            finalContent.push({
                type: "placeholder",
                attrs: {
                    id: uuidv4(),
                },
            });
        }

        if (isFooterRequired) {
            finalContent.push({
                type: "footerComponent",
                attrs:
                    footerCurrentContentComponentIndex != -1
                        ? footerCurrentContentComponent.attrs
                        : footerTemplateComponent.attrs,
            });
        } else {
            if (footerCurrentContentComponent != -1) {
                finalContent = finalContent.filter((node) => node.type != "footerComponent");
            }
        }

        editor.commands.setContent({
            type: "doc",
            content: finalContent,
        });
        setTimeout(() => {
            dispatch(setSelectedTemplate(parseInt(template_val)));

            onSave("auto");
        }, 0);
    };

    useEffect(() => {
        if (isInit.current) {
            compareAndReplace();
        } else {
            isInit.current = true;
        }
    }, [editor, template_val]);

    useEffect(() => {
        if (selectedTemplate != -1) {
            setValue("template", "" + selectedTemplate);
        }
    }, [selectedTemplate]);

    return (
        <ThemeFormProvider form={{ control, handleSubmit: () => {} } as any} onSubmit={null}>
            <div>
                <div className="w-full pt-4 px-5">
                    <div className="text-sm text-[#667085] mb-2 flex items-center">Page Style</div>
                    <Field
                        formGroup={false}
                        name="template"
                        type="select"
                        options={[
                            { label: "Content Oriented", value: "0" },
                            { label: "Company Oriented", value: "1" },
                            { label: "Landing Page", value: "2" },
                        ]}
                        selectFieldProps={{
                            className: "!h-[34px]",
                            sx: {
                                ".MuiOutlinedInput-notchedOutline": {
                                    borderRadius: "8px !important",
                                },
                            },
                            IconComponent: ArrowDown,
                        }}
                    />
                </div>
            </div>
        </ThemeFormProvider>
    );
};

export default BoardProperties;
